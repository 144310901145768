import service from "@/utils/require"

// 服务商列表
export const getProviderServerList = (params) => service({
    url: '/providerServerItem/getProviderServerList',
    method: 'get',
    params
})

// 服务商详情
export const getClientServerDetails = (id) => service({
    url: 'providerServerItem/getClientServerDetails/' + id,
    method: 'get'
})


// pc端创建服务商商品订单，返回二维码链接
export const createServerItemOrderByQR = (params) => {
    return service.post("/serverItemOrder/createServerItemOrderByQR", params)
}

// 查询用户购买的服务订单详情
export const userServerItemOrderDetails = (orderId) => service({
    url: `/serverItemOrder/userServerItemOrderDetails/${orderId}`,
    method: 'get'
})

// 获取服务商品评价分页列表
export const getServerItemEvaluatePage = (params) => service({
    url: `/providerServerItem/getServerItemEvaluatePage`,
    method: 'get',
    params
})