<template>
  <div>
    <div
      class="grid grid-cols-3 gap-2 p-2"
      v-if="imgList.length > 0"
      v-loading="loading"
    >
      <div
        class="relative w-[131px] h-[131px] rounded-2xl"
        v-for="(item, index) in imgList"
        :key="index"
      >
        <img :src="item.imageUrlVisit" class="w-full h-full rounded-2xl" />
        <el-popover
          placement="bottom-end"
          width="100"
          trigger="hover"
          :tabindex="1"
        >
          <template #reference>
            <div
              class="absolute top-2 bg-[#54C752] right-2 rounded-sm px-1 shadow"
            >
              <i class="el-icon-more text-white"></i>
            </div>
          </template>
          <ul class="menu">
            <el-popover
              placement="right"
              width="100"
              trigger="hover"
              :tabindex="2"
              v-if="!isvip"
            >
              <template #reference v-if="item?.itemList?.length > 0">
                <li>发送到图生图</li>
              </template>
              <ul class="menu">
                <li
                  v-for="(image, index) in item.itemList"
                  :key="index"
                  class="flex items-center justify-between"
                  @click="sendToDetail(image.imageUrl, 'image')"
                >
                  参考图{{ index + 1 }}
                  <img :src="image.imageUrl" alt="" class="w-[40px] h-[40px]" />
                </li>
              </ul>
            </el-popover>
            <el-popover
              placement="right"
              width="100"
              trigger="hover"
              :tabindex="3"
              v-if="!isvip"
            >
              <template #reference v-if="item?.itemList?.length > 0">
                <li>发送到文生图</li>
              </template>
              <ul class="menu">
                <li
                  v-for="(image, index) in item.itemList"
                  :key="index"
                  class="flex items-center justify-between"
                  @click="sendToDetail(image.imageUrl, 'text')"
                >
                  参考图{{ index + 1 }}
                  <img :src="image.imageUrl" alt="" class="w-[40px] h-[40px]" />
                </li>
              </ul>
            </el-popover>
            <el-popover
              placement="right"
              width="100"
              trigger="hover"
              :tabindex="4"
              v-if="isvip"
            >
              <template #reference v-if="item?.itemList?.length > 0">
                <li>发送到图片融合</li>
              </template>
              <ul class="menu">
                <li
                  v-for="(image, index) in item.itemList"
                  :key="index"
                  class="flex items-center justify-between"
                  @click="sendToDetailVip(image.imageUrl, 'fusion')"
                >
                  参考图{{ index + 1 }}
                  <img :src="image.imageUrl" alt="" class="w-[40px] h-[40px]" />
                </li>
              </ul>
            </el-popover>
            <el-popover
              placement="right"
              width="100"
              trigger="hover"
              :tabindex="5"
              v-if="isvip"
            >
              <template #reference v-if="item?.itemList?.length > 0">
                <li>发送到条件生图</li>
              </template>
              <ul class="menu">
                <li
                  v-for="(image, index) in item.itemList"
                  :key="index"
                  class="flex items-center justify-between"
                  @click="
                    sendToDetailVip(image.imageUrl, 'conditiongeneration')
                  "
                >
                  参考图{{ index + 1 }}
                  <img :src="image.imageUrl" alt="" class="w-[40px] h-[40px]" />
                </li>
              </ul>
            </el-popover>
            <el-popover
              placement="right"
              width="100"
              trigger="hover"
              :tabindex="6"
              v-if="isvip"
            >
              <template #reference v-if="item?.itemList?.length > 0">
                <li>发送到自由创作</li>
              </template>
              <ul class="menu">
                <li
                  v-for="(image, index) in item.itemList"
                  :key="index"
                  class="flex items-center justify-between"
                  @click="sendToDetailVip(image.imageUrl, 'creative')"
                >
                  参考图{{ index + 1 }}
                  <img :src="image.imageUrl" alt="" class="w-[40px] h-[40px]" />
                </li>
              </ul>
            </el-popover>
            <li @click="toDetail(item)">重新生成</li>
            <li @click="toDetail(item)">详情信息</li>
            <li @click="toDetail(item)">下载</li>
            <li @click="deleteRecord(item)">删除</li>
          </ul>
        </el-popover>
      </div>
    </div>
    <div class="w-full h-96 flex justify-center items-center" v-else>
      <el-empty description="暂无历史记录,快去生成吧~"></el-empty>
    </div>
    <div class="w-full">
      <el-pagination
        class="mt-4"
        background
        layout="total, prev, pager, next"
        :total="total"
        :hide-on-single-page="true"
        @current-change="getHistory"
      >
      </el-pagination>
    </div>
  </div>
</template>
    
  <script>
import { deleteHistoryRecord, getUserHistory } from '@/api/workbench'

export default {
  props: {
    type: String,
    default: ''
  },
  data() {
    return {
      limit: 18,
      page: 1,
      total: 0,
      imgList: [],
      loading: false
    }
  },
  computed: {
    // 判断是否为会员
    isvip() {
      return localStorage.getItem('userInfo')
        ? JSON.parse(localStorage.getItem('userInfo'))?.isVip == 1
        : false
    }
  },
  methods: {
    async getHistory(page = 1) {
      this.loading = true
      const result = await getUserHistory({
        page: page,
        limit: this.limit,
        taskStatus: 'finish_success'
      })
      if (result) {
        this.total = result.total
        this.imgList = result.rows
      }
      this.loading = false
    },
    toDetail(item) {
      this.$message.success('跳转中...请在详情页面中操作')
      try {
        setTimeout(() => {
          this.$router.push({
            path: `/workbench/textgeneration`,
            query: { id: item.id }
          })
          window.location.reload()
        }, 1500)
      } catch (e) {
        console.log(e)
      }
    },
    sendToDetail(item, type) {
      type === 'image'
        ? this.$router.push({
            path: `/workbench/imagegeneration`,
            query: { imageUrl: item }
          })
        : this.$router.push({
            path: `/workbench/textgeneration`,
            query: { imageUrl: item }
          })
      window.location.reload()
    },
    sendToDetailVip(item, type) {
      type === 'conditiongeneration'
        ? this.$router.push({
            path: `/workbench/conditiongeneration`,
            query: { imageUrl: item }
          })
        : type === 'fusion'
        ? this.$router.push({
            path: `/workbench/fusion`,
            query: { imageUrl: item }
          })
        : this.$router.push({
            path: `/workbench/creative`,
            query: { imageUrl: item }
          })
      window.location.reload()
    },
    deleteRecord(item) {
      console.log(item)
      deleteHistoryRecord(item.id).then((res) => {
        if (res) {
          this.$message.success('删除成功')
          this.getHistory()
        }
      })
    }
  },
  mounted() {
    this.getHistory()
  }
}
</script>
    
  <style>
.menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu li {
  padding: 8px 12px;
  cursor: pointer;
}

.menu li:hover {
  background-color: #f0f0f0;
}
</style>
  