<template>
  <shejiDialog v-model="value" width="800px">
    <div class="p-2">
      <div class="flex justify-between">
        <div class="text-xl text-left mb-5">创建供应商资料</div>
        <div
          class="bg-[#54C752] w-16 h-8 rounded-2xl leading-7 text-white cursor-pointer"
          @click="save"
        >
          保存
        </div>
      </div>
      <div class="text-lg text-left mb-5">供应商联系信息</div>
      <div>
        <div class="flex items-center justify-start mt-5">
          <div class="text-left w-28 mr-2 mt-2 text-[#878787] text-sm mb-2">
            * 公司名称
          </div>
          <el-input
            v-model="form.companyName"
            placeholder="请输入公司名称"
          ></el-input>
        </div>
        <div class="flex items-center justify-start mt-5">
          <div class="text-left w-28 mr-2 mt-2 text-[#878787] text-sm mb-2">
            * 联系人
          </div>
          <el-input
            v-model="form.linkName"
            placeholder="请输入联系人名称"
          ></el-input>
        </div>
        <div class="flex items-center justify-start mt-5">
          <div class="text-left w-28 mr-2 mt-2 text-[#878787] text-sm mb-2">
            * 联系电话
          </div>
          <el-input
            v-model="form.linkPhone"
            placeholder="请输入电话号码"
            maxlength="11"
          ></el-input>
        </div>
        <div class="flex items-center justify-start mt-5">
          <div class="text-left w-28 mr-2 mt-2 text-[#878787] text-sm mb-2">
            * 联系地址
          </div>
          <el-autocomplete
            v-model="form.linkAddress"
            placeholder="请输入详细地址"
            :fetch-suggestions="querySearch"
            @select="handleSelect"
          >
            <template #suffix>
              <el-link type="primary" class="absolute top-1" @click="showMap"
                >定位</el-link
              >
            </template>
          </el-autocomplete>
        </div>
        <div class="flex items-center justify-start mt-5">
          <div class="text-left w-28 mr-2 mt-2 text-[#878787] text-sm mb-2">
            电子地图
          </div>
          <div
            id="container"
            style="width: 400px; height: 200px; margin-left: -15px"
          ></div>
        </div>
        <div class="flex items-center justify-start mt-5">
          <div class="text-left w-28 mr-2 mt-2 text-[#878787] text-sm mb-2">
            企业简介
          </div>
          <el-input type="textarea" placeholder="请输入企业简介"></el-input>
        </div>
      </div>
    </div>
  </shejiDialog>
</template>

<script>
import { addProvider } from '@/api/workbench'
import shejiDialog from '@/components/shejiDialog'
import AMapLoader from '@amap/amap-jsapi-loader'
export default {
  components: {
    shejiDialog
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        if (val) {
          this.initAMap()
        }
        this.$emit('update:modelValue', val)
      }
    }
  },
  data() {
    return {
      location: '',
      mapVisible: false,
      map: null,
      geocoder: null,
      autoCompleter: null,
      form: {
        companyName: '',
        linkName: '',
        linkPhone: '',
        linkAddress: '',
        longitude: '',
        latitude: ''
      }
    }
  },
  methods: {
    initAMap() {
      window._AMapSecurityConfig = {
        securityJsCode: 'cadcdec9265cfa9abb365079ef94d3b3'
      }
      AMapLoader.load({
        key: '37b3c1c391e2a3c4e51b6eafccd81107', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.Scale', 'AMap.AutoComplete', 'AMap.Geocoder'] //需要使用的的插件列表，如比例尺'AMap.Scale'，支持添加多个如：['...','...']
      })
        .then((AMap) => {
          this.map = new AMap.Map('container', {
            // 设置地图容器id
            viewMode: '3D', // 是否为3D地图模式
            zoom: 11, // 初始化地图级别
            center: [116.397428, 39.90923] // 初始化地图中心点位置
          })
          this.autoCompleter = new AMap.AutoComplete({
            input: 'location',
            country: '中国'
          })
          this.geocoder = new AMap.Geocoder()
        })
        .catch((e) => {
          console.log(e)
        })
    },
    querySearch(queryString, cb) {
      console.log(queryString)
      if (!this.autoCompleter) {
        return
      }
      this.autoCompleter.search(queryString, (status, result) => {
        if (status === 'complete') {
          const suggestions = result.tips.map((tip) => ({
            value: tip.name,
            district: tip.district,
            address: tip.address
          }))
          cb(suggestions)
        }
      })
    },
    handleSelect(item) {
      this.location = item.value
      this.showMap()
    },
    async showMap() {
      if (this.location) {
        this.geocoder.getLocation(this.location, (status, result) => {
          console.log(status, location)
          if (status === 'complete' && result.info === 'OK') {
            this.form.longitude = result.geocodes[0].location.lng
            this.form.latitude = result.geocodes[0].location.lat
            if (!this.map) {
              this.map = new AMap.Map('container', {
                zoom: 13,
                center: [
                  result.geocodes[0].location.lng,
                  result.geocodes[0].location.lat
                ]
              })
            } else {
              this.map.setCenter([
                result.geocodes[0].location.lng,
                result.geocodes[0].location.lat
              ])
            }
          }
        })
      }
    },
    // 保存供应商
    async save() {
      if (Object.keys(this.form).some((item) => item === '')) {
        this.$message.error('参数错误')
        return
      }
      const result = await addProvider(this.form)
      if (result) {
        this.$message.success('保存成功')
        this.value = false
      }
    }
  },
  mounted() {
    this.initAMap()
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-autocomplete {
  width: 100%;
  position: relative;
}
</style>