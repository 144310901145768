<template>
  <div class="facusAfansList">
    <div class="facusAfansList-item" v-for="(item, index) in list" :key="index">
      <img class="w-full h-[114px] rounded-[9px]" :src="item.cover" />
      <div class="w-[62px] h-[30px] relative">
        <img
          class="absolute w-[62px] h-[62px] rounded-full border-4 border-white -top-[32px]"
          :src="item.avatar"
        />
      </div>
      <div class="mt-[7px] text-lg font-bold text-[#404040]">
        {{ item.nickname }}
      </div>
      <div
        v-if="item.isAttend"
        class="attendBtn border border-[#969696] cursor-pointer"
        @click="handleCancel(item)"
      >
        已关注
      </div>
      <div v-else class="attendBtn bg-[#54C752] text-white cursor-pointer">
        关注
      </div>
      <div class="mt-[15px] text-[#3D3D3D] text-xs">
        {{ item.desc || '这个人很懒，什么都没有留下。' }}
      </div>
    </div>
    <comfirmDialog
      v-model="cancelVisible"
      content="确认取消关注？"
      @confirm="handleCancelAttend"
    ></comfirmDialog>
  </div>
</template>

<script>
import comfirmDialog from '@/components/comfirmDialog/index.vue'
export default {
  name: 'facusAfansList',
  components: { comfirmDialog },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      cancelVisible: false
    }
  },
  methods: {
    handleCancel(item) {
      this.cancelVisible = true
    },
    handleCancelAttend() {
      this.cancelVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.facusAfansList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 44px;
  &-item {
    width: 324px;
    height: 300px;
    padding: 16px 17px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #a5a5a5;
    border-radius: 15px;
    margin-right: 34px;
    margin-bottom: 36px;
  }
  &-item:nth-child(4n) {
    margin-right: 0;
  }
}
.attendBtn {
  width: 96px;
  height: 28px;
  border-radius: 14px;
  font-size: 16px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>