<template>
  <div class="flex items-center justify-center">
    <div class="w-[1400px]">
      <!-- tab -->
      <div class="w-full flex items-center justify-between">
        <div class="flex">
          <div
            class="mx-6 pb-2 cursor-pointer text_gray"
            v-for="(item, index) in tabList"
            :key="index"
            :class="{ active_router: index === tabIndex }"
            @click="tabIndex = index"
          >
            <div class="text-lg">
              {{ item.title }}
            </div>
          </div>
        </div>
        <div
          v-if="tabIndex == 1"
          class="w-[107px] h-[33px] rounded-[50px] border border-[#C4BFBF] flex items-center justify-center text-base"
        >
          一键回关
        </div>
      </div>
      <!-- list -->
      <facusAfansList v-if="dataList.length" :list="dataList"></facusAfansList>
      <!-- page -->
      <div
        v-if="dataList.length"
        class="w-full flex items-center justify-between"
      >
        <div>共{{ total }}条数据</div>
        <el-pagination background layout="prev, pager, next" :total="total">
        </el-pagination>
      </div>
      <!-- nodata -->
      <noData v-else></noData>
    </div>
  </div>
</template>

<script>
import noData from '@/components/noData/index.vue'
import facusAfansList from '../components/facusAfansList.vue'
export default {
  name: 'facusAfans',
  components: { noData, facusAfansList },
  data() {
    return {
      dataList: [],
      total: 20,
      tabIndex: 0,
      tabList: [{ title: '关注' }, { title: '粉丝' }]
    }
  },
  mounted() {
    const defaultIndex = this.$route.query.defaultIndex
    this.tabIndex = Number(defaultIndex) || 0
    this.getList()
  },
  methods: {
    getList() {
      this.dataList = [
        {
          nickname: '李厚霖d',
          isAttend: true,
          desc: null,
          cover:
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
          avatar:
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg'
        },
        {
          nickname: 'DGDGG',
          isAttend: false,
          desc: null,
          cover:
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
          avatar:
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg'
        },
        {
          nickname: 'DFEHHJJ',
          isAttend: true,
          desc: null,
          cover:
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
          avatar:
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg'
        },
        {
          nickname: 'FDGBBV',
          isAttend: true,
          desc: null,
          cover:
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
          avatar:
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg'
        },
        {
          nickname: 'DSFSDFWQ',
          isAttend: true,
          desc: null,
          cover:
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
          avatar:
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg'
        },
        {
          nickname: '李厚霖d',
          isAttend: true,
          desc: null,
          cover:
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
          avatar:
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg'
        },
        {
          nickname: '李厚霖d',
          isAttend: true,
          desc: null,
          cover:
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
          avatar:
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg'
        },
        {
          nickname: '李厚霖d',
          isAttend: true,
          desc: null,
          cover:
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
          avatar:
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.text_gray {
  color: #666666;
}
.active_router {
  @apply font-black relative;
  color: #000;
  &::after {
    @apply block absolute right-0.5 bottom-0.5 h-1;
    content: '';
    width: 20px;
    border-radius: 2.5px;
    background: #54c752;
  }
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #d000ff;
}
</style>