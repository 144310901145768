var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"memberCardHead",style:({
    color: _vm.vipColor[_vm.userInfo.level] ? _vm.vipColor[_vm.userInfo.level] : '#AFAFAF'
  })},[(_vm.userInfo.level == 1)?_c('img',{staticClass:"bg",attrs:{"src":require("@/assets/vip/vip1.png")}}):(_vm.userInfo.level == 2)?_c('img',{staticClass:"bg",attrs:{"src":require("@/assets/vip/vip2.png")}}):(_vm.userInfo.level == 3)?_c('img',{staticClass:"bg",attrs:{"src":require("@/assets/vip/vip3.png")}}):_c('img',{staticClass:"bg",attrs:{"src":require("@/assets/vip/vip.png")}}),_c('div',{staticStyle:{"display":"flex","flex":"1"}},[_c('img',{staticClass:"avatar",attrs:{"src":_vm.userInfo.avatar}}),_c('div',[_c('div',{staticClass:"userName"},[_vm._v(_vm._s(_vm.userInfo.userName))]),_c('div',{staticStyle:{"display":"flex","align-items":"center","margin-top":"6px"}},[(_vm.userInfo.level == 1)?_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/vip/vip1_icon.png")}}):_vm._e(),(_vm.userInfo.level == 2)?_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/vip/vip2_icon.png")}}):_vm._e(),(_vm.userInfo.level == 3)?_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/vip/vip3_icon.png")}}):_vm._e(),_c('div',{staticClass:"no"},[_vm._v("NO: "+_vm._s(_vm.userInfo.userId))])])])]),_c('div',{staticClass:"item",style:({
      'border-left': `1px solid ${
        _vm.vipColor[_vm.userInfo.level] ? _vm.vipColor[_vm.userInfo.level] : '#AFAFAF'
      }`,
      'border-right': `1px solid ${
        _vm.vipColor[_vm.userInfo.level] ? _vm.vipColor[_vm.userInfo.level] : '#AFAFAF'
      }`
    })},[_c('div',[_vm._v("加速卡次数：")]),_c('div',{staticStyle:{"display":"flex","align-items":"baseline"}},[_c('div',{staticClass:"no"},[_vm._v("本月")]),(_vm.userInfo.isVip && _vm.userInfo.isVip == 1)?_c('div',{staticClass:"mNum"},[_vm._v(" "+_vm._s(_vm.userInfo.freeFastNum - _vm.userInfo.freeFastNumMonth || 0)+"/"+_vm._s(_vm.userInfo.freeFastNum || 0)+" ")]):_c('div',{staticClass:"mNum"},[_vm._v("0 / 0")])])]),(_vm.userInfo.isVip)?_c('div',{staticClass:"item",style:({
      'border-right': `1px solid ${
        _vm.vipColor[_vm.userInfo.level] ? _vm.vipColor[_vm.userInfo.level] : '#AFAFAF'
      }`
    })},[_c('div',[_vm._v("AI生成次数：")]),_vm._m(0)]):_c('div',{staticClass:"item",style:({
      'border-right': `1px solid ${
        _vm.vipColor[_vm.userInfo.level] ? _vm.vipColor[_vm.userInfo.level] : '#AFAFAF'
      }`
    })},[_c('div',[_vm._v("AI能力：")]),_c('div',{staticStyle:{"display":"flex","align-items":"baseline"}},[_c('div',{staticClass:"no"},[_vm._v("电力券")]),_c('div',{staticClass:"mNum"},[_vm._v(_vm._s(_vm.userInfo.powerCoupon || 0))]),_c('div',{staticClass:"no"},[_vm._v("张")])])]),_c('div',{staticStyle:{"flex":"1"}},[_c('div',{staticStyle:{"display":"flex","padding-left":"60px","box-sizing":"border-box"}},[(_vm.userInfo.level == 1)?_c('img',{attrs:{"src":require("@/assets/vip/vip1_tag.svg")}}):(_vm.userInfo.level == 2)?_c('img',{attrs:{"src":require("@/assets/vip/vip2_tag.svg")}}):(_vm.userInfo.level == 3)?_c('img',{attrs:{"src":require("@/assets/vip/vip3_tag.svg")}}):_c('img',{attrs:{"src":require("@/assets/vip/vip_tag.svg")}}),(_vm.userInfo.isVip)?_c('div',{staticClass:"tips"},[_vm._v(" 鞋样设计 | 有效期至:"+_vm._s(_vm.userInfo.vipEndTime)+" ")]):_c('div',{staticClass:"tips"},[_vm._v("开通会员享无限创意特权")])]),_c('div',{staticStyle:{"display":"flex","justify-content":"center","margin-top":"24px"}},[(_vm.userInfo.isVip)?_c('div',{class:['open', `open${_vm.userInfo.level}`]},[_vm._v(" 立即续费 ")]):_c('div',{staticClass:"open"},[_vm._v("立即开通")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","align-items":"baseline"}},[_c('div',{staticClass:"no"},[_vm._v("有效期内")]),_c('div',{staticClass:"mNum"},[_vm._v("无限")])])
}]

export { render, staticRenderFns }