<template>
  <comfirmDialog
    width="678px"
    height="601px"
    title="封面设置"
    titleAlign="left"
    confirmTxt="设为封面"
    :showCancel="false"
    v-model="coverDialogVisible"
    @confirm="handleConfirm"
  >
    <!-- <template slot="titleRight">
      <div class="titleRight">
        最多选6图，按选择顺序依次展示。第1图为合集详情页顶部封面展示图。
      </div>
    </template> -->
    <div class="albumInfoCover">
      <el-checkbox-group v-model="checkList" :max="1" @change="handleCheckbox">
        <div class="albumInfoCover-list" v-loading="loading">
          <div
            class="albumInfoCover-item"
            v-for="(item, index) in albumList"
            :key="index"
          >
            <albumInfoItem
              :isSelected="true"
              :imgUrl="item.imgUrl"
              :issue="false"
              :checkValue="item.id"
              :showMask="checkList.includes(item.id)"
            ></albumInfoItem>
          </div>
        </div>
      </el-checkbox-group>
    </div>
  </comfirmDialog>
</template>

<script>
import albumInfoItem from './albumInfoItem.vue'
import comfirmDialog from '@/components/comfirmDialog/index.vue'
import { userCollectionImgList, collectionCoverImg } from '@/api/album/index.js'
export default {
  name: 'albumInfoCover',
  components: {
    albumInfoItem,
    comfirmDialog
  },
  props: {
    coverImg: {
      type: Object,
      default: () => {}
    },
    collectionId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      checkList: [],
      albumList: [],
      loading: false,
      coverDialogVisible: false
    }
  },
  methods: {
    /* 打开弹窗 */
    handleOpen() {
      this.handleInit()
      this.coverDialogVisible = true
      if (this.coverImg && this.coverImg.id) {
        this.checkList = [this.coverImg.id]
      }
    },

    /* 多选 */
    handleCheckbox(e) {
      console.log('handleCheckbox', e, this.checkList)
    },

    /* 初始化 */
    async handleInit() {
      let result = await userCollectionImgList({
        page: 1,
        limit: -1,
        collectionArticleId: this.collectionId
      }).catch((e) => {})
      this.albumList = (result && result.rows) || []
    },

    /* 设为封面 */
    handleConfirm() {
      if (!this.checkList.length) {
        return this.$message.error('请选择一个封面')
      }
      collectionCoverImg(this.collectionId, this.checkList[0]).then((res) => {
        if (!res.code == 200) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.checkList = []
        this.coverDialogVisible = false
        this.$emit('fresh')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.titleRight {
  font-size: 12px;
  color: #646464;
  margin-left: 15px;
  font-weight: normal;
}
.albumInfoCover {
  flex: 1;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: scroll;
  -ms-overflow-style: none; /* 适用于 Internet Explorer 和旧版 Edge */
  scrollbar-width: none; /* 适用于 Firefox */
  &-list {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
  }
  &-item {
    width: 151px;
    margin-right: 10px;
  }
  &-item:nth-child(4n) {
    margin-right: 0;
  }
}
/* Webkit 浏览器 */
.albumInfoCover::-webkit-scrollbar {
  display: none;
}
</style>