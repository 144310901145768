<template>
  <div>
    <div
      class="border-l border-b w-full h-12 bg-white flex justify-center items-center"
    >
      <div
        class="w-4/5 h-9 rounded-lg bg-[#F0F0F0] flex justify-center items-center px-2 py-1"
      >
        <div
          class="flex-1 h-7 rounded-lg cursor-pointer leading-7 select-none"
          :class="activeItem === 'other' && 'text-white bg-black'"
          v-if="leftContentName"
          @click="activeItem = 'other'"
        >
          {{ leftContentName }}
        </div>
        <div
          class="flex-1 h-7 leading-7 rounded-lg cursor-pointer select-none"
          :class="activeItem === 'history' && 'text-white bg-black'"
          @click="activeItem = 'history'"
        >
          历史记录
        </div>
      </div>
    </div>
    <!-- 其他容器 - 通过插槽传入 -->
    <div v-if="activeItem === 'other'">
      <slot name="firstTag"></slot>
    </div>
    <!-- 历史记录 -->
    <div v-else>
      <HistoryRecord :type="type" />
    </div>
  </div>
</template>

<script>
import HistoryRecord from './historyRecord.vue'

export default {
  props: {
    leftContentName: {
      type: String,
      default: '参数设置'
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activeItem: this.leftContentName ? 'other' : 'history'
    }
  },
  components: {
    HistoryRecord
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #54c752;
  color: #fff;
}
</style>