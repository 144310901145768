<template>
  <div>
    <el-dialog
      :visible.sync="value"
      @open="get"
      append-to-body
      :show-close="false"
    >
      <div class="-mt-4">
        <div class="flex items-center">
          <div
            class="w-16 h-8 leading-7 border text-center rounded-2xl bg-[#D8D8D8] text-black cursor-pointer"
            @click="value = false"
          >
            返回
          </div>
          <div class="ml-3 text-2xl text-black">交易详情</div>
        </div>
        <div class="mt-4">
          <div class="h-32 rounded-xl p-2 bg-[#262626] text-white">
            <div
              class="flex justify-between items-center pb-2 mb-2 border-b border-[#1A1A1A] font-medium"
            >
              <div
                class="text-lg text-white font-extrabold px-1"
                :class="
                  vipList.find((i) => Number(i.key) == Number(info.vipLevel))
                    ?.ename
                "
              >
                {{
                  vipList.find((i) => Number(i.key) == Number(info.vipLevel))
                    ?.name
                }}权益卡
              </div>
              <div>{{ info.remarkTitle }}</div>
            </div>
            <div>
              <div>
                <div>会员生效时间:</div>
                <div>{{ info.updateTime }}</div>
              </div>
              <div class="mt-3">
                <div>会员到期时间:</div>
                <div>{{ info.vipEndTime }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4 rounded-xl p-2 bg-[#262626] text-white">
          <div>
            <div>订单编号:</div>
            <div>{{ info.id }}</div>
          </div>
          <div class="mt-3">
            <div>金额:</div>
            <div>{{ info.totalMoney }}</div>
          </div>
          <div class="mt-3">
            <div>下单时间:</div>
            <div>{{ info.createTime }}</div>
          </div>
          <div class="mt-3">
            <div>支付时间:</div>
            <div>{{ info.updateTime }}</div>
          </div>
          <div class="mt-3">
            <div>支付方式:</div>
            <div>{{ info.payChannelName }}</div>
          </div>
          <div class="mt-3">
            <div>收款方:</div>
            <div>{{ info.payee }}</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
      
  <script>
import { getMemberDetails, getMemberList } from '@/api/vip'

export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    }
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  },
  data() {
    return {
      info: {
        id: '',
        userId: '',
        vipMoney: '',
        vipLevel: '1',
        vipIpCount: '',
        buyIpCount: '',
        ipMoney: '',
        totalMoney: '',
        orderStatus: '',
        payPlateformNo: '',
        payPlateformChannel: '',
        payPlateformStatus: '',
        payChannel: '',
        payChannelName: '',
        vipTime: '',
        createTime: '',
        updateTime: '',
        vipEndTime: '',
        remarkTitle: '鞋样设计',
        payee: ''
      },
      vipList: [
        {
          key: 1,
          name: '白银',
          ename: 'baiyin'
        },
        {
          key: 2,
          name: '黄金',
          ename: 'huangjin'
        },
        {
          key: 3,
          name: '铂金',
          ename: 'bojin'
        }
      ]
    }
  },
  methods: {
    async get() {
      const result = await getMemberDetails({
        id: this.id
      })
      if (result) {
        this.info = result.data
      }
    }
  }
}
</script>
      
  <style lang="scss" scoped>
.baiyin {
  background: radial-gradient(rgba(237, 240, 241, 1), rgba(201, 217, 222, 1));
  color: #7b91a5;
  border-radius: 6px;
}
.huangjin {
  background: radial-gradient(rgba(255, 236, 193, 1), rgba(234, 167, 113, 1));
  color: #c08049;
  border-radius: 6px;
}
.bojin {
  background: radial-gradient(rgba(247, 244, 255, 1), rgba(171, 148, 254, 1));
  color: #885dd6;
  border-radius: 6px;
}
</style>
    