<template>
  <commonLayout
    title="我的订单"
    :tabList="tabList"
    :mt="64"
    @tab="handleTab"
    @search="handleSearch"
  >
    <!-- 电力券、服务 -->
    <commonCollapse
      v-if="(current == 1 || current == 2) && orderList.length"
      v-loading="loading"
      :list="orderList"
    >
      <template v-slot="{ item, mainItem }">
        <div class="myOrder-wrap" @click="handleSkip(item)">
          <div class="myOrder-item">
            <div v-if="current == 1" class="title">
              购买电力券-支付{{ item.payMoney || 0 }}元
            </div>
            <div v-if="current == 2" class="title">
              {{ item.serverItemTitle }}-支付{{ item.totalPrice || 0 }}元
            </div>
          </div>
          <div class="myOrder-item">
            <div>{{ item.createTime }}</div>
            <div class="_flex" v-if="current == 1">
              <div>+{{ item.powerCoupon || 0 }}</div>
              <img class="icon" src="../assets/order-electron.svg" />
            </div>
          </div>
        </div>
      </template>
    </commonCollapse>

    <!-- 会员 -->
    <div class="myOrder-vip" v-else-if="current == 3 && orderList.length">
      <div
        v-for="(item, index) in orderList"
        :key="index"
        :class="['item', `item${Number(item.vipLevel)}`]"
        @click="handleSkip(item)"
      >
        <div class="info">
          <div v-if="item.vipLevel == 1" class="title">白银权益卡</div>
          <div v-if="item.vipLevel == 2" class="title">黄金权益卡</div>
          <div v-if="item.vipLevel == 3" class="title">铂金权益卡</div>
          <div class="name">{{ item.remarkTitle }}</div>
        </div>
        <div class="line"></div>
        <div class="info">
          <div>会员生效时间：</div>
          <div>{{ item.updateTime }}</div>
        </div>
        <div class="info">
          <div>会员到期时间：</div>
          <div>{{ item.vipEndTime }}</div>
        </div>
        <div class="info">
          <div>支 付 金 额：</div>
          <div>￥{{ item.totalMoney }} 元</div>
        </div>
        <div class="info">
          <div>交易详情</div>
          <img v-if="item.vipLevel == 1" src="../assets/order-vip1.svg" />
          <img v-if="item.vipLevel == 2" src="../assets/order-vip2.svg" />
          <img v-if="item.vipLevel == 3" src="../assets/order-vip3.svg" />
        </div>
      </div>
    </div>

    <el-empty v-else :image-size="200"></el-empty>
  </commonLayout>
</template>

<script>
import dayjs from 'dayjs'
import commonLayout from '../components/commonLayout.vue'
import commonCollapse from '../components/commonCollapse.vue'
import dateArrayMixins from '../mixins/dateArrayMixins.js'
import {
  userPayList,
  userOrderList,
  userServerItemOrderList
} from '@/api/user/myOrder.js'
export default {
  name: 'myOrder',
  mixins: [dateArrayMixins],
  components: { commonLayout, commonCollapse },
  data() {
    return {
      current: null,
      searchKey: null,
      loading: false,
      orderList: [],
      tabList: [
        {
          title: '电力券',
          key: '1',
          api: userOrderList,
          queryPage: 'pageNum',
          querySize: 'pageSize',
          queryStatus: 'payStatus'
        },
        {
          title: '服务',
          key: '2',
          api: userServerItemOrderList,
          queryPage: 'page',
          querySize: 'limit',
          queryStatus: 'payStatus'
        },
        {
          title: '会员',
          key: '3',
          api: userPayList,
          queryPage: 'page',
          querySize: 'limit',
          queryStatus: 'orderStatus'
        }
      ]
    }
  },
  methods: {
    /* 点击tab */
    handleTab(key) {
      this.current = key
      this.handleInit()
    },

    /* 搜索 */
    handleSearch(key) {
      this.searchKey = key
      this.handleInit()
    },

    /* 获取列表 */
    handleInit() {
      let query = {}
      let item = this.tabList.find((v) => v.key == this.current)
      if (!item) return
      query[item.queryPage] = 1
      query[item.querySize] = -1
      //   query[item.queryStatus] = 1
      //   if (this.current == 2) {
      //     query.orderStatus = 1
      //   }
      this.loading = true
      item
        .api(query)
        .then((res) => {
          let list = (res && res.rows) || []
          if (this.current == 1 || this.current == 2) {
            list = list.map((v) => {
              v.title = dayjs(v.createTime).format('YYYY年MM月')
              return v
            })
            list = this.classifyArrayGroupBySameFieldAlpha(list, 'title')
          }
          if (this.current == 3) {
            list = list.map((v) => {
              v.vipEndTime =
                v.vipEndTime &&
                dayjs(v.vipEndTime).format('YYYY-MM-DD HH:mm:ss')
              return v
            })
          }
          this.orderList = list
          console.log(`结果${this.current}`, list)
        })
        .finally(() => {
          this.loading = false
        })
    },

    /* 跳转 */
    handleSkip(item) {
      this.$router.push({
        path: '/my/myOrderInfo',
        query: {
          id: item.id,
          type: this.current
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.myOrder {
  &-wrap {
    padding: 14px 0;
    box-sizing: border-box;
    border-bottom: 1px solid #d8d8d8;
    cursor: pointer;
  }
  &-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #3d3d3d;
    .title {
      font-size: 16px;
      font-weight: 500;
      color: #000000;
    }
    .icon {
      margin-left: 6px;
    }
  }
  &-item + &-item {
    margin-top: 12px;
  }
  &-vip {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 675px;
      height: 176px;
      border-radius: 10px;
      padding: 16px;
      box-sizing: border-box;
      margin-bottom: 44px;
      margin-right: 46px;
      overflow: hidden;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
    }
    .item:nth-child(2n) {
      margin-right: 0;
    }
    .item1 {
      color: #7b91a5;
      background: radial-gradient(55% 55% at 13% 5%, #edf0f1 0%, #c9d9de 100%);
      .line {
        width: 100%;
        height: 1px;
        background: #b6c8cd;
        margin-top: 11px;
        margin-bottom: 8px;
      }
    }
    .item2 {
      color: #925e32;
      background: radial-gradient(123% 123% at 0% 10%, #ffecc1 0%, #eaa771 99%);
      .line {
        width: 100%;
        height: 1px;
        background: #d1a579;
        margin-top: 11px;
        margin-bottom: 8px;
      }
    }
    .item3 {
      color: #885dd6;
      background: linear-gradient(
        104deg,
        #f7f4ff -8%,
        #d3bafc 38%,
        #ab94fe 87%
      );
      .line {
        width: 100%;
        height: 1px;
        background: #ac8bea;
        margin-top: 11px;
        margin-bottom: 8px;
      }
    }
    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 18px;
      box-sizing: border-box;
    }
    .info + .info {
      margin-top: 5px;
    }
    .title {
      font-weight: 900;
      font-size: 16px;
    }
    .name {
      font-size: 16px;
      font-weight: 500;
    }
  }
}
._flex {
  display: flex;
  align-items: center;
}
</style>