<template>
  <div>
    <comfirmDialog
      v-model="joinDialogVisible"
      width="430px"
      height="400px"
      title="加入合集"
      titleAlign="left"
      confirmTxt="确定"
      :showCancel="false"
      @confirm="handleConfirm"
    >
      <div class="albumCoverJoin" v-if="coverList.length">
        <el-radio-group v-model="checkImg" style="width: 100%">
          <div
            class="albumCoverJoin-item"
            v-for="(item, index) in coverList"
            :key="index"
          >
            <div style="display: flex; align-items: center">
              <el-radio :label="item.id"></el-radio>
              <div class="open" v-if="item.isOpen == 1">公开</div>
              <div class="open noopen" v-else>私有</div>
              <div class="name">{{ item.title }}</div>
            </div>
            <div class="name">{{ item.collectionCount || 0 }}组</div>
          </div>
        </el-radio-group>
      </div>
      <div v-else></div>
    </comfirmDialog>
  </div>
</template>

<script>
import comfirmDialog from '@/components/comfirmDialog/index.vue'
import { userCollectionList, batchAddImg } from '@/api/album/index.js'
export default {
  name: 'albumCoverJoin',
  components: { comfirmDialog },
  data() {
    return {
      checkList: [],
      coverList: [],
      checkImg: null,
      joinDialogVisible: false
    }
  },
  methods: {
    /* 打开弹窗 */
    openJoinDialog(checkList) {
      this.checkImg = null
      this.handleInitCover()
      this.checkList = checkList
      this.joinDialogVisible = true
    },

    /* 获取已有合集列表 */
    async handleInitCover() {
      let result = await userCollectionList({ page: 1, limit: -1 }).catch(
        (e) => {}
      )
      result = (result && result.rows) || []
      this.coverList = result
    },

    /* 点击按钮 */
    handleConfirm() {
      batchAddImg(this.checkImg, this.checkList).then((res) => {
        if (res.code != 200) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.joinDialogVisible = false
        this.$emit('fresh')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.albumCoverJoin {
  width: 100%;
  height: 300px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* 适用于 Internet Explorer 和旧版 Edge */
  scrollbar-width: none; /* 适用于 Firefox */
  margin-top: 10px;
  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    box-sizing: border-box;
    .open {
      width: 40px;
      height: 21px;
      border-radius: 6px;
      background: #d000ff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #ffffff;
      margin-right: 10px;
    }
    .noopen {
      background: #000000;
    }
    .name {
      font-size: 16px;
      color: #000;
    }
  }
}
/* Webkit 浏览器 */
.albumCoverJoin::-webkit-scrollbar {
  display: none;
}
::v-deep .el-radio {
  margin-right: 10px;
}
::v-deep .el-radio__label {
  display: none;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: #54c752;
  background: #54c752;
}
</style>