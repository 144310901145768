<template>
  <div>
    <div class="discover_bg">
      <div class="flex justify-between">
        <div class="flex items-center">
          <img
            :src="articleInfo.creatorUser.avatar"
            alt=""
            class="w-10 h-10 rounded-full"
          />
          <span class="text-white ml-4">{{articleInfo.creatorUser.nickName}}</span>
          <div
            v-if="!articleInfo.isAttenCreator"
            class="w-16 h-6 text-center bg-[#54C752] rounded-xl text-white ml-4 text-sm leading-6 cursor-pointer"
          >
            +关注
          </div>
        </div>
        <div class="flex w-14 h-6 bg-[#FFF] rounded-xl justify-between p-1">
          <img src="@/assets/electron.svg" alt="" class="cursor-pointer" />
          <img src="@/assets/share.svg" alt="" class="cursor-pointer" />
        </div>
      </div>
      <div class="flex">
        <div
          class="w-16 h-6 text-center bg-[#000000] rounded font-extrabold italic text-white leading-6 cursor-pointer"
        >
          合集
        </div>
        <div class="ml-4 font-extrabold text-white">{{ articleInfo.title || "" }}</div>
      </div>
      <div class="flex text-white font-medium text-left">
        {{ articleInfo.content || "" }}
      </div>
    </div>

    <div></div>

    <div class="grid grid-cols-6 gap-3 mt-4">
      <div class="w-72" v-for="(item,index) in articleList" :key="index">
        <img :src="item.imgUrl" alt="" class="w-72 rounded-xl" />,
        <div
          class="flex w-72 h-16 flex-col justify-center items-center bg-[#54C752] text-white rounded-3xl cursor-pointer"
        >
          <span class="text-2xl font-black">一键同款</span>
          <div class="flex text-sm">
            （消耗 <img src="@/assets/electron.svg" /> -{{item.sameStyleCoupon}}电力券）
          </div>
        </div>
      </div>
    </div>

    <comments></comments>
  </div>
</template>

<script>
import comments from "@/components/comments";
import {
  collectionDetails,
  userCollectionImgList,
  createSameAiJob
} from "@/api/album/index.js";

export default {
  components: {
    comments
  },
  data() {
    return {
      articleId: "",
      articleInfo: {},
      queryParam: {
        page: 1,
        limit: 10,
        collectionArticleId: ""
      },
      articleList: []
    };
  },
  mounted() {
    this.articleId = this.$route.params.id;
    this.queryParam.collectionArticleId = this.articleId;
    this.getCollectionDetails();
    this.getUserCollectionImgList()
  },
  methods: {
    getCollectionDetails() {
      collectionDetails(this.articleId).then(res => {
        this.articleInfo = res.data;
      });
    },
    getUserCollectionImgList() {
      userCollectionImgList(this.queryParam).then(res => {
        this.articleList = res.rows;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.discover_bg {
  @apply w-full h-52 rounded-xl flex flex-col justify-around px-6 py-1;
  background: url('@/assets/discoverDetailbg.jpg') no-repeat;
  background-size: cover;
}
</style>
