/**
 * 下载图片
 * @param {*} imageUrl 图片地址
 * @param {*} imgName 图片名称
 * @param {*} callback 回调函数
 */
export const downLoadFile = async (imageUrl, imgName, callback) => {
    const response = await fetch(imageUrl)
    const blob = await response.blob()
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = imgName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
    callback && callback();
}