<template>
  <div>
    <div class="grid views" v-if="articleList.length">
      <div
        class="mt-2 rounded-xl cursor-pointer group"
        v-for="(item, index) in articleList"
        :key="index"
        @click="$router.push(`/works/detail/${item.id}`)"
      >
        <PreviewCard
          :isMask="false"
          :isInfo="false"
          :sourceId="item.id"
          :cover="item.coverImgUrl"
          :title="item.title"
          :isLike="item.isAtten"
          @upateIsAtten="item.isAtten = $event"
        />
      </div>
    </div>
    <noData v-else></noData>
  </div>
</template>

<script>
import { creationArticleFindMiniList } from '@/api/user/home'
import noData from '@/components/noData'
import PreviewCard from '@/components/previewCard'
export default {
  name: 'homeTabAi',
  components: { PreviewCard, noData },
  props: ['sortFields', 'sortMode', 'userId'],
  data() {
    return {
      articleList: []
    }
  },
  methods: {
    handleInit() {
      let query = {}
      query.page = 1
      query.limit = -1
      //   query.articleType = 1
      query.userId = this.userId
      query.sortFields = this.sortFields
      query.sortMode =
        this.sortMode == 2 ? 'asc' : this.sortMode == 3 ? 'desc' : null
      creationArticleFindMiniList(query).then((res) => {
        this.articleList = (res && res.rows) || []
        console.log('**Ai作品**', this.articleList)
      })
    }
  }
}
</script>

<style>
.views {
  grid-template-columns: repeat(6, 295px);
}
</style>