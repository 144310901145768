<template>
  <commonLayout
    title="我的服务"
    :tabList="tabList"
    :mt="55"
    @tab="handleTab"
    @search="handleSearch"
  >
    <myServiceList
      v-if="serviceList.length"
      v-loading="loading"
      :serviceList="serviceList"
      @fresh="handleInit"
    ></myServiceList>

    <el-empty v-else :image-size="200">
      <div class="nullTxt" @click="$router.push('/service')">
        进入"服务商"页面探索一下吧
      </div>
    </el-empty>
  </commonLayout>
</template>

<script>
import commonLayout from '../components/commonLayout.vue'
import myServiceList from '../components/myServiceList.vue'
import { userServerItemOrderList } from '@/api/user/myService.js'
export default {
  name: 'myService',
  components: {
    commonLayout,
    myServiceList
  },
  data() {
    return {
      current: null,
      loading: false,
      searchKey: null,
      serviceList: [],
      tabList: [
        { title: '全部', key: '-1' },
        { title: '未支付', key: '1' },
        { title: '进行中', key: '2' },
        { title: '已完成', key: '5' },
        { title: '待评价', key: '4' }
      ]
    }
  },
  methods: {
    /* 点击tab */
    handleTab(key) {
      this.current = key
      this.handleInit()
    },

    /* 搜索 */
    handleSearch(key) {
      this.searchKey = key
      this.handleInit()
    },

    /* 获取列表 */
    handleInit() {
      this.loading = true
      let query = {}
      query.page = 1
      query.limit = -1
      if (this.current != '-1') {
        query.orderStatus = this.current
      }
      if (this.searchKey) {
        query.serverItemTitle = this.searchKey
      }
      userServerItemOrderList(query)
        .then((res) => {
          let list = (res && res.rows) || []
          this.serviceList = [...list]
          console.log('服务列表', this.serviceList)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.nullTxt {
  font-size: 18px;
  color: #0033ff;
  cursor: pointer;
}
</style>