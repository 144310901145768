<template>
  <div>
    <div v-if="colletList.length" class="grid grid-cols-5">
      <div
        class="w-[333px] mt-2 cursor-pointer mb-[96px]"
        v-for="(item, index) in colletList"
        :key="index"
      >
        <!-- 图片集 -->
        <div
          class="bg-[#F4F5F7] w-[333px] h-[226px] rounded-xl p-[9px] imgList"
        >
          <div
            class="relative"
            v-for="(it, ind) in item.IMGLIST"
            :key="ind"
            @click.stop="$router.push(`/works/collection/${item.id}`)"
          >
            <img
              v-if="ind < 6"
              class="w-[100px] h-[100px] rounded-[10px]"
              :src="it.imgUrl"
            />
            <div
              class="absolute imgIcon"
              v-if="item.IMGLIST.length > 6 && ind == 5"
            >
              <img src="@/assets/collection/overImg.png" />
            </div>
          </div>
        </div>
        <!-- 创作者自己视角信息 -->
        <template v-if="isMineView">
          <div class="mt-[18px] flex items-center justify-start">
            <div v-if="item.isOpen == 1" class="infoTips bg-[#D000FF]">
              公开
            </div>
            <div v-else class="infoTips bg-[#000000]">私有</div>
            <div class="infoTitle w-[238px]">{{ item.title }}</div>
          </div>
        </template>
        <!-- 他人视角信息 -->
        <template v-else>
          <div class="mt-[18px] flex items-center justify-between">
            <div class="infoTitle">{{ item.title }}</div>
            <div class="text-base font-[350] text-[#666666]">
              {{ item.createTime }}
            </div>
          </div>
          <div v-if="item.content && item.content != ''" class="infoDesc">
            {{ item.content }}
          </div>
          <div class="mt-[18px] flex items-center justify-end">
            <img
              v-if="item.isAtten"
              src="@/assets/attendFull.svg"
              @click="
                handleAttend(item.id, item.isAtten, (status) => {
                  item.isAtten = status
                  --item.attenCount
                })
              "
            />
            <img
              v-else
              src="@/assets/attend.svg"
              @click="
                handleAttend(item.id, item.isAtten, (status) => {
                  item.isAtten = status
                  ++item.attenCount
                })
              "
            />
            <div class="text-sm font-[300] text-[#666666] ml-[5px] mr-[17px]">
              {{ item.attenCount || 0 }}
            </div>
            <img
              v-if="item.isLike"
              src="@/assets/zanFull.svg"
              @click="
                handleLike(item.id, item.isLike, (status) => {
                  item.isLike = status
                  --item.likeCount
                })
              "
            />
            <img
              v-else
              src="@/assets/zan.svg"
              @click="
                handleLike(item.id, item.isLike, (status) => {
                  item.isLike = status
                  ++item.likeCount
                })
              "
            />
            <div class="text-sm font-[300] text-[#666666] ml-[5px] mr-[17px]">
              {{ item.likeCount || 0 }}
            </div>
            <img v-if="item.isRecommend" src="@/assets/commentFull.svg" />
            <img v-else src="@/assets/comment.svg" />
            <div class="text-sm font-[300] text-[#666666] ml-[5px]">
              {{ item.commentNum || 0 }}
            </div>
          </div>
        </template>
      </div>
    </div>
    <noData v-else></noData>
  </div>
</template>

<script>
import { creationArticleFindMiniList } from '@/api/user/home'
import { userCollectionImgList } from '@/api/album/index.js'
import noData from '@/components/noData'
import operateMixins from '../mixins/operateMixins.js'
export default {
  name: 'homeTabCollection',
  components: { noData },
  mixins: [operateMixins],
  props: ['sortFields', 'sortMode', 'userId', 'isMineView'],
  data() {
    return {
      colletList: []
    }
  },
  methods: {
    handleInit() {
      let query = {}
      query.page = 1
      query.limit = -1
      query.articleType = 2
      query.userId = this.userId
      query.sortFields = this.sortFields
      query.sortMode =
        this.sortMode == 2 ? 'asc' : this.sortMode == 3 ? 'desc' : null
      creationArticleFindMiniList(query).then(async (res) => {
        let list = (res && res.rows) || []
        for (let i in list) {
          let result = await userCollectionImgList({
            collectionArticleId: list[i].id,
            page: 1,
            limit: 6
          }).catch((e) => {})
          list[i].IMGLIST = result.rows || []
          list[i].IMGTOTAL = result.total
        }
        this.colletList = list
        console.log('**合集**', this.colletList)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.imgList {
  display: flex;
  flex-wrap: wrap;
  img {
    margin-right: 5px;
  }
  .img:nth-child(3n) {
    margin-right: 0;
  }
}
.imgIcon {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  img {
    width: 22px;
    height: 22px;
  }
}
.infoTitle {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: bold;
  width: 175px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.infoDesc {
  width: 333px;
  font-size: 14px;
  color: #868686;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: left;
  margin-top: 10px;
}
.infoTips {
  width: 36px;
  height: 17px;
  border-radius: 5px;
  text-align: center;
  line-height: 17px;
  font-size: 10px;
  color: #ffffff;
  margin-right: 14px;
}
</style>