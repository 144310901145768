<template>
  <div class="p-4" v-loading="loading">
    <div class="flex justify-between items-center">
      <div class="text-3xl">邀请好友</div>
      <div
        @click="$refs.inviteRecord.open()"
        class="w-20 h-8 leading-7 border border-black rounded-2xl cursor-pointer"
      >
        邀请记录
      </div>
    </div>
    <div class="w-full mt-4 rounded-2xl p-4 invite">
      <div class="invite-bg">
        <img class="icon" src="../assets/invite_bg.png" />
        <div class="info">
          <div class="title">邀请好友加入设辑AI</div>
          <div class="desc">#小程序://设辑/Iv6d4fOOCpSVnmz</div>
          <div class="button" @click="handleShare">分享链接/海报</div>
        </div>
      </div>

      <div class="invite-info">
        <img class="icon1" src="../assets/invite_icon.png" />
        <img class="icon2" src="../assets/invite_icon.png" />
        <div class="item" v-for="(item, index) in infoConfigCols" :key="index">
          <div>{{ userInfo[item.props] || 0 }}</div>
          <div class="label">{{ item.label }}</div>
        </div>
      </div>

      <div class="invite-gift">
        <div class="header">
          <img class="icon" src="../assets/invite_gift.png" />
          <div>参与奖励</div>
        </div>
        <div class="tips">
          每邀请 1 位新用户注册设辑AI专业版，立获 100
          积分奖励，上不封顶。（新用户定义：未注册过设辑AI的用户）
        </div>
        <div class="tips">
          邀请新用户注册后，新用户充值积分，每充值 1 次邀请者可获得 100
          积分奖励； 新用户购买会员，每买 1 次邀请者可获得 1200
          积分奖励(分12月赠送，每月100积分），多次奖励可叠加使用。
        </div>
      </div>

      <div class="invite-gift">
        <div class="header">
          <img class="icon" src="../assets/invite_msg.png" />
          <div>活动须知</div>
        </div>
        <div class="tips">
          如检测到您在邀请过程中存在刷量或通过非正当方式获利的行为，设辑AI有权视严重程度对您的账号采取清空违规所得时长、封禁账号等措施。
        </div>
      </div>

      <div class="invite-over">
        注意:设辑保留随时对规则作出调整的最终解释权。
      </div>

      <inviteShare ref="inviteShare"></inviteShare>

      <inviteRecord ref="inviteRecord"></inviteRecord>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from '@/utils/user'
import inviteShare from './inviteShare.vue'
import inviteRecord from './inviteRecord.vue'
export default {
  name: 'invite',
  components: { inviteShare, inviteRecord },
  data() {
    return {
      loading: false,
      userInfo: {},
      infoConfigCols: [
        { label: '成功邀请好友数量', props: 'inviteCount' },
        { label: '好友购买电力券奖励', props: 'inviteBuyCoupon' },
        { label: '好友购买会员奖励', props: 'inviteBuyVip' },
        { label: '获得总电力券（张）', props: 'inviteAllCoupon' }
      ]
    }
  },
  created() {
    this.handleInit()
  },
  methods: {
    /* 获取用户信息 */
    handleInit() {
      this.userInfo = getUserInfo()
    },

    /* 分享链接/海报 */
    handleShare() {
      this.$refs.inviteShare.open()
    }
  }
}
</script>

<style lang="scss" scoped>
.invite {
  height: 650px;
  overflow-y: scroll;
  &-bg {
    position: relative;
    .icon {
      width: 100%;
    }
    .info {
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      z-index: 1;
      color: #ffffff;
    }
    .title {
      font-size: 36px;
      font-weight: 900;
      white-space: nowrap;
    }
    .desc {
      font-size: 14px;
      font-weight: 350;
      margin-top: 10px;
    }
    .button {
      width: 168px;
      height: 39px;
      border-radius: 19.5px;
      background: #ff7744;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 28px auto;
      cursor: pointer;
    }
  }
  &-info {
    width: 100%;
    height: 141px;
    border-radius: 14px;
    border: solid 1px #f9e586;
    margin-top: 40px;
    position: relative;
    overflow: hidden;
    padding: 16px 26px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    .icon1 {
      top: -26px;
      right: -26px;
      position: absolute;
      z-index: 1;
      width: 118px;
      height: 118px;
    }
    .icon2 {
      left: -9px;
      bottom: 26.9px;
      position: absolute;
      z-index: 1;
      width: 38px;
      height: 38px;
    }
    .item {
      width: 50%;
      display: flex;
      align-items: center;
      flex-direction: column;
      border-bottom: solid 1px #d8d8d8;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
    }
    .label {
      margin-top: 2px;
      font-size: 12px;
      font-weight: normal;
    }
    .item:nth-child(2n) {
      border-left: solid 1px #d8d8d8;
    }
    .item:nth-last-child(1) {
      border-bottom: none;
    }
    .item:nth-last-child(2) {
      border-bottom: none;
    }
  }
  &-gift {
    margin-top: 33px;
    .header {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      font-size: 14px;
      font-weight: bold;
      color: #000000;
    }
    .icon {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      margin-right: 7px;
    }
    .tips {
      font-size: 12px;
      color: #000000;
      text-align: left;
    }
    .tips + .tips {
      margin-top: 13px;
    }
  }
  &-over {
    font-size: 12px;
    color: #7c7c7c;
    margin-top: 45px;
  }
}
.invite::-webkit-scrollbar {
  width: 4px; /*  设置纵轴（y轴）轴滚动条 */
}
.invite::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #727272;
}
.invite::-webkit-scrollbar-track {
  background: #000;
}
</style>