<template>
  <div class="messageInfo">
    <div class="messageInfo-title">
      {{ msgInfo.noticeTitle || msgItem.title }}
    </div>
    <div class="messageInfo-user" v-if="msgType == 1">设辑</div>
    <div class="messageInfo-user">
      {{
        msgInfo.createTime &&
        dayjs(msgInfo.createTime).format('YYYY-MM-DD HH:mm:ss')
      }}
    </div>
    <div class="messageInfo-line"></div>
    <div class="messageInfo-content" v-if="msgType == 2">
      {{ msgInfo.remark }}
    </div>
    <div class="messageInfo-content" v-else>{{ msgInfo.noticeContent }}</div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'messageInfo',
  data() {
    return {
      msgInfo: {},
      msgItem: {},
      msgType: 1,
      dayjs: dayjs,
      msgConfig: [
        { title: '官方公告', key: 1, api: '' },
        { title: '我的收益', key: 2, api: '' },
        { title: '服务反馈', key: 3, api: '' },
        { title: 'AI生成', key: 4, api: '' }
      ]
    }
  },
  watch: {
    '$route.query.id': {
      deep: true,
      immediate: true,
      handler: 'handleInfo'
    }
  },
  methods: {
    handleInfo() {
      this.msgType = this.$route.query.type
      this.msgItem = this.msgConfig.find((item) => item.key == this.msgType)
      this.msgInfo =
        this.$route.query.data && JSON.parse(this.$route.query.data)
      document.title = this.msgItem.title
      console.log('*信息详情*', this.msgInfo, this.msgInfo.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.messageInfo {
  width: 1402px;
  margin: 20px auto;
  text-align: left;
  &-title {
    font-size: 24px;
    font-weight: 500;
    color: #000000;
  }
  &-user {
    font-size: 14px;
    color: #3d3d3d;
    margin-top: 30px;
  }
  &-line {
    width: 100%;
    height: 1px;
    background: #d8d8d8;
    margin-top: 26px;
    margin-bottom: 29px;
  }
  &-content {
    font-size: 14px;
    color: #3d3d3d;
  }
}
</style>