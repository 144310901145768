import discover from "@/views/discover/index.vue"
import discoverDetail from "@/views/discover/detail.vue"
import pageDetail from '@/views/discover/pageDetail'

export default [{
    path: "/discover",
    name: "discover",
    component: discover,
    meta: {
      title: '设辑-发现'
    }
  },
  {
    path: "/discover/detail/:id",
    name: "discoverDetail",
    component: discoverDetail,
    meta: {
      title: '合集详情'
    }
  },
  {
    path: "/discover/pageDetail/:id",
    name: "discoverDetail",
    component: pageDetail,
    meta: {
      title: '笔记详情'
    }
  }
]
