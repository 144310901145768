<template>
  <div>
    <!-- 全部-未选择 -->
    <div class="flex" v-if="currentKey == 1 && !isSelected">
      <div class="albumCenter-button" style="width: 200px; padding: 0 9px">
        <img class="img" src="@/assets/collection/arrow-search.svg" />
        <el-input
          v-model="searchKey"
          placeholder="搜索画面描述"
          @change="handleSearch"
        />
      </div>
      <el-dropdown placement="bottom-start" @command="handleAllStatus">
        <div class="albumCenter-button">
          <div>发布状态</div>
          <img
            style="margin-left: 10px"
            src="@/assets/collection/arrow-down.svg"
          />
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="-1">全部</el-dropdown-item>
          <el-dropdown-item command="1">已发布</el-dropdown-item>
          <el-dropdown-item command="0">未发布</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div class="albumCenter-button">
        <div>画面尺寸</div>
        <img
          style="margin-left: 10px"
          src="@/assets/collection/arrow-down.svg"
        />
      </div>
      <div class="albumCenter-button" @click="handleCheck">
        <img
          class="img"
          style="margin-right: 10px"
          src="@/assets/collection/arrow-check.svg"
        />
        <div class="name">批量选择</div>
      </div>
    </div>
    <!-- 全部-已选择 -->
    <div class="flex" v-if="currentKey == 1 && isSelected">
      <div class="albumCenter-button" @click="handleJoin">
        <div>加入合集</div>
      </div>
      <div class="albumCenter-button" @click="handleLoad">
        <img
          style="margin-right: 10px"
          src="@/assets/collection/arrow-download.svg"
        />
        <div>下载</div>
      </div>
      <div class="albumCenter-button" @click="handleDelAlbum">
        <img
          style="margin-right: 10px"
          src="@/assets/collection/arrow-del.svg"
        />
        <div>删除</div>
      </div>
      <div
        class="albumCenter-button"
        style="margin-right: 0"
        @click="handleCancelCheck"
      >
        <div>取消</div>
      </div>
    </div>
    <!-- 合集-未选择 -->
    <div class="flex" v-if="currentKey == 2 && !isSelected">
      <el-dropdown placement="bottom-start" @command="handleStatus">
        <div class="albumCenter-button">
          <div>发布状态</div>
          <img
            style="margin-left: 10px"
            src="@/assets/collection/arrow-down.svg"
          />
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="-1">全部</el-dropdown-item>
          <el-dropdown-item command="1">公开</el-dropdown-item>
          <el-dropdown-item command="0">私有</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div class="albumCenter-button" @click="handleAdd">
        <img
          style="margin-right: 10px"
          src="@/assets/collection/arrow-add.svg"
        />
        <div>新建合集</div>
      </div>
      <div
        class="albumCenter-button"
        style="margin-right: 0"
        @click="handleCheck"
      >
        <img
          style="margin-right: 10px"
          src="@/assets/collection/arrow-check.svg"
        />
        <div>批量选择</div>
      </div>
    </div>
    <!-- 合集-选择 -->
    <div class="flex" v-if="currentKey == 2 && isSelected">
      <div class="albumCenter-button" @click="handleDel">
        <img
          style="margin-right: 10px"
          src="@/assets/collection/arrow-del.svg"
        />
        <div>删除</div>
      </div>
      <div
        class="albumCenter-button"
        style="margin-right: 0"
        @click="handleCancelCheck"
      >
        <div>取消</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'albumHeadRight',
  props: {
    currentKey: {
      default: null
    }
  },
  data() {
    return {
      searchKey: null,
      isSelected: false
    }
  },
  watch: {
    currentKey() {
      this.isSelected = false
      this.$emit('select', this.isSelected)
    }
  },
  methods: {
    /* 合集发布状态 */
    handleStatus(command) {
      this.$emit('status', command)
    },
    /* 全部发布状态 */
    handleAllStatus(command) {
      this.$emit('statusAll', command)
    },

    /* 新建合集 */
    handleAdd() {
      this.$emit('add')
    },

    /* 加入合集 */
    handleJoin() {
      this.$emit('join')
    },

    /* 批量选择 */
    handleCheck() {
      this.isSelected = true
      this.$emit('select', this.isSelected)
    },

    /* 取消批量选择 */
    handleCancelCheck() {
      this.isSelected = false
      this.$emit('select', this.isSelected)
    },

    /* 删除合集 */
    handleDel() {
      this.$emit('delete')
    },

    /* 删除作品 */
    handleDelAlbum() {
      this.$emit('deleteAlbum')
    },

    /* 全部搜索 */
    handleSearch() {
      this.$emit('search', this.searchKey)
    },

    /* 下载 */
    handleLoad() {
      this.$emit('load')
    }
  }
}
</script>

<style lang="scss" scoped>
.albumCenter-button {
  width: 110px;
  height: 33px;
  border-radius: 50px;
  border: 1px solid #c4bfbf;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #000000;
  cursor: pointer;
  margin-right: 22px;
}
::v-deep .el-input__inner {
  border: none;
  height: 30px;
}
::v-deep .el-input__inner:hover {
  border-color: #fff;
}
</style>