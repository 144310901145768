<template>
  <div>
    <div v-if="noteList.length" class="grid grid-cols-5">
      <div
        class="w-[333px] mt-2 cursor-pointer mb-[58px]"
        v-for="(item, index) in noteList"
        :key="index"
      >
        <!-- 图片集 -->
        <div class="rounded-xl relative">
          <img class="w-[333px] h-[232px] rounded-xl" :src="item.coverImgUrl" />
          <div class="imgNum">
            {{ (item.articleItems && item.articleItems.length) || 0 }}图
          </div>
        </div>

        <!-- 信息 -->
        <template>
          <div v-if="item.title && item.title != ''" class="infoDesc">
            {{ item.title }}
            <div
              class="text-base font-[300] text-[#666666] absolute right-0 bottom-0"
            >
              {{ item.createTime }}
            </div>
          </div>
          <div class="mt-[18px] flex items-center justify-end">
            <img
              v-if="item.isAtten"
              src="@/assets/attendFull.svg"
              @click="
                handleAttend(item.id, item.isAtten, (status) => {
                  item.isAtten = status
                  --item.attenCount
                })
              "
            />
            <img
              v-else
              src="@/assets/attend.svg"
              @click="
                handleAttend(item.id, item.isAtten, (status) => {
                  item.isAtten = status
                  ++item.attenCount
                })
              "
            />
            <div class="text-sm font-[300] text-[#666666] ml-[5px] mr-[17px]">
              {{ item.attenCount || 0 }}
            </div>
            <img
              v-if="item.isLike"
              src="@/assets/zanFull.svg"
              @click="
                handleLike(item.id, item.isLike, (status) => {
                  item.isLike = status
                  --item.likeCount
                })
              "
            />
            <img
              v-else
              src="@/assets/zan.svg"
              @click="
                handleLike(item.id, item.isLike, (status) => {
                  item.isLike = status
                  ++item.likeCount
                })
              "
            />
            <div class="text-sm font-[300] text-[#666666] ml-[5px] mr-[17px]">
              {{ item.likeCount || 0 }}
            </div>
            <img v-if="item.isRecommend" src="@/assets/commentFull.svg" />
            <img v-else src="@/assets/comment.svg" />
            <div class="text-sm font-[300] text-[#666666] ml-[5px]">
              {{ item.commentNum || 0 }}
            </div>
          </div>
        </template>
      </div>
    </div>
    <noData v-else></noData>
  </div>
</template>

<script>
import noData from '@/components/noData/index.vue'
import operateMixins from '../mixins/operateMixins.js'
import { creationArticleFindMiniList } from '@/api/user/home'
export default {
  name: 'homeTabNote',
  components: { noData },
  mixins: [operateMixins],
  props: ['sortFields', 'sortMode', 'userId'],
  data() {
    return {
      noteList: []
    }
  },
  methods: {
    handleInit() {
      let query = {}
      query.page = 1
      query.limit = -1
      query.articleType = 3
      query.userId = this.userId
      query.sortFields = this.sortFields
      query.sortMode =
        this.sortMode == 2 ? 'asc' : this.sortMode == 3 ? 'desc' : null
      creationArticleFindMiniList(query).then((res) => {
        let list = (res && res.rows) || []
        list = list.map((v) => {
          let number = (v.title && v.title.length) || 0
          if (number > 50) {
            v.title = v.title.slice(0, 50)
            v.title = v.title + '...'
          }
          return v
        })
        this.noteList = list
        console.log('**笔记**', this.noteList)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.imgNum {
  padding: 2px 10px;
  box-sizing: border-box;
  width: max-content;
  border-radius: 14px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 11px;
  top: 11px;
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  color: #ffffff;
}
.infoDesc {
  width: 333px;
  font-size: 15px;
  color: #3d3d3d;
  text-align: left;
  margin-top: 10px;
  font-weight: 500;
  position: relative;
}
.infoTips {
  width: 36px;
  height: 17px;
  border-radius: 5px;
  text-align: center;
  line-height: 17px;
  font-size: 10px;
  color: #ffffff;
  margin-right: 14px;
}
</style>