<template>
  <commonLayout
    :mt="20"
    :tabList="tabList"
    :chooseBtnVisible="currentTab == 2"
    @tab="handleTab"
    @search="handleSearch"
    @choose="handleChoose"
  >
    <likeAndCollectHead
      :current="current"
      :isCheck="isChoose"
      :checkLength="checkList.length"
      @check="handleMenu"
      @cancel="handleCancel"
    ></likeAndCollectHead>

    <el-checkbox-group v-model="checkList" @change="handleCheckbox">
      <div class="albumInfoList" v-if="albumList.length" v-loading="loading">
        <div
          class="albumInfoList-item"
          v-for="(item, index) in albumList"
          :key="index"
        >
          <albumInfoItem
            :issue="false"
            :id="item.albumId"
            :isSelected="isChoose"
            :imgUrl="item.imgUrl"
            :checkValue="item.albumId"
            :albumType="item.albumType"
            :albumName="item.albumName"
            :showMask="checkList.includes(item.id)"
          ></albumInfoItem>
        </div>
      </div>
      <el-empty v-else :image-size="200"></el-empty>
    </el-checkbox-group>

    <comfirmDialog
      v-model="cancelVisible"
      cancelTxt="放弃"
      content="确认取消当前选中的收藏内容"
      @confirm="cancelAttend"
    ></comfirmDialog>
  </commonLayout>
</template>

<script>
import comfirmDialog from '@/components/comfirmDialog'
import commonLayout from '../components/commonLayout.vue'
import albumInfoItem from '../components/albumInfoItem.vue'
import likeAndCollectHead from '../components/likeAndCollectHead.vue'
import {
  batchNotAttention,
  userLikeArticleList,
  userBrowseHistoryPage,
  userAttentionArticleList
} from '@/api/user/likeAndCollectHead.js'
export default {
  name: 'likeAndCollect',
  components: {
    comfirmDialog,
    commonLayout,
    albumInfoItem,
    likeAndCollectHead
  },
  data() {
    return {
      page: 1,
      current: 1,
      currentTab: null,
      searchKey: null,
      loading: false,
      isChoose: false,
      cancelVisible: false,
      checkList: [],
      albumList: [],
      tabList: [
        {
          title: '浏览历史',
          key: '1',
          api: userBrowseHistoryPage,
          albumName: 'recordDescribe',
          albumType: 'recordType',
          imgUrl: 'recordCoverImgUrl'
        },
        {
          title: '我收藏的',
          key: '2',
          api: userAttentionArticleList,
          albumName: 'articleDescribe',
          albumType: 'articleType',
          imgUrl: 'articleCoverImgUrl'
        },
        {
          title: '我赞过的',
          key: '3',
          api: userLikeArticleList,
          albumName: 'articleDescribe',
          albumType: 'articleType',
          imgUrl: 'articleCoverImgUrl'
        }
      ]
    }
  },
  methods: {
    /* 点击tab */
    handleTab(key) {
      this.currentTab = key
      this.handleInit(true)
    },

    /* 选择类型 */
    handleMenu(key) {
      this.current = key
      this.handleInit(true)
    },

    /* 选择内容 */
    handleChoose(status) {
      this.isChoose = status
    },

    /* 搜索 */
    handleSearch(key) {
      this.searchKey = key
      this.handleInit(true)
    },

    /* 多选 */
    handleCheckbox(e) {
      console.log('handleCheckbox', e, this.checkList)
    },

    /* 获取列表 */
    handleInit(isFresh) {
      if (isFresh) {
        this.page = 1
        this.albumList = []
      }
      let item = this.tabList.find((v) => v.key == this.currentTab)
      let myApi = item && item.api
      if (!myApi) return
      let query = {}
      query.limit = -1
      query.page = this.page
      if (this.currentTab == 1) {
        query.recordType = this.current
      } else {
        query.articleType = this.current
      }
      query.articleDescribe = this.searchKey
      this.loading = true
      myApi(query)
        .then((res) => {
          let list = (res && res.rows) || []
          list = list.map((v) => {
            v.imgUrl = v[item.imgUrl]
            v.albumType = v[item.albumType]
            v.albumName = v[item.albumName]
            v.albumId = this.currentTab == 1 ? v.recordId : v.articleId
            return v
          })
          this.albumList.push(...list)
          console.log('列表结果', this.albumList)
        })
        .finally(() => {
          this.loading = false
        })
    },

    /* 取消收藏 */
    handleCancel() {
      if (!this.checkList.length) {
        return this.$message.warning('请至少选择一项')
      }
      this.cancelVisible = true
    },
    cancelAttend() {
      let list = []
      this.checkList.forEach((item) => {
        let data = this.albumList.find((v) => item == v.albumId)
        list.push({
          articleId: data.albumId,
          articleType: data.articleType
        })
      })
      batchNotAttention(list).then((res) => {
        this.cancelVisible = false
        this.$message.success(res.msg)
        this.handleInit()
        this.checkList = []
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.albumInfoList {
  width: 100%;
  margin-top: 33px;
  display: flex;
  flex-wrap: wrap;
  &-item {
    width: 220px;
    margin-right: 14px;
    margin-bottom: 18px;
  }
  &-item:nth-child(6n) {
    margin-right: 0;
  }
}
</style>