<template>
  <shejiDialog v-model="visible" width="642px" height="787px">
    <div class="inviteRecord">
      <div class="inviteRecord-header">
        <div class="back" @click="close">
          <img class="icon" src="../assets/invite_right.svg" />
          <div>返回</div>
        </div>
        <div>邀请记录</div>
      </div>

      <div class="inviteRecord-tab">
        <div
          class="item"
          :class="{ active: tabIndex == 1 }"
          @click="tabIndex = 1"
        >
          <div>邀请记录</div>
          <div class="line"></div>
        </div>
        <div
          class="item"
          :class="{ active: tabIndex == 2 }"
          @click="tabIndex = 2"
        >
          <div>奖励记录</div>
          <div class="line"></div>
        </div>
      </div>

      <div v-if="recordList.length" class="inviteRecord-list">
        <div
          class="inviteRecord-item"
          v-for="(item, index) in recordList"
          :key="index"
        >
          <div class="item">
            <img class="avatar" :src="item.avatar" />
            <div>{{ item.nickName || '-' }}</div>
          </div>

          <div v-if="tabIndex == 2 && item.integralType == 4">
            邀请注册奖励：+{{ item.integralUse || 0 }}电力券
          </div>
          <div v-if="tabIndex == 2 && item.integralType == 10">
            积分充值奖励：+{{ item.integralUse || 0 }}电力券
          </div>
          <div v-if="tabIndex == 2 && item.integralType == 11">
            购买会员奖励：+{{ item.integralUse || 0 }}电力券/月*12
          </div>

          <div class="time">{{ item.createTime || '-' }}</div>
        </div>
      </div>

      <noData v-else></noData>

      <div class="inviteRecord-page">
        <div>共 {{ total || 0 }} 项数据</div>
        <div>
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            @current-change="handlePageChange"
          ></el-pagination>
        </div>
      </div>
    </div>
  </shejiDialog>
</template>

<script>
import noData from '@/components/noData'
import shejiDialog from '@/components/shejiDialog'
import { userInviteList, userInviteEarningList } from '@/api/user/index.js'
export default {
  name: 'inviteRecord',
  components: { noData, shejiDialog },
  data() {
    return {
      visible: false,
      tabIndex: null,
      recordList: [],
      total: 0,
      query: {
        pageNum: 1,
        pageSize: 10
      }
    }
  },
  watch: {
    tabIndex: {
      deep: true,
      immediate: true,
      handler: 'handleInit'
    }
  },
  methods: {
    open() {
      this.visible = true
      this.tabIndex = 1
    },

    close() {
      this.visible = false
      this.tabIndex = null
      this.query.pageNum = 1
    },

    handleInit() {
      if (!this.tabIndex) return
      let myapi = this.tabIndex == 1 ? userInviteList : userInviteEarningList
      myapi(this.query).then((res) => {
        let list = (res && res.rows) || []
        if (this.tabIndex == 2) {
          list = list.map((v) => {
            v.avatar = (v.useUser && v.useUser.avatar) || null
            v.nickName = (v.useUser && v.useUser.nickName) || null
            return v
          })
        }
        this.recordList = list
        this.total = (res && res.total) || 0
        console.log('记录', list)
      })
    },

    handlePageChange(data) {
      this.query.pageNum = data
      this.handleInit()
    }
  }
}
</script>

<style lang="scss" scoped>
.inviteRecord {
  height: 760px;
  padding: 16px 18px;
  box-sizing: border-box;
  position: relative;
  &-header {
    display: flex;
    align-items: center;
    font-size: 25px;
    font-weight: 500;
    color: #3d3d3d;
    .back {
      width: 85px;
      height: 40px;
      border-radius: 20px;
      background: #d8d8d8;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-right: 11px;
    }
    .icon {
      margin-right: 10px;
    }
  }
  &-tab {
    margin-top: 20px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    box-sizing: border-box;
    .item {
      font-size: 18px;
      color: #7c7c7c;
      margin-right: 37px;
      cursor: pointer;
    }
    .line {
      width: 100%;
      height: 2px;
      background: #0033ff;
      margin-top: 4px;
      opacity: 0;
    }
    .active {
      font-weight: bold;
      color: #3d3d3d;
      .line {
        opacity: 1;
      }
    }
  }
  &-page {
    left: 0;
    bottom: 0;
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    color: #282828;
    padding: 24px 18px;
    box-sizing: border-box;
  }
  &-list {
    margin-top: 20px;
  }
  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    width: 100%;
    padding: 0 12px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    border-bottom: 1px solid #d8d8d8;
    margin-top: 6px;
    .item {
      display: flex;
      align-items: center;
    }
    .avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 8px;
      flex-shrink: 0;
      background: #d8d8d8;
    }
    .time {
      font-size: 14px;
      color: #9e9e9e;
      font-weight: normal;
    }
  }
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #54c752;
}
</style>