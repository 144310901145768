import service from "@/utils/require"

// 获取电力券套餐
export const getPowerCoupon = (params) => service({
    url: '/powerCoupon/allCoupon',
    method: 'get',
    params
})

/** 创建电力券二维码 */
export const createCouponOrderByQR = (params) => service({
    url: `/powerCoupon/createCouponOrderByQR/${params.couponId}`,
    method: 'post',
    params: {
      isTest: params?.isTest
    }
})

/** 查询电力券详情 */
export const getPowerCouponDetails = (params) => service({
    url: `/powerCoupon/userOrderDetails/${params.id}`,
    method: 'get',
})

/** 创建会员二维码 */
export const createMemberQR = (params) => service.post(`/userVipPayOrder/createPayVipOrderByQR${process.env.NODE_ENV === 'development' ? '?isTest=true' : ''}`, params)

/** 查询会员详情 */
export const getMemberDetails = (params) => service.get(`/userVipPayOrder/userPayDetails/${params.id}`)

/** 查询会员列表 */
export const getMemberList = (params) => service.get('/userVipPayOrder/userPayList', {
    params
})