<!-- 配色选区 -->
<template>
  <shejiDialog v-model="value" height="650px" width="950px">
    <div class="image-selector flex">
      <div v-if="imageSrc" class="canvas-container p-2">
        <canvas
          ref="canvas"
          class="rounded-2xl"
          @mousedown="startDrawing"
          @mousemove="draw"
          @mouseup="endDrawing"
          @contextmenu.prevent="removeRegion"
        ></canvas>
        <img
          :src="imageSrc"
          alt="uploaded"
          ref="image"
          class="hidden"
          @load="onImageLoad"
        />
      </div>
      <div>
        <div class="text-left border-b p-2 min-h-[150px]">
          <div>选区分组</div>
          <div class="flex flex-wrap">
            <div v-for="(item, index) in paths" :key="index">
              <canvas
                :ref="'selectionCanvas' + index"
                class="w-[100px] h-[100px] mt-2"
              ></canvas>
            </div>
          </div>
        </div>
        <div class="text-left p-2">
          <div class="flex items-center mt-3">
            <img src="@/assets/mouseLeft.svg" alt="" srcset="" />
            鼠标左键绘制需要改色的区域
          </div>
          <div class="flex items-center mt-3">
            <img src="@/assets/mouseRight.svg" alt="" srcset="" />
            鼠标右键点击删除不需要改色的区域
          </div>
        </div>
        <div
          class="w-72 ml-3 mt-4 cursor-pointer rounded-lg h-10 leading-9 text-white bg-[#58C752]"
          @click="handleConfirm"
        >
          确认
        </div>
      </div>
    </div>
  </shejiDialog>
</template>
  
  <script>
import ShejiDialog from '@/components/shejiDialog'
import { nextTick } from 'vue'
export default {
  components: {
    ShejiDialog
  },
  props: {
    imageSrc: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  },
  data() {
    return {
      isDrawing: false, // 标识是否正在绘制
      paths: [], // 保存所有绘制的路径
      currentPath: null, // 当前绘制的路径
      ctx: null, // canvas上下文
      imageLoaded: false, // 确保图片已经加载
      canvasScale: 0.5 // canvas缩放比例
    }
  },
  watch: {
    paths: {
      handler(newValue) {
        // 当paths发生变化时，重新渲染Canvas
        this.renderSelectionPanels()
      },
      deep: true
    }
  },
  methods: {
    onImageLoad() {
      // 图片加载完成后，初始化Canvas
      this.initializeCanvas()
      this.renderSelectionPanels()
    },
    initializeCanvas() {
      const canvas = this.$refs.canvas
      const image = this.$refs.image
      const ctx = canvas.getContext('2d')

      // 获取图片的真实宽高
      const imageWidth = image.naturalWidth
      const imageHeight = image.naturalHeight

      // 根据图片的真实宽高调整canvas尺寸
      const maxCanvasWidth = 800 // 可以根据需要调整最大宽度
      const scale = maxCanvasWidth / imageWidth // 计算缩放比例
      this.canvasScale = scale

      // 设置canvas的宽高
      canvas.width = 600
      canvas.height = 600

      this.ctx = ctx
      this.renderCanvas()
    },
    renderCanvas() {
      const canvas = this.$refs.canvas
      const image = this.$refs.image

      // 先绘制图片
      this.ctx.clearRect(0, 0, canvas.width, canvas.height)
      this.ctx.drawImage(image, 0, 0, canvas.width, canvas.height)

      // 绘制灰色遮罩层
      this.ctx.fillStyle = 'rgba(0, 0, 0, 0.6)'
      this.ctx.fillRect(0, 0, canvas.width, canvas.height)

      // 处理路径，高亮显示选中的区域
      this.ctx.globalCompositeOperation = 'destination-out' // 抹掉遮罩以显示图片
      this.paths.forEach((path) => {
        this.ctx.beginPath()
        this.ctx.moveTo(
          path[0].x * this.canvasScale,
          path[0].y * this.canvasScale
        )
        path.forEach((point) => {
          this.ctx.lineTo(
            point.x * this.canvasScale,
            point.y * this.canvasScale
          )
        })
        this.ctx.closePath()
        this.ctx.fill()
      })

      this.ctx.globalCompositeOperation = 'source-over' // 恢复到正常的绘制模式
    },
    startDrawing(event) {
      const { offsetX, offsetY } = event
      this.isDrawing = true
      this.currentPath = [
        { x: offsetX / this.canvasScale, y: offsetY / this.canvasScale }
      ]
    },
    draw(event) {
      if (!this.isDrawing) return

      const { offsetX, offsetY } = event
      this.currentPath.push({
        x: offsetX / this.canvasScale,
        y: offsetY / this.canvasScale
      })

      // 重绘canvas
      this.renderCanvas()

      // 绘制当前正在绘制的路径
      this.ctx.beginPath()
      this.ctx.moveTo(
        this.currentPath[0].x * this.canvasScale,
        this.currentPath[0].y * this.canvasScale
      )
      this.currentPath.forEach((point) => {
        this.ctx.lineTo(point.x * this.canvasScale, point.y * this.canvasScale)
      })
      this.ctx.strokeStyle = '#0074FE'
      this.ctx.lineWidth = 4
      this.ctx.stroke()
    },
    endDrawing(e) {
      if (e.button === 2) {
        this.isDrawing = false
        this.currentPath = null
        return
      }
      if (this.isDrawing && e.button === 0) {
        this.isDrawing = false
        this.paths.push(this.currentPath)
        this.currentPath = null
        this.renderCanvas()
      }
    },
    removeRegion(event) {
      const { offsetX, offsetY } = event
      const x = offsetX / this.canvasScale
      const y = offsetY / this.canvasScale

      // 遍历路径，找到点击的区域
      const pathIndex = this.paths.findIndex((path) => {
        return this.isPointInPath(path, x, y)
      })

      if (pathIndex !== -1) {
        this.paths.splice(pathIndex, 1) // 删除选中的路径
        this.renderCanvas()
      }
    },
    isPointInPath(path, x, y) {
      const ctx = this.ctx
      ctx.beginPath()
      ctx.moveTo(path[0].x * this.canvasScale, path[0].y * this.canvasScale)
      path.forEach((point) => {
        ctx.lineTo(point.x * this.canvasScale, point.y * this.canvasScale)
      })
      ctx.closePath()
      return ctx.isPointInPath(x * this.canvasScale, y * this.canvasScale)
    },
    renderSelectionPanels() {
      nextTick(() => {
        this.paths.forEach((path, index) => {
          const canvas = this.$refs[`selectionCanvas${index}`][0]
          const ctx = canvas.getContext('2d')

          const panelWidth = 100
          const panelHeight = 100

          canvas.width = panelWidth
          canvas.height = panelHeight

          // 黑色背景
          ctx.fillStyle = 'black'
          ctx.fillRect(0, 0, panelWidth, panelHeight)

          // 白色绘制选区
          ctx.strokeStyle = 'white'
          ctx.lineWidth = 2

          const scaleX = panelWidth / this.$refs.canvas.width
          const scaleY = panelHeight / this.$refs.canvas.height

          ctx.beginPath()
          ctx.moveTo(path[0].x * scaleX, path[0].y * scaleY)
          path.forEach((point) => {
            ctx.lineTo(point.x * scaleX, point.y * scaleY)
          })
          ctx.closePath()
          ctx.stroke()
        })
      })
    },
    handleConfirm() {
      if (this.paths.length === 0) {
        this.$message.error('请先绘制选区')
        return
      }
      this.$emit('confirm', this.paths)
      this.value = false
    }
  }
}
</script>
  
<style>
.canvas-container {
  position: relative;
}
canvas {
  border: 1px solid #ccc;
  cursor: crosshair;
}
img.hidden {
  display: none;
}
</style>