<template>
  <div class="comment-wrap">
    <div class="comment-title">综合服务评分</div>
    <div class="score-view">
      <div v-for="(item, index) in scoreList" class="score-item" :key="index">
        <div class="score-name">{{ item.name }}：</div>
        <div class="score-result">
          <div class="star" v-for="i in 5" :key="`${index}_${i}`">
            <template v-if="getScore(scoreData[item.prop], i)">
              <img src="@/assets/service/star_light.svg" />
            </template>
            <template v-else>
              <img src="@/assets/service/star.svg" />
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="comment-title">所有评价</div>
    <div class="comment-list">
      <comment-item
        v-for="(item, index) in commentList"
        :key="index"
        :commentData="item"
        :reply="item.childrenEvaluates && item.childrenEvaluates.length"
      />
      <!-- <comment-item>
        <comment-item :reply="true"></comment-item>
      </comment-item>
      <comment-item /> -->
    </div>
  </div>
</template>

<script>
import commentItem from './commentItem.vue'

export default {
  name: 'Comment',
  components: {
    commentItem
  },
  props: {
    scoreData: {
      type: Object,
      default: () => {
        return {
          value1: 5,
          value2: 4,
          value3: 3
        }
      }
    },
    commentList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      scoreList: [
        {
          name: '专业能力',
          prop: 'value1'
        },
        {
          name: '服务效率',
          prop: 'value2'
        },
        {
          name: '服务态度',
          prop: 'value3'
        }
      ]
    }
  },
  methods: {
    getScore(val, i) {
      return val >= i + 1
    }
  }
}
</script>

<style lang="scss" scoped>
.comment-wrap {
  .comment-title {
    font-size: 16px;
    font-weight: 500;
    font-family: Source Han Sans;
    color: #000;
    text-align: left;
  }
  .score-view {
    margin-top: 20px;
    padding-bottom: 36px;
    .score-item {
      display: flex;
      align-items: center;
      .score-name {
        font-size: 16px;
        font-weight: normal;
        font-family: Source Han Sans;
        color: #666;
      }
      .score-result {
        display: flex;
        align-items: center;
        .star {
          width: 18px;
          height: 17px;
          margin-right: 16px;
          &:last-child {
            margin-right: 0;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>