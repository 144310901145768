<!-- 重新生成 -->
<template>
  <div>
    <layout leftContentName="参数设置" :mode="mode">
      <template slot="firstTag">
        <div class="p-4">
          <div>
            <div class="text-left text-[#666666] text-sm mb-3">
              补充局布描述（选填）
            </div>
            <div class="flex">
              <div class="flex flex-col items-start">
                <div class="text-left text-[#666666] text-sm">添加参考图</div>
                <div class="w-full mt-2 flex justify-start">
                  <el-upload
                    action="https://sheji-ai.cc/systemApi/files-anon"
                    :on-success="handleFileUpload"
                    :before-upload="checkFile"
                    :show-file-list="false"
                    :size="'40%'"
                  >
                    <div
                      class="w-28 h-28 rounded-lg flex justify-center items-center bg-[#F4F5F7]"
                      v-if="JSON.stringify(images) === '{}'"
                    >
                      <img src="@/assets/photo.svg" alt="" />
                    </div>
                    <div class="w-28 h-28 rounded-lg relative" v-else>
                      <div>
                        <img
                          :src="images.url"
                          class="w-28 h-28 rounded-lg"
                          alt=""
                        />
                      </div>
                    </div>
                  </el-upload>
                </div>
              </div>
              <div class="flex flex-1 flex-col items-start ml-2">
                <div class="text-left text-[#666666] text-sm">添加局布描述</div>
                <div class="w-full mt-2">
                  <ElInput
                    v-model="promptCn"
                    type="textarea"
                    :rows="5"
                    maxlength="1000"
                    showWordLimit
                    placeholder="输入你想要补充的内容，支持中英文。"
                    clearable
                  ></ElInput>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template slot="content">
        <div class="w-full h-full flex flex-col">
          <div
            class="flex justify-center items-center"
            style="height: calc(100% - 200px)"
          >
            <div
              class="w-[437px] h-[437px] bg-white border border-dashed border-[#C1C1C1]"
              v-loading="singleImgLoading"
              v-if="JSON.stringify(result) === '{}' && !isWait"
            >
              <img
                :src="singleImg"
                alt=""
                class="w-[437px] h-[437px] rounded-xl"
                v-if="!allImage"
              />
              <img
                :src="allImage"
                alt=""
                class="w-[437px] h-[437px] rounded-xl"
                v-else
              />
            </div>
            <div
              v-else-if="isWait"
              class="w-[437px] h-[437px] relative flex justify-center items-center"
            >
              <img
                src="@/assets/waitBg.svg"
                class="absolute top-0 left-0"
                alt=""
              />
              <div
                class="w-[150px] h-[150px] p-2 px-4 text-white bg-[rgba(0,0,0,.39)] rounded-lg z-[999]"
              >
                <div class="mt-6">{{ progress }}%</div>
                <div class="my-2">正在生成中...</div>
                <div>
                  <el-progress
                    :percentage="progress"
                    color="#54C752"
                    :show-text="false"
                  ></el-progress>
                </div>
              </div>
            </div>
            <div class="w-[537px] h-[437px] flex" v-else>
              <div class="flex flex-col">
                <img
                  :src="item.imageUrlVisit"
                  alt=""
                  v-for="(item, index) in result?.itemList"
                  :key="index"
                  class="w-24 h-24 rounded-xl mt-2 cursor-pointer"
                  :class="
                    activeImage === index ? 'border-2 border-[#54C752]' : ''
                  "
                  @click="activeImage = index"
                />
              </div>
              <div class="ml-4 mt-2 relative">
                <div
                  class="absolute top-4 left-4 w-7 h-7 rounded-full leading-7 text-center bg-[rgba(0,0,0,.5)] text-white"
                >
                  {{ activeImage + 1 }}
                </div>
                <img
                  :src="result.itemList[activeImage].originUrlVisit"
                  alt=""
                  class="w-[407px] h-[407px] rounded-xl"
                />
              </div>
            </div>
          </div>
          <div class="flex justify-center items-center -mt-8">
            <div
              class="w-48 h-12 leading-[48px] bg-white flex justify-center items-center cursor-pointer rounded-lg"
              v-if="JSON.stringify(result) === '{}'"
              @click="
                if (!singleImgLoading) {
                  dialogVisiable = true
                }
              "
            >
              <img src="@/assets/edit.svg" alt="" />
              <div
                class="flex justify-between items-center ml-2"
                :class="singleImgLoading && 'cursor-not-allowed'"
              >
                编辑图片
              </div>
            </div>
            <!-- <div
              class="w-48 h-12 leading-[48px] bg-white flex justify-center items-center cursor-pointer"
              @click="
                type === 'imageGenerator'
                  ? $router.push({
                      path: `/workbench/imagegeneration`,
                      query: { id }
                    })
                  : $router.push({
                      path: `/workbench/textgeneration`,
                      query: { id }
                    })
              "
              v-else
            >
              <img src="@/assets/backEdit.svg" alt="" />
              <div class="flex justify-between items-center ml-2">返回</div>
            </div> -->
          </div>
          <div
            class="absolute bottom-0 h-28 w-full flex justify-center"
            :class="JSON.stringify(result) === '{}' ? 'h-28' : 'h-52'"
          >
            <div
              class="w-[550px] h-14 bg-white rounded-3xl flex justify-center items-center"
              v-if="JSON.stringify(result) === '{}'"
            >
              <div
                class="w-[520px] rounded-3xl h-10 bg-[#54C752] text-white relative flex justify-center items-center cursor-pointer"
                :class="
                  (!cropImage || JSON.stringify(result) !== '{}') &&
                  '!bg-[#D8D8D8] cursor-not-allowed'
                "
                @click="generator"
              >
                <i class="el-icon-loading mr-4" v-if="isWait"></i>
                <div class="text-lg font-bold">立即生成</div>
              </div>
            </div>
            <div
              class="w-[823px] h-[201px] bg-black rounded-[14px] p-4 text-white"
              v-else
            >
              <div class="w-full p-1 pb-5 border-b flex">
                <div class="flex-1 border-r">
                  <div class="flex items-center">
                    <div class="text-[15px] font-medium">微调</div>
                    <div class="text-sm text-[#737373] ml-2">
                      仅支持操作（3天）内的生成结果
                    </div>
                  </div>
                  <div class="flex mt-3 mb-3">
                    <div
                      class="w-16 h-8 leading-8 bg-[#23252F] rounded-md mr-1 cursor-pointer select-none"
                      :class="
                        isMoreThanThree &&
                        'cursor-not-allowed pointer-events-none'
                      "
                      @click="update(1)"
                    >
                      图一
                    </div>
                    <div
                      class="w-16 h-8 leading-8 bg-[#23252F] rounded-md mr-1 cursor-pointer select-none"
                      :class="
                        isMoreThanThree &&
                        'cursor-not-allowed pointer-events-none'
                      "
                      @click="update(2)"
                    >
                      图二
                    </div>
                    <div
                      class="w-16 h-8 leading-8 bg-[#23252F] rounded-md mr-1 cursor-pointer select-none"
                      :class="
                        isMoreThanThree &&
                        'cursor-not-allowed pointer-events-none'
                      "
                      @click="update(3)"
                    >
                      图三
                    </div>
                    <div
                      class="w-16 h-8 leading-8 bg-[#23252F] rounded-md mr-1 cursor-pointer select-none"
                      :class="
                        isMoreThanThree &&
                        'cursor-not-allowed pointer-events-none'
                      "
                      @click="update(4)"
                    >
                      图四
                    </div>
                    <div class="border-r h-8 mx-4"></div>
                    <el-popover
                      placement="top"
                      trigger="hover"
                      v-model="popoverVisible"
                      popper-class="rounded-[14px]"
                    >
                      <div
                        class="w-8 h-8 flex justify-center items-center rounded-md bg-[#23252F] cursor-pointer"
                        slot="reference"
                      >
                        <img src="@/assets/tipmenu.svg" alt="" />
                      </div>
                      <!-- 权益及优惠券信息 -->
                      <div
                        class="flex flex-col items-center justify-center w-[215px]"
                      >
                        <div class="text-lg font-bold text-black">
                          AI生成明细
                        </div>
                        <div class="p-2 w-full">
                          <div v-if="couponInfo?.coupon">
                            <div>电力券消费</div>
                          </div>
                          <el-divider v-if="couponInfo?.coupon"></el-divider>
                          <div v-if="couponInfo?.vip">
                            <div class="text-left font-medium mb-2 text-black">
                              权益卡消耗
                            </div>
                            <div
                              class="flex justify-between mb-2"
                              v-for="(item, index) in couponInfo.vip"
                              :key="index"
                            >
                              <div class="text-xs font-medium text-[#666666]">
                                {{ item.key }}
                              </div>
                              <div
                                class="text-xs font-medium"
                                :style="{
                                  color: item?.color ? item.color : '#000'
                                }"
                              >
                                {{ item.value }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="w-[171px] h-[36px] rounded-[100px] leading-[36px] text-center bg-[#54C752] text-lg font-bold text-white cursor-pointer"
                          @click="popoverVisible = false"
                        >
                          知道了
                        </div>
                      </div>
                    </el-popover>
                  </div>
                </div>
                <div class="flex-1 pl-6">
                  <div class="flex items-center">
                    <div class="text-[15px] font-medium">精调</div>
                    <div class="text-sm text-[#737373] ml-2">
                      局部修改仅支持操作（3天）内的生成结果
                    </div>
                  </div>
                  <div class="flex mt-3">
                    <div
                      class="w-28 h-8 leading-8 bg-[#23252F] rounded-md mr-2 cursor-pointer select-none"
                      :class="
                        isMoreThanThree &&
                        'cursor-not-allowed pointer-events-none'
                      "
                      @click="handleModify"
                    >
                      局部修改
                    </div>
                    <div
                      class="w-28 h-8 leading-8 bg-[#23252F] rounded-md mr-2 cursor-pointer select-none"
                      :class="
                        isMoreThanThree &&
                        'cursor-not-allowed pointer-events-none'
                      "
                      @click="handlePart"
                    >
                      配件选择
                    </div>
                    <div
                      class="w-28 h-8 leading-8 bg-[#23252F] rounded-md mr-2 cursor-pointer select-none"
                      :class="
                        isMoreThanThree &&
                        'cursor-not-allowed pointer-events-none'
                      "
                      @click="
                        $emit('modify', {
                          id: result.id,
                          index: activeImage,
                          mode: 'color',
                          imgUrl: result.itemList[activeImage].originUrlVisit
                        })
                      "
                    >
                      配色修改
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex justify-around py-4">
                <div
                  class="flex flex-col justify-center items-center cursor-pointer"
                  @click="regenerator"
                >
                  <img src="@/assets/regenerator.svg" alt="" />
                  <div class="text-sm font-medium mt-1">重新生成</div>
                </div>
                <div
                  class="flex flex-col justify-center items-center cursor-pointer"
                >
                  <img src="@/assets/backEdit.svg" alt="" />
                  <div class="text-sm font-medium mt-1">返回编辑</div>
                </div>
                <div
                  class="w-[544px] h-11 rounded-3xl bg-[#54C752] text-center leading-10 text-center font-bold cursor-pointer"
                  @click="save"
                >
                  保存
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </layout>
    <shejiDialog v-model="dialogVisiable" width="600px">
      <div class="flex flex-col justify-center items-center">
        <div class="px-2 py-1 bg-[rgba(0,0,0,.39)] rounded-xl text-white mb-4">
          使用钢笔工具圈画出要修改的部位，进行提交
        </div>
        <clipImage
          strokeColor="#00FF00"
          :imgSrc="singleImg"
          @update="handleUpdate"
        />
        <div class="flex justify-center items-center mt-4 cursor-pointer">
          <div
            class="w-48 h-10 leading-10 text-white bg-[#54C752] rounded-xl"
            :class="
              !cropImage
                ? '!bg-[#47484E] !text-[#878787] cursor-not-allowed'
                : ''
            "
            @click="closeDialog"
          >
            提交标记
          </div>
        </div>
      </div>
    </shejiDialog>
  </div>
</template>

<script>
import {
  getJobDetail,
  getSingleImg,
  getUserGeneratorCoupon,
  inpaintImg,
  saveJob,
  upscalImg
} from '@/api/workbench'
import clipImage from '../components/clipImage'
import shejiDialog from '@/components/shejiDialog'
import layout from '../components/layout'
import dayjs from 'dayjs'
export default {
  components: {
    clipImage,
    layout,
    shejiDialog
  },
  computed: {
    id: function () {
      return this.$route.params?.id
    },
    index: function () {
      return this.$route.params?.index
    },
    imgUrl: function () {
      return this.$route.query?.imgUrl
    },
    mode: function () {
      return this.$route.params?.mode
    },
    type: function () {
      return this.$route.query?.type
    },
    // 是否超过三天
    isMoreThanThree: function () {
      return dayjs(this.result.updateTime).diff(dayjs(), 'day') > 3
    }
  },
  data() {
    return {
      promptCn: '',
      images: {},
      taskId: {},
      singleImgLoading: false,
      singleImg: null,
      isWait: false,
      result: {},
      cropImage: '',
      jobId: '',
      dialogVisiable: false,
      progress: 0,
      progressInterval: null,
      timer: null,
      allImage: '',
      couponInfo: null,
      activeImage: 0
    }
  },
  watch: {
    isWait: function (val) {
      if (val) {
        this.progress = 0
        this.progressInterval = setInterval(() => {
          this.progress += 1
          if (this.progress >= 99) {
            clearInterval(this.progressInterval)
          }
        }, 1000)
      } else {
        clearInterval(this.progressInterval)
      }
    }
  },
  methods: {
    closeDialog() {
      if (!this.cropImage) {
        return
      }
      this.dialogVisiable = false
    },
    handlePart() {
      this.$router.push({
        path: `/workbench/part/${this.id}/${
          this.result?.itemList[this.activeImage].id
        }/${this.activeImage}`,
        query: {
          imgUrl: this.result?.itemList[this.activeImage].originUrlVisit,
          type: 'regenerator'
        }
      })
    },
    checkFile(file) {
      const isFormat =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg'
      const isLt2M = file.size / 1024 / 1024 < 200

      if (!isFormat) {
        this.$message.error('上传的图片只能是 PNG、JPG、JPEG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传的图片大小不能超过 200MB!')
      }
      const result = isFormat && isLt2M
      if (result) {
        this.fileLoading = true
        return true
      }
      return false
    },
    // 处理图片上传
    handleFileUpload(val) {
      this.$message.success('上传成功')
      this.images = val
      this.fileLoading = false
    },
    // 清除图片
    deleteImages(e) {
      this.images.splice(e, 1)
    },
    async getSingleImgs() {
      this.singleImgLoading = true
      const result = await getSingleImg(this.id, Number(this.index) + 1)
      if (result) {
        // 开启轮询
        if (result.data) {
          this.jobId = result.data
          this.getDetail(this.jobId, true)
        }
      }
    },
    /** 生成 */
    async generator() {
      if (!this.cropImage) {
        return
      }
      const params = {
        id: this.jobId,
        promptCn: this.promptCn,
        updateImgBase64: this.cropImage,
        referenceImgUrl:
          JSON.stringify(this.images) !== '{}' ? `${this.images.url}` : ''
      }
      this.isWait = true
      const result = await inpaintImg(params)
      if (!result) {
        this.isWait = false
      }
      // 开启轮询
      if (result.data) {
        this.taskId = result.data
        this.getDetail(result.data)
      }
    },
    // 轮询查询状态
    async getDetail(id, getFirst = false) {
      const _get = async () => {
        const result = await getJobDetail(id)
        if (result?.data) {
          const status = result.data.status
          if (!['wait_create', 'wait_finish'].includes(status)) {
            clearInterval(this.timer)
            if (status === 'finish_success') {
              if (getFirst) {
                this.singleImgLoading = false
                this.singleImg = result.data.imageUrlVisit
                this.timer = null
                return
              }
              this.result = result.data
              this.$message.success('生成成功！')
            } else {
              this.result = {}
              this.singleImgLoading = false
              this.$message.error(result.data.errorMessages)
              if (getFirst) {
                this.type === 'imageGenerator'
                  ? this.$router.push({
                      path: `/workbench/imagegeneration`,
                      query: { id }
                    })
                  : this.$router.push({
                      path: `/workbench/textgeneration`,
                      query: { id }
                    })
              }
            }
            this.isWait = false
            this.timer = null
          }
        }
      }
      if (!this.timer) {
        this.timer = setInterval(_get, 1000)
      }
    },
    handleUpdate(crop, all) {
      this.cropImage = crop
      this.allImage = all
    },
    // 升档
    async update(val) {
      console.log('this.result===', this.result)
      const result = await upscalImg(this.result.taskId, val)
      if (result) {
        this.isWait = true
        this.result = {}
        this.taskId = result.data
        this.getDetail(result.data, true)
      }
    },
    // 重新生成任务
    async regenerator() {
      this.$message.warning('暂无法使用')
      //   this.isWait = true
      //   const result = await generatorAgain(this.taskId)
      //   if (!result) {
      //     this.isWait = false
      //   }
      //   // 开启轮询
      //   if (result.data) {
      //     this.result = {}
      //     this.taskId = result.data
      //     this.getDetail(result.data, true)
      //   }
    },
    // 保存
    async save() {
      const result = await saveJob(this.taskId)
      if (result) {
        this.taskId = ''
        this.result = {}
        this.$message.success('保存成功!')
      }
    },
    // 获取当前用户的权益信息
    async getCoupon() {
      const result = await getUserGeneratorCoupon({
        isFast: String(1),
        taskType: 1
      })
      if (result) {
        this.couponInfo = result.data
      }
    },
    handleModify() {
      this.$router.push({
        path: `/workbench/regenerator/${this.result?.id}/${this.activeImage}/local`,
        query: {
          imgUrl: this.result.itemList[this.activeImage].originUrlVisit
        }
      })
      setTimeout(() => {
        window.location.reload()
      }, 500)
    }
  },
  mounted() {
    if (
      !this.id ||
      !this.index ||
      !['local', 'color', 'access'].includes(this.mode)
    ) {
      this.$message.error('请从创作页面-局部修改功能进入')
      this.$router.go(-1)
      return
    }
    this.getSingleImgs()
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-loading-spinner {
  display: flex;
  justify-content: center;
}
</style>
