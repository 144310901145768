import workDetail from "@/views/works/detail"
import collection from '@/views/works/collection'

export default [{
    path: "/works/detail/:id",
    name: "workDetail",
    component: workDetail,
    meta: {
      title: '作品详情'
    }
},
{
    path: "/works/collection/:id",
    name: "collection",
    component: collection,
    meta: {
      title: '合集详情'
    }
  },
]