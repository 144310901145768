import service from "@/utils/require"

/**
 * 获取用户个人信息
 * @param {*} userId 用户id
 * @returns 
 */
export const userDetails = (userId) => {
    return service.get(`/system/user/userDetails/${userId}`, {})
}

/**
 * 获取用户作品列表
 * @param {*} page 
 * @param {*} limit
 * @param {*} classifyId 所属的分类编码（若是全部，则不用传、-1:代表关键、-2:推荐）
 * @param {*} sortFields （1:综合、2:最新、3:同款、4:点赞、5、收藏）
 * @param {*} sortMode 排序方式（asc、desc）
 * @param {*} userId 用户id
 * @param {*} articleType 文章类型（1：普通ai创作，2：合集；3：笔记）
 */
export const creationArticleFindMiniList = (params) => service({
    url: '/creationArticle/findMiniList',
    method: 'get',
    params
})

/**
 * 获取用户服务商列表
 * @param {*} page 
 * @param {*} limit
 * @param {*} userId 用户id
 */
export const getProviderServerList = (params) => service({
    url: '/providerServerItem/getProviderServerList',
    method: 'get',
    params
})

