<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import * as echarts from 'echarts';

require('echarts/theme/macarons') // echarts theme
import { debounce } from '@/utils'

export default {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    titleConfig: {
      type: Array,
      default: () => []
    },
    legedConfig: {
      type: Object,
      default: () => {}
    },
    tooltipConfig: {
      type: Object,
      default: () => {}
    },
    gridConfig: {
      type: Object,
      default: () => {}
    },
    seriesData: {
      type: Array,
      default: () => []
    },
    xAxisData: {
      type: Object,
      default: () => {}
    },
    yAxisData: {
      type: Array,
      default: () => []
    },
    visualMapConfig: {
      type: Array,
      default: () => []
    },
    lineColor: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chart: null
    }
  },
  mounted() {
    this.$nextTick(()=>{
      this.initChart()
      this.__resizeHandler = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 100)
      window.addEventListener('resize', this.__resizeHandler)
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    window.removeEventListener('resize', this.__resizeHandler)
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el)
      const optionConfig = {
        title: this.titleConfig,
        legend: this.legedConfig,
        tooltip: this.tooltipConfig,
        visualMap: this.visualMapConfig,
        toolbox: {
          show: false,
          feature: {
            saveAsImage: {}
          }
        },
        grid: this.gridConfig,
        xAxis: this.xAxisData,
        yAxis: this.yAxisData,
        series: this.seriesData
      }
      if (this.lineColor && this.lineColor.length > 0) {
        optionConfig.color = this.lineColor
      }
      this.chart.setOption(optionConfig)
    }
  }
}
</script>
