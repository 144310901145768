<template>
  <div class="guide-wrap">
    <div class="price">￥{{ info.totalPrice }}元</div>
    <div class="pay-result">支付成功</div>
    <div class="pay-time">支付时间：{{ info.createTime }}</div>
    <div class="pay-ramark">
      <div class="warn-icon">
        <img src="@/assets/service/warn_icon3.svg" />
        <img src="@/assets/service/warn_icon2.svg" />
        <img src="@/assets/service/warn_icon1.svg" />
      </div>
      支付完成，请根据下方指引操作
    </div>
    <div class="guide-content">
      <div class="guide-item">
        <div class="guide-title">
          <div class="guide-step">1</div>
          复制本次订单号提供给客服
        </div>
        <div class="guide-info">
          <div class="order-code">
            订单号：{{ info.orderNo }}
            <div class="last-code">{{ (info.orderNo || '').slice(-4) }}</div>
            <div class="copy" @click="handleCopy">
              <img src="@/assets/service/copy_icon.svg" />
              <div class="copy-text">复制</div>
            </div>
          </div>
          <div class="info-text">复制订单号或提供订单后4位</div>
        </div>
      </div>
      <div class="guide-item">
        <div class="guide-title">
          <div class="guide-step">2</div>
          添加企业微信客服
        </div>
        <div class="guide-info">
          <img class="qrcode" src="@/assets/service/qrcode.png" />
          <div class="qrcode-tips">
            <img class="tip-icon" src="@/assets/service/code_icon.svg" />
            手机扫描二维码
          </div>
        </div>
      </div>
      <div class="guide-item">
        <div class="guide-title">
          <div class="guide-step">3</div>
          为您对接当前服务商
        </div>
        <div class="guide-info">
          <img class="step-img" src="@/assets/service/sevice_bg.svg" />
        </div>
      </div>
      <div class="guide-item">
        <div class="guide-title">
          <div class="guide-step">4</div>
          正式开启服务
        </div>
        <div class="guide-info">
          <div class="guide-finish">
            <img class="success-icon" src="@/assets/service/succee_icon.svg" />
            <div class="finish-text">第三方全程监督，资金监管</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'guide',
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    async handleCopy() {
      try {
        await navigator.clipboard.writeText(this.info.orderNo || '')
        this.$message.success('复制成功')
      } catch (err) {
        console.error('复制到剪贴板失败:', err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.guide-wrap {
  padding-top: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .price {
    font-family: Source Han Sans;
    font-size: 24px;
    font-weight: 500;
    color: #e35141;
    line-height: 1;
  }
  .pay-result {
    font-family: Source Han Sans;
    font-size: 18px;
    font-weight: 500;
    color: #54c752;
    margin-top: 16px;
    line-height: 1;
  }
  .pay-time {
    font-family: Source Han Sans;
    font-size: 14px;
    font-weight: normal;
    color: #878787;
    line-height: 1;
    margin-top: 8px;
  }
  .pay-ramark {
    padding: 0 12px;
    height: 30px;
    margin-top: 28px;
    border-radius: 6px;
    background-color: #fff500;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Source Han Sans;
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    .warn-icon {
      width: 22px;
      height: 20px;
      position: relative;
      margin-right: 6px;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .guide-content {
    margin-top: 54px;
    display: flex;
    .guide-item {
      padding-right: 116px;
      position: relative;
      &::after {
        content: '';
        display: block;
        width: 72px;
        height: 1px;
        background-color: #d8d8d8;
        position: absolute;
        right: 22px;
        top: 15px;
      }
      &:last-child {
        padding-right: 0;
        &::after {
          display: none;
        }
      }
      .guide-title {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: bold;
        line-height: 20px;
        color: #000000;
        .guide-step {
          height: 32px;
          width: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          font-weight: bold;
          line-height: 10px;
          color: #ffffff;
          background-color: #000000;
          border-radius: 50%;
          margin-right: 12px;
        }
      }
      .guide-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        .order-code {
          display: flex;
          align-items: center;
          font-family: Source Han Sans;
          font-size: 14px;
          font-weight: normal;
          line-height: 10px;
          color: #878787;
          .last-code {
            color: #fe5101;
            margin-left: 4px;
          }
          .copy {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-left: 12px;
            img {
              width: 18px;
              height: 18px;
              margin-right: 8px;
            }
            .copy-text {
              font-family: Source Han Sans;
              font-size: 14px;
              font-weight: 500;
              line-height: 10px;
              color: #06a703;
            }
          }
        }
        .info-text {
          font-family: Source Han Sans;
          font-size: 14px;
          font-weight: normal;
          line-height: 10px;
          color: #474747;
          margin-top: 24px;
        }
        .qrcode {
          width: 180px;
          height: 180px;
        }
        .qrcode-tips {
          width: 212px;
          height: 42px;
          border-radius: 10px;
          background-color: #54c752;
          padding: 0 16px;
          display: flex;
          align-items: center;
          font-family: Source Han Sans;
          font-size: 18px;
          font-weight: 500;
          line-height: 1;
          color: #ffffff;
          margin-top: 20px;
          cursor: pointer;
          .tip-icon {
            width: 22px;
            height: 22px;
            margin-right: 18px;
          }
        }
        .step-img {
          width: 243px;
          height: 246px;
          transform: translate(-18px, -24px);
          position: absolute;
        }
        .guide-finish {
          width: 180px;
          height: 172px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .success-icon {
            width: 82px;
            height: 82px;
          }
          .finish-text {
            font-family: Source Han Sans;
            font-size: 12px;
            font-weight: 500;
            color: #04a101;
            margin-top: 18px;
          }
        }
      }
    }
  }
}
</style>