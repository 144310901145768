<template>
  <div>
    <comfirmDialog
      v-model="addCoverVisible"
      width="430px"
      height="600px"
      titleAlign="left"
      :showCancel="false"
      :title="isEditForm ? '合集设置' : '新建合集'"
      @confirm="handleCoverConfirm"
    >
      <div class="albumCoverForm-form" v-loading="loading">
        <div class="albumCoverForm-item">
          <el-input
            v-model="addCoverQuery.title"
            placeholder="请输入合集名称"
          />
        </div>

        <div class="albumCoverForm-item">
          <el-input
            type="textarea"
            :autosize="{ minRows: 6, maxRows: 6 }"
            maxlength="300"
            placeholder="分享合集背后的故事（选填）"
            v-model="addCoverQuery.content"
            show-word-limit
          >
          </el-input>
        </div>

        <div class="albumCoverForm-item">
          <el-select
            v-model="addCoverQuery.classifyId"
            multiple
            placeholder="请选择分类"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in classifyList"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>

        <div class="albumCoverForm-item">
          <div class="flex">
            <div class="name">请选择标签</div>
            <img src="@/assets/collection/suggest-icon.svg" />
          </div>
          <el-radio-group v-model="addCoverQuery.isOpen">
            <div class="radio">
              <el-radio :label="0">
                <span class="public nopublic">私有</span>
                <span class="tips">仅自已可见</span>
              </el-radio>
            </div>
            <div class="radio">
              <el-radio :label="1">
                <span class="public">公开</span>
                <span class="tips">所有人可见</span>
              </el-radio>
            </div>
          </el-radio-group>
        </div>
      </div>
    </comfirmDialog>
  </div>
</template>

<script>
import comfirmDialog from '@/components/comfirmDialog/index'
import {
  addCollection,
  editCollection,
  articleClassify,
  collectionDetails
} from '@/api/album/index.js'
export default {
  name: 'albumCoverForm',
  components: { comfirmDialog },
  data() {
    return {
      loading: false,
      isEditForm: false,
      addCoverQuery: {},
      addCoverVisible: false,
      classifyList: []
    }
  },
  methods: {
    /**
     * 打开弹窗
     * @param {*} status 是否编辑
     */
    async openAddDialog(status) {
      this.isEditForm = status
      this.addCoverVisible = true
      await this.initClassify()
      if (this.isEditForm) {
        await this.initCoverInfo()
      }
    },

    /* 关闭弹窗 */
    closeAddDialog() {
      this.addCoverVisible = false
      this.addCoverQuery = {}
      this.$emit('fresh')
    },

    /* 获取分类 */
    async initClassify() {
      let result = await articleClassify().catch((e) => {})
      let list = result && result.rows
      this.classifyList = list
    },

    /* 获取合集详情 */
    async initCoverInfo() {
      let result = await collectionDetails(this.isEditForm).catch((e) => {})
      result = (result && result.data) || {}
      result.classifyId = result.classifyId ? result.classifyId.split(',') : []
      this.addCoverQuery = result
    },

    /* 新增/编辑合集确认 */
    handleCoverConfirm() {
      if (!this.addCoverQuery.title) {
        return this.$message.error('请输入合集名称')
      }
      let query = JSON.parse(JSON.stringify(this.addCoverQuery))
      query.classifyId = query.classifyId.length
        ? query.classifyId.join(',')
        : null
      this.loading = true
      let myApi = this.isEditForm ? editCollection : addCollection
      myApi(query)
        .then((res) => {
          if (res.code != 200) {
            return this.$message.success(res.msg)
          }
          let msg = this.isEditForm ? '设置成功' : '新建成功'
          this.$message.success(msg)
          this.closeAddDialog()
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.albumCoverForm {
  &-form {
    flex: 1;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
  &-item {
    margin-top: 18px;
    .name {
      font-size: 16px;
      color: #000000;
      margin-right: 6px;
    }
    .radio {
      margin-top: 16px;
      text-align: left;
    }
    .public {
      width: 36px;
      height: 16px;
      border-radius: 5px;
      background: #d000ff;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-left: 12px;
      margin-right: 8px;
      font-size: 10px;
      color: #ffffff;
    }
    .nopublic {
      background: #000000;
    }
    .tips {
      font-size: 14px;
      color: #5e5e5e;
    }
  }
}
::v-deep .el-input__inner {
  height: 52px;
  border-radius: 14px;
  background: #f0f0f0;
  border: 1px solid #c4bfbf;
  font-size: 14px;
  color: #5e5e5e;
}
::v-deep .el-textarea__inner {
  border-radius: 14px;
  background: #f0f0f0;
  border: 1px solid #c4bfbf;
  font-size: 14px;
  color: #5e5e5e;
  padding: 10px 15px;
}
::v-deep .el-textarea .el-input__count {
  background: #f0f0f0;
}
::v-deep .el-tag.el-tag--info {
  background: none;
  border: none;
  color: #5e5e5e;
}
::v-deep .el-tag.el-tag--info .el-tag__close {
  display: none;
}
::v-deep .el-radio-group {
  width: 100%;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: #54c752;
  background: #54c752;
}
</style>