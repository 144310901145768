import service from "@/utils/require"

// 获取用户电力券订单分页列表
export const userOrderList = (params) => service({
    url: '/powerCoupon/userOrderList',
    method: 'get',
    params
})

// ok-查询电力券订单详情
export const userOrderDetails = (id) => service({
    url: `/powerCoupon/userOrderDetails/${id}`,
    method: 'get',
})

// 分页查询用户所购买的服务列表
export const userServerItemOrderList = (params) => service({
    url: '/serverItemOrder/userServerItemOrderList',
    method: 'get',
    params
})

// 查询用户购买的服务订单详情
export const userServerItemOrderDetails = (orderId) => service({
    url: `/serverItemOrder/userServerItemOrderDetails/${orderId}`,
    method: 'get',
})


// ok-获取用户会员订单分页列表
export const userPayList = (params) => service({
    url: '/userVipPayOrder/userPayList',
    method: 'get',
    params
})

// ok-获取用户vip订单详情
export const userPayDetails = (orderId) => service({
    url: `/userVipPayOrder/userPayDetails/${orderId}`,
    method: 'get',
}) 