<template>
  <div>
    <shejiDialog v-model="value" width="1200px">
      <div class="px-3">
        <div class="text-lg text-left">配件选择</div>
        <div class="flex text-lg justify-center mt-4 gap-4 cursor-pointer">
          <div
            @click="changePart('system')"
            :class="activeChoose === 'system' ? 'activeClass' : ''"
          >
            系统库
          </div>
          <div
            @click="changePart('mine')"
            :class="activeChoose === 'mine' ? 'activeClass' : ''"
          >
            我的库
          </div>
          <!-- <div>最近选择</div>
            <div>我的收藏</div> -->
        </div>
        <div class="flex justify-center mt-4 px-4 mb-4 overflow-y-auto">
          <div
            v-for="item in classes"
            :key="item.id"
            class="mr-2 text-lg cursor-pointer"
            @click="changeClasses(item)"
            :class="
              activeClasses.classifyName === item.classifyName
                ? 'activeClass'
                : ''
            "
          >
            {{ item.classifyName }}
            <el-dropdown
              v-if="item.classifyName !== '全部' && activeChoose === 'mine'"
            >
              <span class="el-dropdown-link">
                <i class="el-icon-more rotate-90"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <div class="w-[120px] text-sm">
                  <div
                    class="p-2 hover:bg-[rgba(84,199,82,.1)] cursor-pointer"
                    @click="deleteClassifyMethod(item.id)"
                  >
                    删除
                  </div>
                  <div
                    class="p-2 hover:bg-[rgba(84,199,82,.1)] cursor-pointer"
                    @click="renameClassMethod(item)"
                  >
                    重命名
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="flex justify-between mt-4">
          <div class="px-4 flex w-[300px] overflow-x-auto">
            <div
              class="mr-2 text-sm cursor-pointer"
              v-for="item in material"
              :key="item.id"
              :class="activeMaterial.id === item.id ? 'activeClass' : ''"
              @click="changeMaterial(item)"
            >
              {{ item.textureName }}
            </div>
          </div>
          <div class="flex" v-if="activeChoose === 'mine'">
            <!-- <div
              class="mr-2 cursor-pointer px-3 text-sm bg-[#54c752] text-white rounded-xl"
            >
              编辑
            </div> -->
            <div
              class="cursor-pointer px-3 text-sm bg-[#54c752] text-white rounded-xl"
              @click="addPartVisible = true"
            >
              上传附件
            </div>
          </div>
        </div>
        <div class="h-[400px] mt-4 px-4 grid grid-cols-9 gap-2">
          <div
            class="w-[120px] h-36 p-3 rounded cursor-pointer border flex flex-col justify-center items-center"
            @click="addPartVisible = true"
            v-if="activeChoose === 'mine'"
          >
            <img src="@/assets/image.svg" class="w-12 h-12" alt="" />
            <div class="text-[#0033FF] text-sm mt-1">上传配件图片</div>
            <div class="text-[#878787] text-xs mt-1">
              建议图片像素1080P, 格式JPG/PNG, 大小不超过2MB
            </div>
          </div>
          <div
            class="w-[120px] h-36 p-3 rounded bg-[#F4F5F7] cursor-pointer border"
            :class="activePart === item ? 'border-[#54C752]' : ''"
            v-for="item in partData"
            @click="openPartDetail(item)"
            :key="item.id"
          >
            <div class="border h-24 rounded">
              <img
                class="h-20"
                :src="
                  item.partsSystemItems
                    ? item.partsSystemItems[0].itemImgUrl
                    : item.partsUserItems[0].itemImgUrl
                "
              />
              <div
                v-if="
                  item.partsSystemItems?.length > 0 ||
                  item.partsUserItems?.length > 0
                "
                class="relative left-7"
              >
                <img src="@/assets/fourRound.svg" alt="" />
              </div>
            </div>
            <div class="mt-1 text-sm">{{ item.partsIntroduce }}</div>
          </div>
        </div>
        <!-- 添加分页器 -->
        <div class="flex justify-end">
          <el-pagination
            background
            layout="total, prev, pager, next"
            :total="totalItems"
            :current-page="currentPage"
            :page-size="params.pageSize"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
        <div class="flex justify-center mt-4">
          <div
            class="bg-[#F4F5F7] h-8 mr-3 cursor-pointer leading-8 w-80 rounded-2xl"
            @click="cancelClick"
          >
            取消
          </div>
          <div
            class="bg-[#54C752] h-8 leading-8 cursor-pointer w-80 rounded-2xl text-white"
            @click="confirmClick"
          >
            确定
          </div>
        </div>
      </div>
    </shejiDialog>
    <shejiDialog v-model="partDialogVisiable" width="400px">
      <div>
        <img
          class="w-full h-80"
          v-if="JSON.stringify(clickPart) !== '{}'"
          :src="
            clickPart?.partsSystemItems?.length > 0
              ? clickPart?.partsSystemItems[this.activePartIndex]?.itemImgUrl
              : clickPart?.partsUserItems[this.activePartIndex]?.itemImgUrl
          "
          alt=""
        />
        <div class="flex justify-center mt-2">
          <div
            class="flex justify-start"
            v-if="JSON.stringify(clickPart) !== '{}'"
          >
            <template v-if="clickPart?.partsSystemItems?.length > 0">
              <img
                v-for="(item, index) in clickPart?.partsSystemItems"
                :key="item.id"
                :src="item.itemImgUrl"
                class="w-16 h-16 mr-2 cursor-pointer"
                :class="
                  index == activePartIndex ? 'border border-[#54C752]' : ''
                "
                @click="changImage(index, 'system')"
              />
            </template>
            <template v-else>
              <img
                v-for="(item, index) in clickPart?.partsUserItems"
                :key="item.id"
                :src="item.itemImgUrl"
                class="w-16 h-16 mr-2 cursor-pointer"
                :class="
                  index == activePartIndex ? 'border border-[#54C752]' : ''
                "
                @click="changImage(index, 'mine')"
              />
            </template>
          </div>
        </div>
        <div class="mt-2 ml-2 flex">
          <span class="text-[#878787]">配件编号：</span>
          {{ clickPart?.partsNo }}
        </div>
        <div class="mt-2 ml-2 flex">
          <span class="text-[#878787]">配件材质：</span>
          {{ clickPart?.partsTextureName }}
        </div>
        <div class="mt-2 ml-2 flex">
          <span class="text-[#878787]">配件尺码：</span>
          {{ clickPart?.partsNo }}
        </div>
        <div class="mt-2 ml-2 flex">
          <span class="text-[#878787]">供应商：</span>
          {{ clickPart?.providerName }}
        </div>
        <div class="mt-2 ml-2 flex">
          <span class="text-[#878787]">产品介绍：</span>
          {{ clickPart?.partsIntroduce }}
        </div>
      </div>
    </shejiDialog>
  </div>
</template>
  
  <script>
import shejiDialog from '@/components/shejiDialog'
import addPart from '../addPart'
import {
  deleteClassify,
  getMaterialList,
  getSystemPartsPage,
  getUserAllPartCategory,
  getUserPartsPage,
  renameClass
} from '@/api/workbench'

export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  components: {
    shejiDialog,
    addPart
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  },
  data() {
    return {
      activeChoose: 'system',
      material: [],
      classes: [],
      partData: [],
      activePart: {},
      activeMaterial: {
        id: 'all',
        textureName: '全部'
      },
      activeClasses: {},
      totalItems: 0,
      currentPage: 1,
      params: {
        textureId: undefined,
        classifyId: undefined,
        pageNum: 1,
        pageSize: 27
      },
      clickPart: {},
      partDialogVisiable: false,
      activePart: {},
      activePartImage: '',
      activePartIndex: 0
    }
  },
  mounted() {
    this.getCategory()
    this.getMaterial()
    this.getPartData()
  },
  methods: {
    async getCategory() {
      const result = await getUserAllPartCategory()
      if (result) {
        this.classes = result.data
        this.classes.unshift({
          id: 0,
          classifyName: '全部'
        })
        this.activeClasses = this.classes[0] ? this.classes[0] : {}
      }
    },
    async getMaterial() {
      const result = await getMaterialList()
      if (result) {
        this.material = result.data
        this.material.unshift({
          id: 'all',
          textureName: '全部'
        })
        this.activeMaterial = {
          id: 'all',
          textureName: '全部'
        }
      }
    },
    async getPartData() {
      const params = {
        ...this.params,
        pageNum: this.currentPage
      }
      const result =
        this.activeChoose === 'system'
          ? await getSystemPartsPage(params)
          : await getUserPartsPage(params)
      if (result) {
        this.partData = result.rows
        this.totalItems = result.total
      }
    },
    handleCurrentChange(page) {
      this.currentPage = page
      this.getPartData()
    },
    changePart(type) {
      this.activeChoose = type
      this.getPartData()
    },
    changeClasses(item) {
      this.activeClasses = item
      this.params.classifyId =
        item.classifyName === '全部' ? undefined : item.id
      this.getPartData()
    },
    changeMaterial(item) {
      this.activeMaterial = item
      this.params.textureId = item.textureName === '全部' ? undefined : item.id
      this.getPartData()
    },
    // 打开配件具体选择页面
    openPartDetail(item) {
      this.partDialogVisiable = true
      this.clickPart = item
      this.activePart = item
      this.activePartImage =
        item.partsSystemItems?.length > 0
          ? item.partsSystemItems[0].itemImgUrl
          : item.partsUserItems[0].itemImgUrl
    },
    changImage(index, type) {
      this.activePartIndex = index
      this.activePartImage =
        type === 'system'
          ? this.activePart.partsSystemItems[index].itemImgUrl
          : this.activePart.partsUserItems[index].itemImgUrl
    },
    cancelClick() {
      // 关闭弹窗
      this.value = false
    },
    confirmClick() {
      if (!this.activePart || JSON.stringify(this.activePart) === '{}') {
        this.$message.warning('请先选择配件')
        return
      }
      // 发送选中的配件数据
      this.$emit('selected', {
        activePart: this.activePart,
        activePartImage: this.activePartImage
      })
      // 关闭弹窗
      this.value = false
    },
    deleteClassifyMethod(id) {
      this.$confirm('确定删除该分类？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteClassify(id).then(() => {
          this.$message.success('删除成功')
          this.getCategory()
        })
      })
    },
    renameClassMethod(item) {
      this.$prompt('请输入新的分类名称', '重命名', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[\u4e00-\u9fa5a-zA-Z0-9]{1,20}$/,
        inputErrorMessage: '请输入1-20位的中文、英文、数字'
      })
        .then(({ value }) => {
          renameClass({
            id: item.id,
            classifyName: value
          }).then(() => {
            this.$message.success('重命名成功')
            this.getCategory()
          })
        })
        .catch(() => {})
    }
  }
}
</script>
  
  <style lang="scss" scoped>
/* 自定义分页器背景颜色 */
::v-deep .el-pagination .btn-prev,
::v-deep.el-pagination .btn-next,
::v-deep.el-pagination .el-pager li {
  background-color: #54c752 !important;
  color: white !important;
}

::v-deep .el-pagination ::v-deep.el-pager li:not(.disabled):hover {
  background-color: #44b742 !important;
}

::v-deep .el-pagination .el-icon-arrow-left,
::v-deep.el-pagination .el-icon-arrow-right {
  color: white !important;
}

.activeClass {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 2px;
    background: #54c752;
    bottom: 0px;
    right: 0;
  }
}
</style>