<template>
  <div class="w-full h-[300px] flex flex-col items-center justify-center">
    <img src="@/assets/noData.svg" />
    <div class="text-lg text-[#999999] mt-[30px]">暂无数据</div>
  </div>
</template>

<script>
export default {
  name: 'noData'
}
</script>

<style>
</style>