<template>
  <div class="flex">
    <div
      v-for="(item, index) in infoList"
      :key="index"
      class="mr-[36px] cursor-pointer"
      @click="handleCheck(item)"
    >
      <div class="font-bold text-black text-lg">
        {{ info[item.props] || 0 }}
      </div>
      <div class="text-base text-[#666666]">{{ item.label }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'homeNum',
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      infoList: [
        { label: '关注', props: 'fucusNum' },
        { label: '粉丝', props: 'fansNum' },
        { label: '获赞', props: 'loveNum' },
        { label: '发布', props: 'productNum' },
        { label: '被同款', props: 'sameProductNum' }
      ]
    }
  },
  methods: {
    handleCheck(item) {
      if (['fansNum', 'fucusNum'].includes(item.props)) {
        this.$router.push({
          path: '/my/facusAfans',
          query: {
            defaultIndex: item.props == 'fucusNum' ? 0 : 1
          }
        })
      }
    }
  }
}
</script>

<style>
</style>