<!-- 重新生成 -->
<template>
  <div>
    <layout leftContentName="参数设置" :mode="true">
      <template slot="firstTag">
        <div class="ml-4 mt-4 text-sm text-left">配件库</div>
        <div class="p-4 flex items-center justify-around">
          <div
            class="bg-[#F4F5F7] w-40 h-10 rounded-2xl leading-9 cursor-pointer border"
            :class="activeParts === 'system' ? 'border-[#54C752]  ' : ''"
            @click="changePart('system')"
          >
            系统库
          </div>
          <div
            class="bg-[#F4F5F7] w-40 h-10 rounded-2xl leading-9 cursor-pointer border"
            :class="activeParts === 'mine' ? 'border-[#54C752] ' : ''"
            @click="changePart('mine')"
          >
            我的库
          </div>
        </div>
        <div class="flex px-4 mb-4 overflow-y-auto">
          <div
            v-for="item in classes"
            :key="item.id"
            class="mr-2 text-lg cursor-pointer"
            @click="changeClasses(item)"
            :class="
              activeClasses.classifyName === item.classifyName
                ? 'activeClass'
                : ''
            "
          >
            {{ item.classifyName }}
          </div>
        </div>
        <div class="px-4 flex">
          <el-select v-model="activeMaterial" @change="getPartData">
            <el-option label="全部材质" value="all"></el-option>
            <el-option
              v-for="item in material"
              :key="item.id"
              :label="item.textureName"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="mt-4 px-4 grid grid-cols-3 gap-2">
          <div
            class="w-[120px] h-36 p-3 rounded cursor-pointer border flex flex-col justify-center items-center"
            @click="addPartVisible = true"
            v-if="activeParts === 'mine'"
          >
            <img src="@/assets/image.svg" class="w-12 h-12" alt="" />
            <div class="text-[#0033FF] text-sm mt-1">上传配件图片</div>
            <div class="text-[#878787] text-xs mt-1">
              建议图片像素1080P, 格式JPG/PNG, 大小不超过2MB
            </div>
          </div>
          <div
            class="w-[120px] h-36 p-3 rounded bg-[#F4F5F7] cursor-pointer border"
            :class="activePart?.id === item?.id ? 'border-[#54C752]' : ''"
            v-for="item in partData"
            @click="openPartDetail(item)"
            :key="item.id"
          >
            <div class="border h-24 rounded">
              <img
                class="h-20"
                :src="
                  item.partsSystemItems
                    ? item.partsSystemItems[0].itemImgUrl
                    : item.partsUserItems[0].itemImgUrl
                "
              />
              <div
                v-if="
                  item.partsSystemItems?.length > 0 ||
                  item.partsUserItems?.length > 0
                "
                class="relative left-7"
              >
                <img src="@/assets/fourRound.svg" alt="" />
              </div>
            </div>
            <div class="mt-1 text-sm">{{ item.partsIntroduce }}</div>
          </div>
        </div>
        <div
          class="mt-2 w-9/10 bg-[#eFeFeF] mx-2 p-2 flex justify-center items-center cursor-pointer"
          @click="partSelectVisible = true"
        >
          <img src="@/assets/gengduo.svg" class="mr-2" alt="" />
          更多
        </div>
      </template>
      <template slot="content">
        <div class="w-full h-full flex flex-col">
          <div
            class="flex justify-center items-center"
            style="height: calc(100% - 200px)"
          >
            <div
              class="w-[437px] h-[437px] bg-white border border-dashed border-[#C1C1C1]"
            >
              <img
                :src="detailedImg?.url || imgUrl"
                alt=""
                class="w-[437px] h-[437px] rounded-xl"
              />
            </div>
          </div>
          <div class="flex justify-center items-center -mt-8">
            <div
              class="w-48 h-12 leading-[48px] bg-white flex justify-center items-center cursor-pointer rounded-lg"
              @click="openImageDialog"
            >
              <img src="@/assets/edit.svg" alt="" />
              <div class="flex justify-between items-center ml-2">编辑图片</div>
            </div>
          </div>
          <div
            class="absolute bottom-0 h-28 w-full flex justify-center"
            :class="JSON.stringify(result) === '{}' ? 'h-28' : 'h-52'"
          >
            <div
              class="w-[550px] h-14 bg-white rounded-3xl flex justify-center items-center"
              @click="save"
            >
              <div
                class="w-[520px] rounded-3xl h-10 bg-[#54C752] text-white relative flex justify-center items-center cursor-pointer"
                :class="
                  JSON.stringify(detailedImg) === '{}' &&
                  '!bg-[#D8D8D8] !cursor-not-allowed'
                "
              >
                <i class="el-icon-loading mr-4" v-if="saveLoading"></i>
                <div class="text-lg font-bold">保存</div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </layout>
    <shejiDialog v-model="partDialogVisiable" width="400px">
      <div>
        <img
          class="w-full h-80"
          v-if="JSON.stringify(clickPart) !== '{}'"
          :src="
            clickPart?.partsSystemItems?.length > 0
              ? clickPart?.partsSystemItems[this.activePartIndex]?.itemImgUrl
              : clickPart?.partsUserItems[this.activePartIndex]?.itemImgUrl
          "
          alt=""
        />
        <div class="flex justify-center mt-2">
          <div
            class="flex justify-start"
            v-if="JSON.stringify(clickPart) !== '{}'"
          >
            <template v-if="clickPart?.partsSystemItems?.length > 0">
              <img
                v-for="(item, index) in clickPart?.partsSystemItems"
                :key="item.id"
                :src="item.itemImgUrl"
                class="w-16 h-16 mr-2 cursor-pointer"
                :class="
                  index == activePartIndex ? 'border border-[#54C752]' : ''
                "
                @click="changImage(index, 'system')"
              />
            </template>
            <template v-else>
              <img
                v-for="(item, index) in clickPart?.partsUserItems"
                :key="item.id"
                :src="item.itemImgUrl"
                class="w-16 h-16 mr-2 cursor-pointer"
                :class="
                  index == activePartIndex ? 'border border-[#54C752]' : ''
                "
                @click="changImage(index, 'mine')"
              />
            </template>
          </div>
        </div>
        <div class="mt-2 ml-2 flex">
          <span class="text-[#878787]">配件编号：</span>
          {{ clickPart?.partsNo }}
        </div>
        <div class="mt-2 ml-2 flex">
          <span class="text-[#878787]">配件材质：</span>
          {{ clickPart?.partsTextureName }}
        </div>
        <div class="mt-2 ml-2 flex">
          <span class="text-[#878787]">配件尺码：</span>
          {{ clickPart?.partsNo }}
        </div>
        <div class="mt-2 ml-2 flex">
          <span class="text-[#878787]">供应商：</span>
          {{ clickPart?.providerName }}
        </div>
        <div class="mt-2 ml-2 flex">
          <span class="text-[#878787]">产品介绍：</span>
          {{ clickPart?.partsIntroduce }}
        </div>
      </div>
    </shejiDialog>
    <shejiDialog v-model="dialogVisiable" width="600px">
      <div class="flex flex-col justify-center items-center">
        <div class="px-2 py-1 bg-[rgba(0,0,0,.39)] rounded-xl text-white mb-4">
          使用下方工具可对配件位置大小进行调整
        </div>
        <coverImage
          ref="coverImage"
          :imgSrc="imgUrl"
          :partSrc="activePartImage"
          v-model="rotate"
          @update="handleUpdate"
        />
        <div class="flex rounded-2xl mt-2 items-center bg-[#000000] px-4 py-2">
          <div
            class="flex text-sm flex-col justify-center items-center"
            @click="filp"
          >
            <img src="@/assets/fanzhuan.svg" alt="" />
            <span class="text-[#fff] mt-2">水平翻转</span>
          </div>
          <div class="flex text-sm flex-col justify-center items-center rotate">
            <el-input-number
              v-model="rotate"
              class="custom-rotate-input"
              :controls="false"
            ></el-input-number>
            <span class="text-[#fff]">旋转</span>
          </div>
          <div class="flex justify-center items-center cursor-pointer ml-2">
            <div
              class="w-16 h-10 leading-10 text-white bg-[#54C752] rounded-xl"
              @click="closeDialog"
            >
              保存
            </div>
          </div>
        </div>
      </div>
    </shejiDialog>
    <partSelect
      @selected="changeActivePart"
      v-model="partSelectVisible"
    ></partSelect>
    <addPart
      v-model="addPartVisible"
      @refresh="
        getCategory()
        getPartData()
        getMaterial()
      "
    ></addPart>
  </div>
</template>
  
  <script>
import {
  getMaterialList,
  getSystemPartsPage,
  getUserAllPartCategory,
  getUserGeneratorCoupon,
  getUserPartsPage,
  saveParts
} from '@/api/workbench'
import coverImage from '../components/coverImage'
import shejiDialog from '@/components/shejiDialog'
import layout from '../components/layout'
import SortHeader from '@/components/sortHeader'
import partSelect from '../components/partSelect'
import addPart from '../components/addPart'
export default {
  components: {
    coverImage,
    layout,
    shejiDialog,
    SortHeader,
    partSelect,
    addPart
  },
  computed: {
    id: function () {
      return this.$route.params?.id
    },
    itemId: function () {
      return this.$route.params?.itemid
    },
    index: function () {
      return this.$route.params?.index
    },
    imgUrl: function () {
      return this.$route.query?.imgUrl
    },
    type: function () {
      return this.$route.query?.type
    }
  },
  data() {
    return {
      result: {},
      cropImage: '',
      jobId: '',
      dialogVisiable: false,
      progress: 0,
      progressInterval: null,
      timer: null,
      allImage: '',
      couponInfo: null,
      material: [],
      activeParts: 'system',
      activeMaterial: 'all',
      partData: [],
      clickPart: {},
      activePart: {},
      activePartImage: '',
      activePartIndex: 0,
      partDialogVisiable: false,
      classes: [],
      activeClasses: {},
      rotate: 0,
      detailedImg: {},
      saveLoading: false,
      partSelectVisible: false,
      addPartVisible: false
    }
  },
  watch: {
    isWait: function (val) {
      if (val) {
        this.progress = 0
        this.progressInterval = setInterval(() => {
          this.progress += 1
          if (this.progress >= 99) {
            clearInterval(this.progressInterval)
          }
        }, 1000)
      } else {
        clearInterval(this.progressInterval)
      }
    }
  },
  methods: {
    closeDialog() {
      this.$refs.coverImage.saveImage()
    },
    checkFile(file) {
      const isFormat =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg'
      const isLt2M = file.size / 1024 / 1024 < 200

      if (!isFormat) {
        this.$message.error('上传的图片只能是 PNG、JPG、JPEG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传的图片大小不能超过 200MB!')
      }
      const result = isFormat && isLt2M
      if (result) {
        this.fileLoading = true
        return true
      }
      return false
    },
    // 处理图片上传
    handleFileUpload(val) {
      this.$message.success('上传成功')
      this.images = val
      this.fileLoading = false
    },
    // 清除图片
    deleteImages(e) {
      this.images.splice(e, 1)
    },
    handleUpdate(img) {
      this.detailedImg = img
      console.log(this.detailedImg)
      this.dialogVisiable = false
    },
    // 保存
    async save() {
      if (JSON.stringify(this.detailedImg) === '{}') {
        return
      }
      this.saveLoading = true
      const result = await saveParts({
        jobId: this.id,
        jobItemId: this.itemId,
        imgUrl: this.detailedImg.compressFileUrl,
        originImgUrl: this.detailedImg.url
      })
      if (result) {
        this.detailedImg = {}
        this.$message.success('保存成功!')
        this.$router.push(`/workbench/${this.type}?id=${this.id}`)
      }
      this.saveLoading = false
    },
    // 获取当前用户的权益信息
    async getCoupon() {
      const result = await getUserGeneratorCoupon({
        isFast: String(1),
        taskType: 1
      })
      if (result) {
        this.couponInfo = result.data
      }
    },
    // 获取分类
    async getCategory() {
      const result = await getUserAllPartCategory()
      if (result) {
        this.classes = result.data
        this.classes.unshift({
          id: 0,
          classifyName: '全部'
        })
        this.activeClasses = this.classes[0] ? this.classes[0] : {}
      }
    },
    // 获取用户可选择的配件类别列表
    async getMaterial() {
      const result = await getMaterialList()
      if (result) {
        this.material = result.data
      }
    },
    changePart(partName, first = true) {
      this.activeParts = partName
      this.activeMaterial = 'all'
      this.getPartData()
    },
    changeClasses(val) {
      this.activeClasses = val
      this.getPartData()
    },
    /** 获取系统库/我的库数据 */
    async getPartData() {
      const params = {
        textureId:
          this.activeMaterial === 'all' ? undefined : this.activeMaterial,
        classifyId:
          this.activeClasses.classifyName === '全部'
            ? undefined
            : this.activeClasses.id,
        pageNum: 1,
        pageSize: 12
      }
      const result =
        this.activeParts === 'system'
          ? await getSystemPartsPage(params)
          : await getUserPartsPage(params)
      if (result) {
        this.partData = result.rows
      }
    },
    // 打开配件具体选择页面
    openPartDetail(item) {
      this.partDialogVisiable = true
      this.clickPart = item
      this.activePart = item
      this.activePartImage =
        item.partsSystemItems?.length > 0
          ? item.partsSystemItems[0].itemImgUrl
          : item.partsUserItems[0].itemImgUrl
    },
    openImageDialog() {
      if (this.imgUrl && this.activePartImage) {
        this.dialogVisiable = true
        return
      }
      this.$message.error('请先选择素材')
    },
    changImage(index, type) {
      this.activePartIndex = index
      this.activePartImage =
        type === 'system'
          ? this.activePart.partsSystemItems[index].itemImgUrl
          : this.activePart.partsUserItems[index].itemImgUrl
    },
    filp() {
      this.$refs.coverImage.toggleFlip()
    },
    changeActivePart(val) {
      this.activePart = val.activePart
      this.activePartImage = val.activePartImage
    }
  },
  mounted() {
    if (!this.id) {
      this.$message.error('请从创作页面进入')
      this.$router.go(-1)
      return
    }
    this.getCategory()
    this.getPartData()
    this.getMaterial()
  }
}
</script>
  
  <style lang="scss" scoped>
::v-deep .el-loading-spinner {
  display: flex;
  justify-content: center;
}
.rotate {
  ::v-deep .el-input {
    .el-input__inner {
      background-color: #323232 !important;
      color: #ffffff !important;
      width: 50px !important;
      height: 30px !important;
      border: 0;
      margin: 0;
      padding: 0;
    }
  }
}
::v-deep .el-select .el-input__inner {
  width: 150px;
  background-color: #f4f5f7;
  height: 32px; /* 调整高度 */
}
.custom-rotate-input {
  ::v-deep .el-input__inner {
    background-color: #323232 !important;
    color: #ffffff !important;
    width: 50px !important;
    border: 0;
    height: 30px !important;
    margin: 0;
    padding: 0;
  }
}
.activeClass {
  position: relative;
  &::before {
    content: '';
    width: 10px;
    height: 2px;
    position: absolute;
    background: #54c752;
    bottom: 0px;
    right: 0;
  }
}
</style>