<template>
  <div class="flex items-center justify-between py-2 px-8 border-b">
    <div>
      <div class="flex items-center">
        <div @click="$router.push('/')">
          <img src="@/assets/shejilogo.svg" alt="" />
        </div>
        <div class="ml-10 flex min-w-80">
          <template v-if="showMenu">
            <div
              class="ml-6 cursor-pointer"
              v-for="(item, key) in headerItem"
              :key="key"
            >
              <router-link
                :to="item.link"
                class="text-lg"
                active-class="active_router"
                v-if="!item.disabled"
              >
                {{ item.title }}
              </router-link>
              <div
                class="text-lg"
                v-else
                @click="$message.warning('该板块暂未开放')"
              >
                {{ item.title }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-around">
      <img
        src="@/assets/register.svg"
        alt=""
        class="cursor-pointer"
        @click="buyInviteVisible = true"
      />
      <ElInput
        placeholder="请输入搜索关键词"
        prefix-icon="el-icon-search"
        style="width: 192px"
        class="ml-4"
      ></ElInput>
      <div
        class="w-28 p-1 rounded-[50px] border border-[#DBDBDB] flex items-center justify-around cursor-pointer ml-4"
      >
        <img src="@/assets/change.svg" />
        切换领域
      </div>
      <msgPopover ref="msgPopover"></msgPopover>
      <RouterLink to="/login" v-if="JSON.stringify(userInfo) === '{}'">
        <div
          class="w-28 p-1 rounded-[50px] border border-[#666666] flex items-center justify-around cursor-pointer ml-4"
        >
          登录/注册
        </div>
      </RouterLink>
      <div v-else class="flex items-center">
        <img
          :src="require(`@/assets/user/${userInfo.level}.svg`)"
          alt=""
          class="w-24 h-12 object-fill mr-6 ml-4"
          v-if="userInfo.isVip"
        />
        <el-popover placement="bottom-start" width="450" trigger="click">
          <div>
            <div class="flex items-center px-2">
              <div class="text-lg font-extrabold">{{ userInfo.nickName }}</div>
              <img
                :src="require(`@/assets/user/${userInfo.level}.svg`)"
                alt=""
                class="w-28 h-16 object-fill ml-6"
                v-if="userInfo.isVip"
              />
            </div>
            <!-- <div>
              <div>关注{{}}</div>
              <div>粉丝{{}}</div>
              <div>发布{{}}</div>
            </div> -->
            <div class="grid grid-cols-5 mt-4 gap-2">
              <div
                class="cursor-pointer flex flex-col justify-center items-center"
                @click="$router.push(`/my/home?id=${userInfo.userId}`)"
              >
                <img
                  :src="
                    require(`@/assets/user/${
                      [1, 2, 3].includes(userInfo.level) ? userInfo.level : 0
                    }/home.svg`)
                  "
                  alt=""
                />
                <span class="mt-1">我的主页</span>
              </div>
              <div
                class="cursor-pointer flex flex-col justify-center items-center"
                @click="$router.push('/my/memberCard')"
              >
                <img
                  :src="
                    require(`@/assets/user/${
                      [1, 2, 3].includes(userInfo.level) ? userInfo.level : 0
                    }/vip.svg`)
                  "
                  alt=""
                />
                <span class="mt-1">我的会员</span>
              </div>
              <div
                class="cursor-pointer flex flex-col justify-center items-center"
                @click="$router.push('/my/electron')"
              >
                <img
                  :src="
                    require(`@/assets/user/${
                      [1, 2, 3].includes(userInfo.level) ? userInfo.level : 0
                    }/electron.svg`)
                  "
                  alt=""
                />
                <span class="mt-1">电力券</span>
              </div>
              <div
                class="cursor-pointer flex flex-col justify-center items-center"
                @click="$router.push('/my/albumCenter')"
              >
                <img
                  :src="
                    require(`@/assets/user/${
                      [1, 2, 3].includes(userInfo.level) ? userInfo.level : 0
                    }/creator.svg`)
                  "
                  alt=""
                />
                <span class="mt-1">创作者中心</span>
              </div>
              <div
                class="cursor-pointer flex flex-col justify-center items-center"
                @click="$router.push('/my/likeAndCollect')"
              >
                <img
                  :src="
                    require(`@/assets/user/${
                      [1, 2, 3].includes(userInfo.level) ? userInfo.level : 0
                    }/good.svg`)
                  "
                  alt=""
                />
                <span class="mt-1">赞和收藏</span>
              </div>
              <div
                class="cursor-pointer flex flex-col justify-center items-center"
                @click="$router.push('/my/myService')"
              >
                <img
                  :src="
                    require(`@/assets/user/${
                      [1, 2, 3].includes(userInfo.level) ? userInfo.level : 0
                    }/service.svg`)
                  "
                  alt=""
                />
                <span class="mt-1">服务</span>
              </div>
              <div
                class="cursor-pointer flex flex-col justify-center items-center"
                @click="$router.push('/my/myOrder')"
              >
                <img
                  :src="
                    require(`@/assets/user/${
                      [1, 2, 3].includes(userInfo.level) ? userInfo.level : 0
                    }/order.svg`)
                  "
                  alt=""
                />
                <span class="mt-1">订单</span>
              </div>
              <div
                class="cursor-pointer flex flex-col justify-center items-center"
                @click="$router.push('/my/myIncome')"
              >
                <img
                  :src="
                    require(`@/assets/user/${
                      [1, 2, 3].includes(userInfo.level) ? userInfo.level : 0
                    }/income.svg`)
                  "
                  alt=""
                />
                <span class="mt-1">收益</span>
              </div>
            </div>
            <div class="grid grid-cols-4 mt-10 gap-2">
              <div
                v-if="userType == 1"
                class="cursor-pointer flex flex-col justify-center items-center"
                @click="$router.push('/my/memberList')"
              >
                <img src="@/assets/member/member_icon.svg" alt= />
                <span class="mt-1">成员管理</span>
              </div>
              <div
                v-else
                class="cursor-pointer flex flex-col justify-center items-center"
                @click="handleSwitch"
              >
                <div class="checkItem">
                  <div
                    class="checkItem-item"
                    :class="{ active: userType == 3 }"
                  >
                    个
                  </div>
                  <div
                    class="checkItem-item"
                    :class="{ active: userType == 2 }"
                  >
                    企
                  </div>
                </div>
                <span class="mt-1">账号切换</span>
              </div>
              <div
                class="cursor-pointer flex flex-col justify-center items-center"
              >
                <div class="checkItem">
                  <div class="checkItem-item" :class="{ actives: true }">
                    中
                  </div>
                  <div class="checkItem-item" :class="{ actives: false }">
                    英
                  </div>
                </div>
                <span class="mt-1">语言切换</span>
              </div>
              <div
                class="cursor-pointer flex flex-col justify-center items-center"
              >
                <img src="@/assets/member/invite_icon.svg" alt= />
                <span class="mt-1">邀请好友</span>
              </div>
              <div
                class="cursor-pointer flex flex-col justify-center items-center"
              >
                <img src="@/assets/member/cooperate_icon.svg" alt= />
                <span class="mt-1">商业合作</span>
              </div>
            </div>
            <el-divider></el-divider>
            <div class="flex p-2 pt-0 cursor-pointer" @click="logout">
              <img src="@/assets/logout.svg" alt="" />
              <span class="ml-2">退出登录</span>
            </div>
          </div>
          <img
            :src="userInfo.avatar"
            alt=""
            class="w-10 h-10 rounded-full cursor-pointer"
            slot="reference"
          />
        </el-popover>
      </div>
    </div>
    <buyInvite v-model="buyInviteVisible" defaultKey="invite"></buyInvite>
  </div>
</template>

<script>
// import { getUserInfo } from '@/utils/user'
import { getUserInfo } from "@/api/user";
import { changeTeamType, changeUserOwnType } from "@/api/user/member.js";
import msgPopover from "./msgPopover.vue";
import buyInvite from "@/views/vip/components/buy.vue";

export default {
  name: "shejiHeader",
  components: { msgPopover, buyInvite },
  props: {
    showMenu: {
      type: Boolean,
      default: true
    }
  },
  data: () => {
    return {
      buyInviteVisible: false,
      headerItem: [
        {
          title: "首页",
          link: "/home"
        },
        {
          title: "AI创作",
          link: "/workbench"
        },
        {
          title: "关注",
          link: "/concern"
        },
        {
          title: "发现",
          link: "/discover"
        },
        {
          title: "资讯",
          link: "/information"
        },
        {
          title: "服务商",
          link: "/service"
        },
        {
          title: "我的图库",
          link: "/mylibrary"
        }
      ],

      userType: null,
      userInfo: {}
    };
  },
  methods: {
    logout() {
      this.$confirm("确定退出登录吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        localStorage.removeItem("authorization");
        localStorage.removeItem("userInfo");
        localStorage.removeItem("userType");
        this.$router.push("/login");
      });
    },

    async handleGetInfo() {
      console.log("更新数据");
      let userType = null;
      const userInfo = await getUserInfo();
      localStorage.setItem("userInfo", JSON.stringify(userInfo.user));

      if (userInfo.user.isVip == 1 && userInfo.userType == 1) {
        userType = 1; // 企业主号/个人号
      } else if (userInfo.userType == 2) {
        userType = 2; // 企业子号
      } else {
        userType = 3; // 纯个人号
      }
      localStorage.setItem("userType", userType);

      this.userType = userType;
      this.userInfo = userInfo.user;
      this.$root.$emit("UPDATE_USERINFO_RESULT"); /* 更新用户信息接口回调 */
    },

    handleSwitch() {
      let switchApi = this.userType == 2 ? changeUserOwnType : changeTeamType;
      switchApi()
        .then((res) => {
          this.$message.success(res.msg);
          this.handleGetInfo();
        })
        .catch((e) => {
        });
    }
  },
  mounted() {
    const token = localStorage.getItem("authorization");
    if (token) {
      this.handleGetInfo();
      this.$root.$on("UPDATE_USERINFO", this.handleGetInfo); /* 更新用户信息接口 */
      if (this.userInfo && this.userInfo.userId) {
        this.$nextTick(() => {
          this.$refs.msgPopover.handleInit();
        });
      }
    }
    // if (localStorage.getItem('authorization')) {
    //   this.userInfo = getUserInfo()
    //   this.userType = localStorage.getItem('userType')
    // }
  },
  beforeDestroy() {
    this.$root.$off("UPDATE_USERINFO");
  }
};
</script>

<style lang="scss" scoped>
.active_router {
  @apply font-black relative;
  &::after {
    @apply block absolute right-0 h-1;
    content: '';
    width: 14px;
    border-radius: 2.5px;
    background: #54c752;
  }
}

.checkItem {
  width: 66.34px;
  height: 24.44px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: #f7f7f7;
  font-size: 16px;
  font-weight: bold;
  color: #5e5e5e;

  &-item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .active {
    border-radius: 5px;
    color: #ffffff;
    background: #0033ff;
  }

  .actives {
    border-radius: 5px;
    color: #ffffff;
    background: #5e5e5e;
  }
}

::v-deep .el-input__inner {
  @apply rounded-3xl;
}
</style>
