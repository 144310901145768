<template>
  <div class="p-4" v-loading="loading">
    <div class="flex justify-between items-center">
      <div class="text-3xl">购买电力券</div>
      <div
        class="w-20 h-8 leading-7 border border-black rounded-2xl cursor-pointer"
        @click="showList = true"
      >
        记录
      </div>
    </div>
    <div class="w-full mt-4 h-[550px] rounded-2xl bg-black p-4">
      <div
        class="bg-[rgba(77,77,77,.5)] w-full h-[300px] p-4 grid grid-cols-2 gap-3"
      >
        <div
          class="w-full h-[120px] rounded-xl bg-[#323232] cursor-pointer text-white p-4"
          :class="activeKey === item.id && 'border border-[#FF7744] !bg-black'"
          v-for="item in electronInfo"
          :key="item.id"
          @click="changeElectron(item)"
        >
          <div class="flex items-center">
            <img src="@/assets/electron.svg" class="mr-2" alt="" />
            <div class="text-xl">{{ item.title }}</div>
          </div>
          <div class="mt-2 text-left">售价 ￥{{ item.money }}元</div>
          <div class="mt-2 text-left text-sm">{{ item.remark }}</div>
        </div>
      </div>
      <div class="mt-2 text-white h-[200px] flex justify-around items-center">
        <div>
          <div
            id="electronQrCode"
            ref="electronQrCode"
            class="w-[160px] h-[160px]"
          ></div>
        </div>
        <div>
          <div class="text-left">
            应付：
            <span class="text-[#ff7744] text-3xl"
              >￥{{
                electronInfo.find((item) => item.id === activeKey)?.money || 0
              }}</span
            >
            <span class="text-[#ff7744] text-sm ml-2">元</span>
          </div>
          <div class="mt-4 flex justify-between items-center">
            <div class="text-sm">使用微信/支付宝扫码支付</div>
            <div
              class="text-[#9b9b9b] border border-[#9b9b9b] text-sm ml-4 px-1 cursor-pointer"
            >
              发票开具入口
            </div>
          </div>
          <div class="my-4 text-left text-sm cursor-pointer">刷新二维码</div>
          <div class="flex justify-start items-center">
            <el-checkbox v-model="agree"
              >购买即视为同意《积分充值协议》</el-checkbox
            >
          </div>
        </div>
      </div>
    </div>
    <ElectronList v-model="showList" />
  </div>
</template>

<script>
import {
  createCouponOrderByQR,
  getPowerCoupon,
  getPowerCouponDetails
} from '@/api/vip'
import ElectronList from './electronList.vue'
import QRCode from 'qrcodejs2'
export default {
  name: 'buyElectron',
  data() {
    return {
      electronInfo: [],
      activeKey: '',
      agree: false,
      showList: false,
      qrcode: '',
      loading: false,
      interval: null
    }
  },
  components: {
    ElectronList
  },
  methods: {
    async get() {
      this.loading = true
      const result = await getPowerCoupon()
      if (result) {
        this.electronInfo = result.data
        this.changeElectron(result.data[0])
      }
      this.loading = false
    },
    async changeElectron(item) {
      this.loading = true
      this.activeKey = item.id
      const result = await createCouponOrderByQR({
        couponId: item.id,
        isTest: process.env.NODE_ENV === 'development'
      })
      if (result) {
        this.qrcode = result.data.content
        this.generatorQrCode()
        clearInterval(this.interval)
        this.interval = setInterval(() => {
          this.queryPayInfo(result.data.orderId)
        }, 1500)
      }
      this.loading = false
    },
    generatorQrCode() {
      if (this.$refs.electronQrCode) {
        this.$refs.electronQrCode.innerHTML = ''
      }
      new QRCode('electronQrCode', {
        text: this.qrcode,
        width: 160,
        height: 160,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    // 轮询查询支付状态
    async queryPayInfo(id) {
      const result = await getPowerCouponDetails({ id })
      if (result.data.payStatus === 1) {
        clearInterval(this.interval)
        this.$message.success('支付成功')
        this.$emit('pay')
      }
    }
  },
  mounted() {
    this.get()
  },
  destroyed() {
    clearInterval(this.interval)
  }
}
</script>

<style>
</style>