<template>
  <comfirmDialog
    v-model="visible"
    width="520px"
    height="639px"
    cancelTxt="放弃"
    :title="' '"
    :showClose="false"
    @confirm="handleEdit"
  >
    <div class="memberInfoEdit">
      <div class="item">
        <img
          v-if="memberInfo.userAvatar"
          class="avatar"
          :src="memberInfo.userAvatar"
        />
        <div v-else class="avatar"></div>
        <div class="nickName">{{ memberInfo.nickName }}</div>
      </div>
      <div class="item">
        <div class="name">名 称：</div>
        <el-input
          v-model="memberInfo.teamUserName"
          :placeholder="''"
        ></el-input>
      </div>
      <div class="item">
        <div class="name">职 位：</div>
        <el-input
          v-model="memberInfo.teamPosition"
          :placeholder="''"
        ></el-input>
      </div>
      <div class="item">
        <div class="name">有效时间：</div>
        <el-date-picker
          v-model="memberInfo.teamEndTime"
          type="date"
          style="width: 100%"
          :placeholder="''"
          :picker-options="limitDateOptions"
        >
        </el-date-picker>
      </div>
      <div class="item" style="padding-left: 46px; margin-top: 39px">
        <el-checkbox v-model="memberInfo.teamCoupon"
          >授权使用我的电力券账户</el-checkbox
        >
      </div>
      <div class="item" style="padding-left: 46px">
        <el-checkbox v-model="memberInfo.teamCreation"
          >授权使用作品发布功能</el-checkbox
        >
      </div>
    </div>
  </comfirmDialog>
</template>

<script>
import { getUserInfo } from '@/utils/user'
import { editTeamUser } from '@/api/user/member.js'
import comfirmDialog from '@/components/comfirmDialog/index.vue'
export default {
  name: 'memberInfoEdit',
  components: { comfirmDialog },
  data() {
    return {
      visible: false,
      userInfo: {},
      memberInfo: {}
    }
  },
  created() {
    if (localStorage.getItem('authorization')) {
      this.userInfo = getUserInfo()
    }
  },
  computed: {
    limitDateOptions() {
      return {
        disabledDate: (time) => {
          return time.getTime() > new Date(this.userInfo.vipEndTime)
        }
      }
    }
  },
  methods: {
    open(data) {
      this.visible = true
      data = JSON.parse(JSON.stringify(data))
      this.memberInfo = data
      data.teamCoupon = data.teamCoupon == 1 ? true : false
      data.teamCreation = data.teamCreation == 1 ? true : false
    },
    close() {
      this.visible = false
      this.memberInfo = {}
    },
    handleEdit() {
      let data = JSON.parse(JSON.stringify(this.memberInfo))
      data.teamCoupon = data.teamCoupon ? 1 : 0
      data.teamCreation = data.teamCreation ? 1 : 0
      editTeamUser(data).then((res) => {
        this.$message.success(res.msg)
        this.close()
        this.$emit('fresh')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.memberInfoEdit {
  width: 100%;
  height: 100%;
  padding: 0 78px;
  padding-top: 58px;
  box-sizing: border-box;
  .item {
    display: flex;
    align-items: center;
    margin-bottom: 31px;
  }
  .avatar {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    margin-right: 18px;
    flex-shrink: 0;
    background: #d8d8d8;
  }
  .nickName {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
  }
  .name {
    font-size: 16px;
    color: #000000;
    flex-shrink: 0;
  }
  ::v-deep .el-input__inner {
    border-bottom: 1px solid #d8d8d8;
  }
  ::v-deep .el-input--suffix .el-input__inner {
    height: 39px;
    border-radius: 7px;
    border: 1px solid #d8d8d8;
  }
  ::v-deep .el-checkbox__label {
    font-size: 16px;
    color: #0033ff;
  }
}
</style>