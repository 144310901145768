<template>
  <el-drawer
    :visible.sync="value"
    size="600px"
    :before-close="handleClose"
    title="颜色选择"
  >
    <div class="flex justify-center p-4">
      <!-- 左侧颜色调色板 -->
      <div class="flex flex-wrap w-[250px] h-[400px]">
        <div
          v-for="item in usefulColor"
          :key="item"
          class="w-8 h-8 rounded mr-2 mb-2 cursor-pointer"
          :style="{ background: item }"
          @click="setColor(item)"
          :class="{ 'border-2 border-green-500': color === item }"
        ></div>
      </div>
      <!-- 右侧颜色预览与选择区域 -->
      <div class="flex flex-col items-center ml-4 w-[250px]">
        <!-- 颜色预览框 -->
        <input type="color" v-model="color" @change="setColor(color)" />
        <!-- Hex与RGBA输入框 -->
        <div class="mt-2 w-full border !bg-[#E4E4E4]">
          <input
            v-model="hexColor"
            class="w-1/2 p-1"
            placeholder="#HEX"
            disabled
          />
          <input
            v-model="alpha"
            class="w-1/2 p-1"
            type="number"
            placeholder="A(%)"
            min="0"
            max="100"
            disabled
          />
        </div>
        <div class="mt-2 w-full border bg-[#E4E4E4]">
          <input
            v-model="r"
            disabled
            class="w-1/4 p-1"
            type="number"
            placeholder="R"
          />
          <input
            v-model="g"
            disabled
            class="w-1/4 p-1"
            type="number"
            placeholder="G"
          />
          <input
            v-model="b"
            disabled
            class="w-1/4 p-1"
            type="number"
            placeholder="B"
          />
        </div>
      </div>
    </div>
    <!-- 底部确认按钮 -->
    <div class="flex justify-end mt-4 px-6">
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" @click="updateColor">确认</el-button>
    </div>
  </el-drawer>
</template>
  
  <script>
export default {
  props: ['modelValue'],
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  data() {
    return {
      color: '#D31F1F', // 默认颜色
      hexColor: '#D31F1F',
      alpha: 100,
      r: 211,
      g: 31,
      b: 31,
      rangeValue: 0,
      usefulColor: [
        '#FFB6C1',
        '#FFC0CB',
        '#DC143C',
        '#FFF0F5',
        '#DB7093',
        '#FF69B4',
        '#FF1493',
        '#C71585',
        '#DA70D6',
        '#D8BFD8',
        '#DDA0DD',
        '#EE82EE',
        '#FF00FF',
        '#8B008B',
        '#800080',
        '#BA55D3',
        '#9400D3',
        '#9932CC',
        '#4B0082',
        '#8A2BE2',
        '#9370DB',
        '#7B68EE',
        '#6A5ACD',
        '#483D8B',

        '#E6E6FA',
        '#F8F8FF',
        '#0000FF',
        '#0000CD',
        '#191970',
        '#000080',
        '#4169E1',
        '#6495ED',
        '#B0C4DE',
        '#778899',
        '#708090',
        '#1E90FF',
        '#F0F8FF',
        '#4682B4',
        '#87CEFA',
        '#87CEEB',
        '#00BFFF',
        '#ADD8E6',
        '#B0E0E6',
        '#5F9EA0',
        '#F0FFFF',
        '#E1FFFF',
        '#AFEEEE',
        '#00FFFF',
        '#00CED1',
        '#2F4F4F',
        '#008B8B',
        '#008080'
      ]
    }
  },
  methods: {
    handleClose() {
      this.value = false
    },
    setColor(color) {
      console.log(color)
      this.color = color
      this.hexColor = color
      // 转换Hex颜色为RGB
      this.updateRGBFromHex(color)
    },
    updateColor() {
      this.$emit('update', this.color)
      this.handleClose()
    },
    adjustColor() {
      // 根据range的滑动调整颜色，可以加入色相逻辑
    },
    setHexColor() {
      // 将用户输入的Hex颜色转换为RGB和Alpha
      this.updateRGBFromHex(this.hexColor)
    },
    updateRGBFromHex(hex) {
      // 转换Hex到RGB的逻辑
      const bigint = parseInt(hex.slice(1), 16)
      this.r = (bigint >> 16) & 255
      this.g = (bigint >> 8) & 255
      this.b = bigint & 255
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  }
}
</script>
  
  <style scoped>
input {
  text-align: center;
  background: #e4e4e4;
}
input[type='color'] {
  width: 250px;
  height: 250px;
  border: none;
  background: none;
}
</style>