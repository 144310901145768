<template>
  <div>
    <!-- tab -->
    <div class="flex items-center justify-center relative mt-[18px]">
      <div class="flex justify-center">
        <div
          class="ml-6 cursor-pointer text-[#3D3D3D]"
          v-for="(item, index) in tabList"
          :key="index"
          :class="{ active_index: index === tabIndex }"
          @click="handleTab(index)"
        >
          <div class="text-base" :class="{ sort_custom: item.sortType }">
            {{ item.title }}
          </div>
        </div>
      </div>
      <div v-if="tabIndex == 0" class="flex justify-center absolute right-0">
        <div
          class="ml-6 cursor-pointer text-[#3D3D3D]"
          v-for="(item, index) in sortList"
          :key="index"
          :class="{ active_index: index === sortIndex }"
          @click="sortIndex = index"
        >
          <div class="text-base" :class="{ sort_custom: item.sortType }">
            {{ item.name }}
            <template v-if="item.sortType">
              <div class="triangle-top"></div>
              <div class="triangle-bottom"></div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- list -->
    <div
      v-if="articleList.length && [0, 1].includes(tabIndex)"
      class="grid grid-cols-6 mt-[29px]"
    >
      <div
        class="mt-2 rounded-xl cursor-pointer group"
        v-for="(item, index) in articleList"
        :key="index"
        @click="
          tabIndex == 0
            ? $router.push(`/service/detail/${item.id}`)
            : $router.push(`/works/collection/${item.id}`)
        "
      >
        <PreviewCard
          v-if="tabIndex == 0"
          :isMask="false"
          :isLike="false"
          :sourceId="item.id"
          :cover="item.coverImgUrl"
          :title="item.serverTitle"
          :price="item.serverPrice || 0"
          :service="item.serverCount || 0"
          :tongkuanImgId="item.coverId"
          @showErrMsg="dialogVisible = true"
        />
        <PreviewCard
          v-else
          :isMask="false"
          :isInfo="false"
          :isLike="false"
          :tuceNum="item.collectionCount || 0"
          :title="item.title"
          :sourceId="item.id"
          :cover="item.coverImgUrl"
        />
      </div>
    </div>
    <!-- nodata -->
    <noData v-else-if="!articleList.length"></noData>
    <!-- about -->
    <div class="w-full text-left pb-[100px] relative" v-if="tabIndex == 2">
      <div class="text-lg font-bold mt-[36px]">创作领域</div>
      <div class="flex">
        <div class="about_span">潮鞋设计</div>
        <div class="about_span">品牌文化创意</div>
        <div class="about_span">厨窗陈列</div>
        <div class="about_span">插画设计</div>
      </div>
      <div class="text-lg font-bold mt-[36px]">行家简介</div>
      <div class="about_desc">
        提供专业的鞋类设计服务作品可提供文件格式为PSD/AI/CDR/PDF提供专业的鞋类设计服务作品可提供文件格式为PSD/AI/CDR/PDF提供专业的鞋类设计服务作品可提供文件格式为PSD/AI/CDR/PDF
      </div>
      <div class="text-lg font-bold mt-[36px]">位置</div>
      <div class="flex items-center mt-[16px]">
        <img src="@/assets/position.svg" />
        <div class="about_desc" style="margin-top: 0; margin-left: 7px">
          中国.福建省.泉州市
        </div>
      </div>
      <div class="text-lg font-bold mt-[36px]">关联成员</div>
      <div class="flex items-center mt-[16px]">
        <img
          v-for="(item, index) in 3"
          :key="index"
          class="w-[35px] h-[35px] rounded-full mr-[12px]"
          src="https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg"
        />
      </div>
      <div class="about_tips">自2024年6月8日注册服务商</div>
    </div>
    <!-- dialog -->
    <comfirmDialog
      v-model="dialogVisible"
      confirmTxt="立即购买"
      @confirm="handleBuy"
    >
      <div>您当前“电力券”数量不足，</div>
      <div>无法提交此次生成任务。</div>
    </comfirmDialog>
  </div>
</template>

<script>
import PreviewCard from '@/components/previewCard'
import noData from '@/components/noData/index.vue'
import comfirmDialog from '@/components/comfirmDialog/index.vue'
import {
  getProviderServerList,
  creationArticleFindMiniList
} from '@/api/user/home.js'
export default {
  name: 'homeTabService',
  components: { PreviewCard, noData, comfirmDialog },
  props: ['userId'],
  data() {
    return {
      tabIndex: 0,
      sortIndex: 0,
      articleList: [],
      dialogVisible: false,
      tabList: [{ title: '服务项目' }, { title: '图册' }, { title: '关于' }],
      sortList: [{ name: '最新' }, { name: '热门', sortType: 1 }]
    }
  },
  methods: {
    handleTab(index) {
      this.tabIndex = index
      this.handleInit()
    },
    handleInit() {
      let query = {}
      query.page = 1
      query.limit = -1
      query.userId = this.userId
      if (this.tabIndex == 1) {
        query.articleType = 2
      }
      let myApi =
        this.tabIndex == 0
          ? getProviderServerList
          : this.tabIndex == 1
          ? creationArticleFindMiniList
          : null
      if (!myApi) return
      myApi(query).then(async (res) => {
        let list = (res && res.rows) || []
        this.articleList = list
        console.log(`**服务项目${this.tabIndex}**`, this.articleList)
      })
    },

    /* 立即购买 */
    handleBuy() {
      this.$router.push('/my/electron')
    }
  }
}
</script>

<style lang="scss" scoped>
.sort_custom {
  @apply relative;
  padding-right: 12px;
  .triangle-top,
  .triangle-bottom {
    @apply absolute right-0;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }
  .triangle-top {
    @apply top-1;
    border-bottom: 6px solid #a09e9f;
  }
  .triangle-bottom {
    @apply bottom-1;
    border-top: 6px solid #a09e9f;
  }
}
.active_index {
  @apply font-black;
  color: #000;
}
.about_span {
  padding: 1px 12px;
  box-sizing: border-box;
  background: #f4f5f7;
  border-radius: 14px;
  font-size: 16px;
  color: #000000;
  margin: 13px 0;
  margin-right: 17px;
}
.about_desc {
  font-size: 16px;
  color: #666666;
  margin-top: 13px;
}
.about_tips {
  left: 50%;
  bottom: 53px;
  position: absolute;
  transform: translateX(-50%);
  font-size: 16px;
  color: #c1c1c1;
}
</style>