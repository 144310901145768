<template>
  <div>
    <el-row :gutter="16" class="h-[380px]">
      <el-col :span="14" :xl="14" class="h-[380px]">
        <el-carousel indicator-position="inside">
          <el-carousel-item v-for="item in imgsList" :key="item.id">
            <img
              :src="item.slideshowImg"
              alt=""
              class="w-full h-[380px] select-none rounded-2xl"
            />
          </el-carousel-item>
        </el-carousel>
      </el-col>
      <el-col :span="6" :xl="6" class="flex flex-col justify-around h-[380px]">
        <img
          src="@/assets/home/WechatIMG298.png"
          class="cursor-pointer w-full h-[140px] object-fill"
        />
        <img
          src="@/assets/home/WechatIMG826.png"
          class="cursor-pointer w-full h-[140px] object-fill"
        />
        <img
          src="@/assets/home/3.svg"
          class="cursor-pointer w-full h-[140px] object-fill"
        />
      </el-col>
      <el-col :span="4" :xl="4">
        <userCard></userCard>
      </el-col>
    </el-row>

    <el-row :gutter="16" class="mt-4">
      <el-col :span="4" v-for="item in 6" :key="item">
        <img
          :src="
            [4, 5].includes(item)
              ? require(`@/assets/home/list-${item}.svg`)
              : require(`@/assets/home/list-${item}.jpg`)
          "
          alt=""
          class="w-full h-[194px] rounded-xl cursor-pointer"
          @click="$message.warning('比赛中...暂未开放...')"
        />
      </el-col>
    </el-row>

    <sortcontent></sortcontent>
  </div>
</template>

<script>
import shejiFooter from '@/components/shejiFooter'
import sortcontent from './component/sortcontent.vue'
import userCard from './component/userCard.vue'
import { getSlideShowList } from '@/api/common'
import { userInvite } from '@/api/user/index.js'
import { getImformationList } from '@/api/information'

export default {
  data() {
    return {
      imgsList: [],
      informationList: []
    }
  },
  components: {
    shejiFooter,
    sortcontent,
    userCard
  },
  methods: {
    // 获取轮播图
    async getImgs() {
      const result = await getSlideShowList(2)
      this.imgsList = result?.rows || []
    },
    // 获取资讯
    async getList() {
      const result = await getImformationList({
        page: 1,
        limit: -1
      })
      this.informationList = result.rows
    },
    // 绑定邀请人
    async handleInvite(key) {
      let result = await userInvite(key).catch((e) => {})
      if (result && result.code === 200) {
        this.$message.success('邀请成功')
      }
      this.$router.push('/')
    }
  },
  mounted() {
    this.getImgs()
    this.getList()
    if (this.$route.query.inviteCode) {
      this.handleInvite(this.$route.query.inviteCode)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-carousel__container {
  @apply h-[380px] xl:h-[480px];
}
</style>
