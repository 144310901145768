import service from '@/views/service/index.vue';
import serviceDetail from '@/views/service/detail.vue';
export default [{
    path: "/service",
    name: "service",
    component: service,
    meta: {
      title: '设辑-服务商'
    }
  },
  {
    path: "/service/detail/:id",
    name: "serviceDetail",
    component: serviceDetail,
    meta: {
      title: '服务详情'
    }
  },
//   {
//     path: "/information/details/:id",
//     name: "informationDetails",
//     component: informationDetails,
//     meta: {
//         useFooter: false,
//         title: '资讯详情'
//     }
//   }
]