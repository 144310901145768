<template>
  <div class="preview-card">
    <div class="relative">
      <img class="cover" :src="cover" />
      <!-- 遮罩 -->
      <template v-if="isMask">
        <div
          class="w-8 h-8 z-[999] bg-white rounded-full absolute top-4 right-4 hidden group-hover:flex cursor-pointer justify-center items-center"
          @click.stop="atten"
        >
          <img src="@/assets/like.svg" class="w-5 h-5" v-if="!isAtten" />
          <img src="@/assets/likeFull.svg" class="w-5 h-5" v-else />
        </div>
        <div
          @click.stop="handleSame"
          class="absolute z-[999] bottom-4 w-60 rounded-[100px] h-10 leading-10 left-1/2 -ml-[120px] bg-[#54c752] text-[#fff] transition-all hidden group-hover:block"
        >
          一键同款
        </div>
        <!-- 添加遮罩层 -->
        <div
          class="absolute inset-0 bg-black opacity-0 rounded-xl transition-opacity duration-300 group-hover:opacity-35"
        ></div>
      </template>

      <!-- 无遮罩有喜欢 -->
      <template v-if="!isMask && isLike">
        <div
          class="w-8 h-8 z-[999] bg-white rounded-full absolute top-4 right-4 flex cursor-pointer justify-center items-center"
          @click.stop="atten"
        >
          <img src="@/assets/like.svg" class="w-5 h-5" v-if="!isAtten" />
          <img src="@/assets/likeFull.svg" class="w-5 h-5" v-else />
        </div>
      </template>

      <!-- !!!todo: 置顶 -->
      <!-- <div
        class="absolute left-[17px] top-[14px] w-[47px] h-[24px] rounded-md bg-black flex justify-center items-center text-white text-base"
      >
        置顶
      </div> -->

      <!-- 图册 -->
      <div v-if="tuceNum || tuceNum === 0" class="tuce">
        <img src="@/assets/tuce.svg" />
        <div class="ml-[4px]">{{ tuceNum }}</div>
      </div>
    </div>
    <div class="title">{{ title }}</div>

    <!-- !!!todo: 合集 -->
    <!-- <div
      class="w-[48px] h-[24px] border border-[#54C752] flex justify-center items-center text-[#54C752] text-sm rounded-[3px] mt-[6px]"
    >
      合集
    </div> -->

    <!-- 会员享折扣 -->
    <div v-if="isVipCount" class="vipCount">会员享折扣</div>

    <!-- 服务详情 -->
    <div class="info" v-if="isInfo">
      <div class="price">￥{{ price }}元</div>
      <div class="service" v-if="service">服务{{ serviceNum || 0 }}次</div>
    </div>
  </div>
</template>

<script>
import { articleAttention, noArticleAttention } from '@/api/home'
import { createSameAiJob } from '@/api/album/index.js'
export default {
  name: 'PreviewCard',
  props: {
    // 是否添加遮罩
    isMask: {
      type: Boolean,
      default: true
    },
    // 是否展示信息
    isInfo: {
      type: Boolean,
      default: true
    },
    // 是否可以点赞
    isLike: {
      type: Boolean,
      default: true
    },
    // 是否显示会员享折扣
    isVipCount: {
      type: Boolean,
      default: false
    },
    // 图册
    tuceNum: {
      type: [Number, Boolean],
      default: false
    },
    sourceId: {
      type: String,
      default: ''
    },
    cover: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    price: {
      type: Number,
      default: 0
    },
    service: {
      type: [Boolean, Number],
      default: false
    },
    serviceNum: {
      type: Number,
      default: 0
    },
    isAtten: {
      type: Boolean,
      default: false
    },
    tongkuanImgId: {
      default: null
    }
  },
  methods: {
    // 关注/取消关注作品
    async atten() {
      let myApi = this.isAtten ? noArticleAttention : articleAttention
      myApi(this.sourceId).then((res) => {
        if (res && res.code == 200) {
          this.$message.success(res.msg)
          this.$emit('upateIsAtten', !this.isAtten)
        }
      })
    },

    /* 一键同款 */
    handleSame() {
      if (!this.tongkuanImgId) return
      createSameAiJob(this.tongkuanImgId).then((res) => {
        if (res && res.code == 200) {
          const taskId = res.data
          this.$router.push({
            path: '/workbench/textgeneration',
            query: { id: taskId }
          })
        } else if (res) {
          if (res.msg == '当前电力券不足，请先充值') {
            this.$emit('showErrMsg')
            return false
          }
          this.$message.error('生成失败!')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.preview-card {
  width: 282px;
  position: relative;
  .cover {
    width: 282px;
    height: 282px;
    border-radius: 14px;
  }
  .tuce {
    width: 48px;
    height: 25px;
    border-radius: 14px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 12px;
    bottom: 12px;
    font-size: 10px;
    color: #ffffff;
  }

  .title {
    font-size: 16px;
    line-height: 1;
    color: #000;
    margin-top: 16px;
    text-align: left;
  }
  .info {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 18px;
    padding-bottom: 24px;
    padding-right: 48px;
    .price {
      font-size: 18px;
      line-height: 1;
      color: #e35141;
    }
    .service {
      font-size: 14px;
      line-height: 1;
      color: #565455;
    }
  }
  .title,
  .info {
    padding-left: 6px;
  }

  .vipCount {
    width: 118px;
    height: 30px;
    border-radius: 12.5px;
    background: #d0974e;
    position: absolute;
    left: 13px;
    top: 13px;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>