import service from "@/utils/require"

/** 获取作品详情 */
export const getWorksDetail = (id) => {
    return service.get(`/creationArticle/${id}`)
}

/** 获取评论列表 */
export const getarticleCommentList = (id) => {
    return service.get("/articleComment/articleCommentList", {
        params: {
            articleId: id,
            page: 1,
            limit: 999
        }
    })
}

/** 评论作品 */
export const commentArticle = (params) => {
    return service.post("/articleComment/commentArticle", params)
}
/** 给作品点赞 */
export const likeArticle = (id) => {
    return service.post(`/userArticleAttention/like/${id}`)
}

/** 给作品取消点赞 */
export const notLikeArticle = (id) => {
    return service.post(`/userArticleAttention/notLike/${id}`)
}

/** 给评论点赞 */
export const likeComment = (id) => {
    return service.post(`/articleComment/commentLike/${id}`)
}

/** 给评论取消点赞 */
export const notLikeComment = (id) => {
    return service.post(`/articleComment/commentNotLike/${id}`)
}
/** 删除评论 */
export const delComment = (commentId) => {
    return service.delete(`/articleComment/delComment/${commentId}`)
}

/** 关注作者 */
export const followAuthor = (id) => {
    return service.post(`/userAttention/${id}`)
}

/** 取消关注作者 */
export const cancelFollowAuthor = (id) => {
    return service.delete(`/userAttention/${id}`)
}

/** 获取用户默认描述 */
export const getUserDescribe = () => {
    return service.get("/userDescribeClassify/userShowClassify")
}

/** 获取用户全部描述 */
export const getUserAllDescribe = () => {
    return service.get("/userDescribeClassify/userAllClassify")
}

/** 添加用户描述 */
export const addUserDescribe = (params) => {
    return service.post("/userDescribeClassify/addUserClassify", params)
}

/** 删除用户描述 */
export const delUserDescribe = (id) => {
    return service.delete(`/userDescribeClassify/delUserClassify/${id}`)
}

/** 编辑用户描述 */
export const editUserDescribe = (params) => {
    return service.put("/userDescribeClassify/editUserClassify", params)
}

/** 设置置顶 */
export const setTop = (id, top) => {
    return service.post(`/userDescribeClassify/classifyTop/${id}/${top}`)
}


/** 设置固定显示 */
export const setShow = (id, show) => {
    return service.post(`/userDescribeClassify/classifyShow/${id}/${show}`)
}

/** 获取用户词库列表 */
export const getUserAutoLexiconList = (params) => {
    return service.get("/userAutoLexicon/getUserAutoLexiconList", {
        params
    })
}

/** 添加指令 */
export const addLexicon = (params) => {
    return service.post("/userAutoLexicon/addLexicon", params)
}

/** 编辑指令 */
export const editLexicon = (params) => {
    return service.put("/userAutoLexicon/editLexicon", params)
}

/** 删除指令 */
export const deleteLexicon = (id) => {
    return service.delete(`/userAutoLexicon/deleteLexicon/${id}`)
}

/** 添加分类 */
export const addClassify = (params) => {
    return service.post("/userAutoLexicon/addClassify", params)
}

/** 编辑分类 */
export const editClassify = (params) => {
    return service.put("/userAutoLexicon/editClassify", params)
}

/** 删除分类 */
export const deleteClassify = (id) => {
    return service.delete(`/userAutoLexicon/deleteClassify/${id}`)
}