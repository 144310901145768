<template>
  <div>
    <div class="myServiceList">
      <div
        class="myServiceList-item"
        v-for="(item, index) in serviceList"
        :key="index"
      >
        <img v-if="item.serverImgUrl" :src="item.serverImgUrl" class="cover" />
        <div v-else class="cover covernull"></div>
        <div v-if="item.orderStatus == 3" class="point"></div>
        <div class="title">{{ item.serverItemTitle || '-' }}</div>
        <div class="_flex name">
          服务价格：
          <div class="price">¥ {{ item.totalPrice || 0 }}元</div>
        </div>
        <div class="name">服务数量：{{ item.payCount || 0 }} 款</div>
        <div class="name">开始时间：{{ item.createTime }}</div>
        <div class="_flex name">
          服务状态：
          <div v-if="item.orderStatus == 1" class="price">待支付</div>
          <div v-if="item.orderStatus == 2" class="tips">
            进行中 （{{ item.residueServerFinishDay || 0 }}天）
          </div>
          <div v-if="item.orderStatus == 3" class="tips">
            待确认完成 （{{ item.residueConfirmDay || 0 }}天）
          </div>
          <div v-if="item.orderStatus == 4" class="tips">待评价</div>
          <div v-if="item.orderStatus == 5" class="success">已完成</div>
          <!-- <div class="success">已退款</div> -->
          <!-- <div>已取消</div> -->
        </div>
        <div class="item">
          <div class="_flex">
            <img
              class="avatar"
              :src="item.providerUser && item.providerUser.avatar"
            />
            <div class="name" style="margin-top: 0">
              {{ item.providerUser && item.providerUser.nickName }}
            </div>
          </div>
          <el-dropdown @command="handleCommand($event, item)">
            <img src="@/assets/collection/arrow-point.svg" />
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="info">服务详情</el-dropdown-item>
              <el-dropdown-item command="delete">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <comfirmDialog
      v-model="delVisible"
      :cancelTxt="'放弃'"
      :content="`确认删除“${serviceStatus[Number(delItem.orderStatus)]}”订单`"
      @confirm="handleDelete"
    ></comfirmDialog>
  </div>
</template>

<script>
/**
 * orderStatus: 订单状态（1：待支付；2:进行中：3：待确认完成：4：待评价：5:已完成）
 */
import comfirmDialog from '@/components/comfirmDialog/index.vue'
import { userDelServerOrder } from '@/api/user/myService.js'
export default {
  name: 'myServiceList',
  components: { comfirmDialog },
  props: {
    serviceList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      delItem: {},
      delVisible: false,
      serviceStatus: {
        1: '待支付',
        2: '进行中',
        3: '待确认完成',
        4: '待评价',
        5: '已完成'
      }
    }
  },
  methods: {
    handleCommand(command, item) {
      if (command == 'delete') {
        this.delItem = item
        this.delVisible = true
      }
      if (command == 'info') {
        this.$router.push({
          path: '/my/myServiceInfo',
          query: {
            id: item.id
          }
        })
      }
    },

    handleDelete() {
      userDelServerOrder(this.delItem.id).then((res) => {
        this.delVisible = false
        this.$emit('fresh')
        this.$message.success(res.msg)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.myServiceList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  &-item {
    width: 261px;
    position: relative;
    margin-right: 23px;
    margin-bottom: 40px;
  }
  &-item:nth-child(5n) {
    margin-right: 0;
  }
  .cover {
    width: 261px;
    border-radius: 10px;
  }
  .covernull {
    height: 261px;
    background: #bababa;
  }
  .point {
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 50%;
    background: #d000ff;
    left: 13px;
    top: 12px;
  }
  .title {
    width: 261px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 24px;
    margin-bottom: 8px;
    font-size: 18px;
    color: #000000;
    font-weight: 500;
  }
  .name {
    font-size: 16px;
    color: #878787;
    margin-top: 11px;
    text-align: left;
  }
  .price {
    color: #e35141;
  }
  .tips {
    color: #0033ff;
  }
  .success {
    color: #54c752;
  }
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 11px;
  }
  .avatar {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    margin-right: 5px;
    flex-shrink: 0;
    background: #bababa;
  }
  ._flex {
    display: flex;
    align-items: center;
  }
}
</style>