<template>
  <div>
    <el-collapse v-model="activeNames" @change="handleChange">
      <el-collapse-item
        v-for="(item, index) in list"
        :key="index"
        :title="item.title"
        :name="item.title"
      >
        <template v-if="showItem">
          <div v-for="(it, ind) in item.list" :key="ind">
            <slot :item="it" :mainItem="item"></slot>
          </div>
        </template>
        <template v-else>
          <slot :list="item.list" :mainItem="item"></slot>
        </template>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: 'commonCollapse',
  props: {
    // title-标题, id-关键索引, list-项列表
    list: {
      type: Array,
      default: () => []
    },
    showItem: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      activeNames: []
    }
  },
  watch: {
    list: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.length) {
          this.activeNames = val.map((v) => v.title)
        }
      }
    }
  },
  methods: {
    handleChange() {}
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-collapse {
  border-top: none;
}
::v-deep .el-collapse-item__header {
  font-size: 24px;
  font-weight: bold;
  color: #000000;
}
::v-deep .el-collapse-item__arrow {
  margin: 0 8px 0 8px;
}
::v-deep .el-collapse-item__wrap {
  border-bottom: none;
}
</style>