var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid views"},[_c('div',{staticClass:"mt-2 w-[865px] h-[376px] p-4 bg-[#2c2c2c] rounded-xl z-[900] relative col-span-3"},[_c('div',{staticClass:"flex justify-start items-center mb-4"},[_vm._l((_vm.recommendList),function(item,index){return _c('div',{key:index,staticClass:"mr-4 bg-black text-[#C3D1E3] px-3 py-1 rounded cursor-pointer text-xl font-medium",class:_vm.activeRecommend === item.key && 'activeRecommend',on:{"click":function($event){return _vm.changeRecommend(item.key)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),_c('div',{staticClass:"text-[#C3D1E3] ml-6 text-lg font-light"},[_vm._v(" "+_vm._s(_vm.recommendList.find((item) => item.key === _vm.activeRecommend)?.slogan)+" ")])],2),(_vm.activeRecommend === 'recommend')?_c('div',[_vm._m(0)]):_vm._e(),(_vm.activeRecommend === 'hotProject')?_c('div',[_c('div',{staticClass:"hotProject max-w-[870px] flex overflow-auto scrollbar pb-2"},_vm._l((_vm.hotProjectList),function(item,index){return _c('div',{key:index,staticClass:"w-60 h-72 border border-[#F7E296] border-4 rounded-xl relative mr-4 shrink-0 hotProjectChild",style:({
            background: 'url(' + item.coverImgUrl + ')',
            backgroundSize: 'cover'
          }),on:{"click":function($event){item.articleType == 2
              ? _vm.$router.push(`/works/collection/${item.id}`)
              : _vm.$router.push(`/works/detail/${item.id}`)}}},[_c('div',{staticClass:"flex mt-2 px-2 justify-start"},[_c('img',{staticClass:"w-6 h-6 rounded-full",attrs:{"src":item.creatorUser?.avatar,"alt":""}}),_c('span',{staticClass:"ml-2 text-white text-sm"},[_vm._v(_vm._s(item.creatorUser?.nickName))])]),_c('div',{staticClass:"p-2 absolute bottom-2"},[_c('div',{staticClass:"w-20 h-5 bg-black text-white text-sm text-center rounded-xl"},[_vm._v(" 服务100次 ")]),_c('div',{staticClass:"bg-[rgba(0,0,0.13,.6)] w-52 h-9 rounded-md text-lg text-white mt-2 ml-1 leading-9 cursor-pointer"},[_vm._v(" "+_vm._s(item.title)+" ")])])])}),0)]):_vm._e(),(_vm.activeRecommend === 'hotPage')?_c('div',[_vm._m(1)]):_vm._e()]),_vm._l((_vm.articleList),function(item,index){return _c('div',{key:index,staticClass:"mt-2 rounded-xl cursor-pointer group",on:{"click":function($event){return _vm.$router.push(`/service/detail/${item.id}`)}}},[_c('PreviewCard',{attrs:{"sourceId":item.id,"cover":item.coverImgUrl,"title":item.serverTitle,"price":item.serverPrice,"serviceNum":item.serverCount,"service":true,"isMask":false,"isLike":false,"isVipCount":true}})],1)})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"w-60 h-72 border border-[#F7E296] border-4 rounded-xl relative bg"},[_c('div',{staticClass:"bg-[#000] w-full h-52 rounded-xl absolute bottom-0 flex justify-center items-center"},[_c('div',{staticClass:"rounded-full w-28 h-28 border border-[#F7E296] border-4 absolute left-1/2 -ml-14 -top-14"},[_c('img',{attrs:{"src":require("@/assets/user/head.png"),"alt":""}})]),_c('div',{staticClass:"mx-auto text-[#C3D1E3] absolute bottom-2 w-48 flex flex-col justify-center items-center"},[_c('div',{staticClass:"font-bold"},[_vm._v("无名老角")]),_c('div',{staticClass:"mt-2 bg-[#54C752] w-16 h-6 text-white rounded-xl cursor-pointer"},[_vm._v(" 关注 ")]),_c('div',{staticClass:"mt-2 text-xs line-clamp-2 overflow-hidden"},[_vm._v(" 我是无名老角 ")]),_c('div',{staticClass:"flex mt-2"},[_c('div',{staticClass:"flex flex-col items-center justify-center mr-2"},[_c('span',{staticClass:"text-sm font-bold"},[_vm._v("96")]),_c('span',{staticClass:"text-xs"},[_vm._v("关注")])]),_c('div',{staticClass:"flex flex-col items-center justify-center mr-2"},[_c('span',{staticClass:"text-sm font-bold"},[_vm._v("128")]),_c('span',{staticClass:"text-xs"},[_vm._v("粉丝")])]),_c('div',{staticClass:"flex flex-col items-center justify-center mr-2"},[_c('span',{staticClass:"text-sm font-bold"},[_vm._v("1053")]),_c('span',{staticClass:"text-xs"},[_vm._v("获赞")])]),_c('div',{staticClass:"flex flex-col items-center justify-center mr-2"},[_c('span',{staticClass:"text-sm font-bold"},[_vm._v("103")]),_c('span',{staticClass:"text-xs"},[_vm._v("发布")])]),_c('div',{staticClass:"flex flex-col items-center justify-center mr-2"},[_c('span',{staticClass:"text-sm font-bold"},[_vm._v("10")]),_c('span',{staticClass:"text-xs"},[_vm._v("被同款")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hotPage"},[_c('div',{staticClass:"w-60 h-72 border border-[#F7E296] border-4 rounded-xl relative"},[_c('div',{staticClass:"flex mt-2 px-2 justify-start"},[_c('img',{staticClass:"w-6 h-6 rounded-full",attrs:{"src":"","alt":""}}),_c('span',{staticClass:"ml-2 text-white text-sm"},[_vm._v("TESTNAME")])]),_c('div',{staticClass:"p-2 absolute bottom-2 line-clamp-3 text-white"},[_vm._v(" 这里显示笔记还是看出可惜那时课程难度就卡死你才可能农村库拉索尼查看蓝色看来你吃辣看出考虑·拿出来看三层楼看似冷酷才能卢卡斯内存卡内存颗粒大女除了看到吕尼 ")])])])
}]

export { render, staticRenderFns }