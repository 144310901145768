<template>
  <div>
    <el-drawer :visible.sync="value" :show-close="false" @open="getList">
      <div class="absolute w-full h-screen">
        <div class="flex justify-between">
          <div class="w-full text-left text-xl ml-4">
            <i class="el-icon-arrow-left" @click="value = false"></i>
            {{ this.type }}词库
          </div>
          <div
            class="w-16 h-8 leading-7 mr-4 border rounded cursor-pointer"
            v-if="!editMode"
            @click="editMode = true"
          >
            管理
          </div>
        </div>
        <div class="px-2">
          <el-collapse v-model="active" class="mt-4">
            <el-collapse-item
              v-for="item in list"
              :key="item.id"
              :title="item.classifyName"
              :name="item.id"
            >
              <template slot="title">
                <el-input
                  v-if="item.edit"
                  v-model="item.classifyName"
                  style="width: 150px"
                  @keyup.enter.native="editClassifyMethod(item)"
                ></el-input>
                <span v-else>{{ item.classifyName }}</span>
                <el-dropdown v-if="editMode">
                  <i class="el-icon-more rotate-90 ml-4"></i>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="item.edit = true"
                      >重命名</el-dropdown-item
                    >
                    <el-dropdown-item
                      @click.native="deleteClassifyMethod(item)"
                      danger
                      >删除</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
              <div
                v-for="l in item.lexiconList"
                :key="l.id"
                class="mb-4 input py-4"
                :class="activeLexcion.id == l.id ? 'active' : ''"
                @click="chooseLexicon(l)"
              >
                <el-input
                  type="textarea"
                  :rows="5"
                  v-model="l.lexiconContent"
                  :disabled="!editMode || !l.edit"
                >
                </el-input>
                <div class="flex justify-end" v-if="editMode">
                  <el-button type="text" v-if="!l.edit" @click="l.edit = true"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    v-else
                    @click="editLexiconMethod(item, l)"
                    >保存</el-button
                  >
                  <el-button type="text" @click="deleteLexiconMethod(l.id)"
                    >删除</el-button
                  >
                </div>
              </div>
              <div
                @click="
                  addDialog = true
                  addId = item.id
                "
                class="cursor-pointer"
              >
                创建新指令
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div
          v-if="editMode"
          class="mt-4 cursor-pointer"
          @click="addClassifyDialog = true"
        >
          创建新分类
        </div>
        <div class="w-full mt-4">
          <el-button type="primary" v-if="editMode" @click="editMode = false"
            >保存</el-button
          >
          <el-button type="primary" v-else @click="handleConfirm"
            >确定</el-button
          >
        </div>
      </div>
    </el-drawer>
    <el-dialog :visible.sync="addDialog" title="添加指令">
      <el-input
        placeholder="请输入指令"
        type="textarea"
        v-model="addDescribe"
        :rows="5"
      >
      </el-input>
      <div class="mt-4">
        <el-button type="primary" @click="addLexiconMethod">保存</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="addClassifyDialog" title="添加分类">
      <el-input
        placeholder="请输入分类"
        type="text"
        v-model="addClassifyContent"
      ></el-input>
      <div class="mt-4">
        <el-button type="primary" @click="addClassifyMethod">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  addClassify,
  addLexicon,
  deleteClassify,
  deleteLexicon,
  editClassify,
  editLexicon,
  getUserAutoLexiconList
} from '@/api/work'
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    }
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  },
  data() {
    return {
      list: [],
      active: '',
      editMode: false,
      addDialog: false,
      addId: '',
      addDescribe: '',
      addClassifyDialog: false,
      addClassifyContent: '',
      activeLexcion: {}
    }
  },
  methods: {
    async getList() {
      this.activeLexcion = {}
      const result = await getUserAutoLexiconList({
        moduleId: this.id
      })
      if (result) {
        this.list = result.data.map((item) => ({
          ...item,
          edit: false,
          lexiconList: item.lexiconList?.map((i) => ({ ...i, edit: false }))
        }))
      }
    },
    /** 添加指令 */
    async addLexiconMethod() {
      if (!this.addDescribe) {
        this.$message.error('请输入指令')
        return
      }
      const result = await addLexicon({
        classifyId: this.addId,
        lexiconContent: this.addDescribe
      })
      if (result) {
        this.$message.success('添加成功')
        this.addDescribe = ''
        this.addDialog = false
        this.getList()
      }
    },
    /** 编辑指令 */
    async editLexiconMethod(item, l) {
      const result = await editLexicon({
        id: l.id,
        classifyId: item.classifyId,
        lexiconContent: l.lexiconContent
      })
      if (result) {
        item.edit = false
        this.editMode = false
        this.getList()
        this.$message.success('修改成功')
      }
    },
    /** 删除指令 */
    async deleteLexiconMethod(id) {
      const result = await deleteLexicon(id)
      if (result) {
        this.getList()
        this.editMode = false
        this.$message.success('删除成功')
      }
    },
    /** 选中指令 */
    chooseLexicon(item) {
      console.log(123, item)
      if (!this.editMode) {
        console.log(item)
        this.activeLexcion = item
      }
    },
    /** 添加分类 */
    async addClassifyMethod() {
      if (!this.addClassifyContent) {
        this.$message.error('请输入分类')
        return
      }
      const result = await addClassify({
        classifyName: this.addClassifyContent,
        moduleId: this.id
      })
      if (result) {
        this.$message.success('添加成功')
        this.addClassifyContent = ''
        this.editMode = false
        this.addClassifyDialog = false
        this.getList()
      }
    },
    /** 编辑分类 */
    async editClassifyMethod(item) {
      const result = await editClassify({
        id: item.id,
        classifyName: item.classifyName,
        moduleId: this.id
      })
      if (result) {
        item.edit = false
        this.editMode = false
        this.$message.success('修改成功')
        this.getList()
      }
    },
    /** 删除分类 */
    async deleteClassifyMethod(item) {
      const result = await this.$confirm(
        '删除该分类，分类中的所有描述指令将被一并删除！该操作无法恢复！',
        '操作提示',
        {
          confirmButtonText: '确认删除',
          cancelButtonText: '我再想想',
          type: 'warning'
        }
      )
      if (result) {
        const result = await deleteClassify(item.id)
        if (result) {
          this.$message.success('删除成功')
          this.editMode = false
          this.getList()
        }
      }
    },
    /** 确认 */
    handleConfirm() {
      this.$emit('confirm', this.activeLexcion)
      this.value = false
    }
  }
}
</script>

<style lang="scss" scoped>
.input {
  @apply cursor-pointer;
  ::v-deep .el-textarea.is-disabled .el-textarea__inner {
    cursor: pointer;
  }
}
.active {
  ::v-deep .el-textarea__inner {
    border: 1px solid #54c752 !important;
  }
}

::v-deep .el-button--primary {
  @apply w-64 rounded-2xl;
  background: #54c752;
  border: 1px solid #54c752;
  &:hover {
    background: #54c752;
    border: 1px solid #54c752;
  }
}
</style>