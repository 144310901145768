<template>
  <div class="memberCardItem">
    <div class="memberCardItem-title">{{ title }}</div>
    <div class="memberCardItem-content">
      <slot>
        <div class="memberCardItem-list">
          <div
            class="memberCardItem-item"
            v-for="(item, index) in list"
            :key="index"
            @click="handleClick(item)"
          >
            <div style="display: flex">
              <img class="icon" :src="item.icon" />
              <div class="name">{{ item.name }}</div>
              <slot :name="item.lSlot"></slot>
            </div>
            <img class="right" src="@/assets/vip/arrow-right.svg" />
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'memberCardItem',
  props: {
    title: {
      type: String,
      default: '标题'
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleClick(item) {
      if (!item.emit) return
      this.$emit(item.emit, item)
    }
  }
}
</script>

<style lang="scss" scoped>
.memberCardItem {
  text-align: left;
  &-title {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
  }
  &-content {
    width: 100%;
    height: 316px;
    margin-top: 12px;
    border-radius: 14px;
    background: #ffffff;
    border: 2px solid #e4e4e4;
  }
  &-list {
    padding: 0 29px;
    box-sizing: border-box;
  }
  &-item {
    padding: 26px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    .icon {
      width: 22px;
      height: 22px;
      margin-right: 12px;
    }
    .name {
      font-size: 16px;
      color: #000000;
    }
    .right {
      width: 9px;
      height: 16px;
      flex-shrink: 0;
    }
  }
}
</style>