<template>
  <commonLayout
    title="我的收益"
    :tabList="tabList"
    :mt="64"
    @tab="handleTab"
    @search="handleSearch"
  >
    <commonCollapse
      v-if="incomeList.length"
      v-loading="loading"
      :list="incomeList"
    >
      <template v-slot="{ item, mainItem }">
        <!-- 作品收益/分享奖励 -->
        <div class="myIncome-wrap" v-if="current == 1 || current == 3">
          <div class="myIncome-item">
            <div class="title">{{ item.name }}</div>
          </div>
          <div class="myIncome-item">
            <div>{{ item.createTime }}</div>
            <div class="_flex">
              <div>+{{ item.powerCoupon || 0 }}</div>
              <img class="icon" src="../assets/order-electron.svg" />
            </div>
          </div>
        </div>
        <!-- 邀请奖励 -->
        <div class="myIncome-invite" v-if="current == 2">
          <div class="_flex">
            <img class="avatar" :src="item.avatar" />
            <div class="name">{{ item.name }}</div>
          </div>
          <div>
            <div v-if="item.isBuyVip" class="_flex">
              <div class="title">{{ item.title }}</div>
              <img
                class="icon"
                src="../assets/order-electron.svg"
                style="margin-right: 5px"
              />
              <div class="title">/月*{{ item.vipMonth }}</div>
            </div>
            <div v-else class="_flex">
              <div class="title">{{ item.title }}</div>
              <img class="icon" src="../assets/order-electron.svg" />
            </div>
            <div class="time">{{ item.createTime }}</div>
          </div>
        </div>
      </template>
    </commonCollapse>

    <el-empty v-else :image-size="200"></el-empty>
  </commonLayout>
</template>

<script>
import commonLayout from '../components/commonLayout.vue'
import commonCollapse from '../components/commonCollapse.vue'
import dateArrayMixins from '../mixins/dateArrayMixins.js'
export default {
  name: 'myIncome',
  mixins: [dateArrayMixins],
  components: { commonLayout, commonCollapse },
  data() {
    return {
      loading: false,
      current: null,
      searchKey: null,
      incomeList: [],
      tabList: [
        {
          title: '作品收益',
          key: '1',
          api: null
        },
        {
          title: '邀请奖励',
          key: '2',
          api: null
        },
        {
          title: '分享奖励',
          key: '3',
          api: null
        }
      ]
    }
  },
  methods: {
    /* 点击tab */
    handleTab(key) {
      this.current = key
      this.handleInit()
    },

    /* 搜索 */
    handleSearch(key) {
      this.searchKey = key
      this.handleInit()
    },

    /* 获取列表 */
    handleInit() {
      //   let query = {}
      //   let item = this.tabList.find((v) => v.key == this.current)
      //   if (!item) return
      //   this.loading = true
      //   item
      //     .api(query)
      //     .then((res) => {
      //       let list = (res && res.rows) || []
      //       list = list.map((v) => {
      //         v.title = dayjs(v.createTime).format('YYYY年MM月')
      //         return v
      //       })
      //       list = this.classifyArrayGroupBySameFieldAlpha(list, 'title')
      //       this.incomeList = list
      //       console.log(`结果${this.current}`, list)
      //     })
      //     .finally(() => {
      //       this.loading = false
      //     })
      if (this.current == 1) {
        this.incomeList = [
          {
            id: '2023年3月',
            title: '2023年3月',
            list: [
              {
                name: '《我的XX产品设计》被一键同款',
                createTime: '2024-10-24 15:00:40',
                powerCoupon: 50,
                title: '2024年10月'
              },
              {
                name: '《河边的女孩》被一键同款',
                createTime: '2024-10-24 15:00:40',
                powerCoupon: 50,
                title: '2024年10月'
              },
              {
                name: '《河边的女孩》被保存原图',
                createTime: '2024-10-24 15:00:40',
                powerCoupon: 5,
                title: '2024年10月'
              }
            ]
          },
          {
            id: '2023年2月',
            title: '2023年2月',
            list: [
              {
                name: '《我的XX产品设计》被一键同款',
                createTime: '2024-10-24 15:00:40',
                powerCoupon: 50,
                title: '2024年10月'
              },
              {
                name: '《河边的女孩》被一键同款',
                createTime: '2024-10-24 15:00:40',
                powerCoupon: 50,
                title: '2024年10月'
              },
              {
                name: '《河边的女孩》被保存原图',
                createTime: '2024-10-24 15:00:40',
                powerCoupon: 5,
                title: '2024年10月'
              }
            ]
          }
        ]
      }
      if (this.current == 2) {
        this.incomeList = [
          {
            id: '2023年3月',
            title: '2023年3月',
            list: [
              {
                name: '三K工作室',
                title: '邀请注册奖励：+100',
                avatar:
                  'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
                createTime: '2024-10-24 15:00:40',
                powerCoupon: 5
              },
              {
                name: '鬼才设计',
                title: '积分充值奖励：+10',
                avatar:
                  'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
                createTime: '2024-10-24 15:00:40',
                powerCoupon: 5
              },
              {
                name: '晨光2024',
                isBuyVip: true,
                vipMonth: 12,
                title: '购买会员奖励：+100',
                avatar:
                  'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
                createTime: '2024-10-24 15:00:40',
                powerCoupon: 5
              },
              {
                name: 'ashhsahdjhd',
                isBuyVip: true,
                vipMonth: 24,
                title: '购买会员奖励：+100',
                avatar:
                  'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
                createTime: '2024-10-24 15:00:40',
                powerCoupon: 5
              }
            ]
          }
        ]
      }
      if (this.current == 3) {
        this.incomeList = [
          {
            id: '2023年3月',
            title: '2023年3月',
            list: [
              {
                name: '分享《潮鞋AI智能生成》奖励',
                createTime: '2024-10-24 15:00:40',
                powerCoupon: 5,
                title: '2024年10月'
              },
              {
                name: '分享《朋克未来风运动鞋》奖励',
                createTime: '2024-10-24 15:00:40',
                powerCoupon: 50,
                title: '2024年10月'
              },
              {
                name: '分享《设计师如何驾驭AI》奖励',
                createTime: '2024-10-24 15:00:40',
                powerCoupon: 5,
                title: '2024年10月'
              }
            ]
          },
          {
            id: '2023年2月',
            title: '2023年2月',
            list: [
              {
                name: '分享《潮鞋合集》奖励',
                createTime: '2024-10-24 15:00:40',
                powerCoupon: 50,
                title: '2024年10月'
              },
              {
                name: '分享《乐福鞋2024智能生成》奖励',
                createTime: '2024-10-24 15:00:40',
                powerCoupon: 50,
                title: '2024年10月'
              },
              {
                name: '分享《徒步鞋AI智能生成》奖励',
                createTime: '2024-10-24 15:00:40',
                powerCoupon: 5,
                title: '2024年10月'
              }
            ]
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.myIncome {
  &-wrap {
    padding: 14px 0;
    box-sizing: border-box;
    border-bottom: 1px solid #d8d8d8;
    cursor: pointer;
  }
  &-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #3d3d3d;
    .title {
      font-size: 16px;
      font-weight: 500;
      color: #000000;
    }
    .icon {
      margin-left: 6px;
    }
  }
  &-item + &-item {
    margin-top: 12px;
  }
  &-invite {
    padding: 14px 0;
    box-sizing: border-box;
    border-bottom: 1px solid #d8d8d8;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      flex-shrink: 0;
      margin-right: 8px;
      background: #000;
    }
    .name {
      width: 119px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
      font-weight: 500;
      color: #000000;
      text-align: left;
    }
    .title {
      font-size: 16px;
      font-weight: 500;
      color: #000000;
    }
    .icon {
      margin-left: 5px;
    }
    .time {
      font-size: 14px;
      color: #9e9e9e;
      margin-top: 4px;
    }
  }
}
._flex {
  display: flex;
  align-items: center;
}
</style>