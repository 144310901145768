<template>
  <div class="memberCardOpen">
    <div class="memberCardOpen-item" v-for="(item, index) in list" :key="index">
      <div class="item">
        <img
          :class="[`${index == 0 ? 'icon' : 'icons'}`]"
          :src="require(`@/assets/vip/item${Number(index + 1)}.png`)"
        />
        <div>
          <div class="name">{{ item.name }}</div>
          <div class="tips">{{ item.tips }}</div>
        </div>
      </div>
      <div v-if="item.isActive" class="isActive">已领取</div>
      <div v-else class="isnoActive">去开通</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'memberCardOpen',
  data() {
    return {
      list: [
        {
          name: '设辑体验卡',
          tips: '注册即送，10次免费生成次数',
          isActive: true
        },
        {
          name: '白银权益卡',
          tips: '无限生成次数，1GB云空间，300/月加速卡',
          isActive: false
        },
        {
          name: '黄金权益卡',
          tips: '无限生成次数，10GB云空间，赠送2个IP',
          isActive: false
        },
        {
          name: '铂金权益卡',
          tips: '无限生成次数，无限云空间，赠送4个IP',
          isActive: false
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.memberCardOpen {
  padding: 28px 21px;
  box-sizing: border-box;
  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .item {
    display: flex;
    align-items: center;
  }
  .icon {
    width: 37px;
    height: 44px;
    flex-shrink: 0;
    margin-right: 12px;
    margin-left: 15px;
    margin-bottom: 12px;
  }
  .icons {
    width: 68px;
    height: 68px;
    flex-shrink: 0;
    // margin-right: 12px;
  }
  .name {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
  }
  .tips {
    font-size: 12px;
    font-weight: 500;
    color: #999999;
  }
  .isActive {
    width: 72px;
    height: 28px;
    border-radius: 100px;
    border: 1.5px solid #c4bfbf;
    font-size: 16px;
    color: #c4bfbf;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .isnoActive {
    width: 72px;
    height: 28px;
    border-radius: 100px;
    font-size: 16px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #54c752;
    cursor: pointer;
  }
}
</style>