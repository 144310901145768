<template>
  <commonLayout title="成员管理" :searhBtnVisible="false">
    <div class="memberAdd">
      <img class="memberAdd-img" :src="shareImg" />
      <div class="memberAdd-tips">将二维码发送给要添加成员的微信</div>
      <div class="memberAdd-button" @click="handleDownload">分享二维码</div>
    </div>
  </commonLayout>
</template>

<script>
import commonLayout from '../components/commonLayout'
import { downLoadFile } from '../mixins/download.js'
import { createTeamInviteQR } from '@/api/user/member.js'
export default {
  name: 'memberAdd',
  data() {
    return {
      shareImg: null
    }
  },
  components: { commonLayout },
  created() {
    this.handleInit()
  },
  methods: {
    async handleInit() {
      let result = await createTeamInviteQR().catch((e) => {})
      this.shareImg = result && result.data && result.data.ercodeUrl
    },

    async handleDownload() {
      if (!this.shareImg) return
      downLoadFile(this.shareImg, `成员管理.png`, () => {
        this.$message.success('下载成功!')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.memberAdd {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &-img {
    width: 240px;
    height: 237px;
  }
  &-tips {
    font-size: 16px;
    color: #000000;
    margin-top: 13px;
  }
  &-button {
    width: 172px;
    height: 44px;
    border-radius: 22px;
    background: #54c752;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    margin-top: 80px;
    cursor: pointer;
  }
}
</style>