import concern from "@/views/concern/index.vue"

export default [{
    path: "/concern",
    name: "concern",
    component: concern,
    meta: {
      title: '关注'
    }
  }
]
