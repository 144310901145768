<template>
  <commonLayout
    title="成员作品"
    :tabList="memberList"
    :defaultTab="defaultTab"
    :searhBtnVisible="false"
    @tab="handleTab"
    v-if="memberList.length"
  >
    <!-- menu -->
    <div class="memberProduct-header">
      <div
        class="item"
        :class="{ active: currentMenu == 1 }"
        @click="handleMenu(1)"
      >
        创作
      </div>
      <div
        class="item"
        :class="{ active: currentMenu == 2 }"
        @click="handleMenu(2)"
      >
        发布
      </div>
      <!-- button -->
      <div class="button-item">
        <div class="button-item-button">
          <img class="img" src="@/assets/collection/arrow-search.svg" />
          <el-input
            v-model="searchKey"
            placeholder="搜索画面描述"
            @change="handleSearch"
          />
        </div>
        <!-- <div class="button-item-button">
          <div class="name">筛选</div>
          <img class="img" src="@/assets/collection/arrow-shaixuan.svg" />
        </div> -->
        <!-- <div class="button-item-button">
          <div class="name">批量筛选</div>
          <img class="img" src="@/assets/collection/arrow-check.svg" />
        </div> -->
      </div>
    </div>

    <!-- list -->
    <commonCollapse :list="list" :showItem="false">
      <template v-slot="{ list, mainItem }">
        <div class="memberProduct-list">
          <div
            class="memberProduct-item"
            v-for="(item, index) in list"
            :key="index"
          >
            <albumInfoItem
              :imgUrl="item.imgUrl"
              :issue="item.issue"
            ></albumInfoItem>
          </div>
        </div>
      </template>
    </commonCollapse>
  </commonLayout>
</template>

<script>
import commonLayout from '../components/commonLayout.vue'
import commonCollapse from '../components/commonCollapse.vue'
import albumInfoItem from '../components/albumInfoItem.vue'
import {
  teamUserList,
  userCreationList,
  userIssueCreation
} from '@/api/user/member.js'
import dateArrayMixins from '../mixins/dateArrayMixins.js'
export default {
  name: 'memberProduct',
  mixins: [dateArrayMixins],
  components: { commonLayout, commonCollapse, albumInfoItem },
  data() {
    return {
      searchKey: null,
      defaultTab: null,
      currentKey: null,
      currentMenu: 1,
      activeNames: [],
      memberList: [],
      list: []
    }
  },
  created() {
    this.handleMember()
  },
  methods: {
    async handleMember() {
      let res = await teamUserList().catch((e) => {})
      let list = (res && res.rows) || []
      list = list.map((v) => {
        if (this.$route.query.id && v.userId == this.$route.query.id) {
          this.defaultTab = v.userId
        }
        return {
          key: v.userId,
          title: v.nickName
        }
      })
      this.memberList = list
    },

    handleTab(key) {
      this.currentKey = key
      this.handleInit()
    },

    handleMenu(key) {
      this.currentMenu = key
      this.handleInit()
    },

    handleSearch() {
      this.handleInit()
    },

    handleInit() {
      let query = {}
      query.limit = -1
      query.page = 1
      query.prompt = this.searchKey
      query.userId = this.currentKey
      let myApi = this.currentMenu == 1 ? userCreationList : userIssueCreation
      myApi(query).then((res) => {
        let list = (res && res.rows) || []
        list = list.map((v) => {
          v.title = dayjs(v.createTime).format('YYYY年MM月')
          return v
        })
        list = this.classifyArrayGroupBySameFieldAlpha(list, 'title')
        this.list = list
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.memberProduct {
  &-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 26px;
    .item {
      color: #666666;
      font-size: 18px;
      cursor: pointer;
    }
    .active {
      font-weight: 900;
      color: #000000;
    }
    .item + .item {
      margin-left: 35px;
    }
    .button-item {
      top: 0;
      right: 0;
      position: absolute;
      display: flex;
      align-items: center;
      .img {
      }
      .name {
        font-size: 14px;
        color: #000000;
        margin-right: 10px;
      }
    }
    .button-item-button {
      height: 33px;
      padding: 0 13px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      border: 1px solid #c4bfbf;
      cursor: pointer;
    }
    .button-item-button + .button-item-button {
      margin-left: 16px;
    }
  }
  &-list {
    width: 100%;
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
  }
  &-item {
    width: 222px;
    margin-right: 14px;
    margin-bottom: 19px;
  }
  &-item:nth-child(6n) {
    margin-right: 0;
  }
}
</style>