<template>
  <div class="flex items-center">
    <div class="flex-1 h-full">
      <img src="@/assets/bg.png" class="h-screen" />
    </div>
    <div class="flex-1 flex justify-center">
      <div class="w-96">
        <div class="flex justify-start">
          <div class="text-2xl font-black mb-9">账号登录</div>
        </div>
        <ElForm>
          <div>
            <!-- 手机登录 -->
          </div>
          <div>
            <ElFormItem>
              <ElInput
                placeholder="请输入用户名/手机号"
                v-model="loginForm.username"
              />
            </ElFormItem>
            <ElFormItem>
              <ElInput
                type="password"
                showPassword
                placeholder="请输入密码"
                v-model="loginForm.password"
              >
                <template slot="append">
                  <div class="cursor-pointer">忘记密码</div>
                </template>
              </ElInput>
            </ElFormItem>
          </div>
          <div class="flex justify-start mb-4">
            <ElCheckbox v-model="agree">
              我已阅读和同意<ElLink type="primary" :underline="false"
                >用户协议</ElLink
              >和<ElLink type="primary" :underline="false">隐私条款</ElLink>
            </ElCheckbox>
          </div>
          <ElButton type="primary" @click="login" class="w-full !rounded-3xl"
            >登录</ElButton
          >
        </ElForm>
        <div class="mt-20 flex flex-col items-center">
          <div>第三方登录</div>
          <img
            src="@/assets/wechat.svg"
            width="35"
            class="mt-2 cursor-pointer"
            @click="getWx"
          />
        </div>
        <el-dialog
          title="微信登录"
          :visible.sync="wxCodeDialog"
          width="500px"
          center
          @close="cleanWxInfo"
          :close-on-click-modal="false"
        >
          <div class="flex w-full justify-center items-center">
            <img :src="wxQRCode" alt="" class="w-[250px] h-[250px]" />
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo, getWxLoginState, loginWithOutCode } from '@/api/user'
import { getWxQRCode } from '../../api/user'
export default {
  name: 'Login',
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      wxCodeDialog: false,
      wxQRCode: '',
      scene: '',
      agree: false,
      isValidate: true,
      timer: null
    }
  },
  methods: {
    async login() {
      if (!this.agree) {
        this.$message.error('请先阅读和同意用户协议和隐私条款')
        return
      }
      const result = await loginWithOutCode(this.loginForm)
      if (result) {
        localStorage.setItem('authorization', result.token)
        this.$message.success('登录成功')
        // pref: 把获取用户信息的内容改在首页获取，这样如果用户信息有改变才能实时更新
        // let userType = null
        // const userInfo = await getUserInfo()
        // localStorage.setItem('userInfo', JSON.stringify(userInfo.user))

        // if (userInfo.user.isVip == 1 && userInfo.userType == 1) {
        //   userType = 1 // 企业主号/个人号
        // } else if (userInfo.userType == 2) {
        //   userType = 2 // 企业子号
        // } else {
        //   userType = 3 // 纯个人号
        // }
        // localStorage.setItem('userType', userType)

        this.$router.push('/home')
      }
    },
    async getWx() {
      if (!this.agree) {
        this.$message.error('请先阅读和同意用户协议和隐私条款')
        return
      }
      const result = await getWxQRCode()
      if (result) {
        this.scene = result.data.scene
        this.wxQRCode = result.data.ercodeUrl
        this.wxCodeDialog = true
        this.getWxLoginStatus()
      }
    },
    async getWxLoginStatus() {
      const _get = async () => {
        const result = await getWxLoginState(this.scene)
        if (!result) {
          clearInterval(this.timer)
        }
        if (result.data.state === 1) {
          localStorage.setItem('authorization', result.data?.userToken)
          this.$message.success('微信登录成功')
          //   let userType = null
          //   const userInfo = await getUserInfo()
          //   localStorage.setItem('userInfo', JSON.stringify(userInfo.user))

          //   if (userInfo.user.isVip == 1 && userInfo.userType == 1) {
          //     userType = 1 // 企业主号/个人号
          //   } else if (userInfo.userType == 2) {
          //     userType = 2 // 企业子号
          //   } else {
          //     userType = 3 // 纯个人号
          //   }
          //   localStorage.setItem('userType', userType)

          clearInterval(this.timer)
          this.$router.push('/home')
        }
      }
      if (!this.timer) {
        this.timer = setInterval(_get, 1000)
      }
    },
    cleanWxInfo() {
      this.wxQRCode = ''
      this.scene = ''
      clearInterval(this.timer)
    }
  },
  created() {
    /** 存在登录状态跳至主页 */
    if (localStorage.getItem('authorization')) {
      this.$router.push('/home')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-link--inner,
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #54c752;
}
::v-deep .el-button--primary,
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #54c752;
  border-color: #54c752;
  &:hover {
    background-color: #54c752;
    border-color: #54c752;
  }
}

::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #606266;
}
</style>