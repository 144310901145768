<template>
  <comfirmDialog
    width="678px"
    height="690px"
    title="添加作品"
    titleAlign="left"
    :showCancel="false"
    v-model="addDialogVisible"
    @confirm="handleConfirmAdd"
  >
    <!-- title -->
    <template slot="titleRight">
      <div class="titleRight">
        <div v-if="collectionInfo.isOpen == 1" class="isopen">公开</div>
        <div v-else class="isopen isnoopen">私有</div>
        <div v-if="collectionInfo.isOpen == 1" class="tips">
          公开合集仅支持添加已发布的作品
        </div>
      </div>
    </template>
    <!-- content -->
    <div class="albumInfoAdd">
      <div class="albumInfoAdd-head">
        <div class="albumInfoAdd-button">
          <img class="img" src="@/assets/collection/arrow-search.svg" />
          <el-input
            v-model="searchQuery.prompt"
            placeholder="搜索画面描述"
            @change="handleInit"
          />
        </div>
        <div class="albumInfoAdd-button">
          <div class="name" style="margin-left: 0">画面尺寸</div>
          <img
            class="img"
            style="margin-left: 8px"
            src="@/assets/collection/arrow-down.svg"
          />
        </div>
        <el-dropdown
          v-if="collectionInfo.isOpen != 1"
          placement="bottom-start"
          @command="handleStatus"
        >
          <div class="albumInfoAdd-button">
            <div class="name" style="margin-left: 0">发布状态</div>
            <img
              class="img"
              style="margin-left: 8px"
              src="@/assets/collection/arrow-down.svg"
            />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="-1">全部</el-dropdown-item>
            <el-dropdown-item command="1">已发布</el-dropdown-item>
            <el-dropdown-item command="0">未发布</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <el-checkbox-group v-model="checkList" @change="handleCheckbox">
        <div class="albumInfoAdd-list" v-loading="loading">
          <div
            class="albumInfoAdd-item"
            v-for="(item, index) in albumList"
            :key="index"
          >
            <albumInfoItem
              :isSelected="true"
              :imgUrl="item.imgUrl"
              :issue="item.issue"
              :checkValue="item.id"
              :showMask="checkList.includes(item.id)"
            ></albumInfoItem>
          </div>
        </div>
      </el-checkbox-group>
    </div>
  </comfirmDialog>
</template>

<script>
import albumInfoItem from './albumInfoItem.vue'
import comfirmDialog from '@/components/comfirmDialog/index.vue'
import { userImageStorage, batchAddImg } from '@/api/album/index.js'
export default {
  name: 'albumInfoAdd',
  components: { albumInfoItem, comfirmDialog },
  props: {
    collectionInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      albumList: [],
      checkList: [],
      hisAlbumList: [],
      searchQuery: { page: 1, limit: -1 },
      loading: false,
      addDialogVisible: false
    }
  },
  methods: {
    /* 打开弹窗 */
    handleOpen() {
      this.checkList = []
      this.handleInit()
      this.addDialogVisible = true
    },

    /* 发布状态 */
    handleStatus(command) {
      if (command == 1) {
        this.albumList = this.hisAlbumList.filter((v) => v.issue)
      } else if (command == -1) {
        this.albumList = JSON.parse(JSON.stringify(this.hisAlbumList))
      } else {
        this.albumList = this.hisAlbumList.filter((v) => !v.issue)
      }
    },

    /* 多选 */
    handleCheckbox(e) {
      console.log('handleCheckbox', e, this.checkList)
    },

    /* 初始化图库列表 */
    async handleInit() {
      this.loading = true
      let res = await userImageStorage(this.searchQuery).catch((e) => {})
      this.loading = false
      this.albumList = (res && res.rows) || []
      this.hisAlbumList = JSON.parse(JSON.stringify(this.albumList))
      console.log('初始化图库列表', this.albumList)
    },

    /* 添加作品 */
    handleConfirmAdd() {
      if (!this.checkList.length) {
        return this.$message.error('请至少选择一个作品')
      }
      batchAddImg(this.collectionInfo.id, this.checkList).then((res) => {
        if (!res.code == 200) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.checkList = []
        this.addDialogVisible = false
        this.$emit('fresh')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.titleRight {
  display: flex;
  align-items: center;
  .isopen {
    width: 40px;
    height: 21px;
    border-radius: 6px;
    background: #d000ff;
    font-size: 12px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 14px;
    margin-left: 15px;
  }
  .isnoopen {
    background: #000000;
  }
  .tips {
    font-size: 12px;
    color: #646464;
  }
}
.albumInfoAdd {
  flex: 1;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: scroll;
  -ms-overflow-style: none; /* 适用于 Internet Explorer 和旧版 Edge */
  scrollbar-width: none; /* 适用于 Firefox */
  &-head {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &-button {
    height: 33px;
    padding: 0 13px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    border: 1px solid #c4bfbf;
    cursor: pointer;
    margin-left: 19px;
    .img {
    }
    .name {
      font-size: 14px;
      color: #000000;
      margin-left: 10px;
    }
  }
  &-list {
    width: 100%;
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
  }
  &-item {
    width: 151px;
    margin-right: 10px;
  }
  &-item:nth-child(4n) {
    margin-right: 0;
  }
}
/* Webkit 浏览器 */
.albumInfoAdd::-webkit-scrollbar {
  display: none;
}
::v-deep .el-input__inner {
  border: none;
  height: 30px;
}
::v-deep .el-input__inner:hover {
  border-color: #fff;
}
</style>