<template>
  <div class="flex items-center justify-center">
    <div class="w-[676px] text-left">
      <div class="text-[40px] font-bold text-[#3D3D3D]">个人资料</div>

      <div class="flex mt-[40px]">
        <img
          class="w-[127px] h-[127px] rounded-full mr-[32px]"
          :src="userInfo.avatar"
        />
        <div>
          <div class="font-base text-[#646464] font-bold">头像</div>
          <el-upload
            ref="upload"
            action="https://jsonplaceholder.typicode.com/posts/"
            :limit="1"
            :show-file-list="false"
          >
            <div class="info_change" slot="trigger">更换</div>
          </el-upload>
        </div>
      </div>

      <div class="mt-[30px]">
        <div class="text-[#646464] font-base font-[500] mt-[23px] mb-[15px]">
          更新封面
        </div>
        <el-upload
          ref="upload"
          action="https://jsonplaceholder.typicode.com/posts/"
          :limit="1"
          :show-file-list="false"
        >
          <div slot="trigger">
            <img class="rounded-[14px]" src="@/assets/homeBg.svg" />
          </div>
        </el-upload>
        <div class="text-[#646464] font-base font-[500] mt-[23px] mb-[15px]">
          用户/创作者名称
        </div>
        <div>
          <el-input
            show-word-limit
            v-model="userInfo.userName"
            placeholder="请输入用户/创作者名称"
            :maxlength="20"
          ></el-input>
        </div>
        <div class="text-[#646464] font-base font-[500] mt-[23px] mb-[15px]">
          手机号
        </div>
        <div class="text-sm font-[500]">{{ userInfo.phonenumber }}</div>
        <div class="text-[#646464] font-base font-[500] mt-[23px] mb-[15px]">
          个人简介
        </div>
        <div>
          <el-input
            rows="6"
            show-word-limit
            v-model="userInfo.remark"
            type="textarea"
            placeholder="请介绍一下自已"
            :maxlength="500"
          ></el-input>
        </div>
        <el-divider></el-divider>
        <div class="info_button">确认更改</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from '@/utils/user'
export default {
  name: 'infoEdit',
  data() {
    return {
      userInfo: {}
    }
  },
  mounted() {
    this.userInfo = getUserInfo()
    console.log('userInfo', this.userInfo)
  }
}
</script>

<style lang="scss" scoped>
.info_change {
  width: 60px;
  height: 40px;
  border-radius: 19.5px;
  border: 2px solid #353945;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: #3d3d3d;
  margin-top: 20px;
  cursor: pointer;
}
.info_button {
  width: 100%;
  height: 48px;
  border-radius: 24px;
  background: #54c752;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
::v-deep .el-input__inner {
  height: 47px;
  color: #000000;
  border-radius: 12px;
  font-weight: 500;
  font-size: 12px;
}
::v-deep .el-textarea__inner {
  color: #000000;
  border-radius: 12px;
  font-weight: 500;
  font-size: 12px;
}
</style>