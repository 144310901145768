<template>
  <div class="preview-imgs-view">
    <div class="main">
      <img class="current-img" :src="currentImg" />
    </div>
    <div class="img-list">
      <div class="button-left">
        <template v-if="showLeftArrow">
          <img
            class="light"
            src="@/assets/service/arrow_left_light.png"
            @click="handleImgSlide(-1)"
          />
          <img class="base reverse" src="@/assets/service/arrow_right.png" />
        </template>
      </div>
      <div class="img-box-view">
        <div
          class="img-content"
          :style="{ transform: `translateX(${slideX}px)` }"
        >
          <div
            v-for="(item, index) in imgList"
            :key="index"
            class="img-item cursor-pointer"
            :class="{ 'img-active': index === currentIndex }"
            @click="handleImgClick(index)"
          >
            <img :src="item" />
          </div>
        </div>
      </div>
      <div class="button-right">
        <template v-if="showRightArrow">
          <img
            class="light reverse"
            src="@/assets/service/arrow_left_light.png"
            @click="handleImgSlide(1)"
          />
          <img class="base" src="@/assets/service/arrow_right.png" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'previewImgs',
  props: {
    imgList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      currentIndex: 0,
      slideX: 0
    }
  },
  computed: {
    currentImg() {
      if (!this.imgList.length) {
        return ''
      }
      return this.imgList[this.currentIndex]
    },
    showLeftArrow() {
      if (this.imgList.length < 5) {
        return false
      }
      return this.slideX < 0
    },
    showRightArrow() {
      if (this.imgList.length < 5) {
        return false
      }
      const imgLength = this.imgList.length
      const totalWidth = imgLength * (82 + 26) - 26
      return totalWidth + this.slideX > 514
    }
  },
  methods: {
    handleImgClick(index) {
      this.currentIndex = index
    },
    handleImgSlide(direct) {
      const baseX = 82 + 26
      this.slideX -= direct * baseX
    }
  }
}
</script>

<style lang="scss" scoped>
.preview-imgs-view {
  .main {
    width: 630px;
    height: 630px;
    .current-img {
      height: 100%;
      width: 100%;
      border-radius: 14rpx;
    }
  }
  .img-list {
    margin-top: 28px;
    width: 630px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .img-box-view {
      width: 514px;
      height: 82px;
      overflow: hidden;
      .img-content {
        display: flex;
        align-items: center;
        transition: all 0.3s ease-in-out;
        .img-item {
          height: 82px;
          width: 82px;
          margin-right: 26px;
          flex-shrink: 0;
          &:last-child {
            margin-right: 0;
          }
          img {
            height: 100%;
            width: 100%;
          }
          &.img-active {
            border: 1px solid #54c752;
          }
        }
      }
    }
    .button-left,
    .button-right {
      width: 21px;
      height: 34px;
      img {
        height: 100%;
        width: 100%;
        cursor: pointer;
      }
      .reverse {
        transform: rotateZ(180deg);
      }
      .light {
        display: none;
      }
      &:hover {
        .base {
          display: none;
        }
        .light {
          display: block;
        }
      }
    }
  }
}
</style>