<template>
  <div class="albumInfoItem">
    <div style="position: relative">
      <img :src="imgUrl" @click="handleSkip" />
      <div v-if="showMask" class="mask"></div>
    </div>
    <div v-if="issue" class="issue">已发布</div>
    <div class="checkbox" v-if="isSelected">
      <el-checkbox :label="checkValue"></el-checkbox>
    </div>
    <div v-if="albumName" class="albumName">{{ albumName }}</div>
  </div>
</template>

<script>
export default {
  name: 'albumInfoItem',
  props: {
    // 图片地址
    imgUrl: {
      type: String,
      default: false
    },
    // 是否发布
    issue: {
      default: false
    },
    // 是否可选择
    isSelected: {
      type: Boolean,
      default: false
    },
    // 选择的值
    checkValue: {
      default: null
    },
    // 是否显示遮罩
    showMask: {
      type: Boolean,
      default: false
    },
    // 标题
    albumName: {
      type: String,
      default: null
    },
    // 类型（1:ai文章生成、2:合集、3:笔记）
    albumType: {
      type: [Number, String],
      default: null
    },
    id: {
      type: String,
      default: null
    }
  },
  methods: {
    handleSkip() {
      if (!this.id) return
      if (this.albumType == 1) {
        this.$router.push(`/works/detail/${this.id}`)
      }
      if (this.albumType == 2) {
        this.$router.push({
          path: '/my/albumCoverInfo',
          query: {
            id: this.id
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.albumInfoItem {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  img {
    border-radius: 10px;
  }
  .issue {
    width: 46px;
    height: 20px;
    border-radius: 6px;
    background: #d000ff;
    position: absolute;
    top: 11px;
    left: 11px;
    font-size: 12px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 12;
  }
  .checkbox {
    top: 11px;
    right: 11px;
    position: absolute;
    z-index: 12;
  }
  .mask {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9;
  }
  .albumName {
    font-size: 16px;
    color: #3d3d3d;
    margin-top: 6px;
    text-align: left;
  }
}
::v-deep .el-checkbox__label {
  display: none;
}
::v-deep .el-checkbox__inner {
  border-radius: 50%;
  background: rgba(88, 88, 88, 0.4);
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #d000ff;
  border-color: #d000ff;
}
</style>