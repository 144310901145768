<template>
  <div>
    <el-dialog :visible.sync="value" append-to-body :show-close="false">
      <div class="-mt-4">
        <div class="flex items-center">
          <div
            class="w-16 h-8 leading-7 border text-center rounded-2xl bg-[#D8D8D8] text-black cursor-pointer"
            @click="value = false"
          >
            返回
          </div>
          <div class="ml-3 text-2xl text-black">会员记录</div>
        </div>
        <div class="grid col-span-2 gap-3 mt-4" v-if="info?.length > 0">
          <div
            v-for="(item, index) in info"
            :key="index"
            class="w-[300px] h-40 rounded-xl p-2 bg-[#262626] text-[#C7B480]"
          >
            <div
              class="flex justify-between items-center pb-2 mb-2 border-b border-[#1A1A1A] font-medium"
            >
              <div
                class="text-lg text-white font-extrabold px-1"
                :class="
                  vipList.find((i) => Number(i.key) == Number(item.vipLevel))
                    ?.ename
                "
              >
                {{
                  vipList.find((i) => Number(i.key) == Number(item.vipLevel))
                    ?.name
                }}权益卡
              </div>
              <div>{{ item.remarkTitle }}</div>
            </div>
            <div>
              <div>
                <div>会员生效时间:</div>
                <div>{{ item.updateTime }}</div>
              </div>
              <div class="mt-3">
                <div>会员到期时间:</div>
                <div>{{ item.vipEndTime }}</div>
              </div>
              <div class="mt-3 flex justify-between">
                <div>交易详情</div>
                <div
                  class="border-l-[8px] border-r-[8px] border-b-[16px] rotate-90 border-l-transparent border-r-transparent border-[#C7B480] cursor-pointer"
                  @click="
                    activeId = item.id
                    showList = true
                  "
                ></div>
              </div>
            </div>
          </div>
        </div>
        <el-empty v-else description="暂无内容"></el-empty>
      </div>
    </el-dialog>
    <vipDetail v-model="showList" :id="activeId" />
  </div>
</template>
    
<script>
import { getMemberList } from '@/api/vip'
import vipDetail from './vipDetail.vue'

export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  components: {
    vipDetail
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  },
  data() {
    return {
      info: [
        {
          id: '',
          userId: '',
          vipMoney: '',
          vipLevel: '1',
          vipIpCount: '',
          buyIpCount: '',
          ipMoney: '',
          totalMoney: '',
          orderStatus: '',
          payPlateformNo: '',
          payPlateformChannel: '',
          payPlateformStatus: '',
          payChannel: '',
          payChannelName: '',
          vipTime: '',
          createTime: '',
          updateTime: '',
          vipEndTime: '',
          remarkTitle: '鞋样设计',
          payee: ''
        }
      ],
      vipList: [
        {
          key: 1,
          name: '白银',
          ename: 'baiyin'
        },
        {
          key: 2,
          name: '黄金',
          ename: 'huangjin'
        },
        {
          key: 3,
          name: '铂金',
          ename: 'bojin'
        }
      ],
      activeId: '',
      showList: false
    }
  },
  methods: {
    async get() {
      const result = await getMemberList({
        page: 1,
        limit: 50,
        orderStatus: 1
      })
      if (result) {
        this.info = result.data
      }
    }
  },
  mounted() {
    this.get()
  }
}
</script>
    
<style lang="scss" scoped>
.baiyin {
  background: radial-gradient(rgba(237, 240, 241, 1), rgba(201, 217, 222, 1));
  color: #7b91a5;
  border-radius: 6px;
}
.huangjin {
  background: radial-gradient(rgba(255, 236, 193, 1), rgba(234, 167, 113, 1));
  color: #c08049;
  border-radius: 6px;
}
.bojin {
  background: radial-gradient(rgba(247, 244, 255, 1), rgba(171, 148, 254, 1));
  color: #885dd6;
  border-radius: 6px;
}
</style>
  