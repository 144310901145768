<template>
  <div style="padding: 0px 100px">
    <div class="discover_bg" style="height: auto">
      <div class="flex justify-between">
        <div class="flex items-center">
          <img
            :src="articleInfo.creatorUser.avatar"
            alt=""
            class="w-10 h-10 rounded-full"
          />
          <span class="ml-4">{{ articleInfo.creatorUser.nickName }}</span>
          <div
            v-if="!articleInfo.isAttenCreator"
            class="w-16 h-6 text-center bg-[#54C752] rounded-xl text-white ml-4 text-sm leading-6 cursor-pointer"
          >
            +关注
          </div>
        </div>
        <div class="flex w-14 h-6 bg-[#FFF] rounded-xl justify-between p-1">
          <img src="@/assets/electron.svg" alt="" class="cursor-pointer" />
          <img src="@/assets/share.svg" alt="" class="cursor-pointer" />
        </div>
      </div>
      <div class="flex" style="margin: 20px 0px">
        <div
          class="w-16 h-6 text-center bg-[#000000] rounded font-extrabold italic text-white leading-6 cursor-pointer"
        >
          笔记
        </div>
        <div class="ml-4 font-extrabold text-white">{{ articleInfo.title || "" }}</div>
      </div>
      <div class="flex font-medium text-left" style="display: flex;justify-content: space-between">
        <div class="article-content" style="width: 70%">
          <div v-for="(item,index) in articleInfo.articleItems" :key="index" style="margin-bottom: 40px;">
            <div style="font-family: 思源黑体;font-size: 15px;font-weight: 500;line-height: 25px;letter-spacing: 0px;color: #3D3D3D;margin-bottom: 20px;">
              {{ item.content || "" }}
            </div>
            <div
              class="w-full h-[692px] bg-[#F0F0F0] flex justify-center items-center relative"
            >
              <div
                class="w-12 h-6 bg-[rgba(0,0,0,.5)] rounded-2xl absolute top-4 left-4 text-white font-light"
                style="text-align: center"
              >
                {{ activeImage + 1 }}/{{ item.imgStorages?.length }}
              </div>
              <img
                :src="item.imgStorages?.[activeImage]?.imgUrl"
                alt=""
                class="w-[750px] h-[650px]"
              />
            </div>
          </div>
        </div>
        <div class="article-right" style="width: 28%;margin-left: 2%"></div>
      </div>
    </div>

    <div></div>

    <div class="grid grid-cols-6 gap-3 mt-4">
      <div class="w-72" v-for="(item,index) in articleList" :key="index">
        <img :src="item.imgUrl" alt="" class="w-72 rounded-xl" />,
        <div
          class="flex w-72 h-16 flex-col justify-center items-center bg-[#54C752] text-white rounded-3xl cursor-pointer"
        >
          <span class="text-2xl font-black">一键同款</span>
          <div class="flex text-sm">
            （消耗 <img src="@/assets/electron.svg" /> -{{ item.sameStyleCoupon }}电力券）
          </div>
        </div>
      </div>
    </div>

    <comments></comments>
  </div>
</template>

<script>
import comments from "@/components/comments";
import {
  getPostArticleDetails
} from "@/api/note/index";

export default {
  components: {
    comments
  },
  data() {
    return {
      articleId: "",
      articleInfo: {},
      queryParam: {
        page: 1,
        limit: 10,
        collectionArticleId: ""
      },
      articleList: [],
      activeImage: 0
    };
  },
  mounted() {
    this.articleId = this.$route.params.id;
    this.queryParam.collectionArticleId = this.articleId;
    this.getPostArticleDetails();
  },
  methods: {
    getPostArticleDetails() {
      getPostArticleDetails(this.articleId).then(res => {
        this.articleInfo = res.data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.discover_bg {
  @apply w-full h-52 rounded-xl flex flex-col justify-around px-6 py-1;
  background-size: cover;
}
.tag {
  background: linear-gradient(
      104deg,
      #e8b45a 2%,
      rgba(235, 217, 100, 0.75) 97%,
      rgba(232, 203, 17, 0.51) 97%
  );
}
</style>
