<template>
  <div class="service-detail-wrap">
    <template v-if="paySuccess == 0">
      <div class="project-info">
        <div class="preview-imgs">
          <preview-imgs :imgList="imgList" v-loading="loading" />
        </div>
        <div class="price-content">
          <offer-price
            :priceData="serviceInfo"
            v-loading="loading"
            @pay="handlePay"
            v-if="serviceInfo.serverUser"
          />
        </div>
      </div>
      <div class="project-content">
        <div class="tab-view">
          <div
            class="tab-item cursor-pointer pb-1"
            :class="{ 'tab-active': tabIndex === 0 }"
            @click="handleTabClick(0)"
          >
            服务详情
          </div>
          <div
            class="tab-item cursor-pointer pb-1"
            :class="{ 'tab-active': tabIndex === 1 }"
            @click="handleTabClick(1)"
          >
            评价
            <div class="comment-count">{{ commentListLength || 0 }}条</div>
          </div>
        </div>

        <div class="tab-content">
          <div class="flex-view">
            <service-info v-show="tabIndex === 0" :content="infoContent" />
            <comment
              :scoreData="scoreData"
              v-show="tabIndex === 1"
              :commentList="commentList"
            />
          </div>
          <div class="recommend-view">
            <recommend-list />
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <guide :info="paySuccessData" />
    </template>
    <template>
      <shejiDialog
        v-model="payVisible"
        width="586px"
        height="200px"
        background="#000"
      >
        <div class="flex justify-around items-center text-white">
          <div id="payQrCode" ref="payQrCode" class="w-[160px] h-[160px]"></div>
          <div>
            <div class="text-left">
              应付：
              <span class="text-[#ff7744] text-3xl">{{
                payParam.payMoney
              }}</span>
              <span class="text-[#ff7744] text-sm ml-2">元</span>
            </div>
            <div class="mt-4 flex justify-between items-center">
              <div class="text-sm">使用微信/支付宝扫码支付</div>
              <div
                class="text-[#9b9b9b] border border-[#9b9b9b] text-sm ml-4 px-1 cursor-pointer"
              >
                发票开具入口
              </div>
            </div>
            <div class="my-4 text-left text-sm cursor-pointer">刷新二维码</div>
          </div>
        </div>
      </shejiDialog>
    </template>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import shejiDialog from '@/components/shejiDialog/index.vue'
import previewImgs from './components/previewImgs.vue'
import serviceInfo from './components/serviceInfo.vue'
import comment from './components/comment.vue'
import recommendList from './components/recommendList.vue'
import offerPrice from './components/offerPrice.vue'
import guide from './guide.vue'
import {
  getClientServerDetails,
  createServerItemOrderByQR,
  userServerItemOrderDetails,
  getServerItemEvaluatePage
} from '@/api/service/index'

export default {
  name: 'ServiceDetail',
  components: {
    shejiDialog,
    previewImgs,
    serviceInfo,
    comment,
    recommendList,
    offerPrice,
    guide
  },
  data() {
    return {
      serviceId: null,
      serviceInfo: {},
      commentList: [],
      commentListLength: 0,
      paySuccessData: {},
      loading: false,
      payVisible: false,
      paySuccess: 0, // 0-详情, 1-支付中, 2-支付成功
      imgList: [],
      tabIndex: 0,
      infoContent: '',
      payParam: {}
    }
  },
  mounted() {
    this.serviceId = this.$route.params.id
    if (!this.serviceId) return
    this.handleInit()
    this.handleReplay()
  },
  computed: {
    scoreData() {
      return {
        value1: this.serviceInfo.professionalLevel || 0,
        value2: this.serviceInfo.efficiencyServiceLevel || 0,
        value3: this.serviceInfo.serviceAttitudeLevel || 0
      }
    }
  },
  methods: {
    handleInit() {
      this.loading = true
      getClientServerDetails(this.serviceId)
        .then((res) => {
          let data = res.data || {}
          this.imgList = (data.serverImgList || []).map((v) => v.url)
          this.infoContent = data.serverTxt || ''
          this.serviceInfo = data
          console.log('serviceInfo', this.serviceInfo)
        })
        .finally(() => {
          this.loading = false
        })
    },

    handleTabClick(index) {
      if (this.tabIndex === index) {
        return
      }
      this.tabIndex = index
    },

    async handleReplay() {
      let result = await getServerItemEvaluatePage({
        page: 1,
        limit: -1,
        serverItemId: this.serviceId
      }).catch((e) => {})
      this.commentList = (result && result.rows) || []
      this.commentListLength = (result && result.total) || 0
      console.log('handleReplay', result)
    },

    async handlePay(payParam) {
      this.payParam = payParam
      let result = await createServerItemOrderByQR({
        serverItemId: this.serviceId,
        payCount: payParam.numVal,
        isTest: false
      }).catch((e) => {})
      if (result.code != 200) {
        return this.$message.error(result.msg)
      }
      result = result.data
      console.log('下单', result)
      this.payVisible = true
      this.$nextTick(() => {
        if (this.$refs.payQrCode) {
          this.$refs.payQrCode.innerHTML = ''
        }
        new QRCode('payQrCode', {
          text: result.content,
          width: 160,
          height: 160,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
        })
        this.handleResult(result.orderId)
      })
    },

    handleResult(orderId) {
      let timer = setInterval(async () => {
        let result = await userServerItemOrderDetails(orderId).catch((e) => {})
        console.log('支付结果', result)
        if (result && result.code == 200 && result.data.payStatus == 1) {
          clearInterval(timer)
          this.paySuccess = 2
          this.payVisible = false
          this.paySuccessData = result.data
        }
      }, 3000)
    }
  }
}
</script>

<style lang="scss" scoped>
.service-detail-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  .project-info {
    max-width: 1400px;
    display: flex;
    align-items: stretch;
    .preview-imgs {
      width: 950px;
      padding-top: 24px;
      display: flex;
      justify-content: center;
      margin-right: 20px;
    }
    .price-content {
      width: 430px;
    }
  }
  .project-content {
    max-width: 1400px;
    margin-top: 36px;
    .tab-view {
      display: flex;
      align-items: center;
      padding: 0 16px;
      padding-bottom: 8px;
      border-bottom: 1px solid #f7f7f7;
      margin-bottom: 28px;
      .tab-item {
        color: #a09e9f;
        font-size: 18px;
        font-family: Source Han Sans;
        margin-right: 34px;
        position: relative;
        .comment-count {
          position: absolute;
          right: -2px;
          top: 0;
          transform: translateX(100%);
        }
        &:last-child {
          margin-right: 0;
        }
        &.tab-active {
          @apply relative;
          color: #000;
          &::after {
            @apply block absolute right-0.5 bottom-0 h-1;
            content: '';
            width: 14px;
            border-radius: 2.5px;
            background: #54c752;
          }
        }
      }
    }
    .tab-content {
      display: flex;
      .flex-view {
        width: 950px;
        margin-right: 20px;
      }
      .recommend-view {
        width: 430px;
      }
    }
  }
}
</style>