<template>
  <div
    class="memberCardHead"
    :style="{
      color: vipColor[userInfo.level] ? vipColor[userInfo.level] : '#AFAFAF'
    }"
  >
    <img v-if="userInfo.level == 1" class="bg" src="@/assets/vip/vip1.png" />
    <img
      v-else-if="userInfo.level == 2"
      class="bg"
      src="@/assets/vip/vip2.png"
    />
    <img
      v-else-if="userInfo.level == 3"
      class="bg"
      src="@/assets/vip/vip3.png"
    />
    <img v-else class="bg" src="@/assets/vip/vip.png" />

    <div style="display: flex; flex: 1">
      <img class="avatar" :src="userInfo.avatar" />
      <div>
        <div class="userName">{{ userInfo.userName }}</div>
        <div style="display: flex; align-items: center; margin-top: 6px">
          <img
            v-if="userInfo.level == 1"
            class="icon"
            src="@/assets/vip/vip1_icon.png"
          />
          <img
            v-if="userInfo.level == 2"
            class="icon"
            src="@/assets/vip/vip2_icon.png"
          />
          <img
            v-if="userInfo.level == 3"
            class="icon"
            src="@/assets/vip/vip3_icon.png"
          />
          <div class="no">NO: {{ userInfo.userId }}</div>
        </div>
      </div>
    </div>

    <div
      class="item"
      :style="{
        'border-left': `1px solid ${
          vipColor[userInfo.level] ? vipColor[userInfo.level] : '#AFAFAF'
        }`,
        'border-right': `1px solid ${
          vipColor[userInfo.level] ? vipColor[userInfo.level] : '#AFAFAF'
        }`
      }"
    >
      <div>加速卡次数：</div>
      <div style="display: flex; align-items: baseline">
        <div class="no">本月</div>
        <div class="mNum" v-if="userInfo.isVip && userInfo.isVip == 1">
          {{ userInfo.freeFastNum - userInfo.freeFastNumMonth || 0 }}/{{
            userInfo.freeFastNum || 0
          }}
        </div>
        <div class="mNum" v-else>0 / 0</div>
      </div>
    </div>

    <div
      v-if="userInfo.isVip"
      class="item"
      :style="{
        'border-right': `1px solid ${
          vipColor[userInfo.level] ? vipColor[userInfo.level] : '#AFAFAF'
        }`
      }"
    >
      <div>AI生成次数：</div>
      <div style="display: flex; align-items: baseline">
        <div class="no">有效期内</div>
        <div class="mNum">无限</div>
      </div>
    </div>

    <div
      v-else
      class="item"
      :style="{
        'border-right': `1px solid ${
          vipColor[userInfo.level] ? vipColor[userInfo.level] : '#AFAFAF'
        }`
      }"
    >
      <div>AI能力：</div>
      <div style="display: flex; align-items: baseline">
        <div class="no">电力券</div>
        <div class="mNum">{{ userInfo.powerCoupon || 0 }}</div>
        <div class="no">张</div>
      </div>
    </div>

    <div style="flex: 1">
      <div style="display: flex; padding-left: 60px; box-sizing: border-box">
        <img v-if="userInfo.level == 1" src="@/assets/vip/vip1_tag.svg" />
        <img v-else-if="userInfo.level == 2" src="@/assets/vip/vip2_tag.svg" />
        <img v-else-if="userInfo.level == 3" src="@/assets/vip/vip3_tag.svg" />
        <img v-else src="@/assets/vip/vip_tag.svg" />
        <div v-if="userInfo.isVip" class="tips">
          鞋样设计 | 有效期至:{{ userInfo.vipEndTime }}
        </div>
        <div v-else class="tips">开通会员享无限创意特权</div>
      </div>
      <div style="display: flex; justify-content: center; margin-top: 24px">
        <div v-if="userInfo.isVip" :class="['open', `open${userInfo.level}`]">
          立即续费
        </div>
        <div v-else class="open">立即开通</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'memberCardHead',
  props: {
    userInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      vipColor: {
        1: '#7B91A5',
        2: '#AE8265',
        3: '#885DD6'
      }
    }
  }
}
</script>

<style lang="scss">
.memberCardHead {
  margin-top: 22px;
  width: 100%;
  height: 200px;
  border-radius: 14px;
  position: relative;
  overflow: hidden;
  padding: 56px 45px;
  box-sizing: border-box;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  .avatar {
    width: 63px;
    height: 63px;
    flex-shrink: 0;
    margin-right: 18px;
    border-radius: 50%;
  }
  .userName {
    font-size: 24px;
    font-weight: bold;
    text-align: left;
  }
  .icon {
    width: 160px;
    height: 60px;
    flex-shrink: 0;
    margin-right: 24px;
    margin-left: -10px;
  }
  .no {
    font-size: 14px;
  }
  .item {
    width: 278px;
    padding-left: 31px;
    box-sizing: border-box;
    text-align: left;
  }
  .mNum {
    font-size: 36px;
    font-weight: 900;
    margin-left: 17px;
  }
  .tips {
    font-size: 18px;
    margin-left: 10px;
  }
  .open {
    width: 105px;
    height: 31px;
    border-radius: 15px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
        107deg,
        rgba(255, 250, 240, 0.33) -27%,
        rgba(82, 77, 67, 0) 57%
      ),
      #f0c879;
  }
  .open1 {
    background: linear-gradient(
        107deg,
        rgba(255, 250, 240, 0.33) -27%,
        rgba(82, 77, 67, 0) 57%
      ),
      #526679;
  }
  .open2 {
    background: linear-gradient(
        107deg,
        rgba(255, 250, 240, 0.33) -27%,
        rgba(82, 77, 67, 0) 57%
      ),
      #6a5932;
  }
  .open3 {
    background: linear-gradient(
        107deg,
        rgba(255, 250, 240, 0.33) -27%,
        rgba(82, 77, 67, 0) 57%
      ),
      #662fa5;
  }
}
</style>
