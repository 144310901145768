<template>
  <div class="likeAndCollectHead">
    <div class="likeAndCollectHead-item">
      <div
        class="likeAndCollectHead-button"
        :class="{ active: current == 1 }"
        @click="handleCheck(1)"
      >
        AI创作
      </div>
      <div
        class="likeAndCollectHead-button"
        :class="{ active: current == 2 }"
        @click="handleCheck(2)"
      >
        合集
      </div>
      <div
        class="likeAndCollectHead-button"
        :class="{ active: current == 3 }"
        @click="handleCheck(3)"
      >
        笔记
      </div>
    </div>
    <div class="likeAndCollectHead-item" v-if="isCheck">
      <div class="likeAndCollectHead-button checknum">
        已选中 {{ checkLength || 0 }} 项
      </div>
      <div class="likeAndCollectHead-button" @click="handleCancel">
        取消收藏
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'likeAndCollectHead',
  props: {
    current: {
      type: [String, Number],
      default: 1
    },
    checkLength: {
      type: Number,
      default: 0
    },
    isCheck: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    handleCheck(key) {
      this.$emit('check', key)
    },
    handleCancel() {
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="scss" scoped>
.likeAndCollectHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-item {
    display: flex;
    align-items: center;
  }
  &-button {
    width: 88px;
    height: 33px;
    border-radius: 50px;
    border: 1px solid #c4bfbf;
    font-size: 16px;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  &-button + &-button {
    margin-left: 18px;
  }
  .active {
    color: #ffffff;
    background: #000000;
    border: 1px solid #000000;
  }
  .checknum {
    border: none;
    width: max-content;
    cursor: default;
  }
}
</style>