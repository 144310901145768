<template>
  <commonLayout title="成员管理" :searhBtnVisible="false">
    <div class="memberList-list" v-loading="loading">
      <!-- list -->
      <div
        class="memberList-item"
        v-for="(item, index) in memberList"
        :key="index"
      >
        <div class="avatar-wrap">
          <img v-if="item.userAvatar" class="avatar" :src="item.userAvatar" />
          <div v-else class="avatar"></div>
        </div>
        <div class="title">
          <span>{{ item.teamUserName || item.nickName || '-' }}</span>
          <span v-if="item.teamPosition"> | {{ item.teamPosition }}</span>
        </div>
        <div class="mt-[10px]">到期时间：{{ item.teamEndTime || '' }}</div>
        <div class="item mt-[11px]">
          <div>
            电力券使用：
            <span v-if="item.teamCoupon == 1" class="active">已授权</span>
            <span v-else>未授权</span>
          </div>
          <div>
            作品发布：
            <span v-if="item.teamCreation == 1" class="active">已授权</span>
            <span v-else>未授权</span>
          </div>
        </div>
        <div class="item mt-[18px]" style="justify-content: center">
          <div class="button" @click="handleDel(item)">删除</div>
          <div class="button buttons" @click="$refs.memberInfoEdit.open(item)">
            编辑
          </div>
        </div>
        <div class="line"></div>
        <div style="text-align: left">
          <div>使用数据</div>
          <div class="mt-[19px]">最近使用时间：</div>
          <div class="mt-[10px]">
            电力券使用：{{ item.couponCount || 0 }} 张
          </div>
          <div class="mt-[10px]">
            作品发布：{{ item.creationCount || 0 }} 篇
          </div>
        </div>
        <div class="item mt-[10px]">
          <div>生成图片：{{ item.generatorCount || 0 }} 张</div>
          <div
            class="button buttons"
            style="width: 48.18px"
            @click="handleToView(item)"
          >
            查看
          </div>
        </div>
      </div>
      <!-- add -->
      <div class="memberList-item" @click="handleToAdd">
        <div class="avatar-wrap">
          <div class="avatar"></div>
        </div>
        <div class="wrap">
          <img src="@/assets/member/add_icon.svg" />
          <div class="tips">添加新成员</div>
        </div>
      </div>
    </div>
    <!-- button -->
    <div class="memberList-button">
      <div class="number">
        已添加{{ teamHasCount || 0 }}/{{ teamAllCount || 0 }}总IP数
      </div>
      <div class="button" @click="handleToAdd">添加新成员</div>
    </div>
    <!-- 编辑成员弹窗 -->
    <memberInfoEdit ref="memberInfoEdit" @fresh="handleInit"></memberInfoEdit>
    <!-- 删除成员弹窗 -->
    <comfirmDialog
      v-model="deleteVisible"
      content="确认删除该成员账号吗？"
      cancelTxt="放弃"
      @confirm="handleDelete"
    ></comfirmDialog>
  </commonLayout>
</template>

<script>
import commonLayout from '../components/commonLayout.vue'
import memberInfoEdit from '../components/memberInfoEdit.vue'
import comfirmDialog from '@/components/comfirmDialog/index.vue'
import {
  teamUserList,
  teamUserCountStatistics,
  removeTeamUser
} from '@/api/user/member.js'
export default {
  name: 'memberList',
  components: { commonLayout, memberInfoEdit, comfirmDialog },
  data() {
    return {
      memberList: [],
      loading: false,
      teamAllCount: 0,
      teamHasCount: 0,
      deleteItem: null,
      deleteVisible: false
    }
  },
  created() {
    this.handleFresh()
  },
  methods: {
    handleFresh() {
      this.handleInit()
      this.handleNumber()
    },

    /* 获取成员列表 */
    handleInit() {
      this.loading = true
      teamUserList()
        .then((res) => {
          let list = (res && res.rows) || []
          this.memberList = list
        })
        .finally(() => {
          this.loading = false
        })
    },

    /* 获取用户数量 */
    async handleNumber() {
      let result = await teamUserCountStatistics().catch((e) => {})
      this.teamAllCount =
        (result && result.data && result.data.teamAllCount) || 0
      this.teamHasCount =
        (result && result.data && result.data.teamHasCount) || 0
    },

    /* 添加新成员 */
    handleToAdd() {
      this.$router.push('/my/memberAdd')
    },

    /* 查看成员作品 */
    handleToView(item) {
      this.$router.push({
        path: '/my/memberProduct',
        query: { id: item.userId }
      })
    },

    /* 删除成员 */
    handleDel(item) {
      this.deleteItem = item
      this.deleteVisible = true
    },
    handleDelete() {
      if (!this.deleteItem) return
      removeTeamUser(this.deleteItem.userId).then((res) => {
        this.deleteVisible = false
        this.$message.success(res && res.msg)
        this.handleFresh()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.memberList {
  &-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  &-item {
    width: 265px;
    min-height: 364px;
    margin-top: 50px;
    border-radius: 23px;
    position: relative;
    background: #f4f5f7;
    padding-left: 26px;
    padding-right: 22px;
    padding-bottom: 29px;
    margin-right: 19px;
    box-sizing: border-box;
    color: #666666;
    font-size: 10.99px;
    .avatar-wrap {
      top: -35px;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      .avatar {
        width: 70.21px;
        height: 70.05px;
        border-radius: 50%;
        border: 5.5px solid #ffffff;
        background: #f4f5f7;
      }
    }
    .title {
      font-size: 14px;
      color: #000000;
      font-weight: bold;
      margin-top: 55px;
    }
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .active {
      color: #54c752;
    }
    .button {
      width: 69.69px;
      height: 19px;
      border-radius: 9.62px;
      background: #737373;
      color: #ffffff;
      cursor: pointer;
    }
    .buttons {
      background: #54c752;
    }
    .button + .button {
      margin-left: 8px;
    }
    .line {
      width: 100%;
      height: 1px;
      background: #d4d4d4;
      margin-top: 16px;
      margin-bottom: 8px;
    }
    .wrap {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin-top: 120px;
      cursor: pointer;
    }
    .tips {
      font-size: 16px;
      color: #666666;
      margin-top: 36px;
    }
  }
  &-item:nth-child(5n) {
    margin-right: 0;
  }
  &-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 120px;
    .number {
      font-size: 16px;
      color: #ff4646;
      margin-bottom: 14px;
    }
    .button {
      width: 700px;
      height: 44px;
      border-radius: 22px;
      background: #54c752;
      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
</style>