import home from '@/views/vip/pages/home.vue'
import infoEdit from '@/views/vip/pages/infoEdit.vue'
import electron from "@/views/vip/pages/electron.vue"
import facusAfans from '@/views/vip/pages/facusAfans.vue'
import memberAdd from '@/views/vip/pages/memberAdd.vue'
import memberCard from '@/views/vip/pages/memberCard.vue'
import memberList from '@/views/vip/pages/memberList.vue'
import memberProduct from '@/views/vip/pages/memberProduct.vue'
import albumCenter from '@/views/vip/pages/albumCenter.vue'
import albumCoverInfo from '@/views/vip/pages/albumCoverInfo.vue'
import myService from '@/views/vip/pages/myService.vue'
import myServiceInfo from '@/views/vip/pages/myServiceInfo.vue'
import myOrder from '@/views/vip/pages/myOrder.vue'
import myOrderInfo from '@/views/vip/pages/myOrderInfo.vue'
import myIncome from '@/views/vip/pages/myIncome.vue'
import likeAndCollect from '@/views/vip/pages/likeAndCollect.vue'
import creatorData from "@/views/vip/pages/creatorData";
import fansData from "@/views/vip/pages/fansData";
import messageCenter from '@/views/vip/pages/messageCenter'
import messageCenterComment from '@/views/vip/pages/messageCenterComment'
import messageInfo from '@/views/vip/pages/messageInfo'

export default [
    {
        path: "/my/home",
        name: "home",
        component: home,
        meta: {
            title: '我的主页',
            useFooter: false
        }
    },
    {
        path: "/my/infoEdit",
        name: "infoEdit",
        component: infoEdit,
        meta: {
            title: '编辑资料',
            useFooter: false
        }
    },
    {
        path: "/my/facusAfans",
        name: "facusAfans",
        component: facusAfans,
        meta: {
            title: '我的关注/粉丝',
            useFooter: false
        }
    },
    {
        path: "/my/electron",
        name: "electron",
        component: electron,
        meta: {
            title: '我的电力券',
            useFooter: false
        }
    },
    {
        path: "/my/memberCard",
        name: "memberCard",
        component: memberCard,
        meta: {
            title: '我的会员卡',
            useFooter: false
        }
    },
    {
        path: "/my/memberAdd",
        name: "memberAdd",
        component: memberAdd,
        meta: {
            title: '成员管理',
            useFooter: false
        }
    },
    {
        path: "/my/memberList",
        name: "memberList",
        component: memberList,
        meta: {
            title: '成员管理',
            useFooter: false
        }
    },
    {
        path: "/my/memberProduct",
        name: "memberProduct",
        component: memberProduct,
        meta: {
            title: '成员作品',
            useFooter: false
        }
    },
    {
        path: "/my/albumCenter",
        name: "albumCenter",
        component: albumCenter,
        meta: {
            title: '创作者中心',
            useFooter: false
        }
    },
    {
        path: 'albumCenter/creatorData',
        name: 'creatorData',
        component: creatorData,
        meta: {
            title: '创作者中心'
        }
    },
    {
        path: 'albumCenter/fansData',
        name: 'fansData',
        component: fansData,
        meta: {
            title: '创作者中心'
        }
    },
    {
        path: "/my/likeAndCollect",
        name: "likeAndCollect",
        component: likeAndCollect,
        meta: {
            title: '赞和收藏',
            useFooter: false
        }
    },
    {
        path: "/my/myService",
        name: "myService",
        component: myService,
        meta: {
            title: '我的服务',
            useFooter: false
        }
    },
    {
        path: "/my/myServiceInfo",
        name: "myServiceInfo",
        component: myServiceInfo,
        meta: {
            title: '服务详情',
            useFooter: false
        }
    },
    {
        path: "/my/myOrder",
        name: "myOrder",
        component: myOrder,
        meta: {
            title: '订单',
            useFooter: false
        }
    },
    {
        path: "/my/myOrderInfo",
        name: "myOrderInfo",
        component: myOrderInfo,
        meta: {
            title: '订单详情',
            useFooter: false
        }
    },
    {
        path: "/my/myIncome",
        name: "myIncome",
        component: myIncome,
        meta: {
            title: '收益',
            useFooter: false
        }
    },
    {
        path: "/my/albumCoverInfo",
        name: "albumCoverInfo",
        component: albumCoverInfo,
        meta: {
            title: '合集详情',
            useFooter: false
        }
    },
    {
        path: "/my/messageCenter",
        name: "messageCenter",
        component: messageCenter,
        meta: {
            title: '消息中心',
            useFooter: false
        }
    },
    {
        path: "/my/messageCenterComment",
        name: "messageCenterComment",
        component: messageCenterComment,
        meta: {
            title: '消息中心',
            useFooter: false
        }
    },
    {
        path: "/my/messageInfo",
        name: "messageInfo",
        component: messageInfo,
        meta: {
            title: '官方公告',
            useFooter: false
        }
    }
]
