<template>
  <div class="w-full">
    <!-- 背景 -->
    <div class="relative">
      <img src="@/assets/homeBg.svg" />
      <div
        class="absolute top-5 right-5 cursor-pointer"
        @click="$router.push('/my/infoEdit')"
      >
        <img src="@/assets/homeEdit.svg" />
      </div>
    </div>
    <!-- 信息 -->
    <div class="pl-16 pr-32 flex justify-between items-center">
      <div class="relative flex mt-3.5">
        <div class="w-32 h-2">
          <img
            class="w-32 h-32 rounded-full border-2 border-white absolute -top-16"
            :src="userInfo.avatar"
          />
        </div>
        <div>
          <div class="flex">
            <div class="text-2xl text-neutral-700">{{ userInfo.nickName }}</div>
            <template v-if="!isMineView">
              <div
                v-if="userInfo.isAttend == 1"
                class="mx-5 w-24 h-[28px] rounded-[14px] bg-[#54C752] text-base text-white flex items-center justify-center cursor-pointer"
                @click="handleAttend"
              >
                取消关注
              </div>
              <div
                v-else
                class="mx-5 w-24 h-[28px] rounded-[14px] bg-[#54C752] text-base text-white flex items-center justify-center cursor-pointer"
                @click="handleAttend"
              >
                关注
              </div>
            </template>
          </div>
          <div class="text-left text-[#3D3D3D]">
            这个人很懒，什么都没有留下。
          </div>
        </div>
      </div>
      <homeNum :info="userInfo"></homeNum>
    </div>
    <!-- 标签页 -->
    <homeTab
      :tabIndex="tabIndex"
      :sortIndex="sortIndex"
      @tab="tabChange"
      @sort="sortChange"
    ></homeTab>
    <div class="mt-[38px]">
      <!-- AI作品 -->
      <homeTabAi
        ref="homeTabAi"
        v-if="tabIndex == 0"
        :userId="userId"
        :sortMode="sortType"
        :sortFields="sortIndex"
      ></homeTabAi>
      <!-- 合集 -->
      <homeTabCollection
        ref="homeTabCollection"
        v-if="tabIndex == 1"
        :isMineView="isMineView"
      ></homeTabCollection>
      <!-- 笔记 -->
      <homeTabNote ref="homeTabNote" v-if="tabIndex == 2"></homeTabNote>
      <!-- 服务 -->
      <homeTabService
        ref="homeTabService"
        v-if="tabIndex == 3"
      ></homeTabService>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from '@/utils/user'
import homeNum from '../components/homeNum.vue'
import homeTab from '../components/homeTab.vue'
import homeTabAi from '../components/homeTabAi.vue'
import homeTabNote from '../components/homeTabNote.vue'
import homeTabService from '../components/homeTabService.vue'
import homeTabCollection from '../components/homeTabCollection.vue'
import { userDetails } from '@/api/user/home.js'
import { followAuthor, cancelFollowAuthor } from '@/api/work/index.js'
export default {
  name: 'home',
  components: {
    homeNum,
    homeTab,
    homeTabAi,
    homeTabNote,
    homeTabService,
    homeTabCollection
  },
  data() {
    return {
      tabIndex: 0,
      sortIndex: 2,
      sortType: null,
      isMineView: false, // 是否是创作者自己视角
      userInfo: {},
      userId: null,
      subComRefName: [
        'homeTabAi',
        'homeTabCollection',
        'homeTabNote',
        'homeTabService'
      ]
    }
  },
  mounted() {
    this.userId = this.$route.query.id
    this.handleInit()
    let mineInfo = getUserInfo()
    if (mineInfo.userId == this.userId) {
      this.isMineView = true
    }
  },
  methods: {
    /* 切换标签 */
    tabChange(index) {
      this.tabIndex = index
      this.sortChange(2, null)
    },

    /* 切换排序 */
    sortChange(index, sortType) {
      this.sortIndex = index
      this.sortType = sortType
      let subComRef = this.subComRefName[this.tabIndex]
      this.$nextTick(() => {
        this.$refs[subComRef] && this.$refs[subComRef].handleInit()
      })
    },

    /* 获取个人信息 */
    async handleInit() {
      let res = await userDetails(this.userId).catch((e) => {})
      if (res && res.data) {
        this.userInfo = res.data
        console.log('**获取个人信息**', this.userInfo)
      }
      this.tabChange(0)
    },

    /* 关注/取消关注 */
    handleAttend() {
      let attendApi =
        this.userInfo.isAtten == 1 ? cancelFollowAuthor : followAuthor
      attendApi(this.userInfo.userId).then((res) => {
        if (res && res.code == 200) {
          this.$message.success(res.msg)
          this.handleInit()
        }
      })
    }
  }
}
</script>

<style>
</style>