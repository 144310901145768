import router from '@/router';
import axios from 'axios';
import { Message } from "element-ui"

// 创建axios实例
const service = axios.create({
    baseURL: "https://www.sheji-ai.cc/systemApi", // api的base_url
    timeout: 15000 // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
    config => {
        // 可以在这里添加请求头等信息
        // if (store.getters.token) {
        //   config.headers['X-Token'] = getToken();
        // }
        const token = localStorage.getItem("authorization")
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`
        }
        return config;
    },
    error => {
        // 请求错误处理
        console.log(error); // for debug
        Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    response => {
        const res = response?.data;
        if (![0, 200].includes(res.code)) {
            if (res.code === 401) {
                localStorage.removeItem("authorization")
                Message.error("验证权限错误!")
                router.replace("/login")
                return
            }
            Message.error(res.msg)
            return
        }
        return res;
    },
    error => {
        console.log('err' + error); // for debug
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        });
        return Promise.reject(error);
    }
);

export default service;
