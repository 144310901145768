<template>
  <div style="padding: 200px">
    <ElEmpty
      description="暂无数据"
    ></ElEmpty>
  </div>
</template>

<script>
import { userCollectionImgList } from "@/api/album/index.js";
import { findMiniList } from "@/api/home";
import sortHeader from "@/components/sortHeader";

export default {
  components: { sortHeader },
  name: "discover",
  data() {
    return {
      searchKeys: [{
        name: "合集",
        id: "book"
      }, {
        name: "笔记",
        id: "page"
      }],
      defaultSearchKey: "book",
      params: {
        page: 1,
        limit: 10,
        articleType: 2
      },
      dataList: [],
      total: 0
    };
  },
  mounted() {
    // this.getDataList();
  },
  methods: {
    changeSearchKey(data) {
      this.defaultSearchKey = data;
      this.getDataList();
    },
    getDataList() {
      if (this.defaultSearchKey == "book") {
        this.params.articleType = 2;
      } else if (this.defaultSearchKey == "page") {
        this.params.articleType = 3;
      }
      findMiniList(this.params).then(res => {
        if (res.code == 0) {
          this.dataList = res.rows ? res.rows : [];
          this.total = res.total;
          if (this.dataList && this.dataList.length > 0) {
            if (this.params.articleType == 2) {
              for (let i = 0; i < this.dataList.length; i++) {
                let item = this.dataList[i];
                userCollectionImgList({ collectionArticleId: item.id, page: 1, limit: 6 }).then(itemRes => {
                  if (itemRes.code == 0) {
                    const rowImgs = itemRes.rows ? itemRes.rows : [];
                    this.$set(this.dataList[i], "rowImgs", rowImgs);
                  }
                });
                console.log("this.dataList===", this.dataList);
              }
            }
          }
        }
      });
    }
  }
};
</script>

<style>

</style>
