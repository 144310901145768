<template>
  <div class="flex items-center justify-center mt-[19px] homeTab relative">
    <div class="flex items-center">
      <div
        class="mx-14 pb-2 cursor-pointer text_gray"
        v-for="(item, index) in tabList"
        :key="index"
        :class="{ active_router: index === tabIndex }"
        @click="$emit('tab', index)"
      >
        <div class="text-2xl">{{ item.title }}</div>
      </div>
    </div>

    <div class="flex justify-center absolute right-0">
      <div
        class="ml-6 cursor-pointer text-[#3D3D3D]"
        v-for="(item, index) in sortList[tabIndex]"
        :key="index"
        :class="{ active_index: item.key === sortIndex }"
        @click="handleSort(item)"
      >
        <div class="text-base" :class="{ sort_custom: item.sortType }">
          {{ item.name }}
          <template v-if="item.sortType">
            <div
              class="triangle-top"
              :class="{ active1: item.sortType == 2 }"
            ></div>
            <div
              class="triangle-bottom"
              :class="{ active2: item.sortType == 3 }"
            ></div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'homeTab',
  props: {
    tabIndex: {
      type: Number,
      default: 0
    },
    sortIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      tabList: [
        { title: 'AI作品' },
        { title: '合集' },
        { title: '笔记' },
        { title: '服务' }
      ],
      sortList: {
        // 2 升序； 3 降序
        0: [
          { name: '最新', key: 2 },
          { name: '同款', sortType: 1, key: 3 },
          { name: '点赞', sortType: 1, key: 4 },
          { name: '收藏', sortType: 1, key: 5 }
        ],
        1: [{ name: '全部' }, { name: '公开' }, { name: '私有' }],
        2: [
          { name: '最新', key: 2 },
          { name: '点赞', sortType: 1, key: 4 },
          { name: '收藏', sortType: 1, key: 5 }
        ],
        3: []
      }
    }
  },
  methods: {
    handleSort(item) {
      if (this.sortIndex == item.key && item.sortType) {
        item.sortType = item.sortType == 1 ? 2 : item.sortType == 2 ? 3 : 1
      }
      this.$emit('sort', item.key, item.sortType)
    }
  }
}
</script>

<style lang="scss" scoped>
.homeTab {
  border-bottom: 1px solid #d8d8d8;
}
.active_router {
  @apply font-black relative;
  color: #000;
  &::after {
    @apply block absolute right-0.5 bottom-0.5 h-1;
    content: '';
    width: 20px;
    border-radius: 2.5px;
    background: #54c752;
  }
}
.sort_custom {
  @apply relative;
  padding-right: 12px;
  .triangle-top,
  .triangle-bottom {
    @apply absolute right-0;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }
  .triangle-top {
    @apply top-1;
    border-bottom: 6px solid #a09e9f;
  }
  .triangle-bottom {
    @apply bottom-1;
    border-top: 6px solid #a09e9f;
  }
  .active1 {
    @apply top-1;
    border-bottom: 6px solid #000;
  }
  .active2 {
    @apply bottom-1;
    border-top: 6px solid #000;
  }
}
.active_index {
  @apply font-black;
  color: #000;
}
</style>