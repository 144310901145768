<template>
  <div class="albumCenter">
    <div class="center-header" style="display: flex;justify-content: center">

    </div>
    <div style="min-height: calc(100vh - 105px);width: 100%;background: #000">
      <div style="width: 100%;height: 10px"></div>
      <div style="display: flex;width: 98%;margin: 30px auto;align-items: center">
        <div @click="$router.push('/my/albumCenter')"
             style="cursor:pointer;font-size: 14px;font-weight: 500;font-family: 思源黑体;line-height: 15px;letter-spacing: 0em;text-align: center;color: #FFFFFF;display: flex;border: 2px solid #878787;box-sizing: border-box;border-radius: 20px;padding: 8px 20px">
          <div><img src="../../../assets/back-icon.png" alt="" style="width: 8px;height: 15px"></div>
          <div style="margin-left: 10px;">
            返回
          </div>
        </div>
        <div
          style="font-family: Source Han Sans;font-size: 36px;font-weight: bold;line-height: 15px;letter-spacing: 0px;color: #FFFFFF;margin-left: 22px;">
          AI创作数据
        </div>
      </div>


      <div style="background: #1D1D1F;border-radius: 20px;opacity: 1;width: 98%;margin: 35px auto;height: 568px;">
        <div
          style="font-size: 18px;font-family: Source Han Sans;font-weight: bold;line-height: 15px;letter-spacing: 0px;color: #FFFFFF;text-align: left;margin-left: 24px;padding-top: 24px;">
          内容数据
        </div>


        <div
          style="display: flex;align-items: center;justify-content: space-between;width: 98%;margin: 30px auto 20px;border-bottom: 1px solid #404040;padding-bottom: 20px;">
          <div style="width: 25%;border-right: 1px solid #404040">
            <div
              style="font-size: 14px;font-family: Source Han Sans;font-weight: normal;line-height: 10px;text-align: center;letter-spacing: 0px;color: #FFFFFF;margin-bottom: 20px;">
              {{creatorCount || 0}}
            </div>
            <div
              style="font-family: Source Han Sans;font-size: 14px;font-weight: normal;line-height: 10px;text-align: center;letter-spacing: 0px;color: #FFFFFF;">
              作画
            </div>
            <div>
              <div style="height: 5px;width: 32px;background: #D000FF;margin: 20px auto 0px;border-radius: 20px"></div>
            </div>
          </div>

          <div style="width: 25%;border-right: 1px solid #404040">
            <div
              style="font-size: 14px;font-family: Source Han Sans;font-weight: normal;line-height: 10px;text-align: center;letter-spacing: 0px;color: #FFFFFF;margin-bottom: 20px;">
              {{articleCount || 0}}
            </div>
            <div
              style="font-family: Source Han Sans;font-size: 14px;font-weight: normal;line-height: 10px;text-align: center;letter-spacing: 0px;color: #FFFFFF;">
              发布作品
            </div>
            <div>
              <div style="height: 5px;width: 32px;background: #0033FF;margin: 20px auto 0px;border-radius: 20px"></div>
            </div>
          </div>

          <div style="width: 25%;border-right: 1px solid #404040">
            <div
              style="font-size: 14px;font-family: Source Han Sans;font-weight: normal;line-height: 10px;text-align: center;letter-spacing: 0px;color: #FFFFFF;margin-bottom: 20px;">
              {{sameCount || 0}}
            </div>
            <div
              style="font-family: Source Han Sans;font-size: 14px;font-weight: normal;line-height: 10px;text-align: center;letter-spacing: 0px;color: #FFFFFF;">
              被同款
            </div>
            <div>
              <div style="height: 5px;width: 32px;background: #168115;margin: 20px auto 0px;border-radius: 20px"></div>
            </div>
          </div>

          <div style="width: 25%;">
            <div
              style="font-size: 14px;font-family: Source Han Sans;font-weight: normal;line-height: 10px;text-align: center;letter-spacing: 0px;color: #FFFFFF;margin-bottom: 20px;">
              {{invitationCount || 0}}
            </div>
            <div
              style="font-family: Source Han Sans;font-size: 14px;font-weight: normal;line-height: 10px;text-align: center;letter-spacing: 0px;color: #FFFFFF;">
              发布帖子
            </div>
            <div>
              <div style="height: 5px;width: 32px;background: #FFA500;margin: 20px auto 0px;border-radius: 20px"></div>
            </div>
          </div>

        </div>

        <div style="width: 98%;margin: 20px auto">
          <div
            style="font-family: Source Han Sans;font-size: 18px;font-weight: bold;line-height: 15px;letter-spacing: 0px;color: #FFFFFF;text-align: left">
            内容趋势图
          </div>
          <BarAndLine
            ref="barLine"
            :legedConfig="legedConfig"
            :tooltipConfig="tooltipConfig"
            :xAxisData="xAxis"
            :yAxisData="yAxis"
            :seriesData="seriesData"
            :gridConfig="gridConfig"
            :height="'400px'"
            :width="'102%'"
            :lineColor="['#09A0FF','#19FF90','#158AFF','#48FFAB']"></BarAndLine>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import albumList from "../components/albumList.vue";
import albumHeadRight from "../components/albumHeadRight.vue";
import albumCoverList from "../components/albumCoverList.vue";
import albumCoverForm from "../components/albumCoverForm.vue";
import albumCoverJoin from "../components/albumCoverJoin.vue";
import {
  userImageStorage,
  userCollectionList,
  userCollectionImgList
} from "@/api/album/index.js";
import BarAndLine from "@/components/echarts/BarAndLine";
import {
  userBusinessDataStatistics,
  userBusinessCount
} from "@/api/user/statistics";

export default {
  name: "albumCenter",
  components: {
    albumList,
    albumHeadRight,
    albumCoverList,
    albumCoverForm,
    albumCoverJoin,
    BarAndLine
  },
  data() {
    return {
      creatorCount: 0,
      articleCount: 0,
      sameCount: 0,
      invitationCount: 0,
      searchKey: null,
      albumList: [],
      initAlbumList: [],
      userInfo: {},
      currentKey: 1,
      loading: false,
      // x轴时间字段列表
      timeList: [],
      legedConfig: {
        data: ["作画", "发布作品", "被同款", "发布帖子"],
        show: false
      },
      tooltipConfig: {
        trigger: "axis"
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: [],
        axisLabel: {
          color: "#fff"
        }
      },
      yAxis: {
        type: "value",
        axisLabel: {
          color: "#fff"
        },
        splitLine: { //网格线：隐藏
          lineStyle: {
            color: "#404040",
            type: "dashed" // 线型为虚线
          }
        }
      },
      // 作画数据数量列表
      creatorDataList: [],
      // 发布作品的数据数量列表
      articleDataList: [],
      // 同款数据列表
      sameDataList: [],
      // 帖子数据列表
      invitationDataList: [],
      seriesData: [
        {
          name: "作画",
          type: "line",
          stack: "Total",
          data: [],
          lineStyle: {
            color: "#D000FF"
          },
          itemStyle: {
            color: "#D000FF"
          }
        },
        {
          name: "发布作品",
          type: "line",
          stack: "Total",
          data: this.articleDataList,
          lineStyle: {
            color: "#0033FF"
          },
          itemStyle: {
            color: "#0033FF"
          }
        },
        {
          name: "被同款",
          type: "line",
          stack: "Total",
          data: this.sameDataList,
          lineStyle: {
            color: "#168115"
          },
          itemStyle: {
            color: "#168115"
          }
        },
        {
          name: "发布帖子",
          type: "line",
          stack: "Total",
          data: this.invitationDataList,
          lineStyle: {
            color: "#FFA500"
          },
          itemStyle: {
            color: "#FFA500"
          }
        }
      ],
      gridConfig: {}
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log("userInfo===", this.userInfo);
    this.handleTab(1);
    this.getUserBusinessDataStatistics();
    this.getUserBusinessCount();
  },
  methods: {
    toCreatorDataView() {
      this.$router.push("/albumCenter/creatorData");
    },
    /* 点击tab */
    handleTab(key) {
      this.currentKey = key;
      this.handleInit();
    },

    /* 搜索 */
    handleSearch(key) {
      this.searchKey = key;
      this.handleInit();
    },
    /**
     * 获取用户业务数据趋势统计
     */
    getUserBusinessDataStatistics() {
      userBusinessDataStatistics({}).then(res => {
        const data = res.data;
        this.seriesData[0].data = data.creatorCountList.map(x => x ? x : 0);
        this.seriesData[1].data = data.articleCountList;
        this.seriesData[2].data = data.sameCountList;
        this.seriesData[3].data = data.invitationCountList;
        this.xAxis.data.push(...data.dateTimeList);
        // this.$set(this, "timeList", data.dateTimeList);
        // this.timeList = data.dateTimeList
        this.$refs.barLine.initChart();
      });
    },
    /**
     * 获取用户业务数据统计情况
     */
    getUserBusinessCount() {
      userBusinessCount({}).then(res => {
        const data = res.data
        this.creatorCount = data.aiCreatorCount
        this.articleCount = data.aiArticleCount
        this.sameCount = data.sameCount
        this.invitationCount = data.invitationCount
      });
    },
    /* 初始化 */
    handleInit() {
      this.loading = true;
      let myApi = this.currentKey == 1 ? userImageStorage : userCollectionList;
      myApi({ page: 1, limit: -1, prompt: this.searchKey })
        .then(async (res) => {
          let list = (res && res.rows) || [];
          if (this.currentKey == 2) {
            // 合集
            for (let i in list) {
              let result = await userCollectionImgList({
                collectionArticleId: list[i].id,
                page: 1,
                limit: 6
              }).catch((e) => {
              });
              list[i].IMGLIST = result.rows || [];
              list[i].IMGTOTAL = result.total;
            }
          }
          this.albumList = list;
          this.initAlbumList = JSON.parse(JSON.stringify(list));
          console.log("初始化", list);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /* 发布状态 */
    handleStatus(status) {
      JSON.stringify();
      if (status == 1) {
        this.albumList = this.initAlbumList.filter((v) => v.isOpen == 1);
      } else if (status == -1) {
        this.albumList = JSON.parse(JSON.stringify(this.initAlbumList));
      } else {
        this.albumList = this.initAlbumList.filter((v) => v.isOpen != 1);
      }
    },
    handleStatusAll(status) {
      if (status == 1) {
        this.albumList = this.initAlbumList.filter((v) => v.issue);
      } else if (status == -1) {
        this.albumList = JSON.parse(JSON.stringify(this.initAlbumList));
      } else {
        this.albumList = this.initAlbumList.filter((v) => !v.issue);
      }
    },

    /* 选择 */
    handleSelect(status) {
      if (this.currentKey == 1) {
        this.$refs.albumList.initStatus(status);
      }
      if (this.currentKey == 2) {
        this.$refs.albumCoverList.initStatus(status);
      }
    },

    /* 全部-加入合集 */
    handleAlbumListJoin(checkList) {
      this.$refs.albumCoverJoin.openJoinDialog(checkList);
    }
  }
};
</script>

<style lang="scss" scoped>

.albumCenter {
  width: 1402px;
  margin: -1.5rem auto;
  min-height: calc(100vh - 55px);

  .center-header {
    width: 100%;
    height: 50px;
    background-size: cover;
  }

  &-title {
    font-size: 40px;
    font-weight: bold;
    color: #3d3d3d;
    margin-right: 68px;
  }

  &-tab {
    font-size: 18px;
    font-weight: 500;
    color: #666666;
    margin-right: 35px;
    cursor: pointer;
  }
}

.flex_ {
  display: flex;
  align-items: center;
}

.flex_center_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.active_router {
  @apply font-black relative;
  color: #000;

  &::after {
    @apply block absolute right-0.5 -bottom-1 h-1;
    content: '';
    width: 20px;
    border-radius: 2.5px;
    background: #54c752;
  }
}
</style>
