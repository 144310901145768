<template>
  <shejiDialog v-model="visible" width="642px" height="884px">
    <div class="inviteShare">
      <div class="inviteShare-header">
        <div class="back" @click="close">
          <img class="icon" src="../assets/invite_right.svg" />
          <div>返回</div>
        </div>
        <div>邀请好友</div>
      </div>
      <div class="inviteShare-canvas" id="printPhoto">
        <img class="img" src="../assets/invite_canvas.png" />
        <img class="logo" src="../assets/invite_canvaslogo.png" />
        <div class="qrcode-container">
          <img class="qrcode" :src="qrcode" />
          <div class="text">长按识别进入设辑AI</div>
        </div>
      </div>
      <div class="inviteShare-button">
        <div class="item" @click="handleLink">
          <div class="image">
            <img src="../assets/invite_link.svg" />
          </div>
          <div>复制连接</div>
        </div>
        <div class="item" @click="handleSave">
          <div class="image">
            <img src="../assets/invite_photo.svg" />
          </div>
          <div>保存海报</div>
        </div>
      </div>
    </div>
  </shejiDialog>
</template>

<script>
import shejiDialog from '@/components/shejiDialog'
import { userInvitationQr, userInvitationCode } from '@/api/user/index.js'
export default {
  name: 'inviteShare',
  components: { shejiDialog },
  data() {
    return {
      visible: false,
      loading: false,
      qrcode: null
    }
  },
  methods: {
    open() {
      this.visible = true
      this.handleQrImg()
    },

    close() {
      this.visible = false
    },

    // 获取邀请二维码
    async handleQrImg() {
      let result = await userInvitationQr().catch((e) => {})
      if (result && result.code != 200) {
        return this.$message.error('获取邀请码失败')
      }
      this.qrcode = result.data.ercodeUrl
      console.log('邀请好友二维码', this.qrcode)
    },

    // 获取用户邀请码
    async handleLink() {
      let result = await userInvitationCode().catch((e) => {})
      if (result && result.code != 200) {
        return this.$message.error('获取邀请码失败')
      }
      this.handleCopy(`${location.host}/home?inviteCode=${result.data}`)
    },

    // 复制链接
    async handleCopy(text) {
      const oInput = document.createElement('input')
      oInput.value = text
      document.body.appendChild(oInput)
      oInput.select()
      document.execCommand('Copy')
      oInput.remove()
      this.$message.success('复制成功')
    },

    // 保存图片
    handleSave() {
      html2canvas(document.querySelector('#printPhoto')).then((canvas) => {
        var img = canvas.toDataURL('image/png')
        var link = document.createElement('a')
        link.href = img
        link.download = '邀请二维码.png'
        link.click()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.inviteShare {
  min-height: 684px;
  padding: 16px 18px;
  box-sizing: border-box;
  //   overflow-y: scroll;
  &-header {
    display: flex;
    align-items: center;
    font-size: 25px;
    font-weight: 500;
    color: #3d3d3d;
    .back {
      width: 85px;
      height: 40px;
      border-radius: 20px;
      background: #d8d8d8;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-right: 11px;
    }
    .icon {
      margin-right: 10px;
    }
  }
  &-canvas {
    margin: 20px auto;
    position: relative;
    .img {
      margin: 0 auto;
    }
    .logo {
      width: 101px;
      height: 96px;
      position: absolute;
      left: 50%;
      top: 96px;
      transform: translateX(-50%);
      z-index: 1;
    }
    .qrcode-container {
      left: 50%;
      bottom: 100px;
      transform: translateX(-50%);
      position: absolute;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .qrcode {
        width: 76px;
        height: 76px;
        background: #000;
        display: block;
      }
      .text {
        font-size: 10px;
        font-weight: 350;
        color: #000000;
        margin-top: 4px;
      }
    }
  }
  &-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    margin: 26px auto;
    .item {
      width: 64px;
      font-size: 12px;
      color: #3d3d3d;
      cursor: pointer;
    }
    .image {
      width: 64px;
      height: 64px;
      background: #f4f4f4;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 6px;
    }
    .item + .item {
      margin-left: 47px;
    }
  }
}
// .inviteShare::-webkit-scrollbar {
//   width: 4px; /*  设置纵轴（y轴）轴滚动条 */
// }
// .inviteShare::-webkit-scrollbar-thumb {
//   border-radius: 10px;
//   box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
//   background: #727272;
// }
// .inviteShare::-webkit-scrollbar-track {
//   background: #000;
// }
</style>