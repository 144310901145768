<template>
  <div class="albumCenter">
    <div class="center-header" style="display: flex;justify-content: center">
      <div class="center-header-content" style="padding: 50px 0px 150px;">
        <div class="center-header-img"
             style="width: 84px;height: 84px;overflow: hidden;border-radius: 100px;margin: 0px auto">
          <img style="width: 100%;height: 100%;" :src="userInfo.avatar" alt="">
        </div>
        <div
          style="font-weight: bold;line-height: 15px;text-align: center;letter-spacing: 0px;color: #FFFFFF;font-size: 24px;margin-top: 20px;">
          {{ userInfo.nickName }}
        </div>
        <div
          style="line-height: 10px;text-align: center;letter-spacing: 0px;color: #FFFFFF;font-size: 14px;margin-top: 20px;">
          已加入设辑128天
        </div>
      </div>
    </div>
    <div style="min-height: calc(100vh - 305px);width: 100%;background: #000">
      <div style="width: 100%;height: 10px"></div>
      <div style="background: #1D1D1F;border-radius: 20px;opacity: 1;width: 98%;margin: 0px auto;height: 195px;">
        <div
          style="font-size: 18px;font-family: Source Han Sans;font-weight: bold;line-height: 15px;letter-spacing: 0px;color: #FFFFFF;text-align: left;margin-left: 24px;padding-top: 24px;">
          AI创作服务
        </div>

        <div style="display: flex;align-items: center;justify-content: space-between;width: 98%;margin: 0px auto">

          <div
            @click="$router.push('/mylibrary')"
            style="background: #2C2C2C;opacity: 1;border-radius: 15px;width: 32%;height: 108px;display: flex;justify-content: center;align-items: center;margin-top: 26px;cursor: pointer">
            <div>
              <div style="display: flex;justify-content: center;">
                <img src="../../../assets/albumcenter/creator-icon.png" alt="" style="height: 22px;width: 22px">
              </div>
              <div
                style="margin-top: 16px;font-size: 14px;font-weight: normal;line-height: 10px;text-align: center;letter-spacing: 0px;color: #FFFFFF;font-family: Source Han Sans;">
                我的创作
              </div>
            </div>
          </div>

          <div
            @click="$router.push('/my/home')"
            style="background: #2C2C2C;opacity: 1;border-radius: 15px;width: 32%;height: 108px;display: flex;justify-content: center;align-items: center;margin-top: 26px;cursor: pointer">
            <div>
              <div style="display: flex;justify-content: center;">
                <img src="../../../assets/albumcenter/biji-icon.png" alt="" style="height: 22px;width: 22px">
              </div>
              <div
                style="margin-top: 16px;font-size: 14px;font-weight: normal;line-height: 10px;text-align: center;letter-spacing: 0px;color: #FFFFFF;font-family: Source Han Sans;">
                我的笔记
              </div>
            </div>
          </div>


          <div
            @click="$router.push('/my/home')"
            style="background: #2C2C2C;opacity: 1;border-radius: 15px;width: 32%;height: 108px;display: flex;justify-content: center;align-items: center;margin-top: 26px;cursor: pointer">
            <div>
              <div style="display: flex;justify-content: center;">
                <img src="../../../assets/albumcenter/send_icon.png" alt="" style="height: 22px;width: 22px">
              </div>
              <div
                style="margin-top: 16px;font-size: 14px;font-weight: normal;line-height: 10px;text-align: center;letter-spacing: 0px;color: #FFFFFF;font-family: Source Han Sans;border: 1px solid #FFFFFF;border-radius: 16px;padding:10px 26px">
                我要发布
              </div>
            </div>
          </div>

        </div>

      </div>


      <div style="background: #1D1D1F;border-radius: 20px;opacity: 1;width: 98%;margin: 35px auto;height: 300px;">
        <div
          style="font-size: 18px;font-family: Source Han Sans;font-weight: bold;line-height: 15px;letter-spacing: 0px;color: #FFFFFF;text-align: left;margin-left: 24px;padding-top: 24px;">
          数据中心
        </div>


        <div
          style="display: flex;align-items: center;justify-content: space-between;width: 98%;margin: 30px auto 20px;">
          <div style="width: 25%;border-right: 1px solid #404040">
            <div
              style="font-size: 14px;font-family: Source Han Sans;font-weight: normal;line-height: 10px;text-align: center;letter-spacing: 0px;color: #FFFFFF;margin-bottom: 20px;">
              {{ creatorCount }}
            </div>
            <div
              style="font-family: Source Han Sans;font-size: 14px;font-weight: normal;line-height: 10px;text-align: center;letter-spacing: 0px;color: #FFFFFF;">
              作画
            </div>
          </div>

          <div style="width: 25%;border-right: 1px solid #404040">
            <div
              style="font-size: 14px;font-family: Source Han Sans;font-weight: normal;line-height: 10px;text-align: center;letter-spacing: 0px;color: #FFFFFF;margin-bottom: 20px;">
              {{ articleCount }}
            </div>
            <div
              style="font-family: Source Han Sans;font-size: 14px;font-weight: normal;line-height: 10px;text-align: center;letter-spacing: 0px;color: #FFFFFF;">
              发布作品
            </div>
          </div>

          <div style="width: 25%;border-right: 1px solid #404040">
            <div
              style="font-size: 14px;font-family: Source Han Sans;font-weight: normal;line-height: 10px;text-align: center;letter-spacing: 0px;color: #FFFFFF;margin-bottom: 20px;">
              {{ sameCount }}
            </div>
            <div
              style="font-family: Source Han Sans;font-size: 14px;font-weight: normal;line-height: 10px;text-align: center;letter-spacing: 0px;color: #FFFFFF;">
              被同款
            </div>
          </div>

          <div style="width: 25%;">
            <div
              style="font-size: 14px;font-family: Source Han Sans;font-weight: normal;line-height: 10px;text-align: center;letter-spacing: 0px;color: #FFFFFF;margin-bottom: 20px;">
              {{ invitationCount }}
            </div>
            <div
              style="font-family: Source Han Sans;font-size: 14px;font-weight: normal;line-height: 10px;text-align: center;letter-spacing: 0px;color: #FFFFFF;">
              发布帖子
            </div>
          </div>
        </div>

        <div style="display: flex;align-items: center;justify-content: space-between;width: 98%;margin: 0px auto">

          <div
            @click="toCreatorDataView"
            style="background: #2C2C2C;opacity: 1;border-radius: 15px;width: 49%;height: 108px;display: flex;justify-content: center;align-items: center;margin-top: 26px;cursor: pointer">
            <div>
              <div style="display: flex;justify-content: center;">
                <img src="../../../assets/albumcenter/ai-data.png" alt="" style="height: 22px;width: 22px">
              </div>
              <div
                style="margin-top: 16px;font-size: 14px;font-weight: normal;line-height: 10px;text-align: center;letter-spacing: 0px;color: #FFFFFF;font-family: Source Han Sans;">
                AI创作数据
              </div>
            </div>
          </div>

          <div
            @click="$router.push('/albumCenter/fansData')"
            style="background: #2C2C2C;opacity: 1;border-radius: 15px;width: 49%;height: 108px;display: flex;justify-content: center;align-items: center;margin-top: 26px;cursor: pointer">
            <div>
              <div style="display: flex;justify-content: center;">
                <img src="../../../assets/albumcenter/fensi-icon.png" alt="" style="height: 22px;width: 22px">
              </div>
              <div
                style="margin-top: 16px;font-size: 14px;font-weight: normal;line-height: 10px;text-align: center;letter-spacing: 0px;color: #FFFFFF;font-family: Source Han Sans;">
                粉丝数据
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import albumList from "../components/albumList.vue";
import albumHeadRight from "../components/albumHeadRight.vue";
import albumCoverList from "../components/albumCoverList.vue";
import albumCoverForm from "../components/albumCoverForm.vue";
import albumCoverJoin from "../components/albumCoverJoin.vue";
import {
  userImageStorage,
  userCollectionList,
  userCollectionImgList
} from "@/api/album/index.js";
import {
  userBusinessCount
} from "@/api/user/statistics";

export default {
  name: "albumCenter",
  components: {
    albumList,
    albumHeadRight,
    albumCoverList,
    albumCoverForm,
    albumCoverJoin
  },
  data() {
    return {
      creatorCount: 0,
      articleCount: 0,
      sameCount: 0,
      invitationCount: 0,
      searchKey: null,
      albumList: [],
      initAlbumList: [],
      userInfo: {},
      currentKey: 1,
      loading: false
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log("userInfo===", this.userInfo);
    this.handleTab(1);
    this.getUserBusinessCount()
  },
  methods: {
    /**
     * 获取用户业务数据统计情况
     */
    getUserBusinessCount() {
      userBusinessCount({}).then(res => {
        const data = res.data
        this.creatorCount = data.aiCreatorCount
        this.articleCount = data.aiArticleCount
        this.sameCount = data.sameCount
        this.invitationCount = data.invitationCount
      });
    },
    toCreatorDataView() {
      this.$router.push('/albumCenter/creatorData')
    },
    /* 点击tab */
    handleTab(key) {
      this.currentKey = key;
      this.handleInit();
    },

    /* 搜索 */
    handleSearch(key) {
      this.searchKey = key;
      this.handleInit();
    },

    /* 初始化 */
    handleInit() {
      this.loading = true;
      let myApi = this.currentKey == 1 ? userImageStorage : userCollectionList;
      myApi({ page: 1, limit: -1, prompt: this.searchKey })
        .then(async (res) => {
          let list = (res && res.rows) || [];
          if (this.currentKey == 2) {
            // 合集
            for (let i in list) {
              let result = await userCollectionImgList({
                collectionArticleId: list[i].id,
                page: 1,
                limit: 6
              }).catch((e) => {
              });
              list[i].IMGLIST = result.rows || [];
              list[i].IMGTOTAL = result.total;
            }
          }
          this.albumList = list;
          this.initAlbumList = JSON.parse(JSON.stringify(list));
          console.log("初始化", list);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /* 发布状态 */
    handleStatus(status) {
      if (status == 1) {
        this.albumList = this.initAlbumList.filter((v) => v.isOpen == 1);
      } else if (status == -1) {
        this.albumList = JSON.parse(JSON.stringify(this.initAlbumList));
      } else {
        this.albumList = this.initAlbumList.filter((v) => v.isOpen != 1);
      }
    },
    handleStatusAll(status) {
      if (status == 1) {
        this.albumList = this.initAlbumList.filter((v) => v.issue);
      } else if (status == -1) {
        this.albumList = JSON.parse(JSON.stringify(this.initAlbumList));
      } else {
        this.albumList = this.initAlbumList.filter((v) => !v.issue);
      }
    },

    /* 选择 */
    handleSelect(status) {
      if (this.currentKey == 1) {
        this.$refs.albumList.initStatus(status);
      }
      if (this.currentKey == 2) {
        this.$refs.albumCoverList.initStatus(status);
      }
    },

    /* 全部-加入合集 */
    handleAlbumListJoin(checkList) {
      this.$refs.albumCoverJoin.openJoinDialog(checkList);
    }
  }
};
</script>

<style lang="scss" scoped>

.albumCenter {
  width: 1402px;
  margin: -1.5rem auto;
  min-height: calc(100vh - 55px);

  .center-header {
    background: url('@/assets/WechatIMG513.png');
    width: 100%;
    height: 250px;
    background-size: cover;
  }

  &-title {
    font-size: 40px;
    font-weight: bold;
    color: #3d3d3d;
    margin-right: 68px;
  }

  &-tab {
    font-size: 18px;
    font-weight: 500;
    color: #666666;
    margin-right: 35px;
    cursor: pointer;
  }
}

.flex_ {
  display: flex;
  align-items: center;
}

.flex_center_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.active_router {
  @apply font-black relative;
  color: #000;

  &::after {
    @apply block absolute right-0.5 -bottom-1 h-1;
    content: '';
    width: 20px;
    border-radius: 2.5px;
    background: #54c752;
  }
}
</style>
