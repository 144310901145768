
import { articleAttention, noArticleAttention } from '@/api/home'
import { likeArticle, notLikeArticle, followAuthor, cancelFollowAuthor } from '@/api/work'
export default {
    name: 'operateMixins',
    methods: {
        /* 关注/取消关注: isAtten */
        handleAttend(id, status, callback) {
            let myApi = status ? noArticleAttention : articleAttention
            myApi(id).then((res) => {
                if (res && res.code == 200) {
                    this.$message.success(res.msg)
                    callback && callback(!status)
                }
            })
        },

        /* 点赞/取消点赞: isLike */
        handleLike(id, status, callback) {
            let myApi = status ? notLikeArticle : likeArticle
            myApi(id).then((res) => {
                if (res && res.code == 200) {
                    this.$message.success(res.msg)
                    callback && callback(!status)
                }
            })
        },

        /* 关注作者/取消关注作者: isAttenCreator */
        handleFollow(id, status, callback) {
            let myApi = status ? cancelFollowAuthor : followAuthor
            myApi(id).then((res) => {
                if (res && res.code == 200) {
                    this.$message.success(res.msg)
                    callback && callback(!status)
                }
            })
        }
    }
}