<template>
  <div class="myOrderInfo">
    <!-- 电力券 -->
    <div class="myOrderInfo-wrap" v-if="orderType == 1">
      <div class="title">{{ orderInfo.powerCoupon || 0 }}电力券</div>
      <div class="item">
        <div>电力券生效时间：</div>
        <div class="content">{{ orderInfo.createTime }}</div>
      </div>
      <div class="item">
        <div>电力券到期时间：</div>
        <div class="content">{{ orderInfo.couponEndTime }}</div>
      </div>
    </div>
    <!-- 服务 -->
    <div class="myOrderInfo-wrap" v-if="orderType == 2">
      <div class="title">{{ orderInfo.serverItemTitle || '-' }}</div>
      <div class="item">
        <div>服务生效时间：</div>
        <div class="content">{{ orderInfo.serverStartTime }}</div>
      </div>
      <div class="item">
        <div>服务完成时间：</div>
        <div class="content">{{ orderInfo.serverEndTime }}</div>
      </div>
    </div>
    <!-- 会员 -->
    <div class="myOrderInfo-wrap" v-if="orderType == 3">
      <div class="title">
        <div v-if="orderInfo.vipLevel == 1" class="vip vip1">白银权益卡</div>
        <div v-if="orderInfo.vipLevel == 2" class="vip vip2">黄金权益卡</div>
        <div v-if="orderInfo.vipLevel == 3" class="vip vip3">铂金权益卡</div>
        <div class="content" style="margin-right: 20px">
          {{ orderInfo.remarkTitle }}
        </div>
      </div>
      <div class="item">
        <div>会员生效时间：</div>
        <div class="content">{{ orderInfo.updateTime }}</div>
      </div>
      <div class="item">
        <div>会员到期时间：</div>
        <div class="content">{{ orderInfo.vipEndTime }}</div>
      </div>
    </div>

    <div class="myOrderInfo-wrap">
      <div class="item">
        <div>订单编号：</div>
        <div v-if="orderType == 2" class="content">{{ orderInfo.orderNo }}</div>
        <div v-else class="content">{{ orderInfo.payPlateformNo }}</div>
      </div>
      <div class="item">
        <div>金额：</div>
        <div class="content" v-if="orderType == 1">
          ￥{{ orderInfo.payMoney }} 元
        </div>
        <div class="content" v-if="orderType == 2">
          ￥{{ orderInfo.totalPrice }} 元
        </div>
        <div class="content" v-if="orderType == 3">
          ￥{{ orderInfo.totalMoney }} 元
        </div>
      </div>
      <div class="item">
        <div>下单时间：</div>
        <div class="content">{{ orderInfo.createTime }}</div>
      </div>
      <div class="item">
        <div>支付时间：</div>
        <div class="content" v-if="orderType == 2">{{ orderInfo.payTime }}</div>
        <div class="content" v-else>{{ orderInfo.updateTime }}</div>
      </div>
      <div class="item">
        <div>支付方式：</div>
        <div class="content" v-if="orderType == 2">
          {{ orderInfo.channelName }}
        </div>
        <div class="content" v-else>
          {{ orderInfo.payChannelName }}
        </div>
      </div>
      <div class="item">
        <div>收款方：</div>
        <div class="content">{{ orderInfo.payee }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  userPayDetails,
  userOrderDetails,
  userServerItemOrderDetails
} from '@/api/user/myOrder.js'
export default {
  name: 'myOrderInfo',
  data() {
    return {
      orderId: null,
      orderType: null,
      orderInfo: {},
      orderConfig: {
        1: {
          api: userOrderDetails
        },
        2: {
          api: userServerItemOrderDetails
        },
        3: {
          api: userPayDetails
        }
      }
    }
  },
  mounted() {
    this.orderId = this.$route.query.id
    this.orderType = this.$route.query.type
    this.handleInit()
  },
  methods: {
    handleInit() {
      if (!this.orderId || !this.orderType) return
      let orderConfig = this.orderConfig[this.orderType]
      orderConfig.api(this.orderId).then((res) => {
        let info = (res && res.data) || {}
        this.orderInfo = info
        console.log(`订单详情${this.orderType}`, this.orderInfo)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.myOrderInfo {
  width: 1396px;
  margin: 0 auto;
  &-wrap {
    width: 100%;
    min-height: 143px;
    border-radius: 10px;
    background: #f7f7f7;
    padding: 17px;
    box-sizing: border-box;
  }
  &-wrap + &-wrap {
    margin-top: 13px;
  }
  .title {
    font-size: 16px;
    font-weight: 900;
    color: #000000;
    padding-bottom: 16px;
    box-sizing: border-box;
    border-bottom: 1px solid #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 23px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
    color: #b0b0b0;
    margin-top: 16px;
  }
  .content {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
  }
  .vip {
    width: 100px;
    height: 30px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .vip1 {
    color: #7b91a5;
    background: radial-gradient(55% 55% at 13% 5%, #edf0f1 0%, #c9d9de 100%);
  }
  .vip2 {
    color: #925e32;
    background: radial-gradient(123% 123% at 0% 10%, #ffecc1 0%, #eaa771 99%);
  }
  .vip3 {
    color: #885dd6;
    background: linear-gradient(104deg, #f7f4ff -8%, #d3bafc 38%, #ab94fe 87%);
  }
}
</style> 