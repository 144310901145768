<template>
  <div class="myServiceInfo">
    <div class="myServiceInfo-left">
      <img class="myServiceInfo-cover" :src="serviceInfo.serverImgUrl" />
      <div class="myServiceInfo-button">
        <!-- 待支付、进行中 -->
        <el-button
          class="single button"
          v-if="[1, 2].includes(serviceInfo.orderStatus)"
          type="success"
          disabled
          >确认完成</el-button
        >
        <!-- 待确认完成 -->
        <el-button
          v-if="serviceInfo.orderStatus == 3"
          type="danger"
          class="double button"
          @click="handleDelay"
          >延长 7 天确认</el-button
        >
        <el-button
          type="success"
          class="double button"
          @click="confirmVisible = true"
          v-if="serviceInfo.orderStatus == 3"
          >确认完成</el-button
        >
      </div>
      <div class="myServiceInfo-enval" v-if="serviceInfo.orderStatus > 3">
        <div class="title">服务综合评分</div>
        <div class="item">
          <div class="name">专业能力：</div>
          <el-rate
            v-model="evalInfo.professionalLevel"
            :disabled="!(serviceInfo.orderStatus == 4)"
          ></el-rate>
        </div>
        <div class="item">
          <div class="name">服务效率：</div>
          <el-rate
            v-model="evalInfo.efficiencyServiceLevel"
            :disabled="!(serviceInfo.orderStatus == 4)"
          ></el-rate>
        </div>
        <div class="item">
          <div class="name">服务态度：</div>
          <el-rate
            v-model="evalInfo.serviceAttitudeLevel"
            :disabled="!(serviceInfo.orderStatus == 4)"
          ></el-rate>
        </div>
        <div class="title">服务评价</div>
        <el-input
          clearable
          type="textarea"
          placeholder="请对本次服务做出评价"
          v-model="evalInfo.content"
          maxlength="200"
          show-word-limit
          :autosize="{ minRows: 5, maxRows: 6 }"
          :disabled="!(serviceInfo.orderStatus == 4)"
        ></el-input>
        <div class="item" style="margin-top: 6px">
          <el-checkbox
            v-model="evalInfo.isAnonymity"
            :disabled="!(serviceInfo.orderStatus == 4)"
            >匿名发表评价</el-checkbox
          >
        </div>
        <div
          class="myServiceInfo-button"
          style="margin-top: 29px"
          v-if="serviceInfo.orderStatus == 4"
        >
          <el-button class="double button" type="info" @click="$router.back()"
            >放弃</el-button
          >
          <el-button type="success" class="double button" @click="handleEval"
            >确认评价</el-button
          >
        </div>
      </div>
    </div>

    <div class="myServiceInfo-right">
      <img
        class="avatar"
        :src="serviceInfo.providerUser && serviceInfo.providerUser.avatar"
      />
      <div>
        <div class="item">
          <div class="name">服 务 商：</div>
          <div class="content">
            {{ serviceInfo.providerUser && serviceInfo.providerUser.nickName }}
          </div>
          <img class="icon" src="@/assets/collection/arrow-right.svg" />
        </div>
        <div class="item">
          <div class="name">服务项目：</div>
          <div class="content">
            {{ serviceInfo.serverItemTitle || '-' }}
          </div>
          <img class="icon" src="@/assets/collection/arrow-right.svg" />
        </div>
        <div class="item">
          <div class="name">服务价格：</div>
          <div class="content">¥ {{ serviceInfo.singlePrice || 0 }}元/款</div>
        </div>
        <div class="item">
          <div class="name">服务数量：</div>
          <div class="content">{{ serviceInfo.payCount || 0 }} 款</div>
        </div>
        <div class="item" style="margin-bottom: 0">
          <div class="name">实际支付：</div>
          <div class="content price">¥ {{ serviceInfo.totalPrice || 0 }}元</div>
        </div>
        <div class="active">
          <img class="succee" src="@/assets/service/succee_icon.svg" />
          <div>资金由平台监管，确认服务完成后结算</div>
        </div>
        <div class="item">
          <div class="name">支付类型：</div>
          <div class="content">{{ serviceInfo.channelName }}</div>
        </div>
        <div class="item">
          <div class="name">服务单号：</div>
          <div class="content">{{ serviceInfo.orderNo }}</div>
        </div>
        <div class="item">
          <div class="name">支付时间：</div>
          <div class="content">{{ serviceInfo.payTime }}</div>
        </div>
        <div class="item">
          <div class="name">开始时间：</div>
          <div class="content">{{ serviceInfo.createTime }}</div>
        </div>
        <div class="item">
          <div class="name">服务对接：</div>
          <div class="content">{{ serviceInfo.serverWay }}</div>
        </div>
        <div class="item">
          <div class="name">交付周期：</div>
          <div class="content">{{ serviceInfo.serverPeriod || 0 }}天/款</div>
        </div>
        <div class="item" style="align-items: flex-start">
          <div class="name">服务状态：</div>
          <div v-if="serviceInfo.orderStatus == 1" class="content status">
            待支付
          </div>
          <div v-if="serviceInfo.orderStatus == 2" class="content status">
            进行中
          </div>
          <div v-if="serviceInfo.orderStatus == 3" class="content status">
            服务商任务已提交，请尽快确认是否完成。
            <div>
              （订单将在{{
                serviceInfo.residueConfirmDay || 0
              }}天后自动完成确认）
            </div>
          </div>
          <div v-if="serviceInfo.orderStatus == 4" class="content status">
            待评价
          </div>
          <div v-if="serviceInfo.orderStatus == 5" class="content status">
            已完成
          </div>
        </div>
        <div class="item">
          <div class="name">完成时间：</div>
          <div class="content">{{ serviceInfo.serverEndTime }}</div>
        </div>
        <div
          v-if="serviceInfo.orderStatus < 4"
          class="name"
          style="margin-bottom: 21px"
        >
          备 注：
        </div>
        <el-input
          clearable
          type="textarea"
          placeholder="请输入备注"
          v-model="serviceInfo.orderRemark"
          maxlength="200"
          show-word-limit
          :autosize="{ minRows: 5, maxRows: 6 }"
          :disabled="!(serviceInfo.orderStatus == 3)"
          v-if="serviceInfo.orderStatus < 4"
        ></el-input>
        <div class="item" v-else>
          <div class="name">备 注：</div>
          <div class="content">{{ serviceInfo.orderRemark }}</div>
        </div>
      </div>
    </div>

    <div class="myServiceInfo-line" v-if="serviceInfo.orderStatus > 3"></div>

    <comfirmDialog
      v-model="confirmVisible"
      cancelTxt="放弃"
      confirmTxt="确认完成"
      content="请确认您已经接收到服务商提供的相应服务后点击“确认完成”完成本次服务订单。"
      @confirm="handleComplete"
    ></comfirmDialog>
  </div>
</template>

<script>
import {
  evaluateOrder,
  confirmServerOrder,
  userPostponeServerOrder,
  userServerItemOrderDetails
} from '@/api/user/myService.js'
import comfirmDialog from '@/components/comfirmDialog/index.vue'
export default {
  name: 'myServiceInfo',
  components: { comfirmDialog },
  data() {
    return {
      serviceId: null,
      evalInfo: { isAnonymity: true },
      serviceInfo: {},
      loading: false,
      confirmVisible: false
    }
  },
  mounted() {
    this.serviceId = this.$route.query.id
    this.handleInit()
  },
  methods: {
    handleInit() {
      if (!this.serviceId) return
      this.loading = true
      userServerItemOrderDetails(this.serviceId)
        .then((res) => {
          let info = (res && res.data) || {}
          this.serviceInfo = JSON.parse(JSON.stringify(info))
          console.log('服務詳情', this.serviceInfo)
          this.$forceUpdate()
        })
        .finally(() => {
          this.loading = false
        })
    },

    handleDelay() {
      userPostponeServerOrder({ orderId: this.serviceId }).then((res) => {
        this.$message.success(res.msg)
        this.handleInit()
      })
    },

    handleComplete() {
      confirmServerOrder({
        orderId: this.serviceId,
        remark: this.serviceInfo.orderRemark
      }).then((res) => {
        this.confirmVisible = false
        this.$message.success(res.msg)
        this.handleInit()
      })
    },

    handleEval() {
      let query = JSON.parse(JSON.stringify(this.evalInfo))
      query.serverOrderId = this.serviceId
      query.isAnonymity = query.isAnonymity ? 1 : 0
      evaluateOrder(query).then((res) => {
        this.$message.success(res.msg)
        this.handleInit()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.myServiceInfo {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  &-left {
    width: 573px;
    margin-right: 81px;
  }
  &-cover {
    width: 100%;
    height: 573px;
    border-radius: 14px;
    background: #bababa;
  }
  &-right {
    display: flex;
    align-items: flex-start;
    .avatar {
      width: 53px;
      height: 53px;
      border-radius: 50%;
      margin-right: 19px;
    }
    .item {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
    }
    .name {
      width: 81px;
      font-size: 16px;
      color: #b0b0b0;
      margin-right: 6px;
    }
    .content {
      font-size: 16px;
      color: #000000;
      width: 165px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
    .icon {
      margin-left: 35px;
      flex-shrink: 0;
    }
    .price {
      color: #e35141;
    }
    .active {
      margin-top: 10px;
      width: 296px;
      height: 30px;
      border-radius: 4px;
      background: #eef8ed;
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: 500;
      color: #04a101;
    }
    .succee {
      width: 22px;
      height: 22px;
      flex-shrink: 0;
      margin-right: 11px;
    }
    .status {
      color: #0033ff;
      white-space: break-spaces;
      width: max-content;
    }
  }
  &-button {
    margin-top: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    .single {
      width: 389px;
    }
    .double {
      width: 270px;
    }
    .button {
      height: 54px;
      border-radius: 27px;
      font-size: 18px;
      font-weight: bold;
    }
  }
  &-enval {
    width: 100%;
    text-align: left;
    .title {
      font-size: 16px;
      color: #000000;
      margin-top: 25px;
      margin-bottom: 15px;
    }
    .item {
      display: flex;
      align-items: center;
      margin-bottom: 2px;
    }
    .name {
      font-size: 16px;
      color: #666666;
      margin-right: 9px;
    }
  }
  &-line {
    width: 100%;
    height: 7px;
    background: #f7f7f7;
    position: absolute;
    top: 633px;
  }
  ::v-deep .el-textarea__inner {
    border-radius: 14px;
    background: #f4f5f7;
    border: none;
    padding: 14px;
    box-sizing: border-box;
  }
  ::v-deep .el-textarea .el-input__count {
    background: #f4f5f7;
  }
  ::v-deep .el-button--info {
    color: #ffffff;
    background: #b0b0b0;
    border-color: #b0b0b0;
  }
  ::v-deep .el-button--success.is-disabled,
  .el-button--success.is-disabled:active,
  .el-button--success.is-disabled:focus,
  .el-button--success.is-disabled:hover {
    border: none;
    color: #ffffff;
    background: #d8d8d8;
  }
  ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #8f8f8f;
  }
  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    border-color: #54c752;
    background-color: #54c752;
  }
}
</style>