<template>
  <div class="recommend-list">
    <div
      class="recommend-item cursor-pointer"
      v-for="(item, index) in list"
      :key="index"
      @click="$router.push(`/service/detail/${item.id}`)"
    >
      <PreviewCard
        :sourceId="item.id"
        :cover="item.coverImgUrl"
        :tongkuanImgId="item.coverImgId"
        :title="item.title"
        :price="9.9"
        :service="10"
        @showErrMsg="dialogVisible = true"
      />
    </div>

    <!-- dialog -->
    <comfirmDialog
      v-model="dialogVisible"
      confirmTxt="立即购买"
      @confirm="handleBuy"
    >
      <div>您当前“电力券”数量不足，</div>
      <div>无法提交此次生成任务。</div>
    </comfirmDialog>
  </div>
</template>

<script>
import { findMiniList } from '@/api/home'
import PreviewCard from '@/components/previewCard'
import comfirmDialog from '@/components/comfirmDialog/index.vue'

export default {
  name: 'recommendList',
  components: {
    PreviewCard,
    comfirmDialog
  },
  data() {
    return {
      list: [],
      dialogVisible: false
    }
  },
  methods: {
    // 根据当前类型获取列表数据
    async getList() {
      const result = await findMiniList({
        page: 1
      })
      const rows = result.rows || []
      this.list = rows.slice(0, 4)
    },

    /* 立即购买 */
    handleBuy() {
      this.$router.push('/my/electron')
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style lang="scss" scoped>
.recommend-list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 40px;
  .recommend-item {
    margin-bottom: 48px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>