<template>
  <div>
    <el-popover
      placement="bottom-start"
      width="430"
      trigger="manual"
      v-model="visible"
    >
      <div>
        <div
          class="flex items-center px-2"
          style="border-bottom: 1px solid #d8d8d8; padding: 17px 0px"
        >
          <div
            style="
              color: #000000;
              letter-spacing: 0px;
              font-family: 思源黑体;
              font-size: 18px;
              font-weight: 900;
              line-height: 20px;
            "
          >
            消息中心
          </div>
        </div>
        <div style="padding: 25px 25px; border-bottom: 1px solid #d8d8d8">
          <div style="display: flex; justify-content: space-between">
            <div
              style="text-align: center; cursor: pointer"
              @click="$router.push(`/my/messageCenter?tab=1`)"
            >
              <div style="width: 56px; height: 56px">
                <img
                  src="@/assets/message-like-icon.png"
                  alt=""
                  style="width: 100%; height: 100%"
                />
              </div>
              <div style="margin-top: 10px">赞和收藏</div>
            </div>

            <div
              style="text-align: center; cursor: pointer"
              @click="$router.push(`/my/messageCenter?tab=2`)"
            >
              <div style="width: 56px; height: 56px">
                <img
                  src="@/assets/message-atten-icon.png"
                  alt=""
                  style="width: 100%; height: 100%"
                />
              </div>
              <div style="margin-top: 10px">关注</div>
            </div>

            <div
              style="text-align: center; cursor: pointer"
              @click="$router.push(`/my/messageCenter?tab=3`)"
            >
              <div style="width: 56px; height: 56px">
                <img
                  src="@/assets/message-same-icon.png"
                  alt=""
                  style="width: 100%; height: 100%"
                />
              </div>
              <div style="margin-top: 10px">被同款</div>
            </div>

            <div
              style="text-align: center; cursor: pointer"
              @click="$router.push(`/my/messageCenterComment`)"
            >
              <div style="width: 56px; height: 56px">
                <img
                  src="@/assets/message-icon-message.png"
                  alt=""
                  style="width: 100%; height: 100%"
                />
              </div>
              <div style="margin-top: 10px">评论</div>
            </div>
          </div>
        </div>

        <div style="padding: 25px 25px">
          <div
            style="
              display: flex;
              align-items: center;
              margin-bottom: 30px;
              cursor: pointer;
            "
            v-if="lastAnnouncement && lastAnnouncement.noticeId"
            @click="$refs.msgInfoPop.open({ type: 1 })"
          >
            <div style="width: 50px; height: 50px">
              <img
                src="@/assets/message-logo-icon.png"
                alt=""
                style="width: 100%; height: 100%"
              />
            </div>

            <div style="margin-left: 14px">
              <div style="display: flex; justify-content: space-between">
                <div
                  style="
                    color: #000000;
                    font-family: 思源黑体;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 20px;
                  "
                >
                  官方公告
                </div>
                <div>
                  {{ lastAnnouncement.createTime }}
                </div>
              </div>

              <div
                style="
                  font-family: OPPOSans;
                  font-size: 14px;
                  font-weight: normal;
                  line-height: 20px;
                  letter-spacing: 0px;
                  color: #3d3d3d;
                  margin-top: 10px;
                "
              >
                {{ lastAnnouncement.noticeTitle }}
              </div>
            </div>
          </div>

          <div
            style="
              display: flex;
              align-items: center;
              margin-bottom: 30px;
              cursor: pointer;
            "
            v-if="userLastEarning && userLastEarning.id"
            @click="$refs.msgInfoPop.open({ type: 2 })"
          >
            <div style="width: 50px; height: 50px">
              <img
                src="@/assets/message-coupon-icon.png"
                alt=""
                style="width: 100%; height: 100%"
              />
            </div>

            <div style="margin-left: 14px">
              <div style="display: flex; justify-content: space-between">
                <div
                  style="
                    color: #000000;
                    font-family: 思源黑体;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 20px;
                  "
                >
                  我的收益
                </div>
                <div>
                  {{
                    dayjs(userLastEarning.createTime).format(
                      'YYYY-MM-DD HH:mm:ss'
                    )
                  }}
                </div>
              </div>

              <div
                style="
                  font-family: OPPOSans;
                  font-size: 14px;
                  font-weight: normal;
                  line-height: 20px;
                  letter-spacing: 0px;
                  color: #3d3d3d;
                  margin-top: 10px;
                "
              >
                本次上新3款模型，包括精彩模型和漫画模型...
              </div>
            </div>
          </div>

          <div
            style="
              display: flex;
              align-items: center;
              margin-bottom: 30px;
              cursor: pointer;
            "
            v-if="lastServiceNotice && lastServiceNotice.id"
            @click="$refs.msgInfoPop.open({ type: 3 })"
          >
            <div style="width: 50px; height: 50px">
              <img
                src="@/assets/message-server-icon.png"
                alt=""
                style="width: 100%; height: 100%"
              />
            </div>

            <div style="margin-left: 14px">
              <div style="display: flex; justify-content: space-between">
                <div
                  style="
                    color: #000000;
                    font-family: 思源黑体;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 20px;
                  "
                >
                  服务反馈
                </div>
                <div>
                  {{ lastServiceNotice.createTime }}
                </div>
              </div>

              <div
                style="
                  font-family: OPPOSans;
                  font-size: 14px;
                  font-weight: normal;
                  line-height: 20px;
                  letter-spacing: 0px;
                  color: #3d3d3d;
                  margin-top: 10px;
                "
              >
                {{ lastServiceNotice.noticeContent }}
              </div>
            </div>
          </div>

          <div
            style="
              display: flex;
              align-items: center;
              margin-bottom: 30px;
              cursor: pointer;
            "
            v-if="aiGeneratorLastResult && aiGeneratorLastResult.id"
            @click="$refs.msgInfoPop.open({ type: 4 })"
          >
            <div style="width: 50px; height: 50px">
              <img
                src="@/assets/message-creator-icon.png"
                alt=""
                style="width: 100%; height: 100%"
              />
            </div>

            <div style="margin-left: 14px">
              <div style="display: flex; justify-content: space-between">
                <div
                  style="
                    color: #000000;
                    font-family: 思源黑体;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 20px;
                  "
                >
                  AI生成
                </div>
                <div>
                  {{ aiGeneratorLastResult.createTime }}
                </div>
              </div>

              <div
                style="
                  font-family: OPPOSans;
                  font-size: 14px;
                  font-weight: normal;
                  line-height: 20px;
                  letter-spacing: 0px;
                  color: #3d3d3d;
                  margin-top: 10px;
                "
              >
                本次上新3款模型，包括精彩模型和漫画模型...
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        src="@/assets/notification.svg"
        class="cursor-pointer ml-4"
        slot="reference"
        @click.stop="visible = true"
      />
    </el-popover>
    <msgInfoPop ref="msgInfoPop"></msgInfoPop>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import {
  getUserGeneratorLastResult,
  getLastServiceNotice,
  getLastAnnouncement,
  getUserEarningList
} from '@/api/message/index'
import msgInfoPop from './msgInfoPop.vue'
export default {
  name: 'msgPopover',
  components: { msgInfoPop },
  data() {
    return {
      dayjs: dayjs,
      visible: false,
      // 最后一次ai生成记录
      aiGeneratorLastResult: {},
      // 最新一条服务反馈公告
      lastServiceNotice: {},
      // 最新一条公告信息
      lastAnnouncement: {},
      // 最新的收益信息
      userLastEarning: {}
    }
  },
  mounted() {
    document.addEventListener(
      'click',
      () => {
        this.visible = false
      },
      true
    )
  },
  beforeDestroy() {
    document.removeEventListener(
      'click',
      () => {
        this.visible = false
      },
      true
    )
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          this.handleInit()
        }
      }
    }
  },
  methods: {
    handleInit() {
      this.userEarningList()
      this.userGeneratorLastResult()
      this.userLastServiceNotice()
      this.userLastAnnouncement()
    },

    userGeneratorLastResult() {
      getUserGeneratorLastResult().then((res) => {
        this.aiGeneratorLastResult = (res && res.data) || {}
      })
    },

    userLastServiceNotice() {
      getLastServiceNotice().then((res) => {
        this.lastServiceNotice = (res && res.data) || {}
      })
    },

    userLastAnnouncement() {
      getLastAnnouncement().then((res) => {
        this.lastAnnouncement = (res && res.data) || {}
      })
    },
    userEarningList() {
      getUserEarningList({ pageNum: 1, pageSize: 1 }).then((res) => {
        if (res && res.rows && res.rows.length > 0) {
          this.userLastEarning = res.rows[0]
        }
      })
    }
  }
}
</script>

<style>
</style>