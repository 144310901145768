import service from "@/utils/require"

// 查询全部图库
export const userImageStorage = (params) => service({
    url: '/userImageStorage/getPageList',
    method: 'get',
    params
})

// 删除图库
export const delUserImageStorage = (ids) => {
    return service.post("/userImageStorage/delUserImageStorage", {ids})
}

// 查询用户自身合集列表
export const userCollectionList = (params) => service({
    url: '/collectionArticle/userCollectionList',
    method: 'get',
    params
})

// 分页查询合集中图片列表
export const userCollectionImgList = (params) => service({
    url: '/collectionArticle/userCollectionImgList',
    method: 'get',
    params
})

// 获取分类列表
export const articleClassify = (params={page: 1, limit: -1}) => service({
    url: '/articleClassify/list',
    method: 'get',
    params
})

// 用户创建合集
export const addCollection = (params) => {
    return service.post("/collectionArticle/addCollection", params)
}
 

// 编辑合集
export const editCollection = (params) => {
    return service.post("/collectionArticle/editCollection", params)
}

// 批量删除合集
export const delCollections = (params) => {
    return service.post("/collectionArticle/delCollections", params)
}

// 查询合集详情（不包含合集内的图片列表）
export const collectionDetails = (collectionId) => service({
    url: `collectionArticle/collectionDetails/${collectionId}`,
    method: 'get'
})

// 往合集中批量添加图片
export const batchAddImg = (collectionId, params) => {
    return service.post(`/collectionArticle/batchAddImg/${collectionId}`, params)
}

// 合集设置封面
export const collectionCoverImg = (collectionId, coverImgId) => {
    return service.post(`/collectionArticle/collectionCoverImg/${collectionId}/${coverImgId}`)
}

// 从合集从批量移除图片
export const batchRemoveImg = (collectionId, params) => {
    return service.post(`/collectionArticle/batchRemoveImg/${collectionId}`, params)
}

// 一键同款
export const createSameAiJob = (imageStorageId) => {
    return service.post(`/goapiJob/createSameAiJob/${imageStorageId}`)
}
