<template>
  <div>
    <el-row :gutter="16" class="h-[380px]">
      <el-col :span="14" :xl="14" class="h-[380px]">
        <el-carousel height="380px" indicator-position="inside">
          <el-carousel-item v-for="item in imgsList" :key="item.id">
            <img
              :src="item.slideshowImg"
              alt=""
              class="w-full h-[380px] select-none rounded-xl"
            />
          </el-carousel-item>
        </el-carousel>
      </el-col>
      <el-col :span="6" :xl="6" class="flex flex-col justify-around h-[380px]">
        <img
          src="@/assets/home/WechatIMG298.png"
          class="cursor-pointer w-full h-[140px] object-fill"
        />
        <img
          src="@/assets/home/WechatIMG826.png"
          class="cursor-pointer w-full h-[140px] object-fill"
        />
        <img
          src="@/assets/home/3.svg"
          class="cursor-pointer w-full h-[140px] object-fill"
        />
      </el-col>
      <el-col :span="4" :xl="4" class="min-w-[283px]">
        <userCard></userCard>
      </el-col>
    </el-row>

    <el-row :gutter="16">
      <div class="mt-10 flex min-w-80 justify-center">
        <template>
          <div
            class="mx-14 pb-2 cursor-pointer text_gray"
            v-for="(item, key) in tabList"
            :key="key"
            :class="{ active_router: key === currentKey }"
            @click="tabClick(key)"
          >
            <div class="text-2xl">
              {{ item.title }}
            </div>
          </div>
        </template>
      </div>
    </el-row>

    <el-row :gutter="16">
      <div class="mt-5 flex min-w-80 justify-center relative">
        <template>
          <div
            class="mx-3 cursor-pointer text_gray"
            v-for="(item, index) in typeList"
            :key="index"
            :class="{ active_index: index === typeIndex }"
            @click="typeClick(index)"
          >
            <div class="text-lg">
              {{ item.name }}
            </div>
          </div>
        </template>
        <template>
          <div class="absolute right-0 absolute_center flex justify-center">
            <div
              class="ml-6 cursor-pointer text_gray"
              v-for="(item, index) in sortList"
              :key="index"
              :class="{ active_index: index === sortIndex }"
            >
              <div class="text-base" :class="{ sort_custom: item.sortType }">
                {{ item.name }}
                <template v-if="item.sortType">
                  <div class="triangle-top"></div>
                  <div class="triangle-bottom"></div>
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>
    </el-row>

    <div class="mt-7">
      <scrollContent />
    </div>
  </div>
</template>

<script>
import { getSlideShowList } from '@/api/common'
import userCard from '../home/component/userCard.vue'
import scrollContent from './components/scrollContent.vue'
export default {
  name: 'service',
  components: { userCard, scrollContent },
  data() {
    return {
      imgsList: [],
      tabList: [
        { title: '设计在线' },
        { title: '样品制作' },
        { title: '配件供应商' }
      ],
      typeList: [
        {
          name: '鞋子'
        },
        {
          name: '服装'
        }
        // {
        //   name: '箱包'
        // },
        // {
        //   name: '珠宝'
        // },
        // {
        //   name: '商标'
        // },
        // {
        //   name: '广告'
        // }
      ],
      currentKey: 0,
      typeIndex: 0,
      sortList: [
        {
          name: '推荐'
        },
        {
          name: '最新'
        },
        {
          name: '热门',
          // 1 升序； 2 降序
          sortType: 1
        }
      ],
      sortIndex: 0
    }
  },
  mounted() {
    this.getImgs()
  },
  methods: {
    // 获取轮播图
    async getImgs() {
      const result = await getSlideShowList(1)
      this.imgsList = result?.rows || []
    },

    tabClick(key) {
      if (this.currentKey === key) {
        return
      }
      this.currentKey = key
    },

    typeClick(index) {
      if (this.typeIndex === index) {
        return
      }
      this.typeIndex = index
    },

    sortClick(item, index) {
      if (this.sortIndex === index) {
        if (item.sortType) {
          item.sortType = item.sortType === 1 ? 2 : 1
        }
        return
      }
      this.sortIndex = index
    }
  }
}
</script>

<style lang="scss" scoped>
.text_gray {
  color: #a09e9f;
}
.active_router {
  @apply font-black relative;
  color: #000;
  &::after {
    @apply block absolute right-0.5 bottom-0.5 h-1;
    content: '';
    width: 20px;
    border-radius: 2.5px;
    background: #54c752;
  }
}
.active_index {
  @apply font-black;
  color: #000;
}
.absolute_center {
  top: 50%;
  transform: translateY(-50%);
}
.sort_custom {
  @apply relative;
  padding-right: 12px;
  .triangle-top,
  .triangle-bottom {
    @apply absolute right-0;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }
  .triangle-top {
    @apply top-1;
    border-bottom: 6px solid #a09e9f;
  }
  .triangle-bottom {
    @apply bottom-1;
    border-top: 6px solid #a09e9f;
  }
}
::v-deep .el-input__inner {
  @apply rounded-3xl;
}
</style>