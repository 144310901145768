import service from "@/utils/require"

// 分页查询用户所购买的服务列表
export const userServerItemOrderList = (params) => service({
    url: '/serverItemOrder/userServerItemOrderList',
    method: 'get',
    params
})

// 查询用户购买的服务订单详情
export const userServerItemOrderDetails = (orderId) => service({
    url: `/serverItemOrder/userServerItemOrderDetails/${orderId}`,
    method: 'get'
})

// 用户删除自身的服务订单
export const userDelServerOrder = (orderId) => {
    return service.delete(`/serverItemOrder/userDelServerOrder/${orderId}`)
}

// 用户确认订单完成
export const confirmServerOrder = (params) => {
    return service.post("/serverItemOrder/confirmServerOrder", params)
}

// 用户延长7天确认订单
export const userPostponeServerOrder = (params) => {
    return service.post("/serverItemOrder/userPostponeServerOrder", params)
}

// 用户进行订单评价
export const evaluateOrder = (params) => {
    return service.post("/serverItemOrder/evaluateOrder", params)
}