import service from "@/utils/require"

// 切换用户成团队子账号
export const changeTeamType = () => {
    return service.post("/system/user/changeTeamType", {})
}

// 切换用户成个人账号
export const changeUserOwnType = () => {
    return service.post("/system/user/changeUserOwnType", {})
}

// 获取团队成员分页列表
export const teamUserList = (params = { page: 1, limit: -1 }) => service({
    url: `/userTeam/teamUserList`,
    method: 'get',
    params
})

// 团队用户数量统计
export const teamUserCountStatistics = () => service({
    url: `/userTeam/teamUserCountStatistics`,
    method: 'get'
})

// 创建会员邀请二维码
export const createTeamInviteQR = () => {
    return service.post("/userTeam/createTeamInviteQR", {})
}

// 编辑团队会员信息
export const editTeamUser = (params) => {
    return service.put("/userTeam/editTeamUser", params)
}

// 分页获取团队中指定用户生成的记录列表
export const userCreationList = (params) => service({
    url: `/userTeam/userCreationList`,
    method: 'get',
    params
})

// 分页获取团队中指定用户发布了的记录列表
export const userIssueCreation = (params) => service({
    url: `/userTeam/userIssueCreation`,
    method: 'get',
    params
})

// 删除团队会员
export const removeTeamUser = (teamUserId) => {
    return service.delete(`/userTeam/removeTeamUser/${teamUserId}`, {})
}

// 用户自身退出团队
export const userQuitTeam = () => {
    return service.delete(`/userTeam/userQuitTeam`, {})
}