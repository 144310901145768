<template>
  <div class="service-info-wrap">
    <div v-html="content"></div>
  </div>
</template>

<script>
export default {
  name: 'ServiceInfo',
  props: {
    content: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.service-info-wrap {
  text-align: left;
  font-size: 18px;
  color: #444;
}
</style>