<template>
  <div v-if="visible">
    <div
      class="z-[999] fixed top-0 left-0 w-full h-screen overflow-hidden bg-[rgba(0,0,0,.7)] flex justify-center items-center"
    >
      <div class="msgInfoPop">
        <div class="msgInfoPop-header">
          <i class="el-icon-close" @click="close"></i>
          <div>{{ msgItem.title }}</div>
        </div>

        <div class="msgInfoPop-list" v-if="msgList.length">
          <div
            class="msgInfoPop-item"
            v-for="(item, index) in msgList"
            :key="index"
          >
            <div class="item">
              <div style="display: flex; align-items: center">
                <img
                  class="icon"
                  :src="require(`@/assets/msg/icon_${msgType}.svg`)"
                />
                <div>{{ msgItem.title }}</div>
              </div>
              <div class="time">
                {{
                  (item.createTime &&
                    dayjs(item.createTime).format('YYYY-MM-DD HH:mm:ss')) ||
                  '-'
                }}
              </div>
            </div>

            <div class="line"></div>

            <template v-if="msgType == 1">
              <div class="title">{{ item.noticeTitle }}</div>
              <div class="content">{{ item.noticeContent }}</div>
            </template>

            <template v-if="msgType == 2">
              <div class="content">{{ item.remark }}</div>
            </template>

            <template v-if="msgType == 3">
              <div class="content">{{ item.noticeContent }}</div>
            </template>

            <template v-if="msgType == 4">
              <div class="result">
                <img
                  class="cover"
                  v-if="item.status == 'finish_success'"
                  :src="item.imageUrlVisit"
                />
                <img
                  class="cover"
                  v-if="item.status == 'finish_error'"
                  src="https://www.sheji-ai.cc/systemApi/file/20240807/generator-error.png"
                />
                <img
                  class="cover"
                  v-if="
                    item.status == 'wait_finish' || item.status == 'wait_create'
                  "
                  src="https://www.sheji-ai.cc/systemApi/file/20240808/generating.png"
                />
                <div>
                  <div class="content" v-if="item.status == 'finish_success'">
                    您的AI生成任务已完成，点击可查看作品详情。
                  </div>
                  <div
                    class="content"
                    v-if="
                      item.status == 'wait_finish' ||
                      item.status == 'wait_create'
                    "
                  >
                    当前任务正在生成中，请耐心等待。
                  </div>
                  <div class="content" v-if="item.status == 'finish_error'">
                    您的本次AI生成任务失败，可尝试查看详情重新提交任务。
                  </div>
                  <div class="button" @click="handleToAi(item)">查看详情</div>
                </div>
              </div>
            </template>

            <div class="button" v-if="msgType == 2" @click="handleToInfo(item)">
              查看收益
            </div>
            <div
              class="button"
              v-else-if="msgType != 4"
              @click="handleToInfo(item)"
            >
              查看详情
            </div>
          </div>
        </div>

        <noData v-else></noData>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getNoticeList,
  getUserEarningList,
  getUserNoticeList,
  getUserGeneratorList
} from '@/api/message/index.js'
import dayjs from 'dayjs'
import noData from '../noData/index.vue'

export default {
  name: 'msgInfoPop',
  components: { noData },
  data() {
    return {
      visible: false,
      dayjs: dayjs,
      msgType: 1,
      msgConfig: [
        { title: '官方公告', key: 1, api: getNoticeList },
        { title: '我的收益', key: 2, api: getUserEarningList },
        { title: '服务反馈', key: 3, api: getUserNoticeList },
        { title: 'AI生成', key: 4, api: getUserGeneratorList }
      ],
      msgItem: {},
      msgList: []
    }
  },
  methods: {
    open({ type = 1 }) {
      let msgItem = this.msgConfig.find((item) => item.key == type)
      this.msgItem = msgItem
      this.msgType = type
      this.visible = true
      this.handleInit()
    },

    close() {
      this.visible = false
    },

    handleInit() {
      let query = {}
      if (!this.msgItem.api) return
      if (this.msgType == 1) {
        query.noticeType = 2
      }
      if (this.msgType == 1 || this.msgType == 2 || this.msgType == 3) {
        query.pageNum = 1
        query.pageSize = 30
      }
      if (this.msgType == 4) {
        query.page = 1
        query.limit = 30
      }
      this.msgItem.api(query).then((res) => {
        let list = (res && res.rows) || []
        list = list.map((item) => {
          if (this.msgType == 1) {
            item.id = item.noticeId
          }
          return item
        })
        this.msgList = list
        console.log(`**消息列表${this.msgType}**`, this.msgList)
      })
    },

    handleToAi(item) {
      this.$router.push({
        path: `/workbench/textgeneration`,
        query: { id: item.id }
      })
      this.close()
    },

    handleToInfo(item) {
      this.$router.push({
        path: `/my/messageInfo`,
        query: {
          type: this.msgType,
          data: JSON.stringify(item),
          id: item.id
        }
      })
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.msgInfoPop {
  width: 430px;
  position: relative;
  background: #ebebeb;
  border-radius: 12px;
  border: 1px solid #e5e5e5;
  overflow: hidden;
  &-header {
    width: 100%;
    height: 65px;
    padding: 0 20px;
    box-sizing: border-box;
    background: #fff;
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #000000;
    .el-icon-close {
      cursor: pointer;
      font-size: 20px;
      font-weight: bold;
      margin-right: 20px;
    }
  }
  &-list {
    width: 100%;
    height: 572px;
    overflow-y: scroll;
    padding: 20px;
    box-sizing: border-box;
  }
  &-item {
    margin-bottom: 20px;
    width: 100%;
    border-radius: 14px;
    padding: 16px 18px;
    box-sizing: border-box;
    background: #fff;
    .item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      color: #000000;
    }
    .icon {
      width: 28.48px;
      height: 28.48px;
      border-radius: 50%;
      flex-shrink: 0;
      margin-right: 12px;
    }
    .time {
      font-size: 14px;
      color: #3d3d3d;
    }
    .line {
      width: 100%;
      height: 1px;
      background: #d8d8d8;
      margin-top: 18px;
      margin-bottom: 16px;
    }
    .title {
      font-size: 16px;
      font-weight: bold;
      color: #000000;
      margin-bottom: 4px;
      text-align: left;
    }
    .content {
      font-size: 14px;
      color: #3d3d3d;
      text-align: left;
    }
    .button {
      text-align: end;
      font-size: 16px;
      color: #0033ff;
      cursor: pointer;
      margin-top: 9px;
    }
    .result {
      width: 100%;
      padding: 0 6px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .content {
        width: 216px;
      }
    }
    .cover {
      width: 92px;
      height: 92px;
      border-radius: 3px;
      flex-shrink: 0;
    }
  }
}
.msgInfoPop::-webkit-scrollbar {
  width: 4px; /*  设置纵轴（y轴）轴滚动条 */
}
.msgInfoPop::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #727272;
}
.msgInfoPop::-webkit-scrollbar-track {
  background: #000;
}
</style>