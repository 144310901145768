<template>
  <div class="collection">
    <!-- header -->
    <div class="collection-header" v-loading="loading">
      <img class="img" src="@/assets/collection/infoDetail.png" />
      <img class="bg" src="@/assets/collection/infoDetail_bg.png" />
      <div class="_flex">
        <img
          class="avatar"
          :src="collectionInfo.creatorUser && collectionInfo.creatorUser.avatar"
          @click="
            $router.push(`/my/home?id=${collectionInfo.creatorUser.userId}`)
          "
        />
        <div
          class="nickname"
          @click="
            $router.push(`/my/home?id=${collectionInfo.creatorUser.userId}`)
          "
        >
          {{
            collectionInfo.creatorUser && collectionInfo.creatorUser.nickName
          }}
        </div>
        <div
          class="attend"
          v-if="collectionInfo.isAttenCreator"
          @click="handleFollow"
        >
          取消关注
        </div>
        <div class="attend" v-else @click="handleFollow">+关注</div>
      </div>
      <div class="_flex" style="margin-top: 23px">
        <img src="@/assets/collection/mark.png" />
        <div class="title">{{ collectionInfo.title }}</div>
      </div>
      <div class="desc">{{ collectionInfo.content }}</div>
    </div>
    <!-- tab -->
    <div class="collection-tab">
      <div class="name" :class="{ active: activeTab == 1 }">
        合集内容({{ collectionInfo.collectionCount || 0 }})
      </div>
      <div class="name" :class="{ active: activeTab == 2 }">评论 0</div>
    </div>
    <!-- sort -->
    <div class="collection-sort">
      <div
        class="_flex name"
        :class="{ active: activeSort == 1 }"
        style="margin-left: 0"
      >
        最新
      </div>
      <div class="_flex" :class="{ active: activeSort == 2 }">
        <div class="name">同款</div>
        <img src="@/assets/collection/arrow-sort.svg" />
      </div>
      <div class="_flex" :class="{ active: activeSort == 3 }">
        <div class="name">点赞</div>
        <img src="@/assets/collection/arrow-sort.svg" />
      </div>
      <div class="_flex" :class="{ active: activeSort == 4 }">
        <div class="name">收赞</div>
        <img src="@/assets/collection/arrow-sort.svg" />
      </div>
    </div>
    <!-- list -->
    <div class="collection-list">
      <div
        class="collection-item"
        v-for="(item, index) in collectionImg"
        :key="index"
      >
        <img class="image" :src="item.imgUrl" />
        <div class="tongkuan">
          <img src="@/assets/collection/arrow-tongkuan.svg" />
          <div class="num">{{ item.sameStyleCount || 0 }}</div>
        </div>
        <div class="button" @click="handleSame(item)">
          <div>一键同款</div>
          <div class="_flex dianli" v-if="item.sameStyleCoupon">
            （消耗<img src="@/assets/collection/dianli.svg" />-{{
              item.sameStyleCoupon
            }}电力券）
          </div>
        </div>
      </div>
    </div>
    <!-- comment -->
    <comments :id="$route.params.id" :likeInfo="likeInfo"></comments>
    <!-- dialog -->
    <comfirmDialog
      v-model="dialogVisible"
      confirmTxt="立即购买"
      @confirm="handleBuy"
    >
      <div>您当前“电力券”数量不足，</div>
      <div>无法提交此次生成任务。</div>
    </comfirmDialog>
  </div>
</template>

<script>
import comments from '@/components/comments/index.vue'
import comfirmDialog from '@/components/comfirmDialog/index.vue'
import { followAuthor, cancelFollowAuthor } from '@/api/work/index.js'
import {
  collectionDetails,
  userCollectionImgList,
  createSameAiJob
} from '@/api/album/index.js'
export default {
  name: 'collection',
  components: { comments, comfirmDialog },
  data() {
    return {
      collectionId: null,
      collectionImg: [],
      collectionInfo: {},
      loading: false,
      activeTab: 1,
      activeSort: 1,
      dialogVisible: false
    }
  },
  mounted() {
    this.collectionId = this.$route.params.id
    if (!this.collectionId) return
    this.handleInit()
    this.handleInitImg()
  },
  computed: {
    likeInfo() {
      return {
        likeCount: this.collectionInfo?.likeCount || 0,
        isLike: this.collectionInfo?.isLike
      }
    }
  },
  methods: {
    /* 初始化详情 */
    handleInit() {
      this.loading = true
      collectionDetails(this.collectionId)
        .then((res) => {
          this.collectionInfo = (res && res.data) || {}
          console.log('详情', this.collectionInfo)
        })
        .finally(() => {
          this.loading = false
        })
    },

    /* 初始化图片列表 */
    handleInitImg() {
      userCollectionImgList({
        collectionArticleId: this.collectionId,
        page: 1,
        limit: -1
      }).then((res) => {
        this.collectionImg = (res && res.rows) || []
        console.log('handleInitImg', this.collectionImg)
      })
    },

    /* 关注、取消关注作者 */
    handleFollow() {
      let myApi = this.collectionInfo.isAttenCreator
        ? cancelFollowAuthor
        : followAuthor
      myApi(this.collectionInfo.userId).then((res) => {
        if (res.code != 200) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.handleInit()
      })
    },

    /* 一键同款 */
    handleSame(item) {
      createSameAiJob(item.id).then((res) => {
        if (res && res.code == 200) {
          const taskId = res.data
          this.$router.push({
            path: '/workbench/textgeneration',
            query: { id: taskId }
          })
        } else if (res) {
          if (res.msg == '当前电力券不足，请先充值') {
            return (this.dialogVisible = true)
          }
          this.$message.error('生成失败!')
        }
      })
    },

    /* 立即购买 */
    handleBuy() {
      this.$router.push('/my/electron')
    }
  }
}
</script>

<style lang="scss" scoped>
.collection {
  width: 100%;
  &-header {
    width: 100%;
    height: 201px;
    position: relative;
    border-radius: 14px;
    padding: 16px 50px;
    box-sizing: border-box;
    .img {
      top: 0;
      left: 0;
      position: absolute;
      z-index: -2;
    }
    .bg {
      top: 0;
      left: 0;
      position: absolute;
      backdrop-filter: blur(3px);
      z-index: -1;
    }
    .avatar {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      margin-right: 7px;
    }
    .nickname {
      font-size: 18px;
      color: #ffffff;
    }
    .attend {
      padding: 0 10px;
      box-sizing: border-box;
      height: 27px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 14px;
      background: #54c752;
      font-size: 16px;
      color: #ffffff;
      margin-left: 15px;
      min-width: 72px;
      cursor: pointer;
    }
    .title {
      margin-left: 13px;
      font-size: 18px;
      color: #ffffff;
      font-weight: bold;
    }
    .desc {
      margin-top: 16px;
      font-size: 15px;
      font-weight: 500;
      color: #ffffff;
      text-align: left;
      margin-left: 9px;
    }
  }
  &-tab {
    display: flex;
    align-items: center;
    margin-top: 26px;
    cursor: pointer;
    .name {
      font-size: 18px;
      color: #979797;
      font-weight: 500;
      margin-right: 19px;
    }
    .active {
      font-size: 18px;
      font-weight: bold;
      color: #000000;
    }
  }
  &-sort {
    margin-top: 10px;
    padding-top: 10px;
    box-sizing: border-box;
    border-top: 1px solid #efefef;
    display: flex;
    align-items: center;
    cursor: pointer;
    .name {
      font-size: 16px;
      font-weight: 350;
      color: #3d3d3d;
      margin-left: 34px;
      margin-right: 2px;
    }
    .active {
      font-weight: 500;
      color: #000000;
    }
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 18px;
  }
  &-item {
    width: 279px;
    margin-right: 18px;
    margin-bottom: 83px;
    position: relative;
    .image {
      width: 279px;
      border-radius: 10px;
    }
    .button {
      width: 280px;
      height: 62px;
      border-radius: 126.7px;
      background: #54c752;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin-top: 19px;
      font-size: 22.81px;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
    }
    .dianli {
      font-size: 12px;
      color: #ffffff;
      font-weight: normal;
    }
    .tongkuan {
      height: 29px;
      padding: 0 7px;
      box-sizing: border-box;
      min-width: 58px;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 14px;
      bottom: 100px;
      border-radius: 17.74px;
      .num {
        font-size: 12.67px;
        color: #ffffff;
        margin-left: 5px;
      }
    }
  }
  &-item:nth-child(6n) {
    margin-right: 0;
  }
}
._flex {
  display: flex;
  align-items: center;
}
</style>
