import service from "@/utils/require"

/** 获取首页作品列表分类 */
export const getArticleClassify = () => service.get("/articleClassify/list", {
    params: {
        page: 1,
        limit: -1
    }
})

/** 获取首页作品列表 */
export const findMiniList = (params) => {
    return service.get('/creationArticle/findMiniList', {
        params: {
            ...params,
            limit: 15
        }
    })
}

/** 作品点赞 */
export const articleAttention = (id) => {
    return service.post(`/userArticleAttention/attention/${id}`)
}

/* 作品取消点赞 */
export function noArticleAttention(id) {
    return service.post(`/userArticleAttention/notAttention/${id}`)
}