<template>
  <div>
    <!-- 合集列表 -->
    <el-checkbox-group v-model="checkList" @change="handleCheckbox">
      <div class="albumCoverList" v-if="albumList.length">
        <div class="albumCoverList-list">
          <div
            class="albumCoverList-item"
            v-for="(item, index) in albumList"
            :key="index"
            @click="handleCheck(item)"
          >
            <div class="item">
              <div class="image" v-for="(it, ind) in item.IMGLIST" :key="ind">
                <img :src="it.imgUrl" />
                <div class="over" v-if="ind == 5 && item.IMGTOTAL > 6">
                  <img src="@/assets/collection/overImg.png" />
                </div>
              </div>
              <div class="public" v-if="item.isOpen == 1">公开</div>
              <div class="public isnopublic" v-else>私有</div>

              <div class="checkbox" v-if="isSelected">
                <el-checkbox :label="item.id"></el-checkbox>
              </div>

              <div class="mask" v-if="checkList.includes(item.id)"></div>
            </div>

            <div class="flex_center_between">
              <div class="title">{{ item.title }}</div>
              <div class="tips">{{ item.collectionCount || 0 }}作品</div>
            </div>
          </div>
        </div>
      </div>
      <el-empty v-else :image-size="200"></el-empty>
    </el-checkbox-group>
    <!-- 删除弹窗 -->
    <comfirmDialog
      v-model="delDialogVisible"
      title="删除合集"
      content="该合集内的全部作品将被移出合集，确定删除合集吗？"
      cancelTxt="取消"
      @confirm="handleConfirmDel"
    ></comfirmDialog>
  </div>
</template>

<script>
import { delCollections } from '@/api/album/index.js'
import comfirmDialog from '@/components/comfirmDialog/index'
export default {
  name: 'albumCoverList',
  components: {
    comfirmDialog
  },
  props: {
    albumList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      checkList: [],
      isSelected: false,
      delDialogVisible: false
    }
  },
  methods: {
    /* 选择 */
    initStatus(status) {
      this.isSelected = status
      this.checkList = []
    },

    /* 详情 */
    handleCheck(item) {
      if (this.isSelected) return
      this.$router.push({
        path: '/my/albumCoverInfo',
        query: {
          id: item.id
        }
      })
    },

    /* 多选 */
    handleCheckbox(e) {
      console.log('handleCheckbox', e, this.checkList)
    },

    /* 删除 */
    handleDel() {
      if (!this.checkList.length) {
        return this.$message.error('请至少选择一个合集')
      }
      this.delDialogVisible = true
    },
    handleConfirmDel() {
      delCollections(this.checkList).then((res) => {
        if (res.code != 200) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.checkList = []
        this.delDialogVisible = false
        this.$emit('fresh')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.albumCoverList {
  width: 100%;
  &-list {
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;
  }
  &-item {
    margin-right: 24px;
    margin-bottom: 32px;
    cursor: pointer;
    .item {
      width: 332px;
      height: 225px;
      border-radius: 16px;
      background: #d8d8d8;
      padding: 9px;
      box-sizing: border-box;
      position: relative;
      display: flex;
      flex-wrap: wrap;
    }
    .image {
      width: 100px;
      height: 100px;
      border-radius: 10px;
      margin-right: 7px;
      overflow: hidden;
      position: relative;
    }
    .image:nth-child(3n) {
      margin-right: 0;
    }
    .over {
      width: 43px;
      height: 43px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 50%;
      top: 50%;
      background: rgba(255, 255, 255, 0.2);
      transform: translate(-50%, -50%);
    }
    .public {
      width: 40px;
      height: 21px;
      border-radius: 6px;
      background: #d000ff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #ffffff;
      position: absolute;
      left: 14px;
      top: 14px;
      z-index: 12;
    }
    .isnopublic {
      background: #000000;
    }
    .title {
      font-size: 18px;
      font-weight: bold;
      color: #3d3d3d;
      margin-top: 18px;
      text-align: left;
      width: 195px;
      white-space: nowrap; // 强制一行显示
      overflow: hidden; // 超出隐藏
      text-overflow: ellipsis; // 省略号
    }
    .tips {
      font-size: 16px;
      font-weight: 300;
      color: #666666;
    }
    .mask {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 16px;
      background: rgba(0, 0, 0, 0.6);
      z-index: 9;
    }
    .checkbox {
      top: 15px;
      right: 15px;
      position: absolute;
      z-index: 12;
    }
    ::v-deep .el-checkbox__label {
      display: none;
    }
    ::v-deep .el-checkbox__inner {
      border-radius: 50%;
      background: rgba(88, 88, 88, 0.4);
    }
    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #d000ff;
      border-color: #d000ff;
    }
  }
  &-item:nth-child(4n) {
    margin-right: 0;
  }
  .flex_center_between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>