<template>
  <div>
    <layout
      leftContentName="参数设置"
      @generator="generator"
      @revocation="revocation"
      @regenerator="regenerator"
      @save="save"
      @update="update"
      :couponInfo="couponInfo"
      :result="taskInfo"
      :isWait="isWait"
      type="textgeneration"
    >
      <template slot="firstTag">
        <div class="p-4" v-loading="fileLoading">
          <div class="flex flex-col items-start">
            <div>正向指令</div>
            <div class="w-full mt-2">
              <ElInput
                v-model="promptCn"
                type="textarea"
                :rows="5"
                maxlength="1000"
                showWordLimit
                placeholder="输入你想要AI生成的内容，支持中英文。"
                clearable
              ></ElInput>
            </div>
          </div>
          <el-divider></el-divider>
          <div class="flex flex-col items-start">
            <div>反向指令（选填）</div>
            <div class="w-full mt-2">
              <ElInput
                v-model="reversePrompt"
                type="textarea"
                :rows="5"
                maxlength="1000"
                showWordLimit
                placeholder="输入你不想要AI生成的内容，支持中英文。"
                clearable
              ></ElInput>
            </div>
          </div>
          <el-divider></el-divider>
          <div class="flex flex-col items-start">
            <div>添加参考图（选填）</div>
            <div class="w-full mt-2 flex justify-start">
              <el-upload
                action="https://sheji-ai.cc/systemApi/files-anon"
                :on-success="handleFileUpload"
                :before-upload="checkFile"
                :show-file-list="false"
                :size="'40%'"
              >
                <div
                  class="w-28 h-28 rounded-lg flex justify-center items-center bg-[#F4F5F7]"
                  v-if="JSON.stringify(images) === '{}'"
                >
                  <img src="@/assets/photo.svg" alt="" />
                </div>
                <div class="w-28 h-28 rounded-lg relative" v-else>
                  <div
                    class="w-5 h-5 rounded-lg absolute top-2 right-2"
                    @click="deleteImages"
                  >
                    <img src="@/assets/delete.svg" />
                  </div>
                  <div>
                    <img
                      :src="images.url"
                      class="w-28 h-28 rounded-lg"
                      alt=""
                    />
                  </div>
                </div>
              </el-upload>
            </div>
            <div
              class="w-full flex items-center mt-3"
              v-if="JSON.stringify(images) !== '{}'"
            >
              <div class="w-[100px] text-sm font-light mr-3">参考图权重</div>
              <div class="w-full">
                <el-slider v-model="weight"></el-slider>
              </div>
            </div>
          </div>
          <el-divider></el-divider>
          <basicSetting @update="updateSetting" />
        </div>
        <div class="processBox" v-if="processVisible">
          <el-progress
            type="circle"
            :percentage="processNumber"
            :status="processStatus"
          ></el-progress>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import { createJob } from '@/api/workbench'
import layout from '../components/layout'
import basicSetting from '../components/basicSetting'
import mixins from '../mixin'
export default {
  mixins: [mixins],
  methods: {
    /** 生成 */
    async generator() {
      if (!this.promptCn) {
        this.$message.error('请输入正向指令')
        return
      }
      const params = {
        taskType: 1,
        promptCn: this.promptCn,
        reversePrompt: this.reversePrompt,
        aspectRatio: this.ratio,
        modelTheme: this.activeModel.id,
        isFast: this.mode,
        referenceImgUrl:
          JSON.stringify(this.images) !== '{}'
            ? `${this.images.url}::${(this.weight * 2) / 100}`
            : ''
      }
      this.isWait = true
      const result = await createJob(params)
      if (!result) {
        this.isWait = false
      }
      // 开启轮询
      if (result.data) {
        this.taskId = result.data
        this.getDetail(result.data)
      }
    }
  },

  components: {
    layout,
    basicSetting
  }
}
</script>

<style lang="scss" scoped>
.processBox {
  top: 20%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  z-index: 99;
}
::v-deep .el-textarea__inner {
  border: 0;
  background: #f4f5f7;
}
::v-deep .el-textarea .el-input__count {
  color: #000;
  background: #f4f5f7;
}
::v-deep .el-slider__bar {
  background: #54c752;
}
::v-deep .el-slider__button {
  border-color: #54c752;
}
</style>
