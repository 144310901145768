<template>
  <comfirmDialog
    v-model="visible"
    width="484px"
    height="719px"
    :title="' '"
    :showCancel="false"
    confirmTxt="退出会员子账号"
    @confirm="handleExit"
  >
    <div class="memberExit">
      <div class="memberExit-info">
        <img v-if="userInfo.avatar" class="avatar" :src="userInfo.avatar" />
        <div v-else class="avatar"></div>
        <div>
          <div class="title">{{ userInfo.nickName }}</div>
          <div class="title">名 称：{{ userInfo.teamUserName }}</div>
          <div class="title">职 位：{{ userInfo.teamPosition }}</div>
          <div class="name">到期时间：{{ teamEndTime }}</div>
          <div class="name">
            电力券使用：
            <span v-if="userInfo.teamCoupon == 1" class="active">已授权</span>
            <span v-else>未授权</span>
          </div>
          <div class="name">
            作品发布：
            <span v-if="userInfo.teamCreation == 1" class="active">已授权</span>
            <span v-else>未授权</span>
          </div>
        </div>
      </div>
      <div class="memberExit-line"></div>
      <div class="memberExit-info">
        <img class="icon" src="@/assets/member/info_icon.svg" />
        <div>
          <div class="tips">信息有误可联系主账号修改</div>
          <div class="tips">
            主动关闭会员子账号使用权益后，该账号将无法享受会员相关权益！
          </div>
          <div class="tips">退出后该账号将恢复为常规账号</div>
          <div class="error">通过加入会员可享有同主账号相同使用体验</div>
        </div>
      </div>
    </div>
  </comfirmDialog>
</template>

<script>
import dayjs from 'dayjs'
import comfirmDialog from '@/components/comfirmDialog/index.vue'
import { userQuitTeam } from '@/api/user/member.js'
export default {
  name: 'memberExit',
  props: {
    userInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      visible: false
    }
  },
  components: { comfirmDialog },
  computed: {
    teamEndTime() {
      return (
        this.userInfo.teamEndTime &&
        dayjs(this.userInfo.teamEndTime).format('YYYY-MM-DD')
      )
    }
  },
  methods: {
    open() {
      this.visible = true
    },
    close() {
      this.visible = false
    },
    handleExit() {
      userQuitTeam().then((res) => {
        this.close()
        this.$message.success(res && res.msg)
        this.$root.$emit('UPDATE_USERINFO')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.memberExit {
  width: 100%;
  height: 100%;
  padding: 51px 59px;
  box-sizing: border-box;
  &-info {
    display: flex;
    align-items: flex-start;
  }
  &-line {
    width: 100%;
    height: 1px;
    background: #f4f5f7;
    margin-top: 7px;
    margin-bottom: 9px;
  }
  .avatar {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    margin-right: 18px;
    flex-shrink: 0;
    background: #d8d8d8;
  }
  .icon {
    margin-right: 8px;
    flex-shrink: 0;
    margin-top: 6px;
  }
  .title {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 33px;
    text-align: left;
  }
  .name {
    font-size: 16px;
    color: #666666;
    margin-bottom: 33px;
    text-align: left;
  }
  .active {
    color: #0033ff;
  }
  .tips {
    font-size: 16px;
    font-weight: 350;
    color: #515151;
    margin-bottom: 14px;
    text-align: left;
  }
  .error {
    font-size: 16px;
    font-weight: 350;
    color: #ff2c61;
    text-align: left;
  }
}
</style>