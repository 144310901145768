import service from "@/utils/require";

/**
 * 用户业务数据趋势统计
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const userBusinessDataStatistics = (params) => service({
  url: "/system/user/userBusinessDataStatistics",
  method: "get",
  params
});


/**
 * 统计用户业务数据
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const userBusinessCount = (params) => service({
  url: "/system/user/userBusinessCount",
  method: "get",
  params
});
