<template>
  <div class="albumCenter">
    <div class="center-header" style="display: flex;justify-content: center">

    </div>
    <div style="min-height: calc(100vh - 105px);width: 100%;background: #000">
      <div style="width: 100%;height: 10px"></div>
      <div style="display: flex;width: 98%;margin: 30px auto;align-items: center">
        <div @click="$router.push('/my/albumCenter')" style="cursor:pointer;font-size: 14px;font-weight: 500;font-family: 思源黑体;line-height: 15px;letter-spacing: 0em;text-align: center;color: #FFFFFF;display: flex;border: 2px solid #878787;box-sizing: border-box;border-radius: 20px;padding: 8px 20px">
          <div><img src="../../../assets/back-icon.png" alt="" style="width: 8px;height: 15px"> </div>
          <div style="margin-left: 10px;">
            返回
          </div>
        </div>
        <div style="font-family: Source Han Sans;font-size: 36px;font-weight: bold;line-height: 15px;letter-spacing: 0px;color: #FFFFFF;margin-left: 22px;">
          粉丝数据
        </div>
      </div>


      <div style="background: #1D1D1F;border-radius: 20px;opacity: 1;width: 98%;margin: 35px auto;height: 568px;">
        <div
          style="font-size: 18px;font-family: Source Han Sans;font-weight: bold;line-height: 15px;letter-spacing: 0px;color: #FFFFFF;text-align: left;margin-left: 24px;padding-top: 24px;">
          粉丝数据
        </div>


        <div
          style="display: flex;align-items: center;justify-content: space-between;width: 98%;margin: 30px auto 20px;border-bottom: 1px solid #404040;padding-bottom: 20px;">
          <div style="width: 30%;border-right: 1px solid #404040">
            <div
              style="font-size: 14px;font-family: Source Han Sans;font-weight: normal;line-height: 10px;text-align: center;letter-spacing: 0px;color: #FFFFFF;margin-bottom: 20px;">
              53
            </div>
            <div
              style="font-family: Source Han Sans;font-size: 14px;font-weight: normal;line-height: 10px;text-align: center;letter-spacing: 0px;color: #FFFFFF;">
              总粉丝数量
            </div>
          </div>

          <div style="width: 30%;border-right: 1px solid #404040">
            <div
              style="font-size: 14px;font-family: Source Han Sans;font-weight: normal;line-height: 10px;text-align: center;letter-spacing: 0px;color: #FFFFFF;margin-bottom: 20px;">
              0
            </div>
            <div
              style="font-family: Source Han Sans;font-size: 14px;font-weight: normal;line-height: 10px;text-align: center;letter-spacing: 0px;color: #FFFFFF;">
              新增粉丝数
            </div>

          </div>

          <div style="width: 30%;border-right: 1px solid #404040">
            <div
              style="font-size: 14px;font-family: Source Han Sans;font-weight: normal;line-height: 10px;text-align: center;letter-spacing: 0px;color: #FFFFFF;margin-bottom: 20px;">
              0
            </div>
            <div
              style="font-family: Source Han Sans;font-size: 14px;font-weight: normal;line-height: 10px;text-align: center;letter-spacing: 0px;color: #FFFFFF;">
              流失粉丝数
            </div>

          </div>


        </div>

        <div style="width: 98%;margin: 20px auto">
          <div style="font-family: Source Han Sans;font-size: 18px;font-weight: bold;line-height: 15px;letter-spacing: 0px;color: #FFFFFF;text-align: left">
            新增粉丝趋势图
          </div>
          <BarAndLine
            :legedConfig="legedConfig"
            :tooltipConfig="tooltipConfig"
            :xAxisData="xAxis"
            :yAxisData="yAxis"
            :seriesData="seriesData"
            :gridConfig="gridConfig"
            :height="'400px'"
            :width="'102%'"
            :lineColor="['#09A0FF','#19FF90','#158AFF','#48FFAB']"></BarAndLine>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import albumList from "../components/albumList.vue";
import albumHeadRight from "../components/albumHeadRight.vue";
import albumCoverList from "../components/albumCoverList.vue";
import albumCoverForm from "../components/albumCoverForm.vue";
import albumCoverJoin from "../components/albumCoverJoin.vue";
import {
  userImageStorage,
  userCollectionList,
  userCollectionImgList
} from "@/api/album/index.js";
import BarAndLine from "@/components/echarts/BarAndLine";

export default {
  name: "albumCenter",
  components: {
    albumList,
    albumHeadRight,
    albumCoverList,
    albumCoverForm,
    albumCoverJoin,
    BarAndLine
  },
  data() {
    return {
      searchKey: null,
      albumList: [],
      initAlbumList: [],
      userInfo: {},
      currentKey: 1,
      loading: false,
      legedConfig: {
        data: ['粉丝数量'],
        show: false
      },
      tooltipConfig: {
        trigger: 'axis'
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['11-18', '11-19', '11-20', '11-21', '11-22', '11-23', '11-24'],
        axisLabel:{
          color: '#fff'
        }
      },
      yAxis: {
        type: 'value',
        axisLabel:{
          color: '#fff'
        },
        splitLine: { //网格线：隐藏
          lineStyle: {
            color: '#404040',
            type: 'dashed', // 线型为虚线
          }
        }
      },
      seriesData: [
        {
          name: '粉丝数量',
          type: 'line',
          stack: 'Total',
          data: [0, 2, 0],
          lineStyle: {
            color: '#D000FF'
          },
          itemStyle: {
            color: '#D000FF'
          }
        }
      ],
      gridConfig: {}
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log("userInfo===", this.userInfo);
    this.handleTab(1);
  },
  methods: {
    toCreatorDataView() {
      this.$router.push('/albumCenter/creatorData')
    },
    /* 点击tab */
    handleTab(key) {
      this.currentKey = key;
      this.handleInit();
    },

    /* 搜索 */
    handleSearch(key) {
      this.searchKey = key;
      this.handleInit();
    },

    /* 初始化 */
    handleInit() {
      this.loading = true;
      let myApi = this.currentKey == 1 ? userImageStorage : userCollectionList;
      myApi({ page: 1, limit: -1, prompt: this.searchKey })
        .then(async (res) => {
          let list = (res && res.rows) || [];
          if (this.currentKey == 2) {
            // 合集
            for (let i in list) {
              let result = await userCollectionImgList({
                collectionArticleId: list[i].id,
                page: 1,
                limit: 6
              }).catch((e) => {
              });
              list[i].IMGLIST = result.rows || [];
              list[i].IMGTOTAL = result.total;
            }
          }
          this.albumList = list;
          this.initAlbumList = JSON.parse(JSON.stringify(list));
          console.log("初始化", list);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /* 发布状态 */
    handleStatus(status) {
      if (status == 1) {
        this.albumList = this.initAlbumList.filter((v) => v.isOpen == 1);
      } else if (status == -1) {
        this.albumList = JSON.parse(JSON.stringify(this.initAlbumList));
      } else {
        this.albumList = this.initAlbumList.filter((v) => v.isOpen != 1);
      }
    },
    handleStatusAll(status) {
      if (status == 1) {
        this.albumList = this.initAlbumList.filter((v) => v.issue);
      } else if (status == -1) {
        this.albumList = JSON.parse(JSON.stringify(this.initAlbumList));
      } else {
        this.albumList = this.initAlbumList.filter((v) => !v.issue);
      }
    },

    /* 选择 */
    handleSelect(status) {
      if (this.currentKey == 1) {
        this.$refs.albumList.initStatus(status);
      }
      if (this.currentKey == 2) {
        this.$refs.albumCoverList.initStatus(status);
      }
    },

    /* 全部-加入合集 */
    handleAlbumListJoin(checkList) {
      this.$refs.albumCoverJoin.openJoinDialog(checkList);
    }
  }
};
</script>

<style lang="scss" scoped>

.albumCenter {
  width: 1402px;
  margin: -1.5rem auto;
  min-height: calc(100vh - 55px);

  .center-header {
    width: 100%;
    height: 50px;
    background-size: cover;
  }

  &-title {
    font-size: 40px;
    font-weight: bold;
    color: #3d3d3d;
    margin-right: 68px;
  }

  &-tab {
    font-size: 18px;
    font-weight: 500;
    color: #666666;
    margin-right: 35px;
    cursor: pointer;
  }
}

.flex_ {
  display: flex;
  align-items: center;
}

.flex_center_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.active_router {
  @apply font-black relative;
  color: #000;

  &::after {
    @apply block absolute right-0.5 -bottom-1 h-1;
    content: '';
    width: 20px;
    border-radius: 2.5px;
    background: #54c752;
  }
}
</style>
