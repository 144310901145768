<template>
  <div class="albumInfoHead">
    <template v-if="!isSelected">
      <div class="albumInfoHead-item">
        <div class="albumInfoHead-button active" @click="handleAddAlbum">
          <img class="img" src="@/assets/collection/arrow-badd.svg" />
          <div class="name">添加作品</div>
        </div>
        <div class="albumInfoHead-button" @click="handleSetCover">
          <img class="img" src="@/assets/collection/arrow-set.svg" />
          <div class="name">合集设置</div>
        </div>
        <div class="albumInfoHead-button" @click="handleCover">
          <img class="img" src="@/assets/collection/arrow-menu.svg" />
          <div class="name">封面设置</div>
        </div>
        <div class="albumInfoHead-button" @click="handleDelCover">
          <img class="img" src="@/assets/collection/arrow-del.svg" />
          <div class="name">删除</div>
        </div>
      </div>
      <div class="albumInfoHead-item">
        <div class="albumInfoHead-button">
          <img class="img" src="@/assets/collection/arrow-search.svg" />
          <el-input
            v-model="searchKey"
            placeholder="搜索画面描述"
            @change="handleSearch"
          />
        </div>
        <div class="albumInfoHead-button">
          <div class="name" style="margin-left: 0">画面尺寸</div>
          <img
            class="img"
            style="margin-left: 8px"
            src="@/assets/collection/arrow-down.svg"
          />
        </div>
        <div class="albumInfoHead-button" @click="handleSelect">
          <img class="img" src="@/assets/collection/arrow-check.svg" />
          <div class="name">批量选择</div>
        </div>
      </div>
    </template>
    <template v-if="isSelected">
      <div class="albumInfoHead-item"></div>
      <div class="albumInfoHead-item">
        <div class="albumInfoHead-button" @click="handleDelAlbum">
          <img class="img" src="@/assets/collection/arrow-back.svg" />
          <div class="name">移出</div>
        </div>
        <div class="albumInfoHead-button" @click="handleLoadAlbum">
          <img class="img" src="@/assets/collection/arrow-download.svg" />
          <div class="name">下载</div>
        </div>
        <!-- <div class="albumInfoHead-button">
          <img class="img" src="@/assets/collection/arrow-del.svg" />
          <div class="name">删除</div>
        </div> -->
        <div class="albumInfoHead-button" @click="handleCancelSelect">
          <div class="name" style="margin-right: 10px">取消</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'albumInfoHead',
  data() {
    return {
      searchKey: null,
      isSelected: false
    }
  },
  methods: {
    /* 添加作品 */
    handleAddAlbum() {
      this.$emit('addAlbum')
    },

    /* 移除作品 */
    handleDelAlbum() {
      this.$emit('delAlbum')
    },

    /* 下载作品 */
    handleLoadAlbum() {
      this.$emit('loadAlbum')
    },

    /* 合集设置 */
    handleSetCover() {
      this.$emit('setCover')
    },

    /* 封面设置 */
    handleCover() {
      this.$emit('cover')
    },

    /* 删除合集 */
    handleDelCover() {
      this.$emit('delCover')
    },

    /* 搜索 */
    handleSearch() {
      this.$emit('search', this.searchKey)
    },

    /* 批量选择 */
    handleSelect() {
      this.isSelected = true
      this.$emit('select', this.isSelected)
    },

    /* 取消选择 */
    handleCancelSelect() {
      this.isSelected = false
      this.$emit('select', this.isSelected)
    }
  }
}
</script>

<style lang="scss" scoped>
.albumInfoHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  &-item {
    display: flex;
    align-items: center;
  }
  &-button {
    height: 33px;
    padding: 0 13px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    border: 1px solid #c4bfbf;
    cursor: pointer;
    .img {
    }
    .name {
      font-size: 14px;
      color: #000000;
      margin-left: 10px;
    }
  }
  &-button + &-button {
    margin-left: 19px;
  }
  .active {
    background: #54c752;
    border: solid 1px #54c752;
    .name {
      color: #ffffff;
    }
  }
}
::v-deep .el-input__inner {
  border: none;
  height: 30px;
}
::v-deep .el-input__inner:hover {
  border-color: #fff;
}
</style>