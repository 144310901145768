import service from "@/utils/require";

/** 查询公告分页列表: pageNum, pageSize, noticeType=2 */
export const getNoticeList = (params) => {
    return service.get(`/system/notice/userList`, {
        params
    });
};

/** 查询用户系统服务反馈信息分页列表: pageNum, pageSize **/
export const getUserNoticeList = (params) => {
    return service.get(`/userServiceNotice/userNoticeList`, {
        params
    });
};

/** 获取用户收益分页列表: pageNum, pageSize **/
export const getUserEarningList = (params) => {
    return service.get(`/userIntegral/userEarningList`, {
        params
    });
};

/**
 * 获取用户生成记录列表
 * @param page
 * @param limit
 */
export const getUserGeneratorList = (params) => {
    return service.get('/goapiJob/getUserGeneratorList', {
        params
    });
};

/** 获取最新一条公告信息 **/
export const getLastAnnouncement = () => {
    return service.get(`/userInformation/lastAnnouncement`, {});
};

/** 获取用户最新一条服务反馈公告 **/
export const getLastServiceNotice = () => {
    return service.get(`/userInformation/lastServiceNotice`, {});
};

/** 获取当前用户最后的ai生成记录 **/
export const getUserGeneratorLastResult = () => {
    return service.get(`/goapiJob/getUserLastResult`, {});
};

/**
 * 获取关注/点赞我的作品的信息
 * @param {*} page 查询的分页页码
 * @param {*} limit 查询的分页长度 
 * @returns 
 */
export const userConcernedList = (params) => {
    return service.get(`userArticleAttention/userConcernedList`, { params });
};

/**
 * 查询当前用户关注/被关注的用户列表
 * @param {*} page 查询的分页页码
 * @param {*} limit 查询的分页长度
 * @param {*} type 查询类型（1:当前用户关注的用户列表；2:当前用户被关注的信息列表）
 * @returns  
 */
export function userAttentionList(params) {
    return service.get(`userAttention/userAttentionList`, { params });
}

/**
 * 查询当前被同款列表
 * @param {*} page 查询的分页页码
 * @param {*} limit 查询的分页长度
 * @returns  
 */
export function userSamedJobList(params) {
    return service.get(`/goapiJob/userSamedJobList`, { params });
}

/**
 * 获取用户发布的评论列表
 * @param {*} page 查询的分页页码
 * @param {*} limit 查询的分页长度
 * @returns comment：评论的内容
 * @returns createTime: 评论时间
 * @returns article：评论的文章信息（“title”：文章标题（可能为空），“content”：文章描述，“coverImgUrl”：文章封面路径）
 */
export function userCommentList(params) {
    return service.get(`/articleComment/userCommentList`, { params });
}

/**
 * 获取用户被评论的列表
 * @param {*} page 查询的分页页码
 * @param {*} limit 查询的分页长度
 * @returns comment：评论的内容
 * @returns createTime: 评论时间
 * @returns article：评论的文章信息（“title”：文章标题（可能为空），“content”：文章描述，“coverImgUrl”：文章封面路径）
 * @returns sendUser：评论的用户信息（包含头像、昵称、账号）
 * @returns parentComment: 上级评论信息（为null就是没有，comment：评论的内容、sendUser发送评论的用户信息，createTime：评论时间）
 */
export function userCommentedList(params) {
    return service.get(`/articleComment/userCommentedList`, { params });
}

// 关注作者用户
export const userAttention = (uid) => {
    return service.post(`/userAttention/${uid}`, {})
}

// 取关用户
export const cancelUserAttention = (uid) => {
    return service.delete(`/userAttention/${uid}`, {})
}

// !!!!!一键回关:待联调确认
export const batchBackAttention = () => {
    return service.post(`/system/user/batchBackAttention`, {})
}