export default {
  name: 'dateArrayMixins',
  methods: {
    /**
     * 分组
     * @param {*} arr 目标对象数组
     * @param {*} filed 分组字段
     * @returns 
     */
    classifyArrayGroupBySameFieldAlpha(arr, filed) {
      let temObj = {}
      for (let i = 0; i < arr.length; i++) {
        let item = arr[i]
        if (!temObj[item[filed]]) {
          temObj[item[filed]] = [item]
        } else {
          temObj[item[filed]].push(item)
        }
      }
      let resArr = []
      Object.keys(temObj).forEach(key => {
        resArr.push({
          id: key,
          title: key,
          list: temObj[key],
        })
      })
      return resArr
    }
  }
}