import { render, staticRenderFns } from "./invite.vue?vue&type=template&id=39ac2b12&scoped=true"
import script from "./invite.vue?vue&type=script&lang=js"
export * from "./invite.vue?vue&type=script&lang=js"
import style0 from "./invite.vue?vue&type=style&index=0&id=39ac2b12&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39ac2b12",
  null
  
)

export default component.exports