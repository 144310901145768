<template>
  <div class="h-12 bg-white flex justify-center items-center">
    <ElPopover
      v-for="item in functionMap"
      :key="item.name"
      trigger="click"
      :disabled="item.show ? false : true"
      popper-class="bg-[#000] p-2"
      :ref="
        item.name === 'publish' || item.name === 'delete'
          ? 'popoverPublish'
          : '11'
      "
    >
      <div v-if="item.name === 'publish'">
        <div class="text-lg text-[#000000]">发布作品</div>
        <el-input
          class="mt-4"
          v-model="info.articleName"
          placeholder="请输入作品名称"
        />
        <ElInput
          class="mt-4"
          v-model="info.remark"
          type="textarea"
          :rows="5"
          maxlength="1000"
          showWordLimit
          placeholder="输入你想要AI生成的内容，支持中英文。"
          clearable
        ></ElInput>
        <div class="flex justify-between mt-4 items-center">
          <span>作品发布数量</span>
          <ElSelect v-model="info.count">
            <ElOption label="仅发布当前选中图片" value="single"></ElOption>
            <ElOption label="发布整组图片" value="all"></ElOption>
          </ElSelect>
        </div>
        <div class="flex justify-between mt-4 items-center">
          <span>请选择分类</span>
          <ElSelect v-model="info.articleClassifyId">
            <ElOption
              v-for="(item, index) in sortList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></ElOption>
          </ElSelect>
        </div>
        <div class="flex justify-between mt-4 items-center">
          <span>创作信息公开范围</span>
          <ElSelect v-model="info.openType">
            <ElOption label="所有人可见" :value="1"></ElOption>
            <ElOption label="仅关注可见" :value="2"></ElOption>
            <ElOption label="仅一键同款可见" :value="3"></ElOption>
            <ElOption label="一键同款 + 30电力券可见" :value="4"></ElOption>
          </ElSelect>
        </div>
        <div class="flex justify-between mt-4 items-center">
          <span>允许下载原图</span>
          <ElSwitch v-model="info.allowDownload"></ElSwitch>
        </div>
        <div class="mt-4 flex justify-between">
          <div
            class="w-[150px] h-[40px] leading-[40px] rounded-2xl cursor-pointer bg-[#F0F0F0] text-center"
            @click="close(0)"
          >
            取消
          </div>
          <div
            class="w-[150px] h-[40px] leading-[40px] rounded-2xl cursor-pointer text-white bg-[#54C752] text-center"
            @click="publish"
          >
            立即发布
          </div>
        </div>
      </div>
      <div v-if="item.name === 'delete'">
        <div class="text-lg text-[#000000]">删除作品</div>
        <div class="flex justify-between mt-4 items-center">
          <span>删除图片范围</span>
          <ElSelect v-model="deleteArea">
            <ElOption label="仅删除当前图片" value="single"></ElOption>
            <ElOption label="删除整组图片" value="all"></ElOption>
          </ElSelect>
        </div>
        <div class="mt-4 flex justify-between">
          <div
            class="w-[140px] h-[40px] leading-[40px] rounded-2xl cursor-pointer bg-[#F0F0F0] text-center"
            @click="close(1)"
          >
            取消
          </div>
          <div
            class="w-[140px] h-[40px] leading-[40px] rounded-2xl cursor-pointer text-white bg-[#54C752] text-center"
            @click="deleteFun"
          >
            删除
          </div>
        </div>
      </div>
      <img
        slot="reference"
        :src="item.icon"
        alt=""
        class="w-5 h-5 mr-8 cursor-pointer"
        @click="callFather(item)"
      />
    </ElPopover>
  </div>
</template>

<script>
import { getArticleClassify } from '@/api/home'
import { deleteJob, deleteJobItem, publishJob } from '@/api/workbench'

export default {
  data() {
    return {
      functionMap: [
        {
          name: 'share',
          icon: require('@/assets/share.svg'),
          title: '分享',
          show: false
        },
        {
          name: 'publish',
          icon: require('@/assets/publish.svg'),
          title: '发布',
          show: true
        },
        {
          name: 'download',
          icon: require('@/assets/download.svg'),
          title: '下载',
          show: false
        },
        {
          name: 'delete',
          icon: require('@/assets/delete.svg'),
          title: '删除',
          show: true
        },
        {
          name: 'revocation',
          icon: require('@/assets/revoke.svg'),
          title: '撤回',
          show: false
        }
      ],
      sortList: [],
      deleteArea: 'single',
      info: {
        articleName: '',
        remark: '',
        articleClassifyId: '',
        openType: 1,
        allowDownload: true,
        count: 'single'
      }
    }
  },
  props: {
    result: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    }
  },
  methods: {
    callFather(item) {
      // 呼唤父亲该干活了
      this.$emit(item.name)
      if (item.name === 'download') {
        this.download()
      }
      if (item.name === 'share') {
        this.share()
      }
    },
    async share() {
      if (JSON.stringify(this.result) === '{}') {
        this.$message.error('获取任务失败，请先生成任务')
        return
      }
      let currentUrl = window.location.href
      const urlParams = new URLSearchParams(window.location.search)
      const idParam = urlParams.get('id')

      if (idParam) {
        // 如果存在?id=XXX，则直接复制当前URL
        this.copyToClipboard(currentUrl)
      } else {
        // 否则拼接后复制
        const newUrl = `${currentUrl}?id=${this.result.id}`
        this.copyToClipboard(newUrl)
      }
    },
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$message.success('分享链接已复制到剪贴板')
        })
        .catch((err) => {
          this.$message.error('分享失败')
          console.error('Failed to copy text: ', err)
        })
    },
    async download() {
      if (JSON.stringify(this.result) === '{}') {
        this.$message.error('获取任务失败，请先生成任务')
        return
      }
      const url = this.result.itemList[this.index].originUrlVisit
      try {
        // 发送请求获取图片
        const response = await fetch(url)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }

        // 将响应体转换为Blob对象
        const blob = await response.blob()

        // 创建一个URL对象
        const urlObject = URL.createObjectURL(blob)

        // 创建一个a标签用于下载
        const a = document.createElement('a')
        a.href = urlObject
        a.download = this.result.itemList[this.index].id // 设置下载的文件名
        document.body.appendChild(a)
        a.click() // 触发点击事件开始下载

        // 清理
        document.body.removeChild(a)
        URL.revokeObjectURL(urlObject)
        this.$message.success('下载成功，请前往浏览器下载页面查看')
      } catch (error) {
        console.error('下载图片失败:', error)
      }
    },
    // 获取作品列表分类
    async getClassify() {
      const result = await getArticleClassify()
      this.sortList.push(...result?.rows)
    },
    // 发布作品
    async publish() {
      if (JSON.stringify(this.result) === '{}') {
        this.$message.error('获取任务失败，请先生成任务')
        return
      }
      for (let i of Object.keys(this.info)) {
        if (!this.info[i]) {
          this.$message.error('请填写所有字段再发布')
          return
        }
      }
      const result = await publishJob({
        imgStorageIds:
          this.info.count === 'single'
            ? [this.result.itemList[this.index].id]
            : this.result.itemList.map((item) => item.id),
        ...this.info,
        allowDownload: this.info.allowDownload ? 1 : 0
      })
      if (result) {
        this.$message.success('发布成功')
        this.$router.push('/home')
      }
    },
    // 删除
    async deleteFun() {
      const result =
        this.deleteArea === 'all'
          ? await deleteJob(this.result.id)
          : await deleteJobItem(this.result.itemList[this.index].id)
      if (result) {
        this.$message.success('删除成功')
        this.$router.push('/home')
      }
    },
    close(index) {
      this.$refs.popoverPublish[index].doClose()
    }
  },
  mounted() {
    this.getClassify()
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner,
::v-deep .el-textarea__inner {
  height: 43px;
  border-radius: 14px;
  background: #f0f0f0;
  font-size: 14px;
  color: #5e5e5e;
}
::v-deep .el-textarea__inner {
  height: unset;
}
::v-deep .el-switch.is-checked .el-switch__core {
  background-color: #54c752;
  border-color: #54c752;
}
::v-deep .el-range-editor.is-active,
::v-deep .el-range-editor.is-active:hover,
::v-deep .el-select .el-input.is-focus .el-input__inner {
  border-color: #54c752;
}
::v-deep .el-select-dropdown__item.selected {
  color: #54c752 !important;
}
::v-deep .el-textarea .el-input__count {
  background: #f0f0f0;
  color: #5e5e5e;
}
</style>