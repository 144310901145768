import service from "@/utils/require"

// 获取用户文章浏览分页记录
export const userBrowseHistoryPage = (params) => service({
    url: '/userBrowseHistory/userBrowseHistoryPage',
    method: 'get',
    params
})

// 用户批量取消收藏文章
export const batchNotAttention = (params) => {
    return service.post("/userArticleAttention/batchNotAttention", params)
}

// 分页获取用户收藏的文章列表
export const userAttentionArticleList = (params) => service({
    url: '/userArticleAttention/userAttentionArticleList',
    method: 'get',
    params
})

// 分页获取用户点赞的文章列表
export const userLikeArticleList = (params) => service({
    url: '/userArticleAttention/userLikeArticleList',
    method: 'get',
    params
})
