<template>
  <commonLayout
    :mt="45"
    :tabList="tabList"
    :defaultTab="currentTab"
    :searhBtnVisible="false"
    @tab="handleTab"
  >
    <div v-if="list.length" class="messageCenterComment-list">
      <div
        class="messageCenterComment-item"
        v-for="(item, index) in list"
        :key="index"
      >
        <img
          class="avatar"
          :src="
            currentTab == 1
              ? item.sendUser && item.sendUser.avatar
              : mineInfo.avatar
          "
        />
        <div style="text-align: left">
          <!-- 个人信息 -->
          <div class="name" v-if="currentTab == 1">
            {{ item.sendUser && item.sendUser.nickName }}
            {{
              item.createTime &&
              dayjs(item.createTime).format('YYYY-MM-DD HH:mm:ss')
            }}
          </div>
          <div class="name" v-if="currentTab == 2">
            {{ mineInfo.nickName }}
            {{
              item.createTime &&
              dayjs(item.createTime).format('YYYY-MM-DD HH:mm:ss')
            }}
          </div>
          <!-- 评论信息 -->
          <div v-if="item.parentComment && currentTab == 1" class="content">
            回复@<span class="user">{{
              item.parentComment.sendUser.nickName
            }}</span
            >{{ item.comment }}
          </div>
          <div v-else-if="currentTab == 1">评论了你：{{ item.comment }}</div>
          <div v-else>{{ item.comment }}</div>
          <!-- 二级评论信息 -->
          <div v-if="item.article" class="card">
            <div v-if="item.parentComment" style="width: 100%">
              <div class="content">
                @<span class="user">{{ mineInfo.nickName }}</span
                >：{{ item.parentComment.comment }}
              </div>
              <div class="line"></div>
            </div>
            <!-- 作品信息 -->
            <div class="article">
              <div class="text">{{ item.article.content }}</div>
              <img class="cover" :src="item.article.coverImgUrl" />
            </div>
          </div>
          <!-- 按钮 -->
          <div class="button" v-if="currentTab == 1">
            <div class="replay" @click="handleReplay(item)">
              <img src="../assets/comment.svg" />
              <div style="margin-left: 10px">回复评论</div>
            </div>
            <div class="replay" @click="handleReplayLike(item)">
              <img v-if="item.userIsLike" src="../assets/attend_active.svg" />
              <img v-else src="../assets/attend.svg" />
            </div>
          </div>
          <div style="margin-top: 10px" v-if="inputVisible">
            <el-input
              v-model="replayData.comment"
              placeholder="请输入您要发表的评论"
              @change="handleReplayTo"
            />
          </div>
        </div>
      </div>
    </div>

    <noData v-else></noData>

    <div class="messageCenterComment-page">
      <div>共 {{ total || 0 }} 项数据</div>
      <div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
  </commonLayout>
</template>

<script>
// !!!!!页面:待联调确认
import dayjs from 'dayjs'
import noData from '@/components/noData/index.vue'
import commonLayout from '../components/commonLayout.vue'
import { getUserInfo } from '@/utils/user'
import { userCommentList, userCommentedList } from '@/api/message/index.js'
import {
  commentArticle,
  likeComment,
  notLikeComment
} from '@/api/work/index.js'
export default {
  name: 'messageCenterComment',
  components: { noData, commonLayout },
  data() {
    return {
      list: [],
      mineInfo: {},
      currentTab: 1,
      dayjs: dayjs,
      tabList: [
        { title: '我收到的评论', key: 1, api: userCommentedList },
        { title: '我发出的评论', key: 2, api: userCommentList }
      ],
      query: {
        page: 1,
        limit: 10
      },
      total: 0,
      replayData: {},
      inputVisible: false
    }
  },
  mounted() {
    this.mineInfo = getUserInfo()
    console.log('mineInfo', this.mineInfo)
  },
  methods: {
    handleTab(key) {
      this.currentTab = key
      this.list = []
      this.query.page = 1
      this.total = 0
      this.handleInit()
    },
    handleInit() {
      let myApi = this.tabList.find((item) => item.key == this.currentTab).api
      myApi(this.query).then((res) => {
        let list = (res && res.rows) || []
        this.total = (res && res.total) || 0
        this.list = list
        console.log('**评论列表**', this.list)
      })
    },
    handlePageChange(data) {
      this.query.page = data
      this.handleInit()
    },
    handleReplay(item) {
      this.replayData = {
        articleId: item.articleId,
        parentId: item.id,
        replyUserId: item.sendUser && item.sendUser.userId
      }
      this.inputVisible = true
    },
    handleReplayTo() {
      commentArticle(this.replayData).then((res) => {
        if (res && res.code == 200) {
          //   this.handleInit()
          this.replayData = {}
          this.inputVisible = false
          this.$message.success('评论成功')
        }
      })
    },
    handleReplayLike(item) {
      let likeApi = item.userIsLike ? notLikeComment : likeComment
      likeApi(item.id).then((res) => {
        if (res && res.code == 200) {
          this.handleInit()
          this.$message.success(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.messageCenterComment {
  &-list {
  }
  &-item {
    display: flex;
    align-items: flex-start;
    padding-top: 44px;
    padding-bottom: 18px;
    box-sizing: border-box;
    border-bottom: 1px solid #d8d8d8;
    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      flex-shrink: 0;
      margin-right: 14px;
      background: #d8d8d8;
    }
    .name {
      font-size: 14px;
      color: #3d3d3d;
      margin-right: 13px;
    }
    .content {
      font-size: 16px;
      font-weight: 500;
      color: #000000;
      margin-top: 8px;
    }
    .user {
      color: #0033ff;
    }
    .card {
      min-width: 303px;
      height: 157px;
      padding: 18px 14px;
      box-sizing: border-box;
      background: #f0f0f0;
      border-radius: 14px;
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .line {
      width: 100%;
      height: 1px;
      background: #d8d8d8;
      margin: 13px 0;
    }
    .article {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    .text {
      //   width: 169px;
      font-size: 16px;
      font-weight: 500;
      color: #666666;
      //   white-space: nowrap; // 强制一行显示
      //   overflow: hidden; // 超出隐藏
      //   text-overflow: ellipsis; // 省略号
    }
    .cover {
      width: 78px;
      height: 78px;
      border-radius: 10px;
      margin-left: 28px;
      flex-shrink: 0;
    }
    .button {
      display: flex;
      align-items: center;
      margin-top: 16px;
    }
    .replay {
      padding: 0 11px;
      height: 33px;
      width: max-width;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #000000;
      border-radius: 50px;
      border: 1px solid #c4bfbf;
      cursor: pointer;
    }
    .replay + .replay {
      margin-left: 18px;
    }
  }
  &-page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    color: #282828;
    margin-top: 50px;
  }
}
::v-deep .el-input__inner {
  height: 40px;
  border-radius: 20px;
  border: 1px solid #dcdfe6;
  //   margin-top: 20px;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #54c752;
}
</style>