<template>
  <commonLayout
    :mt="45"
    :tabList="tabList"
    :defaultTab="currentTab"
    :searhBtnVisible="false"
    @tab="handleTab"
  >
    <template slot="header-right">
      <div
        v-if="currentTab == 2"
        class="messageCenter-button"
        @click="handleAllAttend"
      >
        一键回关
      </div>
    </template>

    <div class="messageCenter">
      <div class="messageCenter-list" v-if="messageList.length">
        <div
          class="messageCenter-item"
          v-for="(item, index) in messageList"
          :key="index"
        >
          <!-- 赞和收藏 -->
          <div class="item" v-if="currentTab == 1">
            <img class="avatar" :src="item.user && item.user.avatar" />
            <div style="text-align: left">
              <div class="name">{{ item.user && item.user.nickName }}</div>
              <div class="tips">
                {{ item.executeType == 1 ? '收藏' : '点赞' }}了你发布的AI作品
              </div>
              <div class="tips">
                {{
                  item.createTime &&
                  dayjs(item.createTime).format('YYYY-MM-DD HH:mm:ss')
                }}
              </div>
            </div>
          </div>

          <!-- 被关注 -->
          <div class="item" v-if="currentTab == 2">
            <img
              class="avatar"
              :src="item.attentionUser && item.attentionUser.avatar"
            />
            <div style="text-align: left">
              <div class="name">
                {{ item.attentionUser && item.attentionUser.nickName }}
              </div>
              <div class="tips">
                关注了你
                {{
                  item.createTime &&
                  dayjs(item.createTime).format('YYYY-MM-DD HH:mm:ss')
                }}
              </div>
            </div>
          </div>

          <!-- 被同款!!!!!待联调确认 -->
          <div class="item" v-if="currentTab == 3">
            <img
              class="avatar"
              :src="item.creatorUser && item.creatorUser.avatar"
            />
            <div style="text-align: left">
              <div class="name">
                {{ item.creatorUser && item.creatorUser.nickName }}
              </div>
              <div class="tips">同款了你的AI创作</div>
              <div class="tips">
                {{
                  item.createTime &&
                  dayjs(item.createTime).format('YYYY-MM-DD HH:mm:ss')
                }}
              </div>
            </div>
          </div>

          <div class="item" v-if="currentTab == 2">
            <div
              v-if="item.isBackTurn == 1"
              class="button active"
              @click="handleAttend(item)"
            >
              已关注
            </div>
            <div v-else class="button" @click="handleAttend(item)">回关</div>
          </div>
          <div class="item" v-else>
            <img
              class="cover"
              :src="
                currentTab == 1 ? item.articleCoverImgUrl : item.imageUrlVisit
              "
            />
          </div>
        </div>
      </div>
      <noData v-else></noData>

      <div class="messageCenter-page">
        <div>共 {{ total || 0 }} 项数据</div>
        <div>
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            @current-change="handlePageChange"
          ></el-pagination>
        </div>
      </div>
    </div>
  </commonLayout>
</template>

<script>
import dayjs from 'dayjs'
import noData from '@/components/noData/index.vue'
import commonLayout from '../components/commonLayout.vue'
import {
  userConcernedList,
  userAttentionList,
  userSamedJobList,
  userAttention,
  cancelUserAttention,
  batchBackAttention
} from '@/api/message/index.js'
export default {
  name: 'messageCenter',
  components: { noData, commonLayout },
  data() {
    return {
      dayjs: dayjs,
      currentTab: 1,
      messageList: [],
      tabList: [
        { title: '赞和收藏', key: 1, api: userConcernedList },
        { title: '被关注', key: 2, api: userAttentionList },
        { title: '被同款', key: 3, api: userSamedJobList }
      ],
      query: {
        page: 1,
        limit: 10
      },
      total: 0
    }
  },
  created() {
    if (this.$route.query.tab) {
      this.currentTab = this.$route.query.tab
    }
    this.handleInit()
  },
  methods: {
    handleTab(key) {
      this.currentTab = key
      this.messageList = []
      this.query.page = 1
      this.total = 0
      this.handleInit()
    },
    handleInit() {
      let myApi = this.tabList.find((item) => item.key == this.currentTab).api
      if (!myApi) return
      let query = JSON.parse(JSON.stringify(this.query))
      if (this.currentTab == 2) {
        query.type = 2
      }
      myApi(query).then((res) => {
        let list = (res && res.rows) || []
        this.messageList = list
        this.total = res.total || 0
        console.log(`**消息中心${this.currentTab}**`, this.messageList)
      })
    },
    handlePageChange(data) {
      this.query.page = data
      this.handleInit()
    },
    handleAttend(item) {
      let uid = item.attentionUserId
      let myApi = item.isBackTurn == 1 ? cancelUserAttention : userAttention
      if (!uid) return
      myApi(uid).then((res) => {
        if (res && res.code == 200) {
          this.$message.success(res.msg)
          item.isBackTurn = item.isBackTurn == 1 ? 0 : 1
        }
      })
    },
    handleAllAttend() {
      batchBackAttention().then((res) => {
        if (res && res.code == 200) {
          this.$message.success(res.msg)
          this.handleTab(this.currentTab)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.messageCenter {
  width: 100%;
  &-list {
    width: 100%;
  }
  &-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d8d8d8;
    .item {
      display: flex;
      align-items: flex-start;
      padding-top: 30px;
      padding-bottom: 15px;
      box-sizing: border-box;
    }
    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      flex-shrink: 0;
      margin-right: 14px;
      background: #d8d8d8;
    }
    .name {
      font-size: 16px;
      font-weight: 500;
      color: #000000;
    }
    .tips {
      font-size: 14px;
      font-weight: normal;
      color: #3d3d3d;
      margin-top: 8px;
    }
    .cover {
      width: 78px;
      height: 78px;
      flex-shrink: 0;
      border-radius: 10px;
    }
    .button {
      width: 73px;
      height: 28px;
      border-radius: 14px;
      background: #0033ff;
      font-size: 16px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .active {
      background: #000000;
    }
  }
  &-page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    color: #282828;
    margin-top: 50px;
  }
  &-button {
    width: 89px;
    height: 33px;
    border-radius: 50px;
    background: #ffffff;
    border: 1px solid #c4bfbf;
    font-size: 14px;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #54c752;
}
</style>