<template>
  <div class="albumCoverInfo">
    <!-- head -->
    <div class="flex_center_between">
      <div class="flex_">
        <div class="inopen" v-if="collectionInfo.isOpen">公开</div>
        <div class="inopen isnoopen" v-else>私有</div>
        <div class="albumCoverInfo-title">{{ collectionInfo.title }}</div>
        <div class="number">{{ collectionInfo.collectionCount || 0 }}</div>
      </div>
      <div class="albumCoverInfo-close" @click="handleBack">
        <i class="el-icon-close" style="font-size: 24px; color: #666666"></i>
      </div>
    </div>
    <!-- button -->
    <albumInfoHead
      @select="handleSelect"
      @delCover="delDialogVisible = true"
      @cover="$refs.albumInfoCover.handleOpen()"
      @search="$refs.albumInfoList.handleInit()"
      @addAlbum="$refs.albumInfoAdd.handleOpen()"
      @delAlbum="$refs.albumInfoList.handleRemove()"
      @loadAlbum="$refs.albumInfoList.handleDownload()"
      @setCover="$refs.albumCoverForm.openAddDialog(collectionId)"
    ></albumInfoHead>
    <!-- list -->
    <albumInfoList
      ref="albumInfoList"
      v-if="collectionId"
      :collectionId="collectionId"
      :collectionName="collectionInfo.title"
    ></albumInfoList>
    <!-- 添加作品 -->
    <albumInfoAdd
      ref="albumInfoAdd"
      :collectionInfo="collectionInfo"
      @fresh="handleFresh"
    ></albumInfoAdd>
    <!-- 新建合集/合集设置 -->
    <albumCoverForm ref="albumCoverForm" @fresh="handleFresh"></albumCoverForm>
    <!-- 封面设置 -->
    <albumInfoCover
      ref="albumInfoCover"
      :coverImg="collectionInfo.coverImg"
      :collectionId="collectionId"
      @fresh="handleFresh"
    ></albumInfoCover>
    <!-- 删除弹窗 -->
    <comfirmDialog
      v-model="delDialogVisible"
      title="删除合集"
      content="该合集内的全部作品将被移出合集，确定删除合集吗？"
      cancelTxt="取消"
      @confirm="handleConfirmDel"
    ></comfirmDialog>
  </div>
</template>

<script>
import albumInfoHead from '../components/albumInfoHead.vue'
import albumInfoList from '../components/albumInfoList.vue'
import albumInfoAdd from '../components/albumInfoAdd.vue'
import albumCoverForm from '../components/albumCoverForm.vue'
import albumInfoCover from '../components/albumInfoCover.vue'
import comfirmDialog from '@/components/comfirmDialog/index'
import { collectionDetails, delCollections } from '@/api/album/index.js'
export default {
  name: 'albumCoverInfo',
  components: {
    albumInfoHead,
    albumInfoList,
    albumInfoAdd,
    albumCoverForm,
    albumInfoCover,
    comfirmDialog
  },
  data() {
    return {
      collectionId: null,
      collectionInfo: {},
      delDialogVisible: false
    }
  },
  mounted() {
    this.collectionId = this.$route.query.id
    this.handleInit()
  },
  methods: {
    handleInit() {
      if (!this.collectionId) return
      collectionDetails(this.collectionId).then((res) => {
        this.collectionInfo = (res && res.data) || {}
        console.log('详情', this.collectionInfo)
      })
    },

    handleBack() {
      this.$router.back()
    },

    handleSelect(status) {
      this.$nextTick(() => {
        this.$refs.albumInfoList.handleSelect(status)
      })
    },

    handleFresh() {
      this.handleInit()
      this.$nextTick(() => {
        this.$refs.albumInfoList.handleInit()
      })
    },

    handleConfirmDel() {
      delCollections([this.collectionId]).then((res) => {
        if (res.code != 200) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.delDialogVisible = false
        this.handleBack()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.albumCoverInfo {
  width: 1402px;
  margin: 0 auto;
  &-title {
    font-size: 24px;
    font-weight: bold;
    color: #3d3d3d;
    margin-right: 25px;
  }
  &-close {
    width: 44px;
    height: 44px;
    border-radius: 10px;
    border: 1px solid #e1e8e8;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .inopen {
    width: 40px;
    height: 21px;
    border-radius: 6px;
    background: #d000ff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #ffffff;
    margin-right: 11px;
  }
  .isnoopen {
    background: #000000;
  }
  .number {
    min-width: 22px;
    min-height: 22px;
    padding: 2px;
    box-sizing: border-box;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.62);
    font-size: 12px;
    color: #ffffff;
  }
}
.flex_ {
  display: flex;
  align-items: center;
}
.flex_center_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>